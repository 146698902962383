import React, { useContext, useEffect, useRef, useState } from "react";
import "../../Styles/Landing.css";
import "../../Styles/Global.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, IconButton, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ResetPasswordModal from "../../Components/Login/ResetPasswordModal";
import { authUserContext } from "../../Components/Contexts/AuthUser";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    fontFamily: "rajdhani-medium",
    border: "3px solid #25A596",
    borderRadius: "4vw",
    maxWidth: "50vw",
    width: "45vw",
    padding: "1rem",
    position: "absolute",
    top: "18vh",
    backgroundColor: "rgba(255,255,255,0.85)",
  },
  "& #customized-dialog-title": {
    display: "flex",
    justifyContent: "center",
    fontFamily: "rajdhani-medium",
    fontSize: "5vw",
    margin: 0,
    padding: 0,
    lineHeight: "5vw",
    fontWeight: "normal",
    marginTop: "1vw",
  },
  "& .MuiPaper-root .MuiTypography-gutterBottom": {
    textAlign: "center",
    fontFamily: "rajdhani-medium",
    fontSize: "2vw",
    marginTop: 0,
    marginBottom: "1rem",
  },
  "& .MuiDialogActions-root": {
    justifyContent: "center",
    padding: 0,
    margin: 0,
  },
  "& .MuiDialogActions-root Button": {
    fontFamily: "rajdhani-medium",
    color: "black",
    backgroundColor: "#FFB017",
    border: "none",
    borderRadius: "10px",
    padding: "1px 20px",
    fontSize: "1.5vw",
    transition: "0.5s",
    textTransform: "none",
    margin: 0,
    width: "5vw",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      transform: "scale(1.05)",
      transition: "0.5s",
    },
  },
  "& #change-password-action-buttons": {
    display: "flex",
    justifyContent: "space-between",
    width: "13vw",
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#343a40",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function LoginPage() {
  document.title = "CSSoch";
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [popup, setPopup] = useState(false);
  const [dialogContent, setDialogContent] = useState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const inputRef = useRef(null);
  const { user, setUser } = useContext(authUserContext);
  const navigate = useNavigate();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (user && user.is_authenticated) {
      navigate("/");
    }
  }, [user]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePopupOpen = () => {
    setDialogContent(
      <>
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handlePopupClose}
        ></BootstrapDialogTitle>
        <ResetPasswordModal emailSent={emailSent} setEmailSent={setEmailSent} />
      </>
    );
    setPopup(true);
  };
  const handlePopupClose = () => {
    setPopup(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/landing`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.user.is_authenticated) {
          setUser(data.user);
        } else {
          setErrorMsg(data.error);
        }
      });
  };

  return (
    <div id='login-page'>
      <img id='surrounding' src='/static/svg/Surrounding.svg' alt='' />
      <a id='logo' href='/'>
        <img
          src='/static/svg/Logo.svg'
          alt='Logo'
          width='182'
          height='100'
          className='logo-image'
        />
      </a>
      <div className='modal-content'>
        <div className='modal-body-login'>
          <div className='row'>
            <div className='image-container col'>
              <img
                src='/static/svg/LoginCheeti.png'
                alt='A happy caterpillar telling you to keep your password secure'
              />
            </div>
            <div className='form-container col'>
              <h2 id='login-text'>Login</h2>
              <form onSubmit={handleSubmit} className='login-form'>
                <input
                  id='username'
                  type='text'
                  placeholder='Username'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  name='username'
                  autocomplete='off'
                  spellcheck='false'
                  required
                  ref={inputRef}
                />
                <div
                  className='d-flex align-items-center password-wrapper'
                  style={{ width: "100%" }}
                >
                  <input
                    id='password'
                    placeholder='Password'
                    name='password'
                    value={password}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <IconButton
                    aria-label='togglepasswordvisibility'
                    onClick={handleClickShowPassword}
                    id='password-toggle-icon-button'
                  >
                    {showPassword ? (
                      <VisibilityIcon id='visibility-icon' />
                    ) : (
                      <VisibilityOffIcon id='visibilityoff-icon' />
                    )}
                  </IconButton>
                </div>

                {errorMsg ? (
                  <p className='error' id='loginError'>
                    {errorMsg.toUpperCase()}
                  </p>
                ) : (
                  <></>
                )}
                <a
                  id='password-reset-button'
                  className='text-center'
                  href='#'
                  onClick={handlePopupOpen}
                >
                  Forgot Password
                </a>
                <button className='submit-button' type='submit'>
                  Let's Go!
                </button>
              </form>
              <a
                className='playstore-link-container'
                href='https://play.google.com/store/apps/details?id=com.cssoch'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='/static/svg/playstore-icon.png'
                  alt='Play store logo'
                />
                <div className='playstore-link-label'>
                  <span>Android App on</span>
                  <span>Google Play</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <BootstrapDialog
        onClose={handlePopupClose}
        aria-labelledby='customized-dialog-title'
        open={popup}
        className='reset-password-dialog'
      >
        {dialogContent}
      </BootstrapDialog>
    </div>
  );
}

export default LoginPage;
