import { useEffect, useRef, useState } from "react";
import GoodJobFeedbackBox from "../FeedbackBox/GoodJobFeedbackBox";
import BadJobFeedbackBox from "../FeedbackBox/BadJobFeedbackBox";
import PuzzleView from "./PuzzleView";
import LevelButtons from "./LevelButtons";

export default function Puzzle(props) {
  const [isLoadig, setIsLoading] = useState(true);
  const [data, setData] = useState({
    levels: props.strapiData.Puzzle,
    numLevels: props.strapiData.Puzzle.length,
  });
  const [currentLevel, setCurrentLevel] = useState(1);
  const [previousLevel, setPreviousLevel] = useState(1);
  const [resourcesList, setResourecesList] = useState([]);
  const [answerChecked, setAnswerChecked] = useState(false);
  const [currentlyClicked, setCurrentlyClicked] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [started, setStarted] = useState(false);
  const [levelData, setLevelData] = useState();
  const [showPuzzleExplanation, setShowPuzzleExplantion] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [disabledSubmitButton, setDisableSubmitButton] = useState(true);
  const [showBadJobFeedback, setShowBadJobFeedback] = useState(false);
  const [showGoodJobFeedback, setShowGoodJobFeedback] = useState(false);
  const [showFeedback, setShowFeedback] = useState(true);
  const [showSolutionButton, setShowSolutionButton] = useState(true);
  const [isRendredOnce, setIsRenderedOnce] = useState(false);
  const [itemName, setItemName] = useState("puzzle");
  const explanationRef = useRef(null);
  useEffect(() => {
    async function initPuzzle() {
      if (props.currentSection === "puzzle") {
        props.teacher && !props.teacherView
          ? (document.title = "CSSoch | Review Puzzle")
          : (document.title = "CSSoch | Puzzle");
        if (!isRendredOnce) {
          await setupPuzzleStatistics();
          loadResources(data);
        }
      }
      setShowBadJobFeedback(false);
      setShowGoodJobFeedback(false);
    }
    initPuzzle();
  }, [props.currentSection]);
  useEffect(() => {
    if (!isLoadig) {
      updateLevelColors();
      loadLevel(currentLevel);
      setIsRenderedOnce(true);
    }
  }, [isLoadig]);

  useEffect(() => {
    if (showPuzzleExplanation)
      explanationRef.current.scrollIntoView({ behavior: "smooth" });
  }, [showPuzzleExplanation]);

  async function setupPuzzleStatistics() {
    if (!props.statusStorage.puzzle_levels.length) {
      const puzzleLevels = [];
      for (let i = 0; i < data.numLevels; i++) {
        puzzleLevels.push({
          feedback: "unavailable",
          option: "unavailable",
          result: "unavailable",
          status: "incomplete",
        });
      }
      await props.updateStatusStorage(
        { puzzle_levels: puzzleLevels },
        itemName
      );
    }
  }
  async function loadResources(loadedData) {
    const resources = [];
    const { numLevels } = data;
    const levelsDone = [];
    for (let i = 0; i < numLevels; i++) levelsDone.push(false);
    for (let i = 0; i < numLevels; i++) {
      let Options;
      const optionValues = [];
      Options = loadedData.levels[i].Options;
      if (!Options) {
        Options = [];
      }
      optionValues.push(loadedData.levels[i]["Question Image"]);
      for (const item of Object.values(Options)) {
        if (item.includes("https://")) {
          optionValues.push(item);
        } else {
          optionValues.push(item);
        }
      }
      // adding option array to resourceList
      resources.push(optionValues);
      setResourecesList(resources);
      levelsDone[i] = true;
      for (let i = 0; i < levelsDone.length; i++) {
        if (!levelsDone[i]) break;
        if (i === levelsDone.length - 1) {
          setLoaded(true);
          await initialize();
        }
      }
    }
  }
  async function initialize() {
    let currentLevelTemp = currentLevel;
    for (let i = 0; i < data.numLevels; i++) {
      if (props.statusStorage.puzzle_levels[i]?.status === "ongoing") {
        currentLevelTemp = i + 1;
        setCurrentLevel(i + 1);
        setPreviousLevel(i + 1);
      }
    }
    const puzzleLevels = JSON.parse(
      JSON.stringify(props.statusStorage.puzzle_levels)
    );
    if (currentLevelTemp === 1 && puzzleLevels[0]?.status !== "ongoing") {
      for (let i = 0; i < puzzleLevels.length; i++) {
        if (puzzleLevels[i]?.status === "incomplete") {
          puzzleLevels[i].status = "ongoing";
          await props.updateStatusStorage({ puzzle_levels: puzzleLevels },
            itemName);
          setCurrentLevel(i + 1);
          setPreviousLevel(i + 1);
          break;
        }
      }
    }
    setIsLoading(false);
  }
  async function updateLevelColors(
    statuses = JSON.parse(JSON.stringify(props.statusStorage)),
    level=currentLevel
  ) {
    let allComplete = true;
    for (let i = 0; i < statuses?.puzzle_levels.length; i++) {
      if((level !== i + 1) && (statuses?.puzzle_levels[i]?.status === "ongoing")) statuses.puzzle_levels[i].status = "incomplete";
      if (statuses?.puzzle_levels[i]?.status !== "completed")
        allComplete = false;
    }
    if (allComplete && statuses.puzzle_status !== "completed") {
      statuses["puzzle_status"] = "completed";
      await props.updateStatusStorage(statuses, itemName);
    } else if (statuses.puzzle_status !== "completed") {
      statuses["puzzle_status"] = "ongoing";
      await props.updateStatusStorage(statuses, itemName);
    }
  }
  async function loadLevel(level) {
    setShowBadJobFeedback(false);
    setShowGoodJobFeedback(false);
    setShowSolutionButton(true);
    if (props.statusStorage.puzzle_levels[level - 1].feedback === "unavailable")
      setShowFeedback(true);
    else setShowFeedback(false);
    setShowSubmitButton(true);
    setShowPuzzleExplantion(false);
    setAnswerChecked(false);
    setStarted(true);
    if (previousLevel === level && started) return;
    setCurrentlyClicked(-1);
    setDisableSubmitButton(true);
    const statuses = JSON.parse(JSON.stringify(props.statusStorage));
    let statusUpdated = false;
    if (
      statuses?.puzzle_status !== "completed" &&
      statuses.puzzle_levels[level - 1]?.status !== "ongoing"
    ) {
      for (let i = 0; i < statuses.puzzle_levels.length; i++) {
        if (statuses.puzzle_levels[i]?.status !== "completed") {
          statuses.puzzle_levels[i].status = "incomplete";
        }
      }
      if (statuses.puzzle_levels[level - 1]?.status !== "completed") {
        statuses.puzzle_levels[level - 1].status = "ongoing";
      }
      statusUpdated = await props.updateStatusStorage({
        puzzle_levels: statuses.puzzle_levels,
      },
      itemName);
    }
    if (!props.teacher || (props.teacher && props.teacherView)) {
      if (statusUpdated) {
        await updateLevelColors(statuses,level);
      } else await updateLevelColors(statuses,level);
    }
    setPreviousLevel(level);
    setLevelData(data.levels[level - 1]);
    if (statuses.puzzle_levels[level - 1]?.status === "completed") {
      displayAnswers(
        statuses.puzzle_levels[level - 1].option,
        false,
        false,
        level,
        statuses.puzzle_levels
      );
    }
  }
  async function displayAnswers(
    clicked,
    immediate,
    answerCheckedTemp = answerChecked,
    currentLevelTemp = currentLevel,
    puzzleLevels = JSON.parse(JSON.stringify(props.statusStorage.puzzle_levels))
  ) {
    let update = false;
    if (!loaded) return;
    if (answerCheckedTemp) return;
    let statuses = JSON.parse(JSON.stringify(props.statusStorage));
    statuses = {...statuses, ...{ puzzle_levels: puzzleLevels }};
    if (statuses.puzzle_levels[currentLevelTemp - 1]?.status !== "completed") {
      statuses.exercise_puzzle_levels_completed++;
      statuses.total_puzzle_levels_completed++;
      statuses.puzzle_levels[currentLevelTemp - 1].status = "completed";
      update = true;
    }
    const letters = ["A", "B", "C", "D"];
    let result = null;
    if (immediate) {
      if (data.levels[currentLevelTemp - 1].correct === letters[clicked - 1]) {
        statuses.puzzle_levels[currentLevelTemp - 1].result = 1;
        result = 1;
      } else {
        statuses.puzzle_levels[currentLevelTemp - 1].result = 0;
        result = 0;
      }
      statuses.puzzle_levels[currentLevelTemp - 1].option = clicked;
      update = true;
    }
    if (update) {
      let statusUpdate = await props.updateStatusStorage(statuses,
        itemName);
      if (!statusUpdate) return;
      setShowSubmitButton(false);
      setShowSolutionButton(false);
    }
    function scrollToBottom() {
      // Get the height of the entire document
      const docHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      // Scroll to the bottom of the page
      window.scrollTo(0, docHeight);
    }
    if (immediate) scrollToBottom();
    setShowPuzzleExplantion(true);
    setAnswerChecked(true);
    if (result === null) {
      if (props.teacherView && update) updateLevelColors(statuses);
      return;
    }
    if(update) updateLevelColors(statuses);
    if (result === 1) setShowGoodJobFeedback(true);
    else setShowBadJobFeedback(true);
  }
  function submitAnswer() {
    if (props.teacher && !props.teacherView) return;
    if (!loaded) return;
    let level = currentlyClicked;
    if (level > 3) level -= 4;
    level += 1;
    displayAnswers(level, true);
  }
  async function handleItemLikedFeedback() {
    const puzzleLevels = JSON.parse(JSON.stringify(props.statusStorage.puzzle_levels));
    puzzleLevels[currentLevel - 1].feedback = 1;
    let statusUpdated = await props.updateStatusStorage({
      puzzle_levels: puzzleLevels,
    }, itemName);
    if (!statusUpdated) return;
    setShowFeedback(false);
  }
  async function handleItemDislikedFeedback() {
    const puzzleLevels = JSON.parse(JSON.stringify(props.statusStorage.puzzle_levels));
    puzzleLevels[currentLevel - 1].feedback = 0;
    let statusUpdated = await props.updateStatusStorage({
      puzzle_levels: puzzleLevels,
    }, itemName);
    if (!statusUpdated) return;
    setShowFeedback(false);
  }
  function onFeedbackNextBtnClick() {
    setShowBadJobFeedback(false);
    setShowGoodJobFeedback(false);
    if (currentLevel === data.numLevels) return;
    setCurrentLevel(currentLevel + 1);
    loadLevel(currentLevel + 1);
  }
  return (
    <>
      {!isLoadig ? (
        <div
          id="puzzle-container"
          className="content-tab d-hidden"
          style={props.currentSection === "puzzle" ? {} : { display: "none" }}
        >
          <LevelButtons
            puzzle={props.strapiData?.Puzzle}
            currentLevel={currentLevel}
            setCurrentLevel={setCurrentLevel}
            loadLevel={loadLevel}
            statusStorage={props.statusStorage}
          />
          {resourcesList[currentLevel - 1][1] === null ? (
            <div id="puzzleunderConstruction">
              <h1>Page Under Construction...</h1>
            </div>
          ) : (
            <PuzzleView
              puzzleStrapiData={data.levels[currentLevel - 1]}
              puzzleData={props.statusStorage.puzzle_levels[currentLevel - 1]}
              submitAnswer={submitAnswer}
              isTeachModule={props.teacherView}
              puzzle_type={"exercise"}
              displaySolution={() => {
                displayAnswers(data.levels[currentLevel - 1].correct, false);
              }}
              answerChecked={answerChecked}
              explanationRef={explanationRef}
              currentlyClicked={currentlyClicked}
              setCurrentlyClicked={setCurrentlyClicked}
              disabledSubmitButton={disabledSubmitButton}
              setDisableSubmitButton={setDisableSubmitButton}
              showPuzzleExplanation={showPuzzleExplanation}
              module_type={"module"}
            />
          )}
          {showBadJobFeedback && (
            <BadJobFeedbackBox
              visibility={showBadJobFeedback}
              setVisibility={setShowBadJobFeedback}
              showFeedback={showFeedback}
              handleItemLikedFeedback={handleItemLikedFeedback}
              handleItemDislikedFeedback={handleItemDislikedFeedback}
              currentItem={"puzzle"}
              handleCurrentSectionChange={props.handleCurrentSectionChange}
              getNext={props.getNext}
              goToNext={props.goToNext}
              Finish={currentLevel === data.numLevels}
              nextBtnOnClickhandler={onFeedbackNextBtnClick}
            />
          )}
          {showGoodJobFeedback && (
            <GoodJobFeedbackBox
              visibility={showGoodJobFeedback}
              setVisibility={setShowGoodJobFeedback}
              showFeedback={showFeedback}
              handleItemLikedFeedback={handleItemLikedFeedback}
              handleItemDislikedFeedback={handleItemDislikedFeedback}
              currentItem={"puzzle"}
              handleCurrentSectionChange={props.handleCurrentSectionChange}
              getNext={props.getNext}
              goToNext={props.goToNext}
              Finish={currentLevel === data.numLevels}
              nextBtnOnClickhandler={onFeedbackNextBtnClick}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
