import React, { useState } from 'react';
import Chart from 'react-apexcharts';

export default function SkillPerformance(props) {
  const { seriesData } = props;
  const [state, setState] = useState({
    series: seriesData,
    options: {
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 0.8,
          },
        },
      },
      colors: ['#FF9A9B', '#FF755C', '#E0325C'],
      chart: {
        type: 'bar',
        height: 150,
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false,
        },
        parentHeightOffset: 0,
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
        },
      },
      xaxis: {
        show: false,
        categories: [''],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 0,
        curve: 'smooth',
        colors: ['#fff'],
      },
      tooltip: {
        y: {
          formatter(val) {
            return val;
          },
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'right',
      },
      dataLabels: {
        enabled: true,
        style: {
          fontFamily: 'rajdhani-medium',
          fontSize: '20px',
          fontWeight: 'normal',
        },
      },
    },
  });
  return (
    <Chart options={state.options} series={state.series} type="bar" height={100} />
  );
}
