import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const requiredFieldText = 'This is required field';

const schema = yup.object({
  schoolName: yup.string().required(requiredFieldText),
  Address: yup.string(),
  City: yup.string(),
  State: yup.string(),
  Pincode: yup.lazy((value) => {
    if (value) {
      return yup.string().nullable().length(6);
    }
    return yup.string().nullable().notRequired();
  }),
  contactPersonName: yup.string().required(requiredFieldText).max(50, 'Name should not be more than 50 character'),
  contactPersonPhone: yup.string().required(requiredFieldText).matches(/^[0-9]*$/, 'Phone must contain only number').length(10, 'Phone should be 10 digits.'),
  contactPersonEmail: yup.string().required(requiredFieldText).email('Email must be valid Email'),
  principalName: yup.string(),
  principalPhone: yup.lazy((value) => {
    if (value) {
      return yup.string().nullable().matches(/^[0-9]*$/, 'Phone must contain only number');
    }
    return yup.string().nullable().notRequired();
  }),
  principalEmail: yup.string().email('Email must be valid Email'),
  adminName: yup.string(),
  adminPhone: yup.lazy((value) => {
    if (value) {
      return yup.string().nullable().matches(/^[0-9]*$/, 'Phone must contain only number');
    }
    return yup.string().nullable().notRequired();
  }),
  adminEmail: yup.string().email('Email must be valid Email'),
  cssochItPocName: yup.string(),
  cssochItPocPhone: yup.lazy((value) => {
    if (value) {
      return yup.string().nullable().matches(/^[0-9]*$/, 'Phone must contain only number').length(10);
    }
    return yup.string().nullable().notRequired();
  }),
  cssochItPocEmail: yup.string().email('Email must be valid Email'),
  'Payment Schedule': yup.lazy((value) => {
    if (value) {
      return yup.string().matches(/^[A-Za-z0-9\s]*$/, 'Payment Schedule must contain only Number or Alphabets');
    }
    return yup.mixed().notRequired();
  }),
}).required();

const useStyles = makeStyles({
  field: {
    marginTop: '5px !important',
    marginBottom: '20px !important',
    display: 'block !important',
  },
});

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export default function EditSchoolDetails() {
  document.title = "CSSoch | Edit School";
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const classes = useStyles();
  const [fetchData, setFetchData] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [noEdit, setNoEdit] = useState(false);
  const [schoolDetails, setSchoolDetails] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isSuperadmin, setIsSuperadmin] = useState(location.state?.user?.role.includes('superadmin'));
  const [schoolName, setSchoolName] = useState();
  const [schoolNameError, setschoolNameError] = useState(false);
  const [schoolCode, setSchoolCode] = useState();
  const [schoolCodeError, setSchoolCodeError] = useState(false);
  const [status, setStatus] = useState('Active');
  const [inactiveDate,setInactiveDate]=useState('')
  const [expiryDate, setExpiryDate] = useState();
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPinCode] = useState('');
  const [contactPersonName, setContactPersonName] = useState('');
  const [contactPersonEmail, setContactPersonEmail] = useState('');
  const [contactPersonPhone, setContactPersonPhone] = useState('');
  const [principalName, setPrincipalName] = useState('');
  const [principalEmail, setPrincipalEmail] = useState('');
  const [principalPhone, setPrincipalPhone] = useState('');
  const [adminName, setAdminName] = useState();
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPhone, setAdminPhone] = useState('');
  const [cssochITPOCName, setCssochITPOCName] = useState('');
  const [cssochITPOCEmail, setCssochITPOCEmail] = useState('');
  const [cssochITPOCPhone, setCssochITPOCPhone] = useState('');
  const [paymentSchedule, setPaymentSchedule] = useState('');
  const [paymentScheduleOther, setPaymentScheduleOther] = useState('');
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/school/details/${isSuperadmin ? location.state.id : location.state.schoolcode}`) 
      .then((res) => res.json())
      .then((data) => {
        schoolDetailsSet(data);
        setisLoading(true);
      });
  }, [fetchData]);
  const schoolDetailsSet = (data) => {
    setSchoolDetails(data.schoolDetails);
    setSchoolName(data.schoolDetails.school_name);
    setSchoolCode(data.schoolDetails.school_code);
    setAddress(data.schoolDetails.address);
    setCity(data.schoolDetails.city);
    setState(data.schoolDetails.state);
    setStatus(data.schoolDetails.status);
    if(data.schoolDetails.status === 'Inactive' || data.schoolDetails.status === 'inactive')
      setInactiveDate(data.schoolDetails?.inactivate_date)
    setExpiryDate(new Date(data.schoolDetails.trial_expiry_date));
    setPinCode(data.schoolDetails.pincode);
    setContactPersonName(data.schoolDetails.contact_person_name);
    setContactPersonEmail(data.schoolDetails.contact_person_email);
    setContactPersonPhone(data.schoolDetails.contact_person_phone);
    setPrincipalName(data.schoolDetails.principal_name);
    setPrincipalEmail(data.schoolDetails.principal_email);
    setPrincipalPhone(data.schoolDetails.principal_phone);
    setAdminName(data.schoolDetails.admin_person_name);
    setAdminEmail(data.schoolDetails.admin_person_email);
    setAdminPhone(data.schoolDetails.admin_person_phone);
    setCssochITPOCName(data.schoolDetails.cssoch_IT_person_name);
    setCssochITPOCPhone(data.schoolDetails.cssoch_IT_person_phone);
    setCssochITPOCEmail(data.schoolDetails.cssoch_IT_person_email);
    setPaymentSchedule(data.schoolDetails.payment_schedule);
    if (['Monthly', 'Quaterly', 'Yearly'].includes(data.schoolDetails.payment_schedule)) {
      setPaymentSchedule(data.schoolDetails.payment_schedule);
    } else {
      setPaymentSchedule('Other');
      setPaymentScheduleOther(data.schoolDetails.payment_schedule);
    }
  };

  const handleCloseSnackBar = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const updateSchoolDetails = (details) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(details),
    };
    const schoolDe = details.schoolDetails;
    if (Object.keys(schoolDe).length) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/school/details/update/${schoolCode}`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setOpen(true);
          setFetchData(!fetchData);
          setTimeout(() => {
            navigate('/admin', { state: { user: location.state?.user } });
          }, 1500);
        });
    } else {
      setOpen(true);
      setNoEdit(true);
    }
  };
  const onSubmit = (inputData) => {
    setNoEdit(false);
    const updateDetails = {};
    let editDetails = {};
    if (schoolDetails.school_name !== inputData.schoolName) {
      editDetails.school_name = inputData.schoolName;
    }
    if (schoolDetails.address !== inputData.Address) {
      editDetails.address = inputData.Address;
    }
    if (schoolDetails.city !== inputData.City) {
      editDetails.city = inputData.City;
    }
    if (schoolDetails.state !== inputData.State) {
      editDetails.state = inputData.State;
    }
    if(isSuperadmin){
      if (schoolDetails.status !== status) {
        editDetails.status = status;
      }
      if(status === 'Inactive'){
        editDetails.inactivate_date = new Date();
      }
      if (schoolDetails.trial_expiry_date !== expiryDate) {
        editDetails.trial_expiry_date = expiryDate;
      }
    }
    if (schoolDetails.pincode !== inputData.Pincode) {
      editDetails.pincode = inputData.Pincode;
    }
    if (schoolDetails.contact_person_name !== inputData.contactPersonName) {
      editDetails.contact_person_name = inputData.contactPersonName;
    }
    if (schoolDetails.contact_person_email !== inputData.contactPersonEmail) {
      editDetails.contact_person_email = inputData.contactPersonEmail;
    }
    if (schoolDetails.contact_person_phone !== inputData.contactPersonPhone) {
      editDetails.contact_person_phone = inputData.contactPersonPhone;
    }
    if (schoolDetails.principal_name !== inputData.principalName) {
      editDetails.principal_name = inputData.principalName;
    }
    if (schoolDetails.principal_email !== inputData.principalEmail) {
      editDetails.principal_email = inputData.principalEmail;
    }
    if (schoolDetails.principal_phone !== inputData.principalPhone) {
      editDetails.principal_phone = inputData.principalPhone;
    }
    if (schoolDetails.admin_person_name !== inputData.adminName) {
      editDetails.admin_person_name = inputData.adminName;
    }
    if (schoolDetails.admin_person_email !== inputData.adminEmail) {
      editDetails.admin_person_email = inputData.adminEmail;
    }
    if (schoolDetails.admin_person_phone !== inputData.adminPhone) {
      editDetails.admin_person_phone = inputData.adminPhone;
    }
    if (schoolDetails.cssoch_IT_person_name !== inputData.cssochItPocName) {
      editDetails.cssoch_IT_person_name = inputData.cssochItPocName;
    }
    if (schoolDetails.cssoch_IT_person_email !== inputData.cssochItPocEmail) {
      editDetails.cssoch_IT_person_email = inputData.cssochItPocEmail;
    }
    if (schoolDetails.cssoch_IT_person_phone !== inputData.cssochItPocPhone) {
      editDetails.cssoch_IT_person_phone = inputData.cssochItPocPhone;
    }
    if (schoolDetails.payment_schedule !== paymentSchedule) {
      if (paymentSchedule === 'Other') {
        editDetails.payment_schedule = inputData['Payment Schedule'];
      } else {
        editDetails.payment_schedule = paymentSchedule;
      }
    }
    updateDetails.schoolDetails = editDetails;
    editDetails = {};
    updateSchoolDetails(updateDetails);
  };
  return (
    <div style={{ backgroundColor: '#DDE9F5' }}>
      {isLoading
        ? (
          <Container>
            <Typography
              variant="h6"
              component="h2"
              style={{ padding: 10 }}
            >
              Edit School Details
            </Typography>
            <div style={{ backgroundColor: 'white', padding: 20, borderRadius: 6 }}>
              {noEdit
                ? (
                  <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackBar}
                    sx={{ top : '10vh !important'}}
                  >
                    <Alert
                        onClose={handleCloseSnackBar}
                        severity="info"
                        sx={{ width: '100%' }}
                      >
                          Form not edited
                      </Alert>
                  </Snackbar>
                )

                : (
                  <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackBar}
                    sx={{ top : '10vh !important'}}
                  >
                    <Alert
                        onClose={handleCloseSnackBar}
                        severity="success"
                        sx={{ width: '100%' }}
                      >
                          School details updated successfully
                      </Alert>
                  </Snackbar>
                )}
              <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <FormControl fullWidth>
                        <FormLabel>School Name*</FormLabel>
                        <TextField
                                className={classes.field}
                                onChange={(e) => setSchoolName(e.target.value)}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={schoolName}
                                inputProps={{ style: { height: 10 } }}
                                {...register('schoolName')}
                              />
                        {errors.schoolName && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.schoolName.message}</span>}
                      </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel className={classes.label}>School Code*</FormLabel>
                        <TextField
                                disabled
                                style={{ width: '100%' }}
                                onChange={(e) => setSchoolCode(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolCodeError}
                                defaultValue={schoolCode}
                                inputProps={{ style: { height: 10 }, readOnly: true }}
                              />
                      </FormControl>
                  </Grid>
                </Grid>
                {isSuperadmin &&
                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                      <FormLabel>
                        Expiry date*
                      </FormLabel>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          openTo="day"
                          views={["year", "month", "day"]}
                          inputFormat="dd/MM/yyyy"
                          value={expiryDate}
                          minDate={new Date()}
                          onChange={(e) => (new Date(e) < new Date()) ? setExpiryDate(new Date()) : setExpiryDate(new Date(e))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item >
                    <FormControl fullWidth>
                      <FormLabel>Status*</FormLabel>
                      <RadioGroup row value={status} onChange={(e) =>setStatus(e.target.value)}>
                        <Stack spacing={2} direction="row">
                          <FormControlLabel value="Active" control={<Radio />} label="Active" />
                          <FormControlLabel value="Inactive" control={<Radio />} label="In-active" />
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                }
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <FormControl fullWidth>
                        <FormLabel>Address</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setAddress(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={address}
                                inputProps={{ style: { height: 10 } }}
                                {...register('Address')}
                              />
                        {errors.Address && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.Address?.message}</span>}
                      </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>City*</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setCity(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={city}
                                inputProps={{ style: { height: 10 } }}
                                {...register('City')}
                              />
                        {errors.City && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.City?.message}</span>}
                      </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>State</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setState(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={state}
                                inputProps={{ style: { height: 10 } }}
                                {...register('State')}
                              />
                        {errors.State && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.State?.message}</span>}
                      </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>PIN Code</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setPinCode(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={pincode}
                                inputProps={{ style: { height: 10 } }}
                                {...register('Pincode')}
                              />
                        {errors.Pincode && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.Pincode?.message}</span>}
                      </FormControl>
                  </Grid>
                </Grid>
                <Typography variant="h6" style={{ color: '#00868D', marginBottom: 10 }}>Contact Details</Typography>
                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>Contact person Name*</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setContactPersonName(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={contactPersonName}
                                inputProps={{ style: { height: 10 } }}
                                {...register('contactPersonName')}
                              />
                        {errors.contactPersonName && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.contactPersonName.message}</span>}
                      </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>Contact person Phone*</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setContactPersonPhone(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={contactPersonPhone}
                                inputProps={{ style: { height: 10 } }}
                                {...register('contactPersonPhone')}
                              />
                        {errors.contactPersonPhone && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.contactPersonPhone.message}</span>}
                      </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>Contact person Email*</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setContactPersonEmail(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={contactPersonEmail}
                                inputProps={{ style: { height: 10 } }}
                                {...register('contactPersonEmail')}
                              />
                        {errors.contactPersonEmail && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.contactPersonEmail.message}</span>}
                      </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>Principal Name</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setPrincipalName(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={principalName}
                                inputProps={{ style: { height: 10 } }}
                                {...register('principalName')}
                              />
                        {errors.principalName && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.principalName.message}</span>}
                      </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>Principal Phone</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setPrincipalPhone(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={principalPhone}
                                inputProps={{ style: { height: 10 } }}
                                {...register('principalPhone')}
                              />
                        {errors.principalPhone && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.principalPhone.message}</span>}
                      </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>Principal Email</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setPrincipalEmail(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={principalEmail}
                                inputProps={{ style: { height: 10 } }}
                                {...register('principalEmail')}
                              />
                        {errors.principalEmail && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors['Principal Email'].message}</span>}
                      </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>Admin Name</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setAdminName(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={adminName}
                                inputProps={{ style: { height: 10 } }}
                                {...register('adminName')}
                              />
                        {errors.adminName && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.adminName.message}</span>}
                      </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>Admin Phone</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setAdminPhone(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={adminPhone}
                                inputProps={{ style: { height: 10 } }}
                                {...register('adminPhone')}
                              />
                        {errors.adminPhone && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.adminPhone.message}</span>}
                      </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>Admin Email</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setAdminEmail(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={adminEmail}
                                inputProps={{ style: { height: 10 } }}
                                {...register('adminEmail')}
                              />
                        {errors.adminEmail && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.adminEmail.message}</span>}
                      </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>CSSoch IT POC Name</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setCssochITPOCName(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={cssochITPOCName}
                                inputProps={{ style: { height: 10 } }}
                                {...register('cssochItPocName')}
                              />
                        {errors.cssochItPocName && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.cssochItPocName.message}</span>}
                      </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>CSSoch IT POC Phone</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setCssochITPOCPhone(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={cssochITPOCPhone}
                                inputProps={{ style: { height: 10 } }}
                                {...register('cssochItPocPhone')}
                              />
                        {errors.cssochItPocPhone && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.cssochItPocPhone.message}</span>}
                      </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                        <FormLabel>CSSoch IT POC Email</FormLabel>
                        <TextField
                                style={{ width: '100%' }}
                                onChange={(e) => setCssochITPOCEmail(e.target.value)}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                error={schoolNameError}
                                defaultValue={cssochITPOCEmail}
                                inputProps={{ style: { height: 10 } }}
                                {...register('cssochItPocEmail')}
                              />
                        {errors.cssochItPocEmail && <span style={{ marginTop: -15, marginBottom: 10, color: 'red' }}>{errors.cssochItPocEmail.message}</span>}
                      </FormControl>
                  </Grid>
                </Grid>
                <Stack spacing={2} direction="row" style={{ justifyContent: 'center' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: '#00868D' }}
                  >
                              Save
                  </Button>

                  <Button
                    onClick={() => navigate(isSuperadmin ? '/admin' : '/school/details',{ state: { ...location.state } })}
                    variant="text"
                    style={{ color: 'black' }}
                  >
                              Cancel
                  </Button>
                </Stack>
              </form>
            </div>
          </Container>
        )
        : (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
    </div>
  );
}
