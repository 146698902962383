import React, { createContext, useState } from 'react';

const filterContext = createContext();

function SetFilter({ children }) {
  const [studentFilter, setStudentFilter] = useState({
    items: [],
  });
  const [teacherFilter, setTeacherFilter] = useState({
    items: [],
  });

  function updateFilter(value, type) {
    if (type === 'teacher') {
      setTeacherFilter(value);
    } else if (type === 'student') {
      setStudentFilter(value);
    }
  }
  return (
    <filterContext.Provider
      value={{ studentFilter, teacherFilter, updateFilter }}
    >
      {children}
    </filterContext.Provider>
  );
}

export { SetFilter, filterContext };
