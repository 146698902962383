import BarChartIcon from '@mui/icons-material/BarChart';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useContext, useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import useFileDownloader from '../../../hooks/useFileDownloader';
import '../../../index.css';
import '../../../Styles/AdminDashboard.css'
import { downloadReportContext } from '../../Contexts/downloadReport';
import { schoolNameContext } from '../../Contexts/schoolName';
import Modules from '../../../Pages/CurriculumMap/Module';
import { PrimaryNavBarContext } from '../Layout';

const drawerWidth = 240;



function ResponsiveDrawer(props) {
  const schoolname = useContext(schoolNameContext);
  const openFileDownloader = useContext(downloadReportContext);
  const [downloadFile, downloaderComponentUI] = useFileDownloader();
  schoolname.updateSchool(props.isSuperAdmin ? schoolname.school : props.user.schoolname);
  const { setIsAdminDashboardOrTeacherClassess } = useContext(PrimaryNavBarContext);

  useEffect(() => {
    if (props.isSuperAdmin) {
      setIsAdminDashboardOrTeacherClassess(false);
    } else {
      setIsAdminDashboardOrTeacherClassess(true);
    }
  }, []);
  // const handleDownloadReport = () => {
  //   const url = {
  //     name: 'TrialSchoolReport',
  //     thumb: 'report.thumb',
  //     file: `${process.env.REACT_APP_API_BASE_URL}/school/report/trial`,
  //     filename: 'reort-trial',
  //   };
  //   downloadFile(url);
  // };
  
  useEffect(() => {
    if (openFileDownloader.open) {
      const { url } = openFileDownloader;
      downloadFile(url);
      openFileDownloader.updateDownloadReport(false, undefined);
    }
  }, [openFileDownloader.open]);


  const navigate = useNavigate();
  const menuItemsSuperAdmin = [
    {
      text: 'Schools',
      icon: <ManageAccounts />,
      path: '/school/list',
    },
    {
      text: 'Curriculum Map',
      icon: <ManageAccounts />,
      path: '/school/curriculum/map',
    },
    {
      text: 'Modules Map',
      icon: <ManageAccounts />,
      path: '/school/module/map',
    },
    {
      text: 'Puzzles Map',
      icon: <ManageAccounts />,
      path: '/school/puzzle/map'
    }
  ];
  const menuItems = [
    {
      text: 'Progress Report',
      icon: <BarChartIcon />,
      path: '/',
    },
    {
      text: 'School Details',
      icon: <svg
        style={{
          userSelect: 'none', width: '1em', height: '1em', display: 'inline-block', fill: 'currentcolor', flexShrink: 0, transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms', fontSize: '1.5rem',
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <g data-name="Layer 2">
          <path d="M11 18h2v4h-2z" />
          <path d="m18 4.32-6-2.4-6 2.4V8H2v14h7v-6h6v6h7V8h-4ZM7 18H5v-2h2Zm0-4H5v-2h2Zm4 0H9v-2h2Zm0-8h2v4h-2Zm4 8h-2v-2h2Zm4 4h-2v-2h2Zm0-6v2h-2v-2Z" />
        </g>
      </svg>,
      path: '/school/details',
    },
    {
      text: 'Manage Students',
      icon: <ManageAccounts />,
      path: '/student/list',
    },
    {
      text: 'Manage Teachers',
      icon: <ManageAccounts />,
      path: '/teacher/list',
    },
  ];

  const [selected, setSelected] = useState('Progress Report');

  const drawer = (
    <div>
      <List>
        {props.isSuperAdmin && menuItemsSuperAdmin.map((item) => (
          <ListItem
            button
            key={item.text}
            onClick={() => navigate(item.path, { state: { user: props.user } })}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}

        {!props.isSuperAdmin && menuItems.map((item) => (
          <ListItem
            button
            selected={selected === item.text}
            key={item.text}
            onClick={() => {
              setSelected(item.text);
              navigate(item.path, { state: { user: props.user, schoolcode: props.user.schoolcode } });
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} primaryTypographyProps={{ fontFamily: 'rajdhani-medium', fontSize: '1.25rem' }} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box className='dashboard-container'>
      {!props.isTeacher ? (<Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, borderRight: '#00868D', top:'8vh' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>): <></> }
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Grid container height={"100%"}>
          <Grid item md={12}>
            {props.children}
            {downloaderComponentUI}
            <h6 style={{ display: 'none' }}>{openFileDownloader.open}</h6>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
export default ResponsiveDrawer;
