export function formatError(
  traceback,
  consoleMode,
  userCode = "",
  interruptExecutionMessage,
  inputDisabledMessage,
  timeoutMessage
) {
  const pattern = /File "<exec>".*/s;
  const match = traceback.match(pattern)[0];

  const tracebackLines = match.split("\n");
  tracebackLines.pop();
  let errorLine = tracebackLines.pop();

  let skipTraceback = false;
  if (
    errorLine.startsWith("Exception: " + interruptExecutionMessage) ||
    errorLine.startsWith("Exception: " + timeoutMessage)
  ) {
    skipTraceback = true;
  }
  let skipLastLine = false;
  if (errorLine.startsWith("Exception: " + inputDisabledMessage)) {
    skipLastLine = true;
  }

  const userCodeLines = userCode.split("\n");

  let output = "";
  let linePattern = /line (\d+)/;
  for (let i = 0; i < tracebackLines.length; i++) {
    if (skipTraceback || consoleMode) break;
    if (i === tracebackLines.length - 1 && skipLastLine) break;
    let tracebackLine = tracebackLines[i];
    if (linePattern.test(tracebackLine)) {
      let lineNumber = parseInt(tracebackLine.match(linePattern)[1], 10);
      if (lineNumber >= 1 && lineNumber <= userCodeLines.length) {
        output += "line " + lineNumber + ":\n";
        output += "\t" + userCodeLines[lineNumber - 1].trim() + "\n";
      }
    }
  }
  output += errorLine;
  const splitOutput = output.split("JsException: ");
  if (splitOutput.length > 1) {
    output = splitOutput[1].trim();
  }

  const pyodidePatterns = [/Pyodide/g, /pyodide/g, /pyodide\.ffi/g];
  pyodidePatterns.forEach((pattern) => {
    output = output.replace(pattern, "");
  });

  return output;
}
