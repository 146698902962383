import { yupResolver } from "@hookform/resolvers/yup";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import ContactDetails from "./Steps/ContactDetails";
import SchoolDetails from "./Steps/SchoolDetails";
import SchoolSubscription from "./Steps/SchoolSubscription";

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: "2px",
    marginRight: "2px",
  },
  button: {
    marginRight: "2px",
  },
  instructions: {
    marginTop: "2px",
    marginBottom: "2px",
    minHeight: "25vh",
  },
  paper: {
    marginTop: "2px",
    marginBottom: "2px",
    padding: "2px",
  },
}));

export default function AddSchool() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    "School details",
    "School contact details",
    "School subscription",
  ];
  const [open, setOpen] = useState(false);
  const [classError, setClassError] = useState(false);
  const [sectionError, setSectionError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const todayDate = new Date();
  const requiredFieldText = "This is required field";
  const schoolRollOver = location.state?.schoolRollOver;
  const rollOverSchoolInfo = location.state?.schoolInfo;
  const [classSection, setClassSection] = useState(
    rollOverSchoolInfo ? rollOverSchoolInfo?.grades_data : {}
  );
  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  document.title = schoolRollOver
    ? "CSSoch | School Rollover"
    : "CSSoch | Add School";

  function updateClassSectionErrors() {
    setClassError(false);
    setSectionError(false);
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <SchoolDetails schoolRollOver={schoolRollOver} />;
      case 1:
        return <ContactDetails />;
      case 2:
        return (
          <SchoolSubscription
            classError={classError}
            sectionError={sectionError}
            updateClassSectionErrors={updateClassSectionErrors}
            classSection={classSection}
            setClassSection={setClassSection}
            schoolRollOver={schoolRollOver}
            academicYearOptionsForRollover={
              rollOverSchoolInfo?.academicYearOptionsForRollover
            }
          />
        );
      case 3:
      default:
        return "Unknown step";
    }
  }

  const onSubmit = (data) => {
    setOpen(!open);
    if (Object.keys(data.classesSections).length) {
      Object.keys(data.classesSections).forEach((element) => {
        if (data.classesSections[element]?.length === 0) {
          setSectionError(true);
          return;
        }
      });
    } else {
      setClassError(true);
      return;
    }
    if (sectionError || classError) {
      return;
    }
    const newSchoolData = {
      schoolDetails: {
        cssoch_IT_person_name: data.cssochItPocName,
        cssoch_IT_person_email: data.cssochItPocEmail,
        principal_name: data.principalName,
        payment_schedule: data.paymentSchedule,
        contact_person_name: data.contactPersonName,
        principal_email: data.principalEmail,
        school_id: schoolRollOver
          ? rollOverSchoolInfo.school_id
          : todayDate.getDate() +
            (todayDate.getMonth() + 1) +
            todayDate.getFullYear() +
            todayDate.getHours() +
            todayDate.getMinutes(),
        status: data.status,
        principal_phone: data.principalPhone,
        admin_person_email: data.adminEmail,
        trial_expiry_date:
          new Date(data.expiryDate) ??
          new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        contact_person_phone: data.contactPersonPhone,
        school_code: data.schoolCode.toLowerCase(),
        address: data.address,
        city: data.city,
        mode: data.mode,
        state: data.state,
        contact_person_email: data.contactPersonEmail,
        admin_person_phone: data.adminPhone,
        curriculum_id: data.assignCurricullum,
        curriculum_name: data.curriculum_name,
        pincode: data.pincode,
        admin_person_name: data.adminName,
        cssoch_IT_person_phone: data.cssochItPocPhone,
        school_name: data.schoolName,
        academic_year: data.academicYear,
        created_at: new Date(),
        updated_at: new Date(),
        inactivate_date: data.status === "Active" ? "" : new Date(),
        is_school_rolled_over: false,
      },
      classes: data.classesSections,
    };
    if (schoolRollOver) {
      newSchoolData["schoolDetails"]["rolled_over_from_year"] =
        rollOverSchoolInfo?.academic_year;
      newSchoolData["schoolDetails"]["is_school_newly_subscribed"] = false;
      newSchoolData["schoolDetails"]["users_count"] =
        rollOverSchoolInfo?.users_count;
    } else {
      newSchoolData["schoolDetails"]["is_school_newly_subscribed"] = true;
    }

    addNewSchool(newSchoolData);
  };

  const addNewSchool = (addData) => {
    setShowLoader(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(addData),
    };
    const url = schoolRollOver
      ? `${process.env.REACT_APP_API_BASE_URL}/school/rollover`
      : `${process.env.REACT_APP_API_BASE_URL}/school/add`;
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.success == true) {
          navigate("/school/list", {
            state: { user: location.state?.user },
          });
        } else {
          setAlertMessage(data.description);
          setShowAlert(true);
        }
        setShowLoader(false);
      });
  };

  const validationSchema = [
    // validation for step1
    yup.object({
      schoolName: yup.string().required(requiredFieldText),
      schoolCode: yup
        .string()
        .required(requiredFieldText)
        .min(2, "Code must be at least 2 characters")
        .lowercase()
        .notOneOf(
          schoolRollOver ? [] : location.state.schoolcode.schoolCodeList,
          "School Code already taken."
        ),
      city: yup.string().required(requiredFieldText),
    }),
    // validation for step2
    yup.object({
      contactPersonName: yup
        .string()
        .required(requiredFieldText)
        .matches(
          /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/,
          "Contact Person Name should have firstname and lastname"
        )
        .max(50, "Name should not be more than 50 character"),
      contactPersonPhone: yup
        .string()
        .required(requiredFieldText)
        .matches(/^[0-9]*$/, "Phone must contain only number")
        .length(10, "Phone should be 10 digits."),
      contactPersonEmail: yup
        .string()
        .required(requiredFieldText)
        .email("Email must be valid Email"),
      principalPhone: yup.lazy((value) => {
        if (value) {
          return yup
            .string()
            .nullable()
            .matches(/^[0-9]*$/, "Phone must contain only number");
        }
        return yup.string().nullable().notRequired();
      }),
      principalEmail: yup.string().email("Email must be valid Email"),
      adminPhone: yup.lazy((value) => {
        if (value) {
          return yup
            .string()
            .nullable()
            .matches(/^[0-9]*$/, "Phone must contain only number");
        }
        return yup.string().nullable().notRequired();
      }),
      adminEmail: yup.string().email("Email must be valid Email"),
      cssochItPocPhone: yup.lazy((value) => {
        if (value) {
          return yup
            .string()
            .nullable()
            .matches(/^[0-9]*$/, "Phone must contain only number")
            .length(10);
        }
        return yup.string().nullable().notRequired();
      }),
      cssochItPocEmail: yup.string().email("Email must be valid Email"),
    }),
    // validation for step3
    yup.object({
      mode: yup.string().required(),
      status: yup.string().required(),
      expiryDate: yup.string().required(requiredFieldText),
      paymentSchedule: yup.string().required(),
      academicYear: yup.string().required(requiredFieldText),
      assignCurricullum: yup.string().required(requiredFieldText),
      classes: yup.lazy((value) => {
        const classSections = getValues("classesSections");
        if (!value && !Object.keys(classSections).length) {
          return yup.string().nullable().required(requiredFieldText);
        } else if (value) {
          return yup
            .string()
            .nullable()
            .matches(
              /^[1-9]|10$/,
              "Invalid class. Please enter a class from 1 to 10"
            );
        } else if (Object.keys(classSections).length) {
          const data = Object.keys(classSections).filter((element) => {
            if (!classSections[element].length) {
              return true;
            }
            return false;
          });
          if (data.length) {
            return yup
              .string()
              .nullable()
              .required("A class must have a section or remove class");
          }
        }
        return yup.string().nullable().notRequired();
      }),
      sections: yup.lazy((value) => {
        const classSections = getValues("classesSections");
        if (!value && !Object.keys(classSections).length) {
          return yup.string().nullable().required(requiredFieldText);
        }
        return yup.string().nullable().notRequired();
      }),
    }),
  ];
  const defaultValues = {
    schoolName: schoolRollOver ? rollOverSchoolInfo?.school_name : "",
    schoolCode: schoolRollOver ? rollOverSchoolInfo?.school_code : "",
    address: schoolRollOver ? rollOverSchoolInfo?.address : "",
    city: schoolRollOver ? rollOverSchoolInfo?.city : "",
    state: schoolRollOver ? rollOverSchoolInfo?.state : "",
    pincode: schoolRollOver ? rollOverSchoolInfo?.pincode : "",
    contactPersonName: schoolRollOver
      ? rollOverSchoolInfo?.contact_person_name
      : "",
    contactPersonPhone: schoolRollOver
      ? rollOverSchoolInfo?.contact_person_phone
      : "",
    contactPersonEmail: schoolRollOver
      ? rollOverSchoolInfo?.contact_person_email
      : "",
    principalName: schoolRollOver ? rollOverSchoolInfo?.principal_name : "",
    principalPhone: schoolRollOver ? rollOverSchoolInfo?.principal_phone : "",
    principalEmail: schoolRollOver ? rollOverSchoolInfo?.principal_email : "",
    adminName: schoolRollOver ? rollOverSchoolInfo?.admin_person_name : "",
    adminPhone: schoolRollOver ? rollOverSchoolInfo?.admin_person_phone : "",
    adminEmail: schoolRollOver ? rollOverSchoolInfo?.admin_person_email : "",
    cssochItPocName: schoolRollOver
      ? rollOverSchoolInfo?.cssoch_IT_person_name
      : "Priyanka Thaker",
    cssochItPocPhone: schoolRollOver
      ? rollOverSchoolInfo?.cssoch_IT_person_phone
      : "9090909090",
    cssochItPocEmail: schoolRollOver
      ? rollOverSchoolInfo?.cssoch_IT_person_email
      : "priyanka@cssoch.in",
    mode: schoolRollOver ? rollOverSchoolInfo?.mode : "Trial",
    status: "Active",
    expiryDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    paymentSchedule: schoolRollOver
      ? rollOverSchoolInfo?.payment_schedule
      : "Half-Yearly",
    classes: "",
    sections: "",
    assignCurricullum: schoolRollOver
      ? rollOverSchoolInfo?.curriculum_id.toString()
      : "",
    curriculum_name: schoolRollOver ? rollOverSchoolInfo?.curriculum_name : "",
    academicYear: "",
    classesSections: schoolRollOver ? rollOverSchoolInfo?.grades_data : "",
    inactiveDate: "",
  };
  const currentValidationSchema = validationSchema[activeStep];
  const methods = useForm({
    shouldUnregister: false,
    defaultValues,
    resolver: yupResolver(currentValidationSchema),
    mode: "onBlur",
  });
  const { handleSubmit, reset, trigger, getValues } = methods;

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseSnackBar = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };

  return (
    <>
      {showLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          {" "}
          <CircularProgress color="inherit" />
          School Creation is in progress. Please wait...
        </Backdrop>
      )}
      <div
        style={{ backgroundColor: "#DDE9F5", height: "100%", paddingTop: 10 }}
      >
        <Container>
          <Box
            sx={{
              width: "100%",
              background: "white",
              paddingTop: "15px",
            }}
          >
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              <FormProvider {...methods}>
                <form>
                  <Typography
                    sx={{
                      mt: 2,
                      mb: 1,
                      ml: 5,
                      mr: 5,
                    }}
                  >
                    {getStepContent(activeStep)}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pt: 2,
                      mr: 2,
                    }}
                  >
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {activeStep === steps.length - 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit(onSubmit)}
                        className={classes.button}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        Next
                      </Button>
                    )}
                  </Box>
                  <Typography
                    variant="span"
                    style={{
                      color: "red",
                      marginLeft: "15px",
                      marginRight: "10px",
                      fontSize: "small",
                    }}
                  >
                    * mandatory field
                  </Typography>
                </form>
              </FormProvider>
            </div>
          </Box>
        </Container>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
        sx={{ top: "10vh !important" }}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
