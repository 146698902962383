import React from "react";

function AssessmentStartScreen(props) {
  const {
    updateStatusStorage,
    pageIsRendered,
    assesmentGamesetInfo,
    setAssesmentRunGameObj,
    Game,
    setViewOnly,
    setShowTestScreen,
    setShowStartScreen,
    setCurrentLevel,
    setRunStartTest,
    itemName,
  } = props;
  return (
    <div className="assesment-start-screen">
      <button
        id="assesment-start-button"
        onClick={async () => {
          let statusUpadted = await updateStatusStorage({
            assessment_state: "started",
          }, itemName);
          if (!statusUpadted) {
            return;
          }
          setViewOnly(false);
          setShowTestScreen(true);
          if (!pageIsRendered && assesmentGamesetInfo) {
            setAssesmentRunGameObj(new Game(assesmentGamesetInfo?.gameType));
          }
          setShowStartScreen(false);
          setCurrentLevel(1);
          setRunStartTest(true);
        }}
      >
        START
      </button>
      <p className="mt-3">
        This test has both games and puzzles. During the test, if you are unable
        to solve a problem, click the ‘Give Up’ button to see the answer.
        However, you will not get any points for that question. After the test
        is over, you can go back and see the questions by clicking the 'Review
        Questions' button.
      </p>
    </div>
  );
}

export default AssessmentStartScreen;
