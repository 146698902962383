import React, { useContext, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { authUserContext } from '../Contexts/AuthUser';

export default function ProtectedRoute() {
  const {user} = useContext(authUserContext);
  const [superAdmin, setSuperAdmin] = useState(user.role.includes('superadmin'));
  const [teacher, setTeacher] = useState(user.role.includes('teacher'));
  const [isStudent, setIsStudent] = useState(user.role.includes('student'));
  let {grade,section,type,moduleNo,moduleId} = useParams();
  return superAdmin ? <Navigate to="/school/list" state={{user:user}}/> : (teacher ? (grade && type && moduleNo && moduleId) ?(<Navigate to={`/notes/${grade}/${section}/${type}/${moduleNo}/${moduleId}`} />): (<Navigate to={`/classes`} />) : (isStudent ? <Navigate to="/home"/> : <Navigate to="/admin/dashboard" />));
}
