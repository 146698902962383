import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import MarkdownView from "react-showdown";
import "../../Styles/Notes.css";
import { PrimaryNavBarContext } from "../Layout/Layout";

function PopupWindow() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [ids, setIds] = useState(queryParams.get("ids") ?? null);
  const [moduleName, setModuleName] = useState(
    queryParams.get("module_name") ?? null);
  const params = useParams();
  const [grade, setGrade] = useState(params.grade ?? queryParams?.get("grade"));
  const [section, setSection] = useState(params.section ?? queryParams?.get("section"));
  const [type, setType] = useState(params.type);
  const [moduleId, setModuleId] = useState(params.moduleId);
  const [content, setContent] = useState();
  const {
    setNavbarModuleData,
    setIsModulePage,
    setTeacherGrade,
    setTeacherSection,
  } = useContext(PrimaryNavBarContext);
  useEffect(() => {
    if(grade && section) {
      setTeacherGrade(grade);
      setTeacherSection(section);
    }
    if (location.pathname.includes("help-instructions")) {
      setNavbarModuleData({
        moduleName: moduleName,
      });
      if(moduleName) setIsModulePage(true);
      document.title = "CSSoch | Help Instructions";
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ids: ids?.split(",").map(Number),
        }),
      };
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/cms/help-instructions`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          setContent(data?.data);
        });
    } else {
      document.title = "CSSoch | Notes";
      setIsModulePage(true);
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/cms/${
          type === "module" ? "modules" : "notebook-set"
        }/notes/${moduleId}`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          setNavbarModuleData({
            moduleName: `${
              type === "module"
                ? data["Module Name"]
                : data?.Notebook_Display_Name ?? data?.Notebook_Name
            }`,
          });
          setContent(data.Notes);
        });
    }
  }, []);
  return (
    <>
      <div className='notes-content ml-0 p-3'>
        {content ? (
          <MarkdownView className='rendernotes' markdown={content} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default PopupWindow;
