import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { authUserContext } from "../../Components/Contexts/AuthUser";
import { useLocation } from "react-router-dom";
import ModuleSideBar from "../../Components/NavBars/ModuleSideBar";
import Story from "../../Components/ModuleComponents/Story";
import Game from "../../Components/ModuleComponents/Game/Game";
import Python from "../../Components/ModuleComponents/Python";
import Puzzle from "../../Components/ModuleComponents/Puzzle/Puzzle";
import Assesment from "../../Components/ModuleComponents/Assessment/Assesment";
import InformatonBar from "../../Components/NavBars/InformatonBar";
import Loader from "../../Components/Loader/Loader";
import "../../Styles/Global.css";
import "../../Styles/Module.css";
import { PrimaryNavBarContext } from "../../Components/Layout/Layout";
import CheckInternet from "../../Components/CheckInternet/CheckInternet";
import { ModuleAPIManagerContext } from "../../Components/Contexts/ModuleAPIManager";

export default function StudentModulePage(props) {
  const { module_id } = useParams();
  const { user } = useContext(authUserContext);
  const url = new URL(document.URL);
  const { state } = useLocation();
  const {
    isLoading,
    setIsLoading,
    statusStorage,
    setStatusStorage,
    statusesSet,
    setStatusesSet,
    structure,
    setStructure,
    moduleName,
    setModuleName,
    initialItem,
    setInitial,
    currentSection,
    setCurrentSection,
    strapiData,
    setStrapiData,
    studentName,
    setStudentName,
    userId,
    setUserId,
    grade,
    setGrade,
    className,
    setClassName,
    moduleNo,
    setModuleNo,
    teacherView,
    setTeacherView,
    failedToUpdateStatus,
    setFailedToUpdateStatus,
    retryCount,
    setRetryCount,
    updateStatusStorage,
    updateItems,
    updateToCloud,
    handleCurrentSectionChange,
  } = useContext(ModuleAPIManagerContext);
  const {
    setIsModulePage,
    setIsTeacherDashboard,
    setNavbarModuleData,
    setTeacherGrade,
    setTeacherSection,
  } = useContext(PrimaryNavBarContext);
  const [settingState, setSettingState] = useState(true);

  useEffect(() => {
    setStatusStorage({ ...statusStorage, moduleID: module_id });
    setInitial(
      document.URL.includes("initial")
        ? url.searchParams.get("initial")
        : "ongoing"
    );
    setCurrentSection(
      document.URL.includes("initial") ? url.searchParams.get("initial") : null
    );
    setUserId(state?.userId ? state.userId : null);
    setGrade(state?.grade ? state.grade : null);
    setClassName(state?.class ? state.class : null);
    setModuleNo(state?.moduleNo ? state.moduleNo : null);
    setTeacherView(state?.view ? state.view : null);
    setSettingState(false);
  }, []);

  useEffect(() => {
    if (!settingState) {
      setIsTeacherDashboard(false);
      setIsModulePage(true);
      setTeacherGrade(grade);
      setTeacherSection(className);
      fetch(`${process.env.REACT_APP_API_BASE_URL}/cms/modules/${module_id}`)
        .then((res) => res.json())
        .then((data) => {
          setStrapiData(data);
          if (user.role.includes("teacher") && teacherView) {
            generateTeacherStatisticsAndStatus(data);
          }
        });
    }
  }, [settingState]);

  useEffect(() => {
    if (strapiData) {
      const isTeacher = user.role.includes("teacher");
      if (!isTeacher || (isTeacher && !teacherView)) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: null,
        };
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/modules/${module_id}`;
        if (user.role.includes("teacher")) {
          requestOptions.body = JSON.stringify({ studentId: userId });
        }
        fetch(apiUrl, requestOptions)
          .then((res) => res.json())
          .then((data) => {
            setModuleName(data.name);
            setStructure(data.structure);
            if (data.student_name) setStudentName(data.student_name);
            const statusStorageTemp = {};
            for (const key in data.statuses) {
              statusStorageTemp[key] = data.statuses[key];
            }
            if (Object.keys(statusStorageTemp).length === 0) {
              generateTeacherStatisticsAndStatus(strapiData);
            } else {
              statusStorageTemp["moduleID"] = module_id;
              setStatusStorage(statusStorageTemp);
            }
            setStatusesSet(true);
          });
      }
    }
  }, [strapiData]);

  useEffect(() => {
    if (structure.length && statusStorage) {
      const statusStorageNew = JSON.parse(JSON.stringify(statusStorage));
      let isDataUpdated = false;
      for (const structureItem of structure) {
        if (
          statusStorage[`${structureItem + "_status"}`] === "ongoing" &&
          initialItem !== structureItem &&
          initialItem !== "ongoing"
        ) {
          if (!user.role.includes("teacher")) {
            statusStorageNew[`${structureItem + "_status"}`] = "incomplete";
            isDataUpdated = true;
          }
        }
      }
      if (statusStorage[`${currentSection + "_status"}`] === "incomplete") {
        if (!user.role.includes("teacher")) {
          statusStorageNew[`${currentSection + "_status"}`] = "ongoing";
          isDataUpdated = true;
        }
      }
      if (initialItem === "ongoing") {
        let found = false;
        for (let i = 0; i < structure.length; i++) {
          if (!statusStorage.hasOwnProperty(`${structure[i] + "_status"}`))
            continue;
          if (statusStorage[`${structure[i] + "_status"}`] === "ongoing") {
            setCurrentSection(structure[i]);
            found = true;
            break;
          }
        }
        if (!found) {
          let sec_id = 0;
          for (let i = 0; i < structure.length; i++) {
            if (statusStorage[`${structure[i] + "_status"}`] === "incomplete") {
              sec_id = i;
              if (!user.role.includes("teacher")) {
                statusStorageNew[`${structure[i] + "_status"}`] = "ongoing";
                isDataUpdated = true;
              }
              break;
            }
          }
          setCurrentSection(structure[sec_id]);
        }
      }
      if (isDataUpdated) setStatusStorage(statusStorageNew);
      updateItems();
      setIsLoading(false);
    }
  }, [statusesSet]);

  useEffect(() => {
    if (currentSection && strapiData && moduleName) {
      setNavbarModuleData({
        moduleName: moduleName,
        currentSection: currentSection,
      });
    }
  }, [currentSection, strapiData, moduleName]);

  function generateTeacherStatisticsAndStatus(strapiData) {
    setModuleName(strapiData["Module Name"]);
    setGrade(grade);
    const structure = [];
    const status = {
      status: "ongoing",
      module_name: strapiData["Module Name"],
    };
    const items = [
      "Story",
      "Game",
      "Python",
      "Puzzle",
      "Assessment",
      "Project",
    ];
    let firstItem = true;
    for (let item of items) {
      if (strapiData[item]?.length) {
        switch (item) {
          case "Story":
            structure.push("story");
            if (teacherView && firstItem) {
              status["story_status"] = "ongoing";
              firstItem = false;
            } else status["story_status"] = "incomplete";
            break;
          case "Game":
            structure.push("game");
            status["game_levels"] = [];
            if (teacherView && firstItem) {
              status["game_status"] = "ongoing";
              firstItem = false;
            } else status["game_status"] = "incomplete";
            break;
          case "Python":
            structure.push("python");
            status["python_levels"] = [];
            if (teacherView && firstItem) {
              status["python_status"] = "ongoing";
              firstItem = false;
            } else status["python_status"] = "incomplete";
            break;
          case "Puzzle":
            structure.push("puzzle");
            status["puzzle_levels"] = [];
            if (teacherView && firstItem) {
              status["puzzle_status"] = "ongoing";
              firstItem = false;
            } else status["puzzle_status"] = "incomplete";
            break;
          default:
            break;
        }
      } else {
        if (item === "Assessment") {
          if (
            strapiData[item]["Game Set"]?.length ||
            strapiData[item]["Puzzle"]?.length ||
            strapiData[item]["Python"]?.length
          ) {
            structure.push("assessment");
            status["assessment_levels"] = [];
            if (teacherView) {
              status["assessment_state"] = "finished";
              status["assessment_status"] = "completed";
            } else {
              status["assessment_state"] = "notstarted";
              status["assessment_status"] = "incomplete";
            }
          }
        }
      }
    }
    const structureItems = [
      "story",
      "game",
      "python",
      "puzzle",
      "activity",
      "assessment",
    ];
    for (let item of structureItems) {
      if (item in structure) {
        status[`${item}_status`] = "ongoing";
        break;
      }
    }
    setStructure(structure);
    setStatusStorage(status);
    setStatusesSet(true);
  }
  function getNext(type) {
    let name;
    for (let i = 0; i < structure.length; i++) {
      if (structure[i] === type) {
        if (i === structure.length) name = structure[0];
        else {
          name = structure[i + 1];
        }
      }
    }
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  return (
    <>
      {!isLoading && strapiData ? (
        <>
          <div className="student-module-page">
            <ModuleSideBar
              backLink={`/home?module=${module_id}&type=module`}
              statusStorage={statusStorage}
              structure={structure}
              handleCurrentSectionChange={handleCurrentSectionChange}
              currentSection={currentSection}
              teacher={user.role.includes("teacher")}
              userId={userId}
              moduleID={module_id}
              grade={grade}
              className={className}
              teacherView={teacherView}
              moduleNo={moduleNo}
              state={state}
            />
            <CheckInternet
              open={failedToUpdateStatus}
              onClose={() => setFailedToUpdateStatus(false)}
            />
            <div style={{ marginTop: "8vh", marginLeft: "10vw" }}>
              {user.role.includes("teacher") && !teacherView && (
                <InformatonBar textToDisplay={`${studentName}`} />
              )}
              <Story
                strapiData={strapiData}
                statusStorage={statusStorage}
                updateStatusStorage={updateStatusStorage}
                getNext={getNext}
                handleCurrentSectionChange={handleCurrentSectionChange}
                currentSection={currentSection}
                teacher={user.role.includes("teacher")}
                teacherView={teacherView}
                goToNext={structure.slice(-1)[0] === "story" ? false : true}
              />
              <Game
                strapiData={strapiData}
                statusStorage={statusStorage}
                updateStatusStorage={updateStatusStorage}
                getNext={getNext}
                handleCurrentSectionChange={handleCurrentSectionChange}
                currentSection={currentSection}
                teacher={user.role.includes("teacher")}
                teacherView={teacherView}
                goToNext={structure.slice(-1)[0] === "game" ? false : true}
              />
              <Puzzle
                strapiData={strapiData}
                statusStorage={statusStorage}
                updateStatusStorage={updateStatusStorage}
                getNext={getNext}
                handleCurrentSectionChange={handleCurrentSectionChange}
                currentSection={currentSection}
                teacher={user.role.includes("teacher")}
                teacherView={teacherView}
                goToNext={structure.slice(-1)[0] === "puzzle" ? false : true}
              />
              <Assesment
                strapiData={strapiData}
                statusStorage={statusStorage}
                updateStatusStorage={updateStatusStorage}
                getNext={getNext}
                handleCurrentSectionChange={handleCurrentSectionChange}
                currentSection={currentSection}
                teacher={user.role.includes("teacher")}
                teacherView={teacherView}
                goToNext={
                  structure.slice(-1)[0] === "assessment" ? false : true
                }
                moduleName={moduleName}
                grade={grade}
                className={className}
              />
              {structure.includes("python") && (
                <Python
                  strapiData={strapiData}
                  statusStorage={statusStorage}
                  updateStatusStorage={updateStatusStorage}
                  getNext={getNext}
                  handleCurrentSectionChange={handleCurrentSectionChange}
                  currentSection={currentSection}
                  teacher={user.role.includes("teacher")}
                  teacherView={teacherView}
                  goToNext={structure.slice(-1)[0] === "python" ? false : true}
                  moduleName={moduleName}
                  grade={grade}
                  className={className}
                  setFailedToUpdateStatus={setFailedToUpdateStatus}
              />
              )}
              {currentSection === "project" && <h2>Project</h2>}
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
