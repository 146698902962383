export function loadGamefromLib(gameObjInfo, isAssesment, isProject) {
  let renderer;
  let categoriesInToolbox;
  let blocklyObjOption;
  let gameObjOption;
  let options;
  const blocklyAsset = {};
  const levels = gameObjInfo.Levels;
  const gameType = gameObjInfo['Game Types'][0]['Game Type Name'];
  const videoUrl = gameObjInfo['Demo Video'];
  const structure = gameObjInfo.Structure;
  const { blocklyImages } = gameObjInfo;
  const errorMessages = gameObjInfo['Game Types'][0]['Error Messages'];
  const successMessages = gameObjInfo['Game Types'][0]['Success Messages'];
  const arrowButtons = gameObjInfo['Game Types'][0].ArrowButtons;
  if (structure === 'codingEnvironment') {
    renderer = gameObjInfo['Config options'].Renderer;
    renderer = renderer.toLowerCase();
    categoriesInToolbox = gameObjInfo['Config options']['Categories In ToolBoX'];
    blocklyObjOption = {
      toolboxRef: isAssesment ? 'assesment-toolbox' : 'toolbox',
      blocklyAreaRef: isAssesment ? 'assesment-blocklyArea' : 'blocklyArea',
      blocklyRef: isAssesment ? 'assesment-blockly' : 'blockly',
      renderer,
      categoriesInToolbox,
    };
    gameObjOption = {
      canvas: isAssesment ? 'assesment-canvas' : 'canvas',
      project: isProject, // TODO: Project will going to support later
    };
    if (isProject) {
      gameObjOption.canvas = 'project-canvas';
      blocklyObjOption.toolboxRef = 'project-toolbox';
      blocklyObjOption.blocklyAreaRef = 'project-blocklyArea';
      blocklyObjOption.blocklyRef = 'project-blockly';
    }
    // Load the options
    options = {
      structure,
      blockly: blocklyObjOption,
      game: gameObjOption,
    };
  } else {
    blocklyObjOption = {
      blocklyAreaRef: isAssesment ? 'assesment-blocklyArea' : 'blocklyArea',
      blocklyRef: isAssesment ? 'assesment-blockly' : 'blockly',
    };
    // Load the options
    options = {
      structure,
      blockly: blocklyObjOption,
    };
  }
  if (blocklyImages.length >= 0) {
    // extract key
    for (const imagePath of blocklyImages) {
      const imagePathComponents = imagePath.split('/');
      let imageKey = imagePathComponents[5];
      if (imageKey.includes('_')) {
        const imageKeyComponents = imageKey.split('_');
        imageKey = '';
        for (let i = 0; i < imageKeyComponents.length - 1; i++) {
          imageKey += imageKeyComponents[i];
        }
      }
      blocklyAsset[imageKey] = imagePath;
    }
  }
  // Create game graphics
  const gameAssets = gameObjInfo['Game Types'][0].Graphics;
  const gameSounds = gameObjInfo['Game Types'][0].Sounds;
  const assets = {
    sounds: {},
  };
  for (const imagePath of gameAssets) {
    const imagePathComponents = imagePath.split('/');
    let imageKey = imagePathComponents[5];
    if (imageKey.includes('_')) {
      const imageKeyComponents = imageKey.split('_');
      imageKey = '';
      for (let i = 0; i < imageKeyComponents.length - 1; i++) {
        imageKey += imageKeyComponents[i];
      }
    }
    assets[imageKey] = imagePath;
  }
  if (structure === 'playgroundEnvironment') {
    delete assets.sounds;
  } else {
    for (const soundPath of gameSounds) {
      const soundPathComponents = soundPath.split('/');
      let soundKey = soundPathComponents[5];
      if (soundKey.includes('_')) {
        const soundKeyComponents = soundKey.split('_');
        soundKey = '';
        for (let i = 0; i < soundKeyComponents.length - 1; i++) {
          soundKey += soundKeyComponents[i];
        }
      }
      assets.sounds[soundKey] = soundPath;
    }
  }
  return {
    assets,
    levels,
    options,
    gameType,
    videoUrl,
    blocklyAsset,
    errorMessages,
    successMessages,
    arrowButtons,
  };
}


export function modifyPlaygroundProjectdata(gameObjInfo) {
  let renderer;
  let categoriesInToolbox;
  let blocklyObjOption;
  let gameObjOption;
  let options;
  const blocklyAsset = {};
  const levels = gameObjInfo.Levels;
  const gameType = gameObjInfo['Game Type']['Name'];
  const structure = 'codingEnvironment';
  const blocklyImages = gameObjInfo['Blockly Images'];
  const errorMessages = gameObjInfo['Game Type']['Error Messages'];
  const successMessages = gameObjInfo['Game Type']['Success Messages'];
  const arrowButtons = gameObjInfo['Game Type'].ArrowButtons;
  if (structure === 'codingEnvironment') {
    renderer = gameObjInfo['Config options'].Renderer;
    renderer = renderer.toLowerCase();
    categoriesInToolbox = gameObjInfo['Config options']['CategoriesInToolbox'];
    blocklyObjOption = {
      toolboxRef: 'toolbox',
      blocklyAreaRef: 'blocklyArea',
      blocklyRef: 'blockly',
      renderer,
      categoriesInToolbox,
    };
    gameObjOption = {
      canvas: 'canvas', // TODO: Project will going to support later
    };
    // Load the options
    options = {
      structure,
      blockly: blocklyObjOption,
      game: gameObjOption,
    };
  } else {
    blocklyObjOption = {
      blocklyAreaRef:'blocklyArea',
      blocklyRef:'blockly',
    };
    // Load the options
    options = {
      structure,
      blockly: blocklyObjOption,
    };
  }
  if (blocklyImages.length >= 0) {
    // extract key
    for (const imagePath of blocklyImages) {
      const imagePathComponents = imagePath.split('/');
      let imageKey = imagePathComponents[5];
      if (imageKey.includes('_')) {
        const imageKeyComponents = imageKey.split('_');
        imageKey = '';
        for (let i = 0; i < imageKeyComponents.length - 1; i++) {
          imageKey += imageKeyComponents[i];
        }
      }
      blocklyAsset[imageKey] = imagePath;
    }
  }
  // Create game graphics
  const gameAssets = gameObjInfo['Game Type'].Graphics;
  const gameSounds = gameObjInfo['Game Type'].Sounds;
  const assets = {
    sounds: {},
  };
  for (const imagePath of gameAssets) {
    const imagePathComponents = imagePath.split('/');
    let imageKey = imagePathComponents[5];
    if (imageKey.includes('_')) {
      const imageKeyComponents = imageKey.split('_');
      imageKey = '';
      for (let i = 0; i < imageKeyComponents.length - 1; i++) {
        imageKey += imageKeyComponents[i];
      }
    }
    assets[imageKey] = imagePath;
  }
  if (structure === 'playgroundEnvironment') {
    delete assets.sounds;
  } else {
    for (const soundPath of gameSounds) {
      const soundPathComponents = soundPath.split('/');
      let soundKey = soundPathComponents[5];
      if (soundKey.includes('_')) {
        const soundKeyComponents = soundKey.split('_');
        soundKey = '';
        for (let i = 0; i < soundKeyComponents.length - 1; i++) {
          soundKey += soundKeyComponents[i];
        }
      }
      assets.sounds[soundKey] = soundPath;
    }
  }
  return {
    assets,
    levels,
    options,
    gameType,
    blocklyAsset,
    errorMessages,
    successMessages,
    arrowButtons,
  };
}