import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  studentPerformanceData: {},
};

export const studentPerformanceDataSlice = createSlice({
  name: "studentPerformanceData",
  initialState,
  reducers: {
    setStudentPerformanceData: (state, action) => {
      state.studentPerformanceData = action.payload;
    },
  },
});

export const { setStudentPerformanceData } =
  studentPerformanceDataSlice.actions;
export default studentPerformanceDataSlice.reducer;
