import { yupResolver } from "@hookform/resolvers/yup";
import HelpIcon from "@mui/icons-material/Help";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { authUserContext } from "../../Components/Contexts/AuthUser";

const useStyles = makeStyles({
  field: {
    marginTop: "5px !important",
    marginBottom: "20px !important",
    display: "block !important",
  },
});

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export default function AddStudent() {
  const [isLoaded, setisLoaded] = useState(false);
  const [studentDetails, setStudentDetails] = useState({});
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState();
  const [status, setStatus] = useState("active");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState();
  const [grade, setGrade] = useState();
  const [section, setSection] = useState();
  const [parentNumber1, setParentNumber1] = useState();
  const [parentNumber2, setParentNumber2] = useState();
  const [studentEmail, setStudentEmail] = useState();
  const [parentEmail, setParentEmail] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [gradeList, setGradeList] = useState();
  const [sectionList, setSectionList] = useState();
  const [usernameList, setUsernameList] = useState([]);
  const [render, setRender] = useState();
  const [open, setOpen] = React.useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [gradeData, setGradeData] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const {user} = useContext(authUserContext);
  const [schoolCode, setSchoolCode] = useState(user.schoolcode);

  const schema = yup
    .object({
      "First Name": yup.string().required().max(20),
      "Middle Name": yup.string().max(20),
      "Last Name": yup.string().required().max(20),
      Username: !editMode ? yup.string().required().notOneOf(usernameList, "Username already exists") : "",
      Gender: yup.string().required(),
      Class: !editMode ? yup.string().required() : yup.string().notRequired(),
      Section: yup.string().required(),
      Password: !editMode ? yup.string().required().min(4).max(20) : "",
      "Parents Number 1": yup.lazy((value) => {
        if (value) {
          return yup
            .string()
            .min(10)
            .max(12)
            .matches(
              /^[0-9]{10}|^[0-9]{12}$/,
              "Contact number must contain numberic character only"
            );
        }
        return yup.mixed().notRequired();
      }),
      "Parents Number 2": yup.lazy((value) => {
        if (value) {
          return yup
            .string()
            .min(10)
            .max(12)
            .matches(
              /^[0-9]{10}|^[0-9]{12}$/,
              "Contact number must contain numberic character only"
            );
        }
        return yup.mixed().notRequired();
      }),
      "Student Email": yup.string().required().email(),
      "Parents Email": yup.string().email(),
    })
    .required();

  const {
    register,
    handleSubmit,
    unregister,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/all-grades/${schoolCode}`)
      .then((res) => res.json())
      .then((data) => {
        setGradeList(Object.keys(data.grade));
        setGradeData(data.grade);
        Object.entries(data.grade).forEach(([key, value]) => {
          if (key === Object.keys(data.grade)[0]) {
            setSectionList(value);
          }
        });
      });
    if (location.state?.mode === "edit") {
      document.title = "CSSoch | Edit Student";
      setEditMode(true);
      unregister("Username");
      unregister("Password");
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/student/details/${location.state?.id}`
      )
        .then((res) => res.json())
        .then((data) => {
          setEditMode(true);
          setStudentDetails(data.studentDetails);
          setFirstName(data.studentDetails.first_name);
          setMiddleName(data.studentDetails.middle_name);
          setLastName(data.studentDetails.last_name);
          setGender(data.studentDetails.gender);
          setStatus(data.studentDetails.status);
          setUsername(data.studentDetails.username);
          setGrade(data.studentDetails.grade);
          fetch(
            `${process.env.REACT_APP_API_BASE_URL}/all-grades/${schoolCode}`
          )
            .then((res) => res.json())
            .then((gradeData) => {
              Object.entries(gradeData.grade).forEach(([key, value]) => {
                if (Number(key) === data.studentDetails.grade) {
                  setSectionList(value);
                }
              });
            });
          setSection(data.studentDetails.section);
          setParentNumber1(data.studentDetails.phone);
          setParentNumber2(data.studentDetails.secondary_phone);
          setStudentEmail(data.studentDetails.email);
          setParentEmail(data.studentDetails.secondary_email);
          setisLoaded(true);
        });
    } else {
      document.title = "CSSoch | Add Student";
      fetch(`${process.env.REACT_APP_API_BASE_URL}/get-all-usernames`)
        .then((res) => res.json())
        .then((data) => {
          setUsernameList(data.username?.map((item) => item.split("@")[0]));
        });
      setisLoaded(true);
    }
  }, []);

  const generateUsername = (fname, lname) => {
    if(editMode) return;
    let count = 1;
    let uname;
    const initial_name = fname[0].toLowerCase() + lname[0].toLowerCase();
    uname = `${initial_name}`;
    if (usernameList.includes(uname)) {
      while (true) {
        uname = `${initial_name + count}`;
        count += 1;
        if (usernameList.includes(uname)) {
          continue;
        } else {
          setValue("Username", uname);
          break;
        }
      }
    } else {
      setValue("Username", uname);
    }
  };
  const handleOnGradeChange = (grade) => {
    setGrade(grade);
    gradeData &&
      Object.entries(gradeData).forEach(([key, value]) => {
        if (key === grade) {
          setSectionList(value);
        }
      });
  };

  const handleCloseSnackBar = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const updatestudentDetails = (update_data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(update_data),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/student/details/update`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setOpen(true);
        setTimeout(() => {
          navigate("/student/list");
        }, 1000);
      });
  };

  const editModeData = (inputData) => {
    const updateDetails = { user_id: location.state?.id };
    const studentDetailsUpdate = {};
    if (studentDetails.first_name !== inputData["First Name"]) {
      studentDetailsUpdate.first_name = inputData["First Name"];
      studentDetailsUpdate.fullname = inputData["Middle Name"]
        ? `${inputData["First Name"]} ${inputData["Middle Name"]} ${inputData["Last Name"]}`
        : `${inputData["First Name"]} ${inputData["Last Name"]}`;
    }
    if (studentDetails.middle_name !== inputData["Middle Name"]) {
      studentDetailsUpdate.middle_name = inputData["Middle Name"];
      studentDetailsUpdate.fullname = inputData["Middle Name"]
        ? `${inputData["First Name"]} ${inputData["Middle Name"]} ${inputData["Last Name"]}`
        : `${inputData["First Name"]} ${inputData["Last Name"]}`;
    }
    if (studentDetails.last_name !== inputData["Last Name"]) {
      studentDetailsUpdate.last_name = inputData["Last Name"];
      studentDetailsUpdate.fullname = inputData["Middle Name"]
        ? `${inputData["First Name"]} ${inputData["Middle Name"]} ${inputData["Last Name"]}`
        : `${inputData["First Name"]} ${inputData["Last Name"]}`;
    }
    if (studentDetails.gender !== gender) {
      studentDetailsUpdate.gender = gender;
    }
    if (studentDetails.grade !== grade || studentDetails.section !== section) {
      studentDetailsUpdate.grade = grade;
      studentDetailsUpdate.section = section;
    }
    if (studentDetails.status !== status) {
      studentDetailsUpdate.status = status;
    }
    if (studentDetails.phone !== inputData["Parents Number 1"]) {
      studentDetailsUpdate.phone = inputData["Parents Number 1"];
    }
    if (studentDetails.secondary_phone !== inputData["Parents Number 2"]) {
      studentDetailsUpdate.secondary_phone = inputData["Parents Number 2"];
    }
    if (studentDetails.email !== inputData["Student Email"]) {
      studentDetailsUpdate.email = inputData["Student Email"];
    }
    if (studentDetails.secondary_email !== inputData["Parents Email"]) {
      studentDetailsUpdate.secondary_email = inputData["Parents Email"];
    }
    updateDetails.details = studentDetailsUpdate;
    return updateDetails;
  };

  const studentInfo = (inputData) => {
    const addData = {};
    const addStudentData = {};
    addStudentData.first_name = inputData["First Name"];
    addStudentData.middle_name = inputData["Middle Name"];
    addStudentData.last_name = inputData["Last Name"];
    addStudentData.fullname = inputData["Middle Name"]
      ? `${inputData["First Name"]} ${inputData["Middle Name"]} ${inputData["Last Name"]}`
      : `${inputData["First Name"]} ${inputData["Last Name"]}`;
    addStudentData.gender = gender;
    addStudentData.status = status;
    addStudentData.username = `${inputData.Username}@${schoolCode}`;
    addStudentData.password = inputData.Password;
    addStudentData.phone = inputData["Parents Number 1"];
    addStudentData.secondary_phone = inputData["Parents Number 2"];
    addStudentData.email = inputData["Student Email"];
    addStudentData.secondary_email = inputData["Parents Email"];
    addStudentData.role = ["student"];
    addData.student_details = addStudentData;
    addData.grades = grade;
    addData.section = section;
    return addData;
  };
  const generatePassword = () => {
    const firstname = getValues("First Name").trim().toLowerCase();
    setValue("Password", firstname.substring(0, 4));
    const firstNameLength = firstname.length;
    if (firstNameLength <= 4) {
      const lastname = getValues("Last Name").trim().toLowerCase();
      setValue(
        "Password",
        firstname + lastname.substring(0, 4 - firstNameLength)
      );
    }
  };

  const addstudentDetails = (addData) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(addData),
    };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/student/add`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setOpen(true);
        setTimeout(() => {
          navigate("/student/list");
        }, 1000);
      });
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = (inputData) => {
    setDisableSave(isValid);
    if (editMode) {
      const updateDetails = editModeData(inputData);
      updatestudentDetails(updateDetails);
    } else {
      const addStudentDetails = studentInfo(inputData);
      if (addStudentDetails) addstudentDetails(addStudentDetails);
    }
  };
  return (
    <div style={{ backgroundColor: "#DDE9F5" }}>
      {isLoaded ? (
        <Container>
          <Typography variant="h6" component="h2" style={{ padding: 10 }}>
            {editMode ? "Edit" : "Add"} Student
            {editMode ? ` - ${username}` : ""}
          </Typography>
          <div
            style={{
              backgroundColor: "white",
              marginTop: 5,
              padding: 20,
              borderRadius: 6,
            }}
          >
            <Typography
              style={{ color: "#00868D" }}
              mb={2}
              variant="h6"
              component="h2"
            >
              Student details
            </Typography>
            {editMode ? (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}
                sx={{ top: "10vh !important" }}
              >
                <Alert
                  onClose={handleCloseSnackBar}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Student details updated successfully
                </Alert>
              </Snackbar>
            ) : (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={6000}
                onClose={handleCloseSnackBar}
                sx={{ top: "10vh !important" }}
              >
                <Alert
                  onClose={handleCloseSnackBar}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Student added successfully
                </Alert>
              </Snackbar>
            )}
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={3}>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormLabel>First Name*</FormLabel>
                    <TextField
                      className={classes.field}
                      variant="outlined"
                      required
                      fullWidth
                      defaultValue={editMode ? firstName : ""}
                      inputProps={{ style: { height: 10 } }}
                      {...register("First Name", {
                        onBlur: (e) => {
                          setFirstName(e.target.value);
                          generatePassword();
                        },
                      })}
                    />
                    {errors["First Name"] && (
                      <span
                        style={{
                          marginTop: -15,
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        {errors["First Name"].message}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Middle Name</FormLabel>
                    <TextField
                      className={classes.field}
                      variant="outlined"
                      required
                      fullWidth
                      defaultValue={editMode ? middleName : ""}
                      inputProps={{ style: { height: 10 } }}
                      {...register("Middle Name")}
                    />
                    {errors["Middle Name"] && (
                      <span
                        style={{
                          marginTop: -15,
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        {errors["Middle Name"].message}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Last Name*</FormLabel>
                    <TextField
                      className={classes.field}
                      variant="outlined"
                      required
                      fullWidth
                      defaultValue={editMode ? lastName : ""}
                      inputProps={{ style: { height: 10 } }}
                      {...register("Last Name", {
                        onBlur: (e) => {
                          setLastName(e.target.value);
                          generateUsername(firstName, e.target.value);
                          if (getValues("First Name").length <= 4)
                            generatePassword();
                        },
                      })}
                    />
                    {errors["Last Name"] && (
                      <span
                        style={{
                          marginTop: -15,
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        {errors["Last Name"].message}
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Gender*</FormLabel>
                    <Select
                      {...register("Gender")}
                      className={classes.field}
                      value={gender}
                      onChange={(e) => {
                        unregister("Gender");
                        setGender(e.target.value);
                      }}
                      SelectDisplayProps={{
                        style: { paddingTop: 10, paddingBottom: 10 },
                      }}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                    {errors.Gender && (
                      <span
                        style={{
                          marginTop: -15,
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        {errors.Gender.message}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Class*</FormLabel>
                    <Select
                      {...register("Class")}
                      className={classes.field}
                      value={grade}
                      onChange={(e) => {
                        unregister("Class");
                        handleOnGradeChange(e.target.value);
                      }}
                      SelectDisplayProps={{
                        style: { paddingTop: 10, paddingBottom: 10 },
                      }}
                      disabled={editMode}
                    >
                      {gradeList &&
                        gradeList.map((grad) => (
                          <MenuItem value={grad}>{grad}</MenuItem>
                        ))}
                    </Select>
                    {errors.Class && (
                      <span
                        style={{
                          marginTop: -15,
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        {errors.Class.message}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Section*</FormLabel>
                    <Select
                      {...register("Section")}
                      className={classes.field}
                      value={section}
                      onChange={(e) => {
                        unregister("Section");
                        setSection(e.target.value);
                      }}
                      SelectDisplayProps={{
                        style: { paddingTop: 10, paddingBottom: 10 },
                      }}
                    >
                      {sectionList &&
                        sectionList.map((section) => (
                          <MenuItem value={section}>{section}</MenuItem>
                        ))}
                    </Select>
                    {errors.Section && (
                      <span
                        style={{
                          marginTop: -15,
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        {errors.Section.message}
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Username*</FormLabel>
                    <TextField
                      disabled={editMode}
                      className={classes.field}
                      onChange={(e) => setUsername(e.target.value)}
                      variant="outlined"
                      required
                      fullWidth
                      defaultValue={editMode ? username : ""}
                      InputProps={{
                        style: { height: 44 },
                        endAdornment: (
                          <InputAdornment
                            style={{
                              visibility: editMode ? "hidden" : "visible",
                            }}
                            position="end"
                          >
                            @{schoolCode}
                          </InputAdornment>
                        ),
                        readOnly: !!editMode,
                      }}
                      {...register("Username")}
                    />
                    {errors.Username && (
                      <span
                        style={{
                          marginTop: -15,
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        {errors.Username.message}
                      </span>
                    )}
                    {!editMode && username && (
                      <span
                        style={{
                          marginTop: -15,
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        Username already exists
                      </span>
                    )}
                  </FormControl>
                </Grid>
                {!editMode && (
                  <Grid
                    style={{ display: editMode ? "none" : "block" }}
                    item
                    md={4}
                  >
                    <FormControl fullWidth>
                      <FormLabel>Password*</FormLabel>
                      <TextField
                        disabled
                        type={showPassword ? "text" : "password"}
                        className={classes.field}
                        variant="outlined"
                        required
                        fullWidth
                        InputProps={{
                          style: { height: 44 },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="togglepasswordvisibility"
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        {...register("Password", {
                          required: "Password is a required field",
                        })}
                      />
                      {errors.Password && (
                        <span
                          style={{
                            marginTop: -15,
                            marginBottom: 10,
                            color: "red",
                          }}
                        >
                          {errors.Password.message}
                        </span>
                      )}
                    </FormControl>
                  </Grid>
                )}
                <Grid item md={1}>
                  <Tooltip title="Password is first four characters of First Name">
                    <IconButton sx={{ marginTop: "28px" }}>
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Status*</FormLabel>
                    <RadioGroup
                      row
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <Stack spacing={2} direction="row">
                        <FormControlLabel
                          value="active"
                          control={<Radio />}
                          label="Active"
                        />
                        <FormControlLabel
                          value="inactive"
                          control={<Radio />}
                          label="In-active"
                        />
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Typography
                style={{ color: "#00868D" }}
                mb={2}
                mt={2}
                variant="h6"
                component="h2"
              >
                Student contact details
              </Typography>
              <Grid container spacing={3}>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Parent's number 1</FormLabel>
                    <TextField
                      className={classes.field}
                      onChange={(e) => setParentNumber1(e.target.value)}
                      variant="outlined"
                      required
                      fullWidth
                      defaultValue={editMode ? parentNumber1 : ""}
                      inputProps={{ style: { height: 10 } }}
                      {...register("Parents Number 1")}
                    />
                    {errors["Parents Number 1"] && (
                      <span
                        style={{
                          marginTop: -15,
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        {errors["Parents Number 1"].message}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Parent's number 2</FormLabel>
                    <TextField
                      className={classes.field}
                      onChange={(e) => setParentNumber2(e.target.value)}
                      variant="outlined"
                      required
                      fullWidth
                      defaultValue={editMode ? parentNumber2 : ""}
                      inputProps={{ style: { height: 10 } }}
                      {...register("Parents Number 2")}
                    />
                    {errors["Parents Number 2"] && (
                      <span
                        style={{
                          marginTop: -15,
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        {errors["Parents Number 2"].message}
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Email*</FormLabel>
                    <TextField
                      className={classes.field}
                      onChange={(e) => setStudentEmail(e.target.value)}
                      variant="outlined"
                      required
                      fullWidth
                      defaultValue={editMode ? studentEmail : ""}
                      inputProps={{ style: { height: 10 } }}
                      {...register("Student Email")}
                    />
                    {errors["Student Email"] && (
                      <span
                        style={{
                          marginTop: -15,
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        {errors["Student Email"].message}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Parent's Email</FormLabel>
                    <TextField
                      className={classes.field}
                      onChange={(e) => setParentEmail(e.target.value)}
                      variant="outlined"
                      required
                      fullWidth
                      defaultValue={editMode ? parentEmail : ""}
                      inputProps={{ style: { height: 10 } }}
                      {...register("Parents Email")}
                    />
                    {errors["Parents Email"] && (
                      <span
                        style={{
                          marginTop: -15,
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        {errors["Parents Email"].message}
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <h6 style={{ display: "none" }}>{render}</h6>
              <Stack
                spacing={2}
                direction="row"
                style={{ justifyContent: "center" }}
              >
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#00868D" }}
                  disabled={disableSave}
                >
                  Save
                </Button>

                <Button
                  onClick={() =>
                    navigate("/student/list")
                  }
                  variant="text"
                  style={{ color: "black" }}
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          </div>
        </Container>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
}
