// MyContext.js
import React, { createContext, useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import loadash from "lodash";
import { authUserContext } from "../Contexts/AuthUser";
import { PrimaryNavBarContext } from "../Layout/Layout";

const NotebookContext = createContext();

const NotebookContextProvider = ({ children }) => {
  const { user } = useContext(authUserContext);
  const { state } = useLocation();
  let { id } = useParams();
  const [grade, setGrade] = useState(state?.grade ? state?.grade : "");
  const [className, setClassName] = useState(state?.class ? state?.class : "");
  const [moduleNumber, setModuleNumber] = useState(
    state?.moduleNo ? state?.moduleNo : ""
  );
  const [studentUserId, setStudentUserId] = useState(
    state?.userId ? state?.userId : ""
  );
  const [notebookStrapiData, setNotebookStrapiData] = useState();
  const [notebookData, setNotebookData] = useState();
  const [dataLoadingCompleted, setDataLoadingCompleted] = useState(false);
  const [totalAssesmentLevels, setTotalAssesmentLevels] = useState(0);
  const [totalExerciseLevels, setTotalExerciseLevels] = useState(0);
  const [currentSectionActivated, setCurrentSectionActivated] = useState(
    state?.initial
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isTeachModule, setIsTeachModule] = useState(
    state?.view ? true : false
  );
  const [isTeacherSeeingStudentWork, setIsTeacherSeeingStudentWork] = useState(
    !state?.view && user?.role?.includes("teacher")
  );
  const [notebookId, setNotebookId] = useState(
    state?.notebookId ? state?.notebookId : id
  );
  const { setIsTeacherDashboard } = useContext(PrimaryNavBarContext);
  const [currentlyRunningLevel, setCurrentlyRunningLevel] = useState(null);

  useEffect(() => {
    setIsTeacherDashboard(false);
  }, []);

  useEffect(() => {
    const fetchNotebookFromStrapi = async () => {
      const strapiResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/cms/notebook-set/${notebookId}`
      );
      const strapiData = await strapiResponse.json();
      setNotebookStrapiData(strapiData);
      setTotalAssesmentLevels(
        strapiData.assessmentCells.filter(
          (assessment) =>
            assessment.type !== "text" && assessment.type !== "table"
        ).length
      );
      setTotalExerciseLevels(
        strapiData.exerciseCells.filter(
          (exercise) => exercise.type !== "text" && exercise.type !== "table"
        ).length
      );
    };
    const fetchUserNotebookData = async () => {
      if (isTeachModule) {
        setNotebookData({});
        return;
      }

      const requestiOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(studentUserId ? { userId: studentUserId } : null),
      };
      const userDataResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/notebook/${notebookId}`,
        requestiOptions
      );
      const userData = await userDataResponse.json();
      setNotebookData(userData);
    };
    if (notebookId) {
      Promise.all([fetchNotebookFromStrapi(), fetchUserNotebookData()]).then(
        () => {
          setDataLoadingCompleted(true);
        }
      );
    }
  }, [notebookId]);

  useEffect(() => {
    if (dataLoadingCompleted) {
      if (currentSectionActivated) {
        if (Object.keys(notebookData).length === 0) {
          const data = {
            name: notebookStrapiData?.notebookName,
            status: "ongoing",
          };
          if (currentSectionActivated === "Exercise") {
            data["exercise_status"] = "ongoing";
          }
          if (currentSectionActivated === "Assessment") {
            data["assessment_status"] = "ongoing";
          }
          uploadNotebookData(data);
        } else {
          let statusUpdate = {
            status:
              notebookData?.status !== "completed"
                ? "ongoing"
                : notebookData?.status,
          };
          if (currentSectionActivated === "Exercise") {
            if (
              !notebookData?.exercise_status ||
              notebookData?.exercise_status === "incomplete"
            ) {
              statusUpdate["exercise_status"] = "ongoing";
            }
            if (notebookData?.assessment_status === "ongoing") {
              statusUpdate["assessment_status"] = "incomplete";
            }
          } else {
            if (notebookData?.exercise_status === "ongoing") {
              statusUpdate["exercise_status"] = "incomplete";
            }
            if (
              !notebookData?.assessment_status ||
              notebookData?.assessment_status === "incomplete"
            ) {
              statusUpdate["assessment_status"] = "ongoing";
            }
          }
          if (Object.keys(statusUpdate).length) {
            uploadNotebookData(statusUpdate);
          }
        }
      } else {
        if (Object.keys(notebookData).length === 0) {
          uploadNotebookData({
            name: notebookStrapiData?.notebookName,
            status: "ongoing",
            exercise_status: notebookStrapiData?.exerciseCells?.length
              ? "ongoing"
              : undefined,
            assessment_status: notebookStrapiData?.assessmentCells?.length
              ? notebookStrapiData?.exerciseCells?.length
                ? "incomplete"
                : "ongoing"
              : undefined,
          });
          if (notebookStrapiData?.exerciseCells?.length) {
            setCurrentSectionActivated("Exercise");
          } else setCurrentSectionActivated("Assessment");
        } else {
          if (notebookData?.assessment_status === "ongoing") {
            setCurrentSectionActivated("Assessment");
          } else {
            let statusUpdate = {
              status:
                notebookData?.status !== "completed"
                  ? "ongoing"
                  : notebookData?.status,
            };
            if (
              notebookData?.assessment_status === "completed" &&
              totalAssesmentLevels > notebookData.assessment_levels_completed
            ) {
              statusUpdate.assessment_status = "incomplete";
            }
            if (
              notebookData?.exercise_status === "completed" &&
              totalExerciseLevels > notebookData.exercise_levels_completed
            ) {
              statusUpdate.exercise_status = "ongoing";
            }

            if (Object.keys(statusUpdate).length) {
              uploadNotebookData(statusUpdate);
            }
            if (
              isTeacherSeeingStudentWork &&
              Object.keys(notebookData).length === 1
            ) {
              if (notebookStrapiData?.exerciseCells?.length) {
                setCurrentSectionActivated("Exercise");
              } else setCurrentSectionActivated("Assessment");
            } else setCurrentSectionActivated("Exercise");
          }
        }
      }
      setIsLoading(false);
    }
  }, [dataLoadingCompleted]);

  const uploadNotebookData = async (data) => {
    if (isTeacherSeeingStudentWork) return;
    //updating the assement status, exercise status and notebook status
    if (
      data.assessment_levels_completed &&
      data.assessment_levels_completed === totalAssesmentLevels
    ) {
      data.assessment_status = "completed";
      if (
        notebookData.exercise_status === "completed" ||
        notebookStrapiData?.exerciseCells?.length === 0
      ) {
        data.status = "completed";
      }
    }
    if (
      data.exercise_levels_completed &&
      data.exercise_levels_completed === totalExerciseLevels
    ) {
      data.exercise_status = "completed";
      if (
        notebookData.assessment_status === "completed" ||
        notebookStrapiData?.assessmentCells?.length === 0
      ) {
        data.status = "completed";
      }
    }
    const uploadData = {
      notebookData: data,
    };

    if (isTeachModule) {
      const noetbookDataCopy = loadash.cloneDeep(notebookData);

      loadash.merge(noetbookDataCopy, data);
      setNotebookData(noetbookDataCopy);
      return;
    }
    const requestiOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(uploadData),
    };
    const uploadResponse = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/upload_notebook_data/${notebookId}`,
      requestiOptions
    );
    const uploadedData = await uploadResponse.json();
    setNotebookData(uploadedData.notebookData);
  };

  return (
    <NotebookContext.Provider
      value={{
        notebookId,
        notebookStrapiData,
        notebookData,
        isLoading,
        uploadNotebookData,
        currentSectionActivated,
        setCurrentSectionActivated,
        isTeachModule,
        grade,
        className,
        moduleNumber,
        isTeacherSeeingStudentWork,
        studentUserId,
        state,
        dataLoadingCompleted,
        currentlyRunningLevel,
        setCurrentlyRunningLevel,
      }}
    >
      {children}
    </NotebookContext.Provider>
  );
};

export { NotebookContext, NotebookContextProvider };
