import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function ClassSectionDetails(props) {
  const {gradeDetails, type=null} = props
  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Box sx={{ marginLeft: 0, marginRight: 2 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Class</TableCell>
                  <TableCell align="center">Section</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(gradeDetails).map(([classes, sectionsData]) => (
                  <TableRow
                    key={classes}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell scope="row" align="center">
                      {classes}
                    </TableCell>
                    <TableCell align="center">
                      {type === "teacher"
                        ? sectionsData.map((s, index) =>
                            index === sectionsData.length - 1 ? s : `${s}, `
                          )
                        : Object.keys(sectionsData).map((s, index) =>
                            Object.keys(sectionsData).length === index + 1
                              ? s
                              : `${s}, `
                          )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
}
