import React from "react";

function FinishScreen(props) {
  const {
    pageIsRendered,
    assesmentGamesetInfo,
    Game,
    setViewOnly,
    setRunStartTest,
    setAssesmentRunGameObj,
    setShowStartScreen,
    setShowTestScreen,
    setShowFinishScreen,
    gameScore,
    puzzleScore,
    pythonScore,
    teacher,
    teacherView,
  } = props;
  return (
    <div className="assesment-finish-screen">
      <button
        id="assesment-view-button"
        onClick={() => {
          if (!pageIsRendered && assesmentGamesetInfo) {
            setAssesmentRunGameObj(new Game(assesmentGamesetInfo?.gameType));
          }
          setViewOnly(true);
          setShowStartScreen(false);
          setShowTestScreen(true);
          setShowFinishScreen(false);
          setRunStartTest(true);
        }}
        style={teacher && !teacherView ? { marginTop: "3vw" } : {}}
      >
        Review Questions
      </button>
      <h2>Report</h2>
      <div className="assesment-finish-table-holder row" style={{ width: "100%" }}>
        {gameScore ? (
          <div className="assesment-finish-table-cell" style={{ width: "40%" }}>
            <h3>Games</h3>
            <table
              className="table table-bordered table-sm"
              style={{ borderCollapse: "collapse" }}
            >
              <thead>
                <tr>
                  <th scope="col">Question</th>
                  <th scope="col">Result</th>
                  <th scope="col">Score</th>
                </tr>
              </thead>
              <tbody className="game-assesment-review-box">
                {gameScore.map((level, index) => {
                  if (index !== gameScore.length - 1) {
                    return (
                      <tr>
                        <td scope="row">{level.num}</td>
                        <td className={`colour-${level.color}`}>
                          {level.result}
                        </td>
                        <td>
                          {level.score}
                          /5
                        </td>
                      </tr>
                    );
                  }
                  return (
                    <tr>
                      <td colSpan={2}>Total</td>
                      <td>
                        {level.score}/{level.total}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}

{pythonScore ? (
          <div className="assesment-finish-table-cell" style={{ width: "40%" }}>
            <h3>Python</h3>
            <table
              className="table table-bordered table-sm"
              style={{ borderCollapse: "collapse" }}
            >
              <thead>
                <tr>
                  <th scope="col">Question</th>
                  <th scope="col">Result</th>
                  <th scope="col">Score</th>
                </tr>
              </thead>
              <tbody className="python-assesment-review-box">
                {pythonScore.map((level, index) => {
                  if (index !== pythonScore.length - 1) {
                    return (
                      <tr>
                        <td scope="row">{level.num}</td>
                        <td className={`colour-${level.color}`}>
                          {level.result}
                        </td>
                        <td>
                          {level.score}
                          /5
                        </td>
                      </tr>
                    );
                  }
                  return (
                    <tr>
                      <td colSpan={2}>Total</td>
                      <td>
                        {level.score}/{level.total}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
        {puzzleScore ? (
          <div className="assesment-finish-table-cell" style={{ width: "40%" }}>
            <h3>Puzzles</h3>
            <table
              className="table table-bordered table-sm"
              style={{ borderCollapse: "collapse" }}
            >
              <thead>
                <tr>
                  <th scope="col">Question</th>
                  <th scope="col">Result</th>
                  <th scope="col">Score</th>
                </tr>
              </thead>
              <tbody className="puzzle-assesment-review-box">
                {puzzleScore.map((level, index) => {
                  if (index !== puzzleScore.length - 1) {
                    return (
                      <tr>
                        <td scope="row">{level.num}</td>
                        <td className={`colour-${level.color}`}>
                          {level.result}
                        </td>
                        <td>
                          {level.score}
                          /1
                        </td>
                      </tr>
                    );
                  }
                  return (
                    <tr>
                      <td colSpan={2}>Total</td>
                      <td>
                        {level.score}/{level.total}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default FinishScreen;
