import MarkdownView from "react-showdown";
import { Divider } from "@mui/material";
import PythonTestCases from "./PythonTestCases";
import VideoIcon from "../../../../Assets/Images/VideoIcon.svg";
export default function PythonInstruction(props) {
  const {
    pythonContainerRef,
    showTestCase,
    title,
    conceptDescription,
    instruction,
  } = props;
  return (
    <div
      id="python-instruction"
      ref={pythonContainerRef}
      style={{ height: "100%" }}
    >
      {props.type === "exercise" ? (
        <div className="demo-video-button">
          {props.strapiData.Python[0]?.videoUrl && (
            <button
              className="watch-help-video ml-auto"
              onClick={() => props.setHelpVideoPopup(true)}
            >
              <img style={{ width: "2vw", margin: 3 }} src={VideoIcon} />
            </button>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="python-instruction-title-container">
        <h1 id="python-instruction-title">{title}</h1>
      </div>
      <Divider sx={{ backgroundColor: "#00868d", opacity: "0.5" }} />
      <MarkdownView markdown={conceptDescription} />
      <Divider sx={{ backgroundColor: "#00868d", opacity: "0.5" }} />
      <MarkdownView markdown={instruction} />
      {showTestCase?.length > 0 && (
        <PythonTestCases showTestCase={showTestCase} />
      )}
    </div>
  );
}
