import React, { useState, useEffect } from "react";
import Papa from "papaparse";

const TableCell = (props) => {
  const { cellIndex, tableCellData } = props;
  const [csvData, setCsvData] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const parsedData = Papa.parse(tableCellData.url, {
          header: true,
          download: true,
          complete: function (results) {
            setHeaders(results?.meta?.fields);
            setCsvData(results?.data);
          },
        });
      } catch (error) {
        console.error("Error fetching CSV:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="table-cell">
      <h2 className="table-title">{tableCellData.title}</h2>
      <div className="table-content">
        <table>
          <thead>
            <tr>
              {headers.length > 0 &&
                headers.map((header) => <th key={header}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {csvData.map((row, index) => (
              <tr key={index}>
                {headers.map((value, index) => (
                  <td key={index}>{row[value]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableCell;
