import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import StudentProgressReport from "./StudentProgressReport";
import StoryButton from "./../ModuleComponentsButtons/StoryButton";
import GameButton from "../ModuleComponentsButtons/GameButton";
import PythonButton from "../ModuleComponentsButtons/PythonButton";
import PuzzleButton from "../ModuleComponentsButtons/PuzzleButton";
import AssesmentButton from "../ModuleComponentsButtons/AssesmentButton";
import { setStudentPerformanceData } from "../../features/TeacherUserData/StudentPerformanceData";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Loader from "../Loader/Loader";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily:"rajdhani-medium",
  },
}));

export default function ModuleSummary(props) {
  const {
    selectedModule,
    activeStudentId,
    setActiveStudentId,
    data,
    moduleType,
    moduleAssignedDate,
    moduleEndDate,
    selectedModuleId,
    section,
    grade,
    modulePublishStatus,
    setUpdateStudentPerformanceToggle,
    updateStudentPerformanceToggle,
    selectedModuleName,
    setActiveStudentName,
    activeStudentName,
    setActiveStudent,
    activeStudent,
  } = props;
  const navigate = useNavigate();
  const [showNoComponentModuleMessage, setShowNoComponentModuleMessage] =useState(false)
  const perfromanceData = useSelector(
    (state) => state.studentPerfromanceData?.studentPerformanceData
  );
  const dispatch = useDispatch();
  const [activeStudentPerformanceData, setActiveStudentPerformanceData] =
    useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setShowNoComponentModuleMessage(false)
    if ((data && modulePublishStatus===true) || (modulePublishStatus===false)) {
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (props.data?.status == "assigned") {
      updateActiveStudentPerformanceData();
    }
  }, [updateStudentPerformanceToggle]);

  const updateActiveStudentPerformanceData = () => {
    if (!activeStudentId) return;
    if (
      perfromanceData[selectedModule] &&
      perfromanceData[selectedModule][activeStudentId]
    ) {
      setActiveStudentPerformanceData(
        perfromanceData[selectedModule][activeStudentId]
      );
      return;
    }

    const payload = {
      id: selectedModuleId,
      student_id: activeStudentId,
      type: moduleType,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/student-performance`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (perfromanceData[selectedModule]) {
          dispatch(
            setStudentPerformanceData({
              ...perfromanceData,
              [selectedModule]: {
                ...perfromanceData[selectedModule],
                [activeStudentId]: data,
              },
            })
          );
        } else {
          dispatch(
            setStudentPerformanceData({
              ...perfromanceData,
              [selectedModule]: { [activeStudentId]: data },
            })
          );
        }
        setActiveStudentPerformanceData(data);
      });
  };
  function viewModule(type) {
    if (type === "teach module" && !data.structure.length) {
      setShowNoComponentModuleMessage(true)
      return;
    }
    const teacherState = {
      grade: grade,
      class: section,
      view: type === "teach module" ? true : false,
      userId: type === "teach module" ? data?.teacher_id : activeStudentId,
      module: selectedModuleId,
      moduleNo: selectedModule,
    };
    if (moduleType === "module") {
      navigate(`/moduleview/${selectedModuleId}`, {
        state: teacherState,
      });
    } else {
      navigate(`/notebookview/${selectedModuleId}`, {
        state: teacherState,
      });
    }
  }

  return !isLoading ? (
    <div className="dashboard-module-content">
      <div className="teacher-dashboard-header">
        <div className="'header-title-container d-flex align-items-center">
          <p className="header-title">{selectedModuleName}</p>
          <button
            className="view-module ml-3"
            onClick={() => viewModule("teach module")}
          >
            Teach Module
          </button>
        </div>
        {modulePublishStatus && data?.status === "assigned" ? (
          <div className="assigned-display">
            <p className="assigned-date">
              <b>Assigned: </b>
              {moduleAssignedDate}
            </p>
            <BootstrapTooltip placement="bottom" title="Change Date">
              <p
                className="deadline-date"
                data-toggle="tooltip"
                data-placement="bottom"
                title=""
                data-original-title="Change Date"
                onClick={() => {
                  props.handlePopupOpen("Change Deadline");
                }}
              >
                <b>Completion: </b>
                {moduleEndDate}
              </p>
            </BootstrapTooltip>
          </div>
        ) : (
          <div className="not-assigned-display">
            <button
              className="assign-module-button"
              disabled={!modulePublishStatus}
              onClick={() => props.handlePopupOpen("Change Deadline")}
            >
              Assign Module
            </button>
          </div>
        )}
      </div>
      <hr />
      {!modulePublishStatus && showNoComponentModuleMessage ?
          (<div className="no-module-component">
            <p>Currently, there are no components in this module. Soon we will add it.</p>
          </div>
          ):(
          <></>
          )
        }
      {modulePublishStatus ? (
        <div className="information-box">
          <div style={{ width: "33.33%" }}>
            <div className="students-box">
              {props.data?.student_info.length ? (
                props.data?.student_info?.map((student, index) => {
                  return (
                    <div
                      className={`student ${
                        activeStudent === index + 1 ? "active" : ""
                      }`}
                      onClick={() => {
                        setActiveStudent(() => index + 1);
                        setActiveStudentId(() => {
                          setUpdateStudentPerformanceToggle((pre) => !pre);
                          return student[0];
                        });
                        setActiveStudentName(() => student[1][0]);
                      }}
                      key={index}
                    >
                      <div className="student-name">
                        <p>
                          {student[1][0].length > 18
                            ? `${
                                student[1][0].split(" ")[0] +
                                " " +
                                student[1][0].split(" ")[1].charAt(0) +
                                "."
                              }`
                            : student[1][0]}
                        </p>
                      </div>
                      {moduleType === "module" ? (
                        <div className="student-progress-preview">
                          {data?.structure.map((type, index) => {
                            let component = null;

                            switch (type) {
                              case "story":
                                component = (
                                  <StoryButton
                                    key={index}
                                    status={student[1][1]?.story}
                                    icon={true}
                                  />
                                );
                                break;
                              case "game":
                                component = (
                                  <GameButton
                                    key={index}
                                    status={student[1][1]?.game}
                                    icon={true}
                                  />
                                );
                                break;
                              case "python":
                                component = (
                                  <PythonButton
                                    key={index}
                                    status={student[1][1]?.python}
                                    icon={true}
                                  />
                                );
                                break;
                              case "puzzle":
                                component = (
                                  <PuzzleButton
                                    key={index}
                                    status={student[1][1]?.puzzle}
                                    icon={true}
                                  />
                                );
                                break;
                              case "assessment":
                                component = (
                                  <AssesmentButton
                                    key={index}
                                    assesmentDetails={{
                                      status: student[1][1]?.assessment,
                                      isgamelevelgiveup:
                                        student[1][1]?.is_game_level_giveup,
                                      ispuzzlelevelgiveup:
                                        student[1][1]?.is_puzzle_level_giveup,
                                      ispythonlevelgiveup:
                                        student[1][1]?.is_python_level_giveup,
                                    }}
                                    teacher={true}
                                    icon={true}
                                  />
                                );
                                break;
                              default:
                                component = null;
                            }

                            return (
                              <>
                                {component}
                                {index !== data?.structure.length - 1 && (
                                  <img
                                    src="/static/svg/HorizontalBarTeacher.svg"
                                    className="bar"
                                    alt=""
                                  />
                                )}
                              </>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="student-progress-preview">
                          {student[1].length > 1 && student[1][1]?.exercise ? (
                            <StoryButton
                              status={
                                student[1][1]?.exercise
                                  ? student[1][1]?.exercise
                                  : "incomplete"
                              }
                              icon={true}
                            ></StoryButton>
                          ) : (
                            <></>
                          )}
                          {student[1].length > 1 &&
                          student[1][1]?.assessment &&
                          student[1][1]?.exercise ? (
                            <>
                              {" "}
                              <img
                                src="/static/svg/HorizontalBarTeacher.svg"
                                className="bar"
                                alt=""
                              />
                            </>
                          ) : (
                            <></>
                          )}
                          {student[1].length > 1 &&
                          student[1][1]?.assessment ? (
                            <AssesmentButton
                              assesmentDetails={{
                                status: student[1][1]?.assessment
                                  ? student[1][1]?.assessment
                                  : "incomplete",
                                isgamelevelgiveup:
                                  student[1][1]?.is_game_level_giveup,
                                ispuzzlelevelgiveup:
                                  student[1][1]?.is_puzzle_level_giveup,
                              }}
                              teacher={true}
                              icon={true}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
          <div style={{ width: "66.66%", height: "100%" }}>
            <StudentProgressReport
              activeStudentId={activeStudentId}
              selectedModuleId={selectedModuleId}
              studentName={activeStudentName}
              studentPerformanceData={activeStudentPerformanceData}
              moduleStatus={data?.status}
              goToViewStudentModulePage={viewModule}
              moduleType={moduleType}
              perfromanceData={perfromanceData}
              selectedModule={selectedModule}
            />
          </div>
        </div>
      ) : (
        <div className="not-assigned-box">
          <h3>Module Not Released</h3>
          <p>
            This module hasn’t been released yet. You will be able to assign
            this module once released.
          </p>
        </div>
      )}
    </div>
  ) : (
    <Loader />
  );
}
