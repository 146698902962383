import React from "react";
import TextCell from "./NotebookCells/TextCell";
import GameCell from "./NotebookCells/GameCell";
import PuzzleCell from "./NotebookCells/PuzzleCell";
import PythonCell from "./NotebookCells/PythonCell";
import TableCell from "./NotebookCells/TableCell";
import "../../Styles/Notebook.css";
import "../../Styles/Module.css";
import NotebookAccordion from "./NotebookAccordion";

function NotebookView({
  notebookStrapiData,
  stateChanges,
  setSelectedLevel,
  accordionNotOpen,
  setAccordionNotOpen,
  csvDataFiles,
  notebookType,
}) {
  return (
    <div className={`${notebookType}notebook-container`}>
      <div className={`notebook-${notebookType}-levels`}>
        {notebookStrapiData[`${notebookType}Cells`]?.map((cell, index) => {
          if (cell.type === "text") {
            return (
              <div
                id={`${notebookType}-cell-${index}`}
                className="cell-container"
                key={index}
              >
                <TextCell cellcontent={cell} cellIndex={index} />
              </div>
            );
          } else if (cell.type === "game") {
            return (
              <div
                id={`${notebookType}-cell-${index}`}
                className="cell-container"
                key={index}
              >
                <NotebookAccordion
                  accordionNotOpen={accordionNotOpen}
                  setAccordionNotOpen={setAccordionNotOpen}
                  index={index}
                  cell={cell}
                  setSelectedLevel={setSelectedLevel}
                  cellType={"Game"}
                  section={notebookType}
                >
                  <GameCell
                    gameSetData={cell}
                    cellIndex={index}
                    notebookType={notebookType}
                    resetState={stateChanges}
                    csvDataFiles={csvDataFiles.current}
                  />
                </NotebookAccordion>
              </div>
            );
          } else if (cell.type === "puzzle") {
            return (
              <div
                id={`${notebookType}-cell-${index}`}
                className="cell-container"
                key={index}
              >
                <NotebookAccordion
                  accordionNotOpen={accordionNotOpen}
                  setAccordionNotOpen={setAccordionNotOpen}
                  index={index}
                  cell={cell}
                  setSelectedLevel={setSelectedLevel}
                  cellType={"Puzzle"}
                  section={notebookType}
                >
                  <PuzzleCell
                    puzzleCellData={cell}
                    cellIndex={index}
                    notebookType={notebookType}
                  />
                </NotebookAccordion>
              </div>
            );
          } else if (cell.type === "python") {
            return (
              <div
                id={`${notebookType}-cell-${index}`}
                className="cell-container python-cell-container"
                key={index}
              >
                <NotebookAccordion
                  accordionNotOpen={accordionNotOpen}
                  setAccordionNotOpen={setAccordionNotOpen}
                  index={index}
                  cell={cell}
                  setSelectedLevel={setSelectedLevel}
                  cellType={"Python"}
                  section={notebookType}
                >
                  <PythonCell
                    cellIndex={index}
                    pythonCellData={cell}
                    teacher={false}
                    teacherView={false}
                    notebookType={notebookType}
                    notebookName={notebookStrapiData?.notebookName}
                  />
                </NotebookAccordion>
              </div>
            );
          } else if (cell.type === "table") {
            csvDataFiles.current = [
              ...csvDataFiles.current,
              [cell.title, cell.url],
            ];
            return (
              <div
                id={`${notebookType}-cell-${index}`}
                className="cell-container"
                key={index}
              >
                <NotebookAccordion
                  accordionNotOpen={accordionNotOpen}
                  setAccordionNotOpen={setAccordionNotOpen}
                  index={index}
                  cell={cell}
                  setSelectedLevel={setSelectedLevel}
                  cellType={"Data Table"}
                  section={notebookType}
                >
                  <TableCell cellIndex={index} tableCellData={cell} />
                </NotebookAccordion>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default NotebookView;
