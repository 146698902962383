import { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, Menu, MenuItem, Toolbar } from "@mui/material";
import "./PrimaryNav.css";
import PlayVideo from "../ModuleComponents/PlayVideo";

function HelpVideos(props) {
  const { videos } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openVideoPopup, setOpenVideoPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const open = Boolean(anchorEl);

  const handleHelpMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleHelpMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Toolbar style={{ padding: "0 23px 0 0" }}>
        <Button
          id="basic-button"
          className="user-name-btn"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleHelpMenuClick}
          endIcon={<ArrowDropDownIcon style={{ fontSize: "1.55vw" }} />}
          style={{ cursor: "pointer" }}
        >
          Help
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleHelpMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            ".MuiMenu-paper": {
              fontFamily: "rajdhani-medium",
              backgroundColor: "rgba(255,255,255,0.9)",
              border: "0.1vw solid #25A596",
              borderRadius: "1vw",
              marginTop: "-1.2vh",
            },
          }}
        >
          {videos?.map((video) => {
            return (
              <MenuItem
                key={video.label}
                onClick={() => {
                  setVideoUrl(video?.video);
                  setOpenVideoPopup(true);
                }}
                sx={{
                  color: "#00868d",
                  fontFamily: "rajdhani-medium",
                  fontSize: "1.55vw",
                }}
              >
                {video.label}
              </MenuItem>
            );
          })}
        </Menu>
      </Toolbar>
      <PlayVideo
        videoUrl={videoUrl}
        setHelpVideoPopup={setOpenVideoPopup}
        helpVideoPopup={openVideoPopup}
      />
    </>
  );
}

export default HelpVideos;
