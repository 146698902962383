import React from "react";

function InformatonBar(props) {
    const { textToDisplay } = props;
  return (
    <div
      className="d-flex justify-content-center"
      style={{ backgroundColor: "#AECB2A", padding: "5px" }}
    >
      <p
        style={{
          margin: 0,
          fontSize: "1.5vw",
          lineHeight: "2.2vw",
        }}
      >
        {textToDisplay}
      </p>
    </div>
  );
}

export default InformatonBar;
