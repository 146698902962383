import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Menu,
  MenuItem,
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState, useEffect } from "react";
import CompanyLogo from "../../Assets/Images/Company.svg";
import { authUserContext } from "../Contexts/AuthUser";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./PrimaryNav.css";
import { PrimaryNavBarContext } from "../Layout/Layout";
import HelpVideos from "./HelpVideos";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    fontFamily: "rajdhani-medium",
    border: "3px solid #25A596",
    borderRadius: "4vw",
    maxWidth: "60vw",
    width: "60vw",
    height: "fit-content",
    padding: "1rem",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255,255,255,0.85)",
  },
  "& #customized-dialog-title": {
    display: "flex",
    justifyContent: "center",
    fontFamily: "rajdhani-medium",
    fontSize: "5vw",
    margin: 0,
    padding: 0,
    marginBottom: "3.2vh",
    lineHeight: "5vw",
    fontWeight: "normal",
    marginTop: "1vw",
  },
  "& .MuiPaper-root .MuiTypography-gutterBottom": {
    textAlign: "center",
    fontFamily: "rajdhani-medium",
    fontSize: "2vw",
    marginTop: 0,
    marginBottom: "1rem",
  },
  "& .MuiDialogActions-root": {
    justifyContent: "center",
    padding: 0,
    margin: 0,
  },
  "& .MuiDialogActions-root Button": {
    fontFamily: "rajdhani-medium",
    color: "black",
    backgroundColor: "#FFB017",
    border: "none",
    borderRadius: "10px",
    padding: "1px 20px",
    fontSize: "1.5vw",
    transition: "0.5s",
    textTransform: "none",
    margin: 0,
    width: "5vw",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      transform: "scale(1.05)",
      transition: "0.5s",
    },
  },
  "& #change-password-action-buttons": {
    display: "flex",
    justifyContent: "space-between",
    width: "13vw",
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#343a40",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function PrimaryNav(props) {
  const location = useLocation();
  const {
    isAdminDashboardOrTeacherClassess,
    isTeacherDashboard,
    teacherGrade,
    teacherSection,
    isModulePage,
    navbarModuleData,
    isTeacherClassesPage,
  } = useContext(PrimaryNavBarContext);
  const isPopupNav = location.pathname.includes("notes") || location.pathname.includes("help-instructions") ? true : false;
  const { user, handleUserLogout } = useContext(authUserContext);
  const isErrorPage = useSelector((state) => state.navbarActions.isErrorPage);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [popup, setPopup] = useState(false);
  const [dialogContent, setDialogContent] = useState();
  const [helpVideos, setHelpVideos] = useState(null);

  useEffect(() => {
    const fetchHelpVideos = () => {
      if (helpVideos) return;
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/cms/video-instruction`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.length) {
            setHelpVideos(data);
          }
          else {
            setHelpVideos([]);
          }
        });
    };
    if (user.role.includes("teacher") && !isPopupNav) fetchHelpVideos();
  }, []);

  const handleLogoutStudents = () => {
    const payload = {
      grade: teacherGrade,
      section: teacherSection,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/logout-class`,
      requestOptions
    ).then((res) => {
      setDialogContent(
        <>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handlePopupClose}
          >
            Logout All Students
          </BootstrapDialogTitle>
          <Typography gutterBottom>All students has been logout. </Typography>
        </>
      );
    });
  };

  const handlePopupOpen = (type) => {
    setDialogContent(
      <>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handlePopupClose}
        >
          {type === "logout" ? "Logout All students" : "Password Reset"}
        </BootstrapDialogTitle>
        <Typography gutterBottom>
          {type === "logout"
            ? `Do you want to Logout All Students of Class ${teacherSection} ?`
            : "Do you want to change your password?"}
        </Typography>
        <DialogActions>
          <div id="change-password-action-buttons">
            <Button
              onClick={
                type === "logout" ? handleLogoutStudents : handleResetPassword
              }
              id="confirm-change-password"
            >
              Yes
            </Button>
            <Button
              onClick={
                type === "logout"
                  ? handlePopupClose
                  : handleChangePasswordPopupClose
              }
              id="denied-change-password"
            >
              No
            </Button>
          </div>
        </DialogActions>
      </>
    );
    setPopup(true);
  };

  const handlePopupClose = () => {
    setPopup(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePasswordPopupClose = () => {
    setPopup(false);
  };
  const handleResetPassword = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/reset-password`,
      requestOptions
    ).then((res) => {
      setDialogContent(
        <>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleChangePasswordPopupClose}
          >
            Password Reset
          </BootstrapDialogTitle>
          <Typography gutterBottom>
            Check the email you are registered with for further instructions.
          </Typography>
        </>
      );
    });
  };
  const handleLogout = () => {
    handleUserLogout();
  };
  if (isErrorPage) {
    return <></>;
  } else {
    return (
      <>
        <div id="rotateContainer">
          <div id="showRotateImage" />
        </div>
        <AppBar
          position="fixed"
          className={`primary-nav ${
            isAdminDashboardOrTeacherClassess ? "admin-nav" : ""
          }`}
          sx={{ boxShadow: "none" }}
        >
          <img
            src={CompanyLogo}
            alt="Company logo"
            id="company-logo"
            onClick={() => {
              document.location = `${process.env.REACT_APP_API_BASE_URL}/landing`;
              sessionStorage.removeItem("userId");
              sessionStorage.removeItem("userGrade");
              sessionStorage.removeItem("class");
              sessionStorage.removeItem("teacherView");
              sessionStorage.removeItem("moduleNo");
            }}
            style={{ cursor: "pointer" }}
          />
          {isModulePage && navbarModuleData ? (
            <>
              <div style={{ lineHeight: 1 }}>
                {user.role.includes("teacher") || (isPopupNav && !user.role.includes("student")) ? (
                  <p
                    className={`submodule-title ${
                      isPopupNav ? "notes-submodule-title" : ""
                    }`}
                  >
                    Grade {teacherGrade + " " + teacherSection}
                  </p>
                ) : (
                  <></>
                )}
                <p
                  className={`submodule-title ${
                    user.role.includes("teacher") || (isPopupNav && !user.role.includes("student"))
                      ? "module-name"
                      : "admin-school-name"
                  } ${isPopupNav ? "notes-submodule-title" : ""}`}
                >
                  {navbarModuleData.moduleName}
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
          {isAdminDashboardOrTeacherClassess || isTeacherDashboard ? (
            <p className="admin-school-name">
              {isTeacherDashboard
                ? "Grade " + teacherGrade + " " + teacherSection
                : user.schoolname}
            </p>
          ) : (
            <></>
          )}
          {!isPopupNav ? (
            <div className="user-dropdown">
              {isTeacherDashboard ? (
                <div className="col sec-nav-btn-container">
                  <button
                    className="logout-students-btn"
                    onClick={() => handlePopupOpen("logout")}
                  >
                    Logout Students
                  </button>
                </div>
              ) : (
                <></>
              )}
              {isTeacherClassesPage && helpVideos?.length > 0 && <HelpVideos videos={helpVideos} />}
              <Toolbar style={{ padding: "0 23px 0 0" }}>
                <Button
                  id="basic-button"
                  className="user-name-btn"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  endIcon={<ArrowDropDownIcon style={{ fontSize: "1.55vw" }} />}
                  style={{ cursor: "pointer" }}
                >
                  {user.fullname}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    ".MuiMenu-paper": {
                      fontFamily: "rajdhani-medium",
                      backgroundColor: "rgba(255,255,255,0.9)",
                      border: "0.1vw solid #25A596",
                      borderRadius: "1vw",
                      marginTop: "-1.2vh",
                      right: "31px !important",
                      left: "unset !important"
                    },
                  }}
                >
                  {!user.role.includes("student") && 
                    <MenuItem
                      onClick={() => handlePopupOpen("Change Password")}
                      sx={{
                        color: "#A2A2A2",
                        fontFamily: "rajdhani-medium",
                        fontSize: "1.55vw",
                      }}
                    >
                      Change Password
                    </MenuItem>
                  }
                  <MenuItem
                    onClick={handleLogout}
                    sx={{
                      color: "#B30A74",
                      fontFamily: "rajdhani-medium",
                      fontSize: "1.55vw",
                    }}
                  >
                    Logout
                    <svg
                      style={{
                        fill: "#B30A74",
                        height: "20px",
                        width: "20px",
                        marginLeft: "4px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path d="M534.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L434.7 224 224 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM192 96c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-53 0-96 43-96 96l0 256c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
                      </svg>
                    </svg>
                  </MenuItem>
                </Menu>
              </Toolbar>
            </div>
          ) : (
            <div></div>
          )}
        </AppBar>
        <BootstrapDialog
          onClose={handleChangePasswordPopupClose}
          aria-labelledby="customized-dialog-title"
          open={popup}
        >
          {dialogContent}
        </BootstrapDialog>
      </>
    );
  }
}
