import { Box, CircularProgress, Typography } from "@mui/material";
import { React, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import HorizontalBarChart from "../../Components/Charts/HorizontalBarChart";
import { authUserContext } from "../../Components/Contexts/AuthUser";

export default function ModuleProgress(props) {
  document.title = "CSSoch | Module Progress";
  const sectionReportLegend = {
    Completed: "#25a596",
    Incomplete: "#fe7d00",
    "Not Assigned": "#DBDBDB",
  };

  const [isLoading, setisLoading] = useState(true);
  const grade = useRef();
  const [tecaherName, setTeacehrName] = useState();
  const selectedSections = useRef([]);
  const [graphData, setGraphData] = useState({});
  const [moduleNames, setModuleNames] = useState([]);
  const [isStudents, setisStudents] = useState();
  const [noModuleAssigned, setNoModuleAssigned] = useState();
  const location = useLocation();
  const { user } = useContext(authUserContext);
  const schoolcode = location.state
    ? location.state.schoolcode
    : user.schoolcode;
  const [gradeWiseData, setGradeWiseData] = useState(
    JSON.parse(sessionStorage.getItem("module_progress_data"))?.hasOwnProperty(
      schoolcode
    )
      ? JSON.parse(sessionStorage.getItem("module_progress_data"))[schoolcode]
      : {}
  );
  let graphDataCollector = {};
  const dataFetchedRef = useRef(true);

  const [gradeChanged, setGradeChanged] = useState(true);
  useEffect(() => {
    setisLoading(true);
    setGraphData();
    setisStudents();
    setNoModuleAssigned();
    grade.current = props.grade;
    selectedSections.current = props.selectedSections;
    graphDataCollector = {};
    setGradeChanged((prev) => !prev);
  }, [props.grade]);

  useEffect(() => {
    const allSectionProgressData = {};
    const dataCollector = {};
    if (dataFetchedRef.current) {
      dataFetchedRef.current = false;
      return;
    }
    if (!gradeWiseData || !gradeWiseData[props.grade]) {
      for (const sec of props.selectedSections) {
        if (props.gradeData[props.grade].includes(sec)) {
          const schoolcode = location.state
            ? location.state.schoolcode
            : user.schoolcode;
          props.gradeData[props.grade].map((section) => {
            fetch(
              `${process.env.REACT_APP_API_BASE_URL}/module-progress/${schoolcode}/${props.grade}/${section}`
            )
              .then((res) => res.json())
              .then((data) => {
                if (selectedSections.current.includes(section)) {
                  allSectionProgressData[section] = data;
                  dataCollector[section] = data;
                } else {
                  allSectionProgressData[section] = data;
                }
                if (
                  Object.keys(dataCollector).length ===
                  selectedSections.current.length
                ) {
                  createGraphsData(dataCollector);
                }
                if (
                  Object.keys(allSectionProgressData).length ===
                  props.gradeData[props.grade].length
                ) {
                  setGradeWiseData((currentValue) => {
                    if (currentValue) {
                      currentValue[props.grade] = allSectionProgressData;
                      return currentValue;
                    }
                    const newValue = {};
                    newValue[props.grade] = allSectionProgressData;
                    return newValue;
                  });
                  const module_progress_data = JSON.parse(
                    sessionStorage.getItem("module_progress_data")
                  );
                  if (!module_progress_data.hasOwnProperty(schoolcode)) {
                    module_progress_data[schoolcode] = {};
                  }
                  module_progress_data[schoolcode][props.grade] =
                    allSectionProgressData;
                  sessionStorage.setItem(
                    "module_progress_data",
                    JSON.stringify(module_progress_data)
                  );
                }
              });
          });
          break;
        }
      }
    }
  }, [gradeChanged]);

  const [sectionsChanged, setSectionChanged] = useState(true);
  useEffect(() => {
    setGraphData();
    setisLoading(true);
    setisStudents();
    setNoModuleAssigned();
    selectedSections.current = props.selectedSections;
    graphDataCollector = {};
    setSectionChanged((prev) => !prev);
  }, [props.selectedSections]);

  useEffect(() => {
    const dataCollector = {};
    if (gradeWiseData && gradeWiseData[props.grade]) {
      Object.keys(gradeWiseData[props.grade]).map((section) => {
        const data = gradeWiseData[props.grade][section];
        if (selectedSections.current.includes(section)) {
          dataCollector[section] = data;
        }
        if (
          Object.keys(dataCollector).length === selectedSections.current.length
        ) {
          createGraphsData(dataCollector);
        }
      });
    }
  }, [sectionsChanged]);

  let createGraphsData = (sectionsData) => {
    let exitFunction = false;
    Object.keys(sectionsData).map((section) => {
      if (!props.gradeData[grade.current].includes(section)) {
        exitFunction = true;
      }
    });
    if (exitFunction) return;
    if (selectedSections.current.length === 1) {
      Object.keys(sectionsData).forEach((section) => {
        const sectionModuleNames = sectionsData[section].moduleNames;
        setModuleNames(sectionModuleNames);
        setTeacehrName(sectionsData[section].teacherName);
        if (sectionsData[section].haveStudents === true) {
          setisStudents(true);
          if (
            sectionsData[section][sectionModuleNames[0]].status ===
            "notassigned"
          ) {
            setNoModuleAssigned(true);
          } else {
            setNoModuleAssigned(false);
            setGraphData(sectionsData[section]);
          }
        } else {
          setisStudents(false);
        }
        setisLoading(false);
      });
    } else {
      let sectionWithNoStudentsCount = 0;
      let sectionToNoModuleAssinedCount = 0;
      Object.keys(sectionsData).forEach((section, index) => {
        const sectionModuleNames = sectionsData[section].moduleNames;
        if (sectionsData[section].haveStudents !== true)
          sectionWithNoStudentsCount++;
        if (
          sectionsData[section][sectionModuleNames[0]]?.status ===
            "notassigned" ||
          sectionModuleNames.length === 0
        )
          sectionToNoModuleAssinedCount++;
      });
      if (sectionWithNoStudentsCount === selectedSections.current.length) {
        setisStudents(false);
        setisLoading(false);
      } else {
        setisStudents(true);
        if (sectionToNoModuleAssinedCount === selectedSections.current.length) {
          setNoModuleAssigned(true);
          setisLoading(false);
        } else {
          setNoModuleAssigned(false);
          const sectionModuleNames =
            sectionsData[Object.keys(sectionsData)[0]].moduleNames;
          setModuleNames(sectionModuleNames);
          let lastAssignedModule = "";
          for (let moduleName of sectionModuleNames) {
            let assignedSections = [];
            let notAssignedSections = [];
            Object.keys(sectionsData).forEach((section, index) => {
              if (sectionsData[section][moduleName].status === "notassigned") {
                notAssignedSections.push(section);
              } else {
                assignedSections.push(section);
                lastAssignedModule = moduleName;
              }
              if (index == 0) {
                graphDataCollector["studentsCount"] =
                  sectionsData[section].studentsCount;
              } else {
                graphDataCollector.studentsCount +=
                  sectionsData[section].studentsCount;
              }
            });
            if (assignedSections.length === 0) {
              graphDataCollector[moduleName] = {
                status: "notassigned",
              };
            } else {
              assignedSections.forEach((section, index) => {
                if (index === 0) {
                  graphDataCollector[moduleName] = JSON.parse(
                    JSON.stringify(sectionsData[section][moduleName])
                  );
                } else {
                  const data = sectionsData[section][moduleName];
                  for (const [idx, value] of graphDataCollector[
                    moduleName
                  ].completed.entries()) {
                    graphDataCollector[moduleName].completed[idx] +=
                      data.completed[idx];
                  }
                  for (const [idx, value] of graphDataCollector[
                    moduleName
                  ].incomplete.entries()) {
                    graphDataCollector[moduleName].incomplete[idx] +=
                      data.incomplete[idx];
                  }
                  for (const [idx, value] of graphDataCollector[
                    moduleName
                  ].notAssigned.entries()) {
                    graphDataCollector[moduleName].notAssigned[idx] +=
                      data.notAssigned[idx];
                  }
                }
              });
              for (let section of notAssignedSections) {
                const data = sectionsData[section];
                for (const [idx, value] of graphDataCollector[
                  moduleName
                ].notAssigned.entries()) {
                  graphDataCollector[moduleName].notAssigned[idx] +=
                    data.studentsCount;
                }
              }
            }
          }
          graphDataCollector["lastAssignedModule"] = lastAssignedModule;
          setGraphData(graphDataCollector);
          setisLoading(false);
        }
      }
    }
  };

  return !isLoading ? (
    <div>
      <div>
        {selectedSections.current.length === 1 && (
          <Typography
            variant="subtitle1"
            style={{
              paddingLeft: 10,
              color: "#00868D",
              marginBottom: 3,
              fontFamily: "rajdhani-medium",
              fontSize: 20,
            }}
          >
            Teacher: {tecaherName}
          </Typography>
        )}
      </div>
      {isStudents && !noModuleAssigned && (
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
          {Object.keys(sectionReportLegend).map((key) => (
            <div
              key={key}
              style={{ display: "flex", alignItems: "center", paddingLeft: 15,paddingBottom:10 }}
            >
              <span
                style={{
                  height: 15,
                  width: 15,
                  backgroundColor: sectionReportLegend[key],
                  borderRadius: "50%",
                }}
              />
              <Typography
                variant="subtitle2"
                style={{
                  paddingLeft: 5,
                  color: "#484C51",
                  fontFamily: "rajdhani-medium",
                  fontSize: 15,
                }}
              >
                {key}
              </Typography>
            </div>
          ))}
        </div>
      )}
      {isStudents === false && (
        <Typography
          variant="subtitle1"
          style={{
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
            fontFamily: "rajdhani-medium",
            fontSize: 20,
          }}
        >
          No Student has been Added yet
        </Typography>
      )}
      {isStudents && noModuleAssigned && (
        <Typography
          variant="subtitle1"
          style={{
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
            fontFamily: "rajdhani-medium",
            fontSize: 20,
          }}
        >
          Course Not Started Yet
        </Typography>
      )}
      {isStudents &&
        !noModuleAssigned &&
        moduleNames &&
        moduleNames.map(
          (key, index) =>
            graphData[key]["status"] === "assigned" && (
              <HorizontalBarChart
                key={key}
                title={key}
                data={graphData}
                index={index}
                axisTicks={index === moduleNames.length - 1}
                topAxis={index === 0}
              />
            )
        )}
    </div>
  ) : (
    <Box sx={{ display: "flex", p: 5 }} justifyContent="center">
      <CircularProgress />
    </Box>
  );
}
