import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardSidebarData: {},
};

export const dashboardSidebarDataSlice = createSlice({
  name: "dashboardSidebarData",
  initialState,
  reducers: {
    setDashboardSidebarData: (state, action) => {
      state.dashboardSidebarData = action.payload;
    },
    updateDashboardSidebarData: (state, action) => {
      if (
        state.dashboardSidebarData[action.payload.grade][action.payload.section]
          .last_module_num < action.payload.moduleNum
      ) {
        state.dashboardSidebarData[action.payload.grade][
          action.payload.section
        ].last_module_num = action.payload.moduleNum;
      }
      state.dashboardSidebarData[action.payload.grade][
        action.payload.section
      ].modules.status[action.payload.moduleNum -1] = action.payload.status;
    },
  },
});

export const { setDashboardSidebarData, updateDashboardSidebarData } =
dashboardSidebarDataSlice.actions;
export default dashboardSidebarDataSlice.reducer;
