import {
  React, useEffect, useRef, useState,
} from 'react';
import Chart from 'react-apexcharts';

function AssesmentGamePythonPerformance(props) {
  const { type } = props;
  const [isSet, setisSet] = useState(false);
  const [state, setState] = useState();
  const [reRender, setRerender] = useState(true);
  const dataRefreshRef = useRef(true);
  const attempted = useRef(false);
  let completedAttempts;
  let gaveupAttempts;
  let categories;

  useEffect(() => {
    if (props.grade === props.data.grade) {
      setisSet(false);
      setState();
      setRerender((prev) => !prev);
    }
  }, [props.data.reRender]);

  useEffect(() => {
    if (dataRefreshRef.current) {
      dataRefreshRef.current = false;
      return;
    }
    if (!isSet) {
      let isAttempted = false;
      props.data.completedAttempts.forEach((item) => {
        if (item > 0) {
          isAttempted = true;
          attempted.current = true;
        }
      });
      props.data.gaveupAttempts.forEach((item) => {
        if (item > 0) {
          attempted.current = true;
          isAttempted = true;
        }
      });
      if (isAttempted) {
        completedAttempts = props.data.completedAttempts;
        gaveupAttempts = props.data.gaveupAttempts;
      } else {
        completedAttempts = [];
        gaveupAttempts = [];
      }

      categories = props.data.categories;
      setState({
        series: [{
          name: 'Median No. of Attempts of Students who Completed',
          data: completedAttempts,
        }, {
          name: 'Median No. of Attempts of Students who Needed Help',
          data: gaveupAttempts,
        },
        ],
        options: {
          colors: ['#25a596', '#B30A74'],
          chart: {
            type: 'bar',
            height: 350,
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          }],
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          stroke: {
            show: true,
            width: 5,
            colors: ['transparent'],
          },
          xaxis: {
            type: 'text',
            categories,
            labels: {
              style: {
                fontFamily: 'rajdhani-medium',
                fontSize: '15px',
              },
            },
            title: {
              text: `${type} Levels`,
              offsetY: 95,
              style: {
                fontFamily: 'rajdhani-medium',
                fontSize: '15px',
              },
            },
            axisBorder: {
              show: true,
              color: '#78909C',
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: 'Med. No. of Attempts',
              style: {
                fontFamily: 'rajdhani-medium',
                fontSize: '15px',
                cssClass: 'apexcharts-yaxis-title',
              },
            },
            labels: {
              formatter(val) {
                return val.toFixed(0);
              },
              style: {
                fontFamily: 'rajdhani-medium',
                fontSize: '15px',
              },
            },
            axisBorder: {
              show: true,
              color: '#78909C',
              offsetX: 0,
              offsetY: 0,
            },
          },
          title: {
            text: `${type} Performance`,
            align: 'center',
            style: {
              fontFamily: 'rajdhani-semibold',
              fontSize: '22px',
              color: '#263238',
            },
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'left',
            fontFamily: 'rajdhani-medium',
            fontSize: '15px',
            offsetX: 30,
            onItemClick: {
              toggleDataSeries: false,
            },
            onItemHover: {
              highlightDataSeries: false,
            },
            markers: {
              width: 15,
              height: 15,
              strokeWidth: 0,
              strokeColor: '#fff',
              fillColors: undefined,
              radius: 15,
              customHTML: undefined,
              onClick: undefined,
              offsetX: 0,
              offsetY: 2,
            },
            formatter: function(seriesName, opts) {
              return '<span style="margin-left: 3px;margin-right: 5px">' + seriesName + '</span>';
            }
          },
          fill: {
            opacity: 1,
          },
          noData: {
            text: 'No Data Available',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              fontFamily: 'rajdhani-medium',
              fontSize: 15,
            },
          },
        },
      });
      setisSet(true);
    }
  }, [reRender]);

  return (
    <>
      {isSet
      && <Chart options={state.options} series={state.series} height={attempted.current ? '524' : '475'} type="bar" />}
    </>
  );
}

export default AssesmentGamePythonPerformance;
