import React from "react";
import { Typography } from "@mui/material";

function ModuleCompletion(props) {
  const { module_completion_data, isPython, moduleData } = props;
  return (
    <div className='module-completion-container'>
      <div className='module-completion-header'>
        <Typography variant='h6' component='h4' className='progress-header'>
          Module Completion
        </Typography>
        <div className='color-status-container'>
          <div className='module-completion-color-status'>
            <span className='module-completion-color-status-dot correct'></span>
            <span className='module-completion-color-status-lable'>
              Correct
            </span>
          </div>
          <div className='module-completion-color-status'>
            <span className='module-completion-color-status-dot incorrect'></span>
            <span className='module-completion-color-status-lable'>
              Incorrect
            </span>
          </div>
          <div className='module-completion-color-status'>
            <span className='module-completion-color-status-dot gave-up'></span>
            <span className='module-completion-color-status-lable'>
              Needed help
            </span>
          </div>
        </div>
      </div>
      <div className='module-completion-content'>
        <div className='module-completion-row'>
          <div className='module-completion-row-content module-completion-row-content-header'>
            <div
              style={{
                width: `${isPython ? "30%" : "35%"}`,
              }}
            >
              <Typography
                variant='h6'
                component='h4'
                className='module-completion-sub-header'
              >
                MODULE
              </Typography>
              <div></div>
            </div>
            <div
              style={{
                width: `${isPython ? "24%" : "35%"}`,
              }}
            >
              <Typography
                variant='h6'
                component='h4'
                className='module-completion-sub-header'
              >
                GAMES
              </Typography>
            </div>
            {isPython && (
              <div
                style={{
                  width: `${isPython ? "24%" : "30%"}`,
                }}
              >
                <Typography
                  variant='h6'
                  component='h4'
                  className='module-completion-sub-header'
                >
                  PYTHON
                </Typography>
              </div>
            )}
            <div
              style={{
                width: `${isPython ? "22%" : "30%"}`,
              }}
            >
              <Typography
                variant='h6'
                component='h4'
                className='module-completion-sub-header'
              >
                PUZZLES
              </Typography>
            </div>
          </div>
        </div>
        <div className='module-completion-row'>
          <div className='module-completion-row-content'>
            {module_completion_data &&
              module_completion_data.map((module, index) => (
                <div className='module-completion-module-row' key={index}>
                  <div style={{ width: `${isPython ? "30%" : "35%"}` }}>
                    <Typography
                      variant='h6'
                      component='h4'
                      className='module-completion-module-label'
                    >
                      <span className='module-label'>M{index + 1}: </span>
                      {module[0]}
                    </Typography>
                    <div></div>
                  </div>
                  <div
                    style={{
                      width: `${isPython ? "24%" : "35%"}`,
                    }}
                  >
                    <div className='module-completion-games'>
                      <div
                        style={{
                          width: `${
                            module[1] == "-" ? "0%" : module[1].replace("*", "")
                          }`,
                          minWidth: `${
                            module[1] === "0%"
                              ? "0%"
                              : module[1] === "-"
                              ? "0%"
                              : isPython
                              ? "20%"
                              : "15%"
                          }`,
                        }}
                        className={`module-completion-games-content ${
                          module[1].includes("*")
                            ? "module-completion-game-give-up"
                            : ""
                        }`}
                      >
                        <span className='module-completion-games-progress'>
                          {moduleData?.statuses[index] === "locked"
                            ? "-"
                            : module[1].replace("*", "")}
                        </span>
                      </div>
                    </div>
                  </div>
                  {isPython && (
                    <div style={{ width: "24%" }}>
                      <div className='module-completion-python'>
                        <div
                          style={{
                            width: `${
                              module.length === 3
                                ? "0%"
                                : module[2] === "-" ? "0%" : module[2].replace("*", "")
                            }`,
                            minWidth: `${
                              module.length === 4 ?
                              (module[2]?.replace("*", "") === "0%" || module[2] === "-")
                                ? "0%"
                                : "20%" : "0%"
                            }`,
                          }}
                          className={`module-completion-python-content ${
                            module.length === 4 && module[2].includes("*")
                              ? "module-completion-game-give-up"
                              : ""
                          }`}
                        >
                          <span className='module-completion-python-progress'>
                            {module.length === 3 ||
                            moduleData?.statuses[index] === "locked"
                              ? "-"
                              : module[2].replace("*", "")}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      width: `${isPython ? "16%" : "20%"}`,
                      padding: `${isPython ? "0 3%" : "0 5%"}`,
                    }}
                    className={`module-completion-puzzles ${moduleData?.statuses[index] === "locked" ? "module-completion-puzzles-locked" : ""}`}
                  >
                    <div
                      className='module-completion-puzzles-status module-completion-puzzles-correct-status'
                    >
                      {moduleData?.statuses[index] === "locked"
                        ? "-"
                        : module.length === 4
                        ? module[3][0]
                        : module[2][0]}
                    </div>
                    <div className='horizontal-bar-container'>
                      <div className='horizontal-bar'></div>
                    </div>
                    <div
                      className='module-completion-puzzles-status module-completion-puzzles-incorrect-status'
                    >
                      {moduleData?.statuses[index] === "locked"
                        ? "-"
                        : module.length === 4
                        ? module[3][1]
                        : module[2][1]}
                    </div>
                    <div className='horizontal-bar-container'>
                      <div className='horizontal-bar'></div>
                    </div>
                    <div
                      className='module-completion-puzzles-status module-completion-puzzles-gave-up-status'
                    >
                      {moduleData?.statuses[index] === "locked"
                        ? "-"
                        : module.length === 4
                        ? module[3][2]
                        : module[2][2]}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModuleCompletion;
