import Game from "@smaskar/gameslib";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Arrow_down_white from "../../../Assets/Images/Arrow_down_white.svg";
import Arrow_left_white from "../../../Assets/Images/Arrow_left_white.svg";
import Arrow_right_white from "../../../Assets/Images/Arrow_right_white.svg";
import Arrow_up_white from "../../../Assets/Images/Arrow_up_white.svg";
import CheetiRight from "../../../Assets/Images/CheetiRight.svg";
import VideoIcon from "../../../Assets/Images/VideoIcon.svg";
import { carryForwardPreviousLevelCode } from "../../../Utils/CarryForwardCode";
import { calculateHeight } from "../../../Utils/helper";
import { loadGamefromLib } from "../../../Utils/LoadGamefromLib";
import ErrorBox from "../FeedbackBox/ErrorBox";
import GoodJobFeedbackBox from "../FeedbackBox/GoodJobFeedbackBox";
import PlayVideo from "../PlayVideo";
import LevelButtons from "./LevelButtons";

function arrow_keys_handler(e) {
  if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
    e.preventDefault();
  }
}
export default function Game1(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [gameInfoObj, setGameInfoObj] = useState();
  const [gameInfo, setGameInfo] = useState();
  const [currentLevel, setCurrentLevel] = useState(1);
  const [previousLevel, setPreviousLevel] = useState(1);
  const [runGameObj, setRunGameObj] = useState();
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something’s not right!");
  const [showSuccess, setShowSucess] = useState(false);
  const [sucessMsg, setSucessMsg] = useState("That was great!");
  const [showFeedback, setShowFeedback] = useState(true);
  const [codingGameSubmitButtonText, setCodingGameSubmitButtonText] =
    useState("Run Code");
  const [helpVideoPopup, setHelpVideoPopup] = useState(false);
  const [intructionBoxHeight, setInstructionBoxHeight] = useState(64.6262);
  const [isRendredOnce, setIsRenderedOnce] = useState(false);
  const [currentLevelJson, setCurrentLevelJson] = useState();
  const [levelsJson, setLevelsJson] = useState([]);
  const [codeCarryForwardLevels, setCodeCarryForwardLevels] = useState([]);
  const [itemName, setItemName] = useState("game");
  const { search } = useLocation();
  let view;
  if (new URLSearchParams(search).get("view")) {
    view = true;
  } else {
    view = false;
  }
  const blocksCountContainerRef = useRef(null);
  const blocksCountRef = useRef(null);
  const gameInstructionRef = useRef(null);

  useEffect(() => {
    if (props.currentSection === "game") {
      props.teacher && !props.teacherView
        ? (document.title = "CSSoch | Review Game")
        : (document.title = "CSSoch | Game");
      if (!isRendredOnce) {
        const fetchPromises = props.strapiData.Game[0].Levels.map((level) =>
          fetch(level["Config File"]).then((res) => res.json())
        );
        Promise.all(fetchPromises)
          .then((results) => {
            results.map((json, index) => {
              if (json.hasOwnProperty("carryForwardCode"))
                setCodeCarryForwardLevels((prevLevels) => [
                  ...prevLevels,
                  index + 1,
                ]);
            });
            const updatedLevelsJson = [...levelsJson, ...results];
            setLevelsJson(updatedLevelsJson);
            setGameInfoObj(props.strapiData.Game[0]);
            setGameInfo(
              loadGamefromLib(props.strapiData.Game[0], false, false)
            );
          })
          .catch((error) => {
            console.error("Error: ", error);
          });
      } else if (
        runGameObj &&
        gameInfo.options.structure === "codingEnvironment"
      )
        runGameObj.getBlocksCount();
    } else {
      window.removeEventListener("keydown", arrow_keys_handler, false);
      if (runGameObj) runGameObj.restart();
    }
    setShowError(false);
    setShowSucess(false);
  }, [props.currentSection]);
  useEffect(() => {
    async function initGame() {
      if (gameInfo) {
        if (!runGameObj) setRunGameObj(new Game(gameInfo.gameType));
        await setupGameStatistics();
        await setupGameLevelButtons();
        setIsLoading(false);
      }
    }
    initGame();
  }, [gameInfo]);
  useEffect(() => {
    if (!isLoading) {
      if (runGameObj && !isRendredOnce) {
        // Setup game with options
        runGameObj.setup(gameInfo.options, false);
        runGameObj.loadBlocklyImgs(gameInfo.blocklyAsset);
        // Load the assets
        runGameObj.loadAssets(gameInfo.assets);
        setIsRenderedOnce(true);
      }
      if (props.teacher && !props.teacherView) updateLevelColors();
      loadLevel(currentLevel);
      return () => {
        runGameObj.restart();
      };
    }
  }, [isLoading]);
  useEffect(() => {
    if (!isLoading) {
      loadLevel(currentLevel);
    }
  }, [currentLevel]);
  useEffect(() => {
    if (currentLevelJson && currentLevelJson.hasOwnProperty("maxBlocks"))
      runGameObj.getBlocksCount();
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (blocksCountRef.current) {
          if (blocksCountRef.current.innerText > currentLevelJson.maxBlocks) {
            blocksCountContainerRef.current.style.border =
              "0.2vw solid #B30A74";
            blocksCountContainerRef.current.style.borderTop = "none";
          } else {
            blocksCountContainerRef.current.style.border = "none";
          }
        }
      });
    });
    if (blocksCountRef.current) {
      observer.observe(blocksCountRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }
    return () => {
      observer.disconnect();
    };
  }, [currentLevelJson, blocksCountRef]);
  useEffect(() => {
    // Additional height calculation when instruction content changes
    calculateHeight(gameInstructionRef, setInstructionBoxHeight);
  }, [gameInstructionRef.current?.clientHeight]);

  async function setupGameStatistics() {
    if (!props.statusStorage.game_levels.length) {
      const gameLevels = [];
      for (let i = 0; i < gameInfo.levels.length; i++) {
        gameLevels.push({
          feedback: "unavailable",
          code: "",
          attempts: 0,
          status: "incomplete",
        });
      }
      await props.updateStatusStorage(
        { game_levels: gameLevels },
        itemName
      );
    }
  }
  async function setupGameLevelButtons(level=null) {
    let currentLvl = 1;
    if (level) {
      setCurrentLevel(level);
      setPreviousLevel(level);
      currentLvl = level;
    }
    for (let i = 0; i < gameInfo.levels.length; i++) {
      if (props.statusStorage.game_levels[i]?.status === "ongoing") {
        if(level && level !== i + 1) {
          const gameLevelsStatus = JSON.parse(JSON.stringify(props.statusStorage.game_levels));
          gameLevelsStatus[i].status = "incomplete";
          if(gameLevelsStatus[level - 1]?.status === "incomplete") gameLevelsStatus[level - 1].status = "ongoing";
          const statusUpdated = await props.updateStatusStorage({ game_levels: gameLevelsStatus }, itemName);
          if(statusUpdated) return;
        }
        else {
          setCurrentLevel(i + 1);
          setPreviousLevel(i + 1);
          currentLvl = i + 1;
        }
      }
    }
    if (
      currentLvl === 1 &&
      props.statusStorage.game_levels[0]?.status !== "ongoing" && !level
    ) {
      for (let i = 0; i < props.statusStorage.game_levels.length; i++) {
        if (props.statusStorage.game_levels[i]?.status === "incomplete") {
          const gameLevelsStatus = JSON.parse(JSON.stringify(props.statusStorage.game_levels));
          gameLevelsStatus[i].status = "ongoing";
          const statusUpdated = await props.updateStatusStorage({ game_levels: gameLevelsStatus }, itemName);
          if(statusUpdated) return;
          setCurrentLevel(i + 1);
          setPreviousLevel(i + 1);
          break;
        }
      }
    }
  }
  function runGame(jsonData) {
    setCurrentLevelJson(jsonData);
    runGameObj.loadLevel(jsonData);
    if (gameInfoObj.Levels[currentLevel - 1].DemoLevel) {
      if (gameInfo.options.structure === "playgroundEnvironment") {
        runGameObj.setOnFinishedLoading(() => {
          runGameObj.loadCode(jsonData.solution.toString());
        });
      } else {
        runGameObj.loadCode(jsonData.solution);
      }
    } else if (props.statusStorage.game_levels[currentLevel - 1].code) {
      if (gameInfo.options.structure === "playgroundEnvironment") {
        runGameObj.setOnFinishedLoading(() => {
          const loadedcode =
            props.statusStorage.game_levels[currentLevel - 1].code;
          runGameObj.loadCode(loadedcode.toString());
        });
      } else {
        const loadedcode =
          props.statusStorage.game_levels[currentLevel - 1].code;
        runGameObj.loadCode(loadedcode.toString());
      }
    } else if (gameInfo.options.structure === "playgroundEnvironment") {
      runGameObj.setOnFinishedLoading(() => {
        // runGameObj.loadCode('');
      });
    } else if (
      jsonData.hasOwnProperty("carryForwardCode") &&
      jsonData.carryForwardCode &&
      (props.statusStorage.game_levels[currentLevel - 2]?.status ===
        "completed" ||
        jsonData.statusToCarryForwardCode ===
          props.statusStorage.game_levels[currentLevel - 2].status)
    ) {
      const previousLevelCode =
        props.statusStorage.game_levels[currentLevel - 2].code;
      const codeToLoad = carryForwardPreviousLevelCode(
        previousLevelCode,
        runGameObj.fetchCode()
      );
      runGameObj.loadCode(codeToLoad.toString());
    }
  }
  async function runCode() {
    const statuses = JSON.parse(JSON.stringify(props.statusStorage));
    const levelsData = JSON.parse(
      JSON.stringify(props.statusStorage.game_levels)
    );
    let blocksUsed;
    if (gameInfo.options.structure === "codingEnvironment")
      blocksUsed = runGameObj.getBlocksCount();
    runGameObj.setFailure((errorCode) => {
      for (const item of gameInfo.errorMessages) {
        if (item.Code === errorCode) {
          setErrorMsg(item.Message);
          if (!props.teacher || (props.teacher && props.teacherView))
            setShowError(true);
          break;
        }
      }
      window.removeEventListener("keydown", arrow_keys_handler, false);
    });
    runGameObj.setSuccess(async () => {
      if (
        gameInfo.options.structure === "codingEnvironment" &&
        currentLevelJson.hasOwnProperty("maxBlocks") &&
        currentLevelJson.maxBlocks &&
        currentLevelJson.maxBlocks < blocksUsed
      ) {
        setErrorMsg(
          "You found the solution but you have used more blocks than expected"
        );
        if (!props.teacher || (props.teacher && props.teacherView))
          setShowError(true);
        window.removeEventListener("keydown", arrow_keys_handler, false);
      } else {
        if (levelsData[currentLevel - 1]?.status !== "completed") {
          statuses["exercise_game_levels_completed"]++;
          statuses["total_game_levels_completed"]++;
          statuses.game_levels = levelsData;
          levelsData[currentLevel - 1].status = "completed";
          let statusUpadted = await props.updateStatusStorage(statuses, itemName);
          if (!statusUpadted) {
            return;
          }
        }
        if (levelsData[currentLevel - 1].feedback === "unavailable")
          setShowFeedback(true);
        else setShowFeedback(false);
        const x = gameInfo.successMessages.length;
        if (x > 0) {
          const msg = gameInfo.successMessages[Math.floor(Math.random() * x)];
          if (msg === "") {
            setSucessMsg(gameInfo.successMessages[0]);
          } else {
            setSucessMsg(msg);
          }
        } else {
          setSucessMsg("");
        }
        if (!props.teacher || (props.teacher && props.teacherView))
          setShowSucess(true);
        if (
          (!props.teacher || (props.teacher && props.teacherView)) &&
          !(statuses?.game_status === "completed")
        ) {
          updateLevelColors(statuses);
        }
        window.removeEventListener("keydown", arrow_keys_handler, false);
      }
    });
    if(gameInfo.options.structure === "codingEnvironment") runGameObj.runCode();
    levelsData[currentLevel - 1].code = runGameObj.fetchCode();
    if (levelsData[currentLevel - 1]?.status !== "completed") {
      levelsData[currentLevel - 1].status = "ongoing";
      levelsData[currentLevel - 1].attempts += 1;
      statuses.game_levels = levelsData;
      let statusUpdated = await props.updateStatusStorage(statuses, itemName);
      if (!statusUpdated) {
        return;
      }
    }
    await setupGameLevelButtons(currentLevel);
    if(gameInfo.options.structure !== "codingEnvironment") runGameObj.runCode();
  }
  function updateLevelColors(statuses = JSON.parse(JSON.stringify(props.statusStorage))) {
    let allComplete = true;
    for (let i = 0; i < statuses?.game_levels?.length; i++) {
      if (statuses.game_levels[i]?.status !== "completed")
        allComplete = false;
    }
    if (allComplete && statuses?.game_status !== "completed") {
      statuses["game_status"] = "completed";
      props.updateStatusStorage(statuses, itemName);
    } else if (
      statuses?.game_status !== "ongoing" &&
      statuses?.game_status !== "completed"
    ) {
      statuses["game_status"] = "ongoing";
      props.updateStatusStorage(statuses, itemName);
    }
  }
  const onResizeWindow = () => {
    calculateHeight(gameInstructionRef, setInstructionBoxHeight);
  };
  async function loadLevel(level) {
    window.removeEventListener("resize", onResizeWindow);
    calculateHeight(gameInstructionRef, setInstructionBoxHeight);
    // Recalculate height on window resize
    window.addEventListener("resize", onResizeWindow);
    const statuses = JSON.parse(JSON.stringify(props.statusStorage));
    let statusUpdated = false;
    if (
      props.statusStorage?.game_status !== "completed" &&
      statuses.game_levels[level - 1]?.status !== "ongoing"
    ) {
      for (let i = 0; i < statuses.game_levels.length; i++) {
        if (statuses.game_levels[i]?.status !== "completed") {
          statuses.game_levels[i].status = "incomplete";
        }
      }
      if (statuses.game_levels[level - 1]?.status !== "completed") {
        statuses.game_levels[level - 1].status = "ongoing";
      }
      statusUpdated = await props.updateStatusStorage(statuses, itemName);
    }
    setShowError(false);
    setShowSucess(false);
    setCodingGameSubmitButtonText("Run Code");
    runGame(levelsJson[level - 1]);
    if (!props.teacher || (props.teacher && props.teacherView)) {
      if (statusUpdated) updateLevelColors(statuses);
      else updateLevelColors();
    }
    setPreviousLevel(currentLevel);
    window.removeEventListener("keydown", arrow_keys_handler, false);
  }
  async function handleItemLikedFeedback() {
    const gameLevels = JSON.parse(JSON.stringify(props.statusStorage.game_levels));
    gameLevels[currentLevel - 1].feedback = 1;
    let statusUpdated = props.updateStatusStorage({ game_levels: gameLevels }, itemName);
    if (!statusUpdated) return;
    setShowFeedback(false);
  }
  async function handleItemDislikedFeedback() {
    const gameLevels = JSON.parse(JSON.stringify(props.statusStorage.game_levels));
    gameLevels[currentLevel - 1].feedback = 0;
    let statusUpdated = props.updateStatusStorage({ game_levels: gameLevels }, itemName);
    if (!statusUpdated) return;
    setShowFeedback(false);
  }
  function handleRunRestartButtonClick() {
    if (codingGameSubmitButtonText === "Run Code") {
      window.addEventListener("keydown", arrow_keys_handler, false);
      runCode();
      setCodingGameSubmitButtonText("Reset");
    } else {
      window.removeEventListener("keydown", arrow_keys_handler, false);
      runGameObj.restart();
      setCodingGameSubmitButtonText("Run Code");
    }
  }

  return (
    <>
      {!isLoading ? (
        <div
          id="game-view"
          className="content-tab d-hidden"
          style={props.currentSection === "game" ? {} : { display: "none" }}
        >
          {gameInfo.options.structure === "playgroundEnvironment" ? (
            <>
              <LevelButtons
                type="playgroundEnvironment"
                gameInfo={gameInfo}
                gameInfoObj={gameInfoObj}
                currentLevel={currentLevel}
                setCurrentLevel={setCurrentLevel}
                statusStorage={props.statusStorage}
              />
              <div
                className="instructions-box"
                style={{ height: `${intructionBoxHeight}px` }}
              >
                <img src={CheetiRight} alt="" />
                <p id="instructions" ref={gameInstructionRef}>
                  {gameInfo.levels[currentLevel - 1].Instruction}
                </p>
                {gameInfo.videoUrl && (
                  <button
                    className="watch-help-video ml-auto"
                    onClick={() => setHelpVideoPopup(true)}
                  >
                    Demo{" "}
                    <img style={{ width: "2vw", margin: 3 }} src={VideoIcon} />
                  </button>
                )}
              </div>
              <div className="game-content-wrapper">
                <div
                  id="blocklyArea"
                  style={{
                    height: "400px",
                    width: "85vw",
                    marginBottom: "0.9vw",
                  }}
                >
                  <div id="blockly" style={{ height: "30vw", width: "47vw" }} />{" "}
                </div>{" "}
              </div>
              <div className="action-buttons-playground">
                <button
                  id="run-code-button"
                  onClick={runCode}
                  disabled={showError || showSuccess}
                  style={{ lineHeight: "2.2vw" }}
                >
                  Submit
                </button>
                <div>
                  {props.teacher && props.teacherView && (
                    <button
                      id="show-solution"
                      style={{ marginRight: "10px", lineHeight: "2.2vw" }}
                      onClick={() =>
                        runGameObj.loadCode(currentLevelJson.solution)
                      }
                    >
                      Show Solution
                    </button>
                  )}
                  <button
                    id="game-restart-button"
                    onClick={() => runGameObj.restart()}
                    disabled={showError || showSuccess}
                    style={{ lineHeight: "2.2vw" }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <LevelButtons
                type="codingEnvironment"
                gameInfo={gameInfo}
                gameInfoObj={gameInfoObj}
                currentLevel={currentLevel}
                setCurrentLevel={setCurrentLevel}
                codeCarryForwardLevels={codeCarryForwardLevels}
                statusStorage={props.statusStorage}
              />
              <div
                className="instructions-box"
                style={{ height: `${intructionBoxHeight}px` }}
              >
                <img src={CheetiRight} alt="" />
                <p id="instructions" ref={gameInstructionRef}>
                  {gameInfo.levels[currentLevel - 1].Instruction}
                </p>
                {gameInfo.videoUrl && (
                  <button
                    className="watch-help-video ml-auto"
                    onClick={() => setHelpVideoPopup(true)}
                  >
                    Demo{" "}
                    <img style={{ width: "2vw", margin: 3 }} src={VideoIcon} />
                  </button>
                )}
              </div>
              <div className="game-content-wrapper">
                <div id="canvas" />{" "}
                <div
                  id="blocklyArea"
                  style={{ height: "400px", width: "calc(85vw - 400px)" }}
                >
                  <div id="blockly" style={{ height: "30vw", width: "47vw" }} />
                  {currentLevelJson &&
                    currentLevelJson.hasOwnProperty("maxBlocks") &&
                    currentLevelJson.maxBlocks && (
                      <div ref={blocksCountContainerRef} id="game-blocks-count">
                        Blocks Used :&nbsp;
                        <span ref={blocksCountRef}>0</span>
                        &nbsp;/&nbsp;
                        {currentLevelJson.maxBlocks}
                      </div>
                    )}
                </div>
              </div>
              <div className="button-container d-flex mt-1">
                <div className="action-buttons">
                  <button
                    id="run-restart-button"
                    onClick={handleRunRestartButtonClick}
                    disabled={showError || showSuccess}
                    style={
                      codingGameSubmitButtonText === "Reset"
                        ? { backgroundColor: "#FFB017", lineHeight: "2.2vw" }
                        : { lineHeight: "2.2vw" }
                    }
                  >
                    {codingGameSubmitButtonText}
                  </button>
                  {gameInfo.arrowButtons && (
                    <>
                      <button
                        id="game-up"
                        onClick={() => {
                          runGameObj.keyClicked("up");
                        }}
                      >
                        <img src={Arrow_up_white} />
                      </button>
                      <button
                        id="game-down"
                        onClick={() => {
                          runGameObj.keyClicked("down");
                        }}
                      >
                        <img src={Arrow_down_white} />
                      </button>
                      <button
                        id="game-left"
                        onClick={() => {
                          runGameObj.keyClicked("left");
                        }}
                      >
                        <img src={Arrow_left_white} />
                      </button>
                      <button
                        id="game-right"
                        onClick={() => {
                          runGameObj.keyClicked("right");
                        }}
                      >
                        <img src={Arrow_right_white} />
                      </button>
                    </>
                  )}
                </div>
                {props.teacher && props.teacherView && (
                  <div
                    className="show-game-solution-btn"
                    style={gameInfo.arrowButtons ? { height: "3.8vw" } : {}}
                  >
                    <button
                      id="show-solution"
                      className="d-flex align-item align-items-center justify-content-center"
                      style={{
                        margin: "0px",
                        fontSize: "1.5vw",
                        lineHeight: "2.2vw",
                      }}
                      onClick={() =>
                        runGameObj.loadCode(currentLevelJson.solution)
                      }
                    >
                      Show Solution
                    </button>
                  </div>
                )}
              </div>
              <xml id="toolbox" style={{ display: "none" }} />
            </>
          )}
          {showError && (
            <ErrorBox
              showError={showError}
              errorMsg={errorMsg}
              currentItem={"game"}
              closeErrorBoxHandler={() => {
                setShowError(false);
              }}
            />
          )}
          {showSuccess && (
            <GoodJobFeedbackBox
              visibility={showSuccess}
              setVisibility={setShowSucess}
              sucessMsg={sucessMsg}
              showFeedback={showFeedback}
              handleItemLikedFeedback={handleItemLikedFeedback}
              handleItemDislikedFeedback={handleItemDislikedFeedback}
              goToNext={props.goToNext}
              Finish={currentLevel === gameInfo.levels.length}
              currentItem={"game"}
              handleCurrentSectionChange={props.handleCurrentSectionChange}
              getNext={props.getNext}
              nextBtnOnClickhandler={() => {
                setShowSucess(false);
                if (currentLevel === gameInfo.levels.length) return;
                setCurrentLevel(currentLevel + 1);
              }}
            />
          )}
          <PlayVideo
            setHelpVideoPopup={setHelpVideoPopup}
            videoUrl={gameInfo.videoUrl}
            helpVideoPopup={helpVideoPopup}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
