import {
  FormControl, FormLabel, Grid, TextField, Typography,
} from '@mui/material';
import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';

const useStyles = makeStyles({
  field: {
    marginTop: '5px !important',
    marginBottom: '20px !important',
    display: 'block !important',
  },
});
export default function ContactDetails() {
  const { control, formState: { errors } } = useFormContext();
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6" style={{ color: '#00868D', marginBottom: 10 }}>Contact Details</Typography>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Controller
            control={control}
            name="contactPersonName"
            rules={{ required: 'this field is required.' }}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>
                  Contact Person Name
                  <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <TextField
                  className={classes.field}
                  id="contactPersonName"
                  variant="outlined"
                  placeholder="Enter Contact Person Name"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.contactPersonName)}
                  helperText={errors.contactPersonName?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            control={control}
            name="contactPersonPhone"
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>
                  Contact Person Phone
                  <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <TextField
                  className={classes.field}
                  id="contactPersonPhone"
                  variant="outlined"
                  placeholder="Enter Contact Person Phone"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.contactPersonPhone)}
                  helperText={errors.contactPersonPhone?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            control={control}
            name="contactPersonEmail"
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>
                  Contact Person Email
                  <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <TextField
                  className={classes.field}
                  id="contactPersonEmail"
                  variant="outlined"
                  placeholder="Enter Contact Person Email"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.contactPersonEmail)}
                  helperText={errors.contactPersonEmail?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Controller
            control={control}
            name="principalName"
            rules={{ required: 'this field is required.' }}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>Principal Name</FormLabel>
                <TextField
                  className={classes.field}
                  id="principalName"
                  variant="outlined"
                  placeholder="Enter Principal Name"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.principalName)}
                  helperText={errors.principalName?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            control={control}
            name="principalPhone"
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>Principal Phone</FormLabel>
                <TextField
                  className={classes.field}
                  id="principalPhone"
                  variant="outlined"
                  placeholder="Enter Principal Phone"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.principalPhone)}
                  helperText={errors.principalPhone?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            control={control}
            name="principalEmail"
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>Principal Email</FormLabel>
                <TextField
                  className={classes.field}
                  id="principalEmail"
                  variant="outlined"
                  placeholder="Enter Principal Email"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.principalEmail)}
                  helperText={errors.principalEmail?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Controller
            control={control}
            name="adminName"
            rules={{ required: 'this field is required.' }}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>Admin Name</FormLabel>
                <TextField
                  className={classes.field}
                  id="adminName"
                  variant="outlined"
                  placeholder="Enter Admin Name"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.adminName)}
                  helperText={errors.adminName?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            control={control}
            name="adminPhone"
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>Admin Phone</FormLabel>
                <TextField
                  className={classes.field}
                  id="adminPhone"
                  variant="outlined"
                  placeholder="Enter Admin Phone"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.adminPhone)}
                  helperText={errors.adminPhone?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            control={control}
            name="adminEmail"
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>Admin Email</FormLabel>
                <TextField
                  className={classes.field}
                  id="adminEmail"
                  variant="outlined"
                  placeholder="Enter Admin Email"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.adminEmail)}
                  helperText={errors.adminEmail?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Controller
            control={control}
            name="cssochItPocName"
            rules={{ required: 'this field is required.' }}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>CSSoch IT POC Name</FormLabel>
                <TextField
                  className={classes.field}
                  id="cssochItPocName"
                  variant="outlined"
                  placeholder="Enter CSSoch IT POC Name"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.cssochItPocName)}
                  helperText={errors.cssochItPocName?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            control={control}
            name="cssochItPocPhone"
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>CSSoch IT POC Phone</FormLabel>
                <TextField
                  className={classes.field}
                  id="cssochItPocPhone"
                  variant="outlined"
                  placeholder="Enter CSSoch IT POC Phone"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.cssochItPocPhone)}
                  helperText={errors.cssochItPocPhone?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            control={control}
            name="cssochItPocEmail"
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>CSSoch IT POC Email</FormLabel>
                <TextField
                  className={classes.field}
                  id="cssochItPocEmail"
                  variant="outlined"
                  placeholder="Enter CSSoch IT POC Email"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.cssochItPocEmail)}
                  helperText={errors.cssochItPocEmail?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
