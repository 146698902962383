import React from 'react'
import {
    DataGrid, GridToolbarContainer, GridToolbarExport, gridClasses,
} from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    evenRows: {
        backgroundColor: 'rgb(238, 238, 238)',
        '&:hover': {
        backgroundColor: 'rgb(238, 238, 238)',
        },
    },
    oddRows: {
        backgroundColor: 'white',
        '&:hover': {
        backgroundColor: 'white',
        },
    }
});
export default function MapDataGrid(props) {
  const { rows , columnVisibility, columns} = props;
  const classes = useStyles();
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <DataGrid
        style={{
        width: "100%",
        background: "white",
        [`${gridClasses.columnHeaderTitleContainer} ${gridClasses.iconButtonContainer}`]:
            {
            visibility: "visible",
            width: "auto",
            },
        }}
        rows={rows}
        columns={columns}
        getRowHeight={() => 'auto'}
        get
        initialState={{
        pagination: {
            paginationModel: {
            pageSize: 10,
            },
        },
        columns: {
            columnVisibilityModel: columnVisibility,
        },
        }}
        components={{
        Toolbar: CustomToolbar,
        }}
        isRowSelectable={(params) => { return false }}
        getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? classes.evenRows : classes.oddRows
        }
        getRowId={() => Math.random()}
        pageSizeOptions={[10]}
    />
)}
