import React, { useContext, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "../../Styles/Global.css";
import "../../Styles/TeacherDashboard.css";
import ModuleSummary from "../../Components/TeacherDashboardPage/ModuleSummary";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomDatePicker from "../../Components/TeacherDashboardPage/CustomDatePicker";
import {
  setModulesData,
  updateMouleStatus,
} from "../../features/TeacherUserData/ModulesData";
import {
  setDashboardSidebarData,
  updateDashboardSidebarData,
} from "../../features/TeacherUserData/DashboardSidebarData";
import { updateClassAssignment } from "../../features/TeacherUserData/ClassesData";
import Loader from "../../Components/Loader/Loader";
import { PrimaryNavBarContext } from "../../Components/Layout/Layout";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    fontFamily: "rajdhani-medium",
    border: "3px solid #25A596",
    borderRadius: "4vw",
    maxWidth: "60vw",
    width: "60vw",
    height: "fit-content",
    padding: "1rem",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255,255,255,0.85)",
  },
  "& #customized-dialog-title": {
    display: "flex",
    justifyContent: "center",
    fontFamily: "rajdhani-medium",
    fontSize: "5vw",
    margin: 0,
    padding: 0,
    marginBottom: "3.2vh",
    lineHeight: "5vw",
    fontWeight: "normal",
    marginTop: "1vw",
  },
  "& .MuiPaper-root .MuiTypography-gutterBottom": {
    textAlign: "center",
    fontFamily: "rajdhani-medium",
    fontSize: "2vw",
    marginTop: 0,
    marginBottom: "1rem",
  },
  "& .MuiDialogActions-root": {
    justifyContent: "center",
    padding: 0,
    margin: 0,
  },
  "& .MuiDialogActions-root Button": {
    fontFamily: "rajdhani-medium",
    color: "black",
    backgroundColor: "#FFB017",
    border: "none",
    borderRadius: "10px",
    padding: "1px 20px",
    fontSize: "1.5vw",
    transition: "0.5s",
    textTransform: "none",
    margin: 0,
    width: "5vw",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      transform: "scale(1.05)",
      transition: "0.5s",
    },
  },
  "& #change-password-action-buttons": {
    display: "flex",
    justifyContent: "space-between",
    width: "13vw",
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#343a40",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: "1vw",
    fontFamily:"rajdhani-medium",
  },
}));

export default function TeacherDashboard() {
  document.title = "CSSoch | Dashboard";
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const modulesData = useSelector(
    (state) => state.dashboardModulesData?.modulesData
  );
  const dashboardSidebarData = useSelector(
    (state) => state.dashboardSidebarData?.dashboardSidebarData
  );
  const { grade, section } = useParams();
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [popup, setPopup] = useState(false);
  const [dialogContent, setDialogContent] = useState();
  const [moduleInfo, setModuleInfo] = useState({});
  const [moduleAssignment, setModuleAssignment] = useState({});
  const [modulePublishStatus, setModulePublishStatus] = useState(true);
  const [selectedModuleData, setSelectedModuleData] = useState(null);
  const [activeStudentId, setActiveStudentId] = useState(null);
  const [activeStudent, setActiveStudent] = useState(1);
  const [activeStudentName, setActiveStudentName] = useState();
  const { state } = useLocation();
  const [moduleType, setModuleType] = useState();
  const [moduleAssignedDate, setModuleAssignedDate] = useState(null);
  const [moduleEndDate, setModuleEndDate] = useState(null);
  const [selectedModuleName, setSelectedModuleName] = useState(null);
  const [isUpdatedModuleStatus, setIsUpdateModuleStatus] = useState(false);
  const [updateStudentPerformanceToggle, setUpdateStudentPerformanceToggle] =
    useState(false);
  const navigate = useNavigate();
  const { setIsAdminDashboardOrTeacherClassess,setNavbarModuleData, setTeacherGrade, setTeacherSection, setIsTeacherDashboard, setIsModulePage } = useContext(PrimaryNavBarContext)

  useEffect(() => {
    setIsAdminDashboardOrTeacherClassess(false);
    setTeacherGrade(grade);
    setTeacherSection(section);
    setIsTeacherDashboard(true);
    setNavbarModuleData({})
    setIsModulePage(false)
    if (dashboardSidebarData[grade] && dashboardSidebarData[grade][section]) {
      updateModuleInfo({ ...dashboardSidebarData[grade][section] });
      return;
    }
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/teacher/dashboard/${grade}/${section}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (dashboardSidebarData[grade]) {
          if (!dashboardSidebarData[grade][section]) {
            dispatch(
              setDashboardSidebarData({
                ...dashboardSidebarData,
                [grade]: { ...dashboardSidebarData[grade], [section]: data },
              })
            );
          }
        } else {
          dispatch(
            setDashboardSidebarData({
              ...dashboardSidebarData,
              [grade]: { [section]: data },
            })
          );
        }
        updateModuleInfo(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedModuleId && selectedModule && moduleInfo) {
      if (
        modulesData[grade] &&
        modulesData[grade][section] &&
        modulesData[grade][section][selectedModule]
      ) {
        const data = modulesData[grade][section][selectedModule];
        setCurrentModuleDataValues(data);
        return;
      }
      const payload = {
        id: selectedModuleId,
        type: moduleInfo?.types[selectedModule - 1],
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/teacher/dashboard/${grade}/${section}`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          data.student_info = Object.entries(data.student_info).sort((a, b) => {
            return a[1][0].localeCompare(b[1][0]);
          });
          updateModulesData(data);
          setCurrentModuleDataValues(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [selectedModuleId, selectedModule, moduleInfo]);

  useEffect(() => {
    if (isUpdatedModuleStatus && moduleEndDate) {
      if (moduleInfo.status[selectedModule - 1] !== "assigned") {
        dispatch(
          updateMouleStatus({
            grade: grade,
            section: section,
            moduleNum: selectedModule,
            status: "assigned",
            startDate: moduleAssignedDate,
            endDate: moduleEndDate,
          })
        );
        dispatch(
          updateDashboardSidebarData({
            grade: grade,
            section: section,
            moduleNum: selectedModule,
            status: "assigned",
          })
        );
        dispatch(
          updateClassAssignment({
            grade: grade,
            section: section,
            moduleNum: selectedModule,
            status: "assigned",
          })
        );
        const newModuleInfo = JSON.parse(JSON.stringify(moduleInfo));
        newModuleInfo.status[selectedModule - 1] = "assigned";
        setModuleInfo(() => newModuleInfo);
        setModuleAssignment(() => newModuleInfo.status);
        const newSelectedModuleData = JSON.parse(
          JSON.stringify(selectedModuleData)
        );
        newSelectedModuleData.status = "assigned";
        newSelectedModuleData.assignment_date = moduleAssignedDate;
        newSelectedModuleData.deadline = moduleEndDate;
        setSelectedModuleData(() => newSelectedModuleData);
      } else {
        dispatch(
          updateMouleStatus({
            grade: grade,
            section: section,
            moduleNum: selectedModule,
            status: "assigned",
            startDate: moduleAssignedDate,
            endDate: moduleEndDate,
          })
        );
        const newSelectedModuleData = JSON.parse(
          JSON.stringify(selectedModuleData)
        );
        newSelectedModuleData.deadline = moduleEndDate;
        setSelectedModuleData(newSelectedModuleData);
      }
    }
    setIsUpdateModuleStatus(false);
    handlePopupClose();
  }, [isUpdatedModuleStatus]);

  const updateModuleInfo = (data) => {
    setModuleInfo({ ...data?.modules });
    const moduleId = state?.module ? state.module : null;
    if (moduleId) {
      setSelectedModuleId(parseInt(moduleId));
      const moduleNo = state?.moduleNo ? state.moduleNo : null;
      setSelectedModuleName(data.modules?.names[parseInt(moduleNo) - 1]);
      setModulePublishStatus(
        data?.modules?.publish_status[parseInt(moduleNo) - 1] ? true : false
      );
      setSelectedModule(parseInt(moduleNo));
      setModuleType(state?.type);
    } else {
      setSelectedModuleName(data.modules?.names[data?.last_module_num - 1]);
      setModulePublishStatus(
        data?.modules?.publish_status[data?.last_module_num - 1] ? true : false
      );
      setSelectedModule(data?.last_module_num);
      setSelectedModuleId(data?.modules?.ids[data?.last_module_num - 1]);
      setModuleType(data?.modules?.types[data?.last_module_num - 1]);
    }
    setModuleAssignment(data?.modules?.status);
    setIsLoading(false);
  };

  const setCurrentModuleDataValues = (data) => {
    setSelectedModuleData({ ...data });
    const studentId = state?.user ? state.user : null;
    if (studentId) {
      for(let i = 0; i < data?.student_info.length; i++) {
        if(parseInt(data?.student_info[i][0]) === parseInt(studentId)) {
          setActiveStudentName(data?.student_info[i][1][0]);
          setActiveStudent(i+1);
          break
        }
      }
      setActiveStudentId((pre) => {
        return parseInt(studentId);
      });
      state.user = null;
    } else {
      setActiveStudentName(data?.student_info.length ? data?.student_info[0][1][0] : null);
      setActiveStudent(1);
      setActiveStudentId((pre) => data?.student_info.length ? data?.student_info[0][0] : null);
    }
    setUpdateStudentPerformanceToggle((pre) => !pre);
    if (data.status === "assigned") {
      setModuleAssignedDate(data?.assignment_date);
      setModuleEndDate(data?.deadline);
    } else {
      const newDateString = new Date().toLocaleDateString("en-GB");
      setModuleAssignedDate(newDateString);
      setModuleEndDate(null);
    }
  };
  const updateModulesData = (data) => {
    if (modulesData[grade]) {
      if (modulesData[grade][section]) {
        if (!modulesData[grade][section][selectedModule]) {
          dispatch(
            setModulesData({
              ...modulesData,
              [grade]: {
                ...modulesData[grade],
                [section]: {
                  ...modulesData[grade][section],
                  [selectedModule]: data,
                },
              },
            })
          );
        }
      } else {
        dispatch(
          setModulesData({
            ...modulesData,
            [grade]: {
              ...modulesData[grade],
              [section]: { [selectedModule]: data },
            },
          })
        );
      }
    } else {
      dispatch(
        setModulesData({
          ...modulesData,
          [grade]: { [section]: { [selectedModule]: data } },
        })
      );
    }
  };
  const handlePopupOpen = (type) => {
    setDialogContent(
      <>
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handlePopupClose}
        >
           Assign Module
        </BootstrapDialogTitle>
          <div className='modal-deadline-picker '>
            <CustomDatePicker
              assignedDate={moduleAssignedDate}
              endDate={moduleEndDate}
              grade={grade}
              section={section}
              moduleId={selectedModuleId}
              type={moduleType}
              moduleName={selectedModuleName}
              setModuleEndDate={setModuleEndDate}
              setIsUpdateModuleStatus={setIsUpdateModuleStatus}
            />
          </div>
      </>
    );
    setPopup(true);
  };

  const handlePopupClose = () => {
    setPopup(false);
  };

  return (
    <>
      <div style={{ margin: 0, padding: 0 }}>
        {!isLoading ? (
          <div className='teacher-dashboard-container'>
            <div style={{ height: "100%" }}>
              <div xs={12} className='teacher-dashboard-module'>
                <div className='teacher-dashboard-side-nav'>
                  <span
                    id='return-assigned-courses'
                    className='return-dashboard'
                    onClick={() => {
                      navigate("/classes");
                    }}
                  >
                    <ArrowBackIosIcon className='arrow-back-icon' />
                    Back
                  </span>
                  {moduleInfo?.ids?.map((item, index) => (
                    <BootstrapTooltip placement="right" title={moduleInfo.names[index]}>
                      <p
                        key={item}
                        className={`module-fetch-button ${
                          moduleAssignment[index] === "assigned"
                            ? "assigned-module"
                            : ""
                        } ${
                          selectedModule === index + 1 ? "selected-module" : ""
                        }`}
                        data-moduleid={item}
                        data-placement='bottom'
                        onClick={() => {
                          setModuleType(moduleInfo?.types[index]);
                          setSelectedModuleName(() => moduleInfo?.names[index]);
                          setModulePublishStatus(
                            () => moduleInfo?.publish_status[index]
                          );
                          setSelectedModule(() => index + 1);
                          setSelectedModuleId(() => item);
                        }}
                      >
                        Module {index + 1}
                      </p>
                    </BootstrapTooltip>
                  ))}
                </div>
                <div className='teacher-dashboard-module-content'>
                    <ModuleSummary
                      selectedModuleId={selectedModuleId}
                      selectedModule={selectedModule}
                      selectedModuleName={selectedModuleName}
                      data={selectedModuleData}
                      grade={grade}
                      section={section}
                      activeStudentId={activeStudentId}
                      setActiveStudentId={setActiveStudentId}
                      handlePopupOpen={handlePopupOpen}
                      moduleType={moduleType}
                      moduleAssignedDate={moduleAssignedDate}
                      moduleEndDate={moduleEndDate}
                      modulePublishStatus={modulePublishStatus}
                      updateStudentPerformanceToggle={
                        updateStudentPerformanceToggle
                      }
                      setUpdateStudentPerformanceToggle={
                        setUpdateStudentPerformanceToggle
                      }
                      setActiveStudentName={setActiveStudentName}
                      activeStudentName={activeStudentName}
                      setActiveStudent={setActiveStudent}
                      activeStudent={activeStudent}
                    />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <BootstrapDialog
        onClose={handlePopupClose}
        aria-labelledby='customized-dialog-title'
        open={popup}
      >
        {dialogContent}
      </BootstrapDialog>
    </>
  );
}
