import React, { useEffect, useRef, useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CustomDatePicker(props) {
  const {
    endDate,
    grade,
    section,
    moduleId,
    type,
    assignedDate,
    moduleName,
    setModuleEndDate,
    setIsUpdateModuleStatus,
  } = props;
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [completionDate, setCompletionDate] = useState(null);
  const inputGroupRef = useRef(null);
  useEffect(() => {
    if (endDate) {
      var parts = endDate.split("/");
      var day = parseInt(parts[0], 10);
      var month = parseInt(parts[1], 10) - 1;
      var year = parseInt(parts[2], 10);
      var date = new Date(year, month, day);
      setCompletionDate(date);
    }
  }, [endDate]);

  const assignModule = () => {
    if (completionDate) {
      const endDateString = completionDate.toLocaleDateString("en-GB");
      const payload = {
        id: moduleId,
        type: type,
        grade: grade,
        section: section,
        assigned_date: assignedDate,
        deadline_date: endDateString,
      };
      if (type === "notebook") {
        payload.name = moduleName;
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/assign-module`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          setModuleEndDate(endDateString);
        });
      setModuleEndDate(endDateString);
    }
    setIsUpdateModuleStatus(true);
  };

  const handleOutsideClick = (event) => {
    if (
      inputGroupRef.current &&
      !inputGroupRef.current.contains(event.target)
    ) {
      setCalendarOpen(false);
    }
  };

  const toggleCalendar = () => {
    setCalendarOpen((pre) => !pre);
  };
  return (
    <div className='module-dates'>
      <div className='module-assigned-date'>
        <span> Assigned Date: </span>
        <span> {assignedDate}</span>
      </div>
      <div className='module-completion-date'>
        <span> Completion Date: </span>
        <div className='input-group' ref={inputGroupRef}>
          <DatePicker
            key={calendarOpen}
            selected={completionDate ? completionDate : null}
            onChange={(date) => {
              setCompletionDate(date);
              setCalendarOpen(false);
            }}
            id='date-picker'
            minDate={startDate}
            open={calendarOpen}
            dateFormat='dd/MM/YYYY'
            openToDate={startDate}
            popperPlacement='bottom-start'
            onClickOutside={handleOutsideClick}
            disabled
          />
          <span
            className='input-group-text'
            id='basic-addon2'
            onClick={toggleCalendar}
          >
            <img src='/svg/calendar.svg' alt='Calendar' />
          </span>
        </div>
      </div>
      <div className='module-set-completion-date'>
        <button
          type='button'
          class='confirm-deadline'
          onClick={() => {
            assignModule();
          }}
        >
          Set Date
        </button>
      </div>
    </div>
  );
}

export default CustomDatePicker;
