import React, { useEffect, useRef } from "react";
import PuzzleView from "../Puzzle/PuzzleView";

function AssessmentPuzzle(props) {
  const {
    data,
    currentLevel,
    showPuzzleExplanation,
    showSubmitButton,
    disabledSubmitButton,
    teacherView,
    teacher,
    showSolutionButton,
    displayAssesmentAnswers,
    statusStorage,
    updateLevelColors,
    setShowGiveUpButton,
    setNextButtonText,
    updateStatusStorage,
    setShowBadJobFeedbackBox,
    currentlyClicked,
    setCurrentlyClicked,
    setDisableSubmitButton,
    loaded,
    answerChecked,
    itemName
  } = props;
  const explanationRef = useRef(null)
  useEffect(() => {
    if(showPuzzleExplanation) explanationRef.current.scrollIntoView({ behavior: 'smooth' });
  },[showPuzzleExplanation])
  async function giveupLevel() {
    const statuses = JSON.parse(JSON.stringify(statusStorage));
    if (statuses.assessment_levels[currentLevel - 1]?.status !== "completed") {
      statuses["total_puzzle_levels_completed"]++;
      statuses["assessment_puzzle_levels_completed"]++;
      statuses.assessment_levels[currentLevel - 1].status = "completed";
      statuses["is_puzzle_level_giveup"] = true;
    }
    statuses.assessment_levels[currentLevel - 1].giveup = 1;
    let sttausUpdated = await updateStatusStorage(statuses,itemName);
    if (!sttausUpdated) return;
    displayAssesmentAnswers(-1, false);
    updateLevelColors(statuses);
    setShowGiveUpButton(false);
    if (currentLevel === data.numLevels) setNextButtonText("Finish");
    setShowBadJobFeedbackBox(true);
  }
  function submitAnswer() {
    if (!loaded) return;
    let level = currentlyClicked;
    if (level > 3) level -= 4;
    level += 1;
    displayAssesmentAnswers(level, true);
  }

  return (
    <div id="puzzle-assesment-view">
      <div className="puzzle-button-holder" />
      <PuzzleView
        puzzleStrapiData={data.levels[currentLevel - 1]}
        puzzleData={props.statusStorage.assessment_levels[currentLevel - 1]}
        submitAnswer={submitAnswer}
        isTeachModule={props.teacherView}
        puzzle_type={"assessment"}
        module_type={"module"}
        displaySolution={() => {
          displayAssesmentAnswers(
              data.levels[currentLevel - 1].correct,
              false,
            );
          }
        }
        answerChecked={answerChecked}
        explanationRef={explanationRef}
        currentlyClicked={currentlyClicked}
        setCurrentlyClicked={setCurrentlyClicked}
        disabledSubmitButton={disabledSubmitButton}
        setDisableSubmitButton={setDisableSubmitButton}
        giveupLevel={giveupLevel}
        showPuzzleExplanation={showPuzzleExplanation}
      />
    </div>
  );
}

export default AssessmentPuzzle;
