import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  classesData: {},
};

export const classesDataSlice = createSlice({
  name: "classesData",
  initialState,
  reducers: {
    setClassesData: (state, action) => {
      state.classesData = action.payload;
    },
    updateClassAssignment: (state, action) => {
      if (Object.keys(state.classesData).length)
        state.classesData["classes"][action.payload.grade][
          action.payload.section
        ][action.payload.moduleNum - 1] = action.payload.status;
    },
  },
});

export const { setClassesData, updateClassAssignment } =
  classesDataSlice.actions;
export default classesDataSlice.reducer;
