import { Select, Grid, FormLabel, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Controller, useFormContext } from "react-hook-form";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

function StudentRollOverClassesMap(props) {
  const { control } = useFormContext();
  const {
    index,
    gradesData,
    onMappingChange,
    optionsToAvoidFromRollOver,
    setOptionsToAvoidFromRollOver,
    optionsToAvoidToRollOver,
    setOptionsToAvoidToRollOver,
    setValue,
    trigger,
    errors,
    setMappingData,
    remove,
  } = props;
  const [selectedGradeFromRollOver, setSelectedGradeFromRollOver] =
    useState(null);
  const [selectedSectionFromRollOver, setSelectedSectionFromRollOver] =
    useState(null);
  const [selectedGradeToRollOver, setSelectedGradeToRollOver] = useState(null);
  const [selectedSectionToRollOver, setSelectedSectionToRollOver] =
    useState(null);
  const [updatedGradesData, setUpdatedGradesData] = useState(null);

  useEffect(() => {
    const filterGrades = (inputGrades, optionsToAvoid) => {
      const filteredGrades = {};
      for (let grade in inputGrades) {
        const filteredSection = inputGrades[grade].filter(
          (section) =>
            !optionsToAvoid.hasOwnProperty(grade) ||
            !optionsToAvoid[grade].includes(section)
        );
        if (filteredSection.length > 0) {
          filteredGrades[grade] = filteredSection;
        }
      }
      return filteredGrades;
    };

    const filteredGradesFromRollOver = filterGrades(
      gradesData["gradesFromRollOver"],
      optionsToAvoidFromRollOver
    );
    const filteredGradesToRollOver = filterGrades(
      gradesData["gradesToRollOver"],
      optionsToAvoidToRollOver
    );

    setUpdatedGradesData({
      gradesFromRollOver: filteredGradesFromRollOver,
      gradesToRollOver: filteredGradesToRollOver,
    });
  }, [optionsToAvoidFromRollOver, optionsToAvoidToRollOver]);

  useEffect(() => {
    function autofillSelectedSectionFromRollOver() {
      if (!updatedGradesData || !selectedSectionFromRollOver) return;
      if (
        !updatedGradesData?.gradesToRollOver?.[
          selectedGradeToRollOver
        ]?.includes(selectedSectionFromRollOver)
      ) {
        return;
      }
      handleSectionToChange({
        target: {
          value: selectedSectionFromRollOver,
        },
      });
    }
    autofillSelectedSectionFromRollOver();
  }, [selectedSectionFromRollOver]);

  function updateOptionsToAvoidToRollOver() {
    let updatedOptionsToAvoid = { ...optionsToAvoidToRollOver };
    if (selectedSectionToRollOver) {
      const indexOfSelectedSection = updatedOptionsToAvoid[
        selectedGradeToRollOver
      ].indexOf(selectedSectionToRollOver);
      if (indexOfSelectedSection > -1) {
        updatedOptionsToAvoid[selectedGradeToRollOver].splice(
          indexOfSelectedSection,
          1
        );
        setOptionsToAvoidToRollOver(updatedOptionsToAvoid);
      }
    }
    setSelectedSectionToRollOver(null);
    setValue(`mappings.${index}.sectionTo`, "");
  }

  function updateGradeOptionsToAvoidFromRollOver() {
    let updatedOptionsToAvoid = { ...optionsToAvoidFromRollOver };
    if (selectedSectionFromRollOver) {
      const indexOfSelectedSection = updatedOptionsToAvoid[
        selectedGradeFromRollOver
      ].indexOf(selectedSectionFromRollOver);
      if (indexOfSelectedSection > -1) {
        updatedOptionsToAvoid[selectedGradeFromRollOver].splice(
          indexOfSelectedSection,
          1
        );
        setOptionsToAvoidFromRollOver(updatedOptionsToAvoid);
      }
    }
    setSelectedSectionFromRollOver(null);
    updateOptionsToAvoidToRollOver();
  }

  const handleGradeFromChange = (e) => {
    updateGradeOptionsToAvoidFromRollOver();
    const updatedGradeFromRollOver = e.target.value;
    const updatedGradeToRollOver = String(
      parseInt(updatedGradeFromRollOver) + 1
    );
    setSelectedGradeFromRollOver(updatedGradeFromRollOver);
    setSelectedGradeToRollOver(updatedGradeToRollOver);
    setValue(`mappings.${index}.gradeFrom`, updatedGradeFromRollOver);
    setValue(`mappings.${index}.sectionFrom`, "");
    setValue(`mappings.${index}.gradeTo`, updatedGradeToRollOver);
    onMappingChange(index, {
      gradeFrom: updatedGradeFromRollOver,
      sectionFrom: null,
      gradeTo: updatedGradeToRollOver,
      sectionTo: null,
    });
    trigger();
  };

  const handleSectionFromChange = (e) => {
    const changedSection = e.target.value;
    let updatedOptionsToAvoid = { ...optionsToAvoidFromRollOver };
    if (
      updatedOptionsToAvoid.hasOwnProperty(selectedGradeFromRollOver) &&
      !updatedOptionsToAvoid[selectedGradeFromRollOver].includes(changedSection)
    ) {
      updatedOptionsToAvoid[selectedGradeFromRollOver].push(changedSection);
    } else if (
      !updatedOptionsToAvoid.hasOwnProperty(selectedGradeFromRollOver)
    ) {
      updatedOptionsToAvoid[selectedGradeFromRollOver] = [changedSection];
    }
    setOptionsToAvoidFromRollOver(updatedOptionsToAvoid);
    setSelectedSectionFromRollOver(changedSection);
    setValue(`mappings.${index}.sectionFrom`, changedSection);
    onMappingChange(index, {
      gradeFrom: selectedGradeFromRollOver,
      sectionFrom: changedSection,
      gradeTo: selectedGradeToRollOver,
      sectionTo: selectedSectionToRollOver,
    });
    trigger();
  };

  const handleGradeToChange = (e) => {
    updateOptionsToAvoidToRollOver();
    setSelectedGradeToRollOver(e.target.value);
    setValue(`mappings.${index}.GradeTo`, e.target.value);
    onMappingChange(index, {
      gradeFrom: selectedGradeFromRollOver,
      sectionFrom: selectedSectionFromRollOver,
      gradeTo: e.target.value,
      sectionTo: null,
    });
    trigger();
  };

  const handleSectionToChange = (e) => {
    const changedSection = e.target.value;
    let updatedOptionsToAvoid = { ...optionsToAvoidToRollOver };
    if (
      updatedOptionsToAvoid.hasOwnProperty(selectedGradeToRollOver) &&
      !updatedOptionsToAvoid[selectedGradeToRollOver].includes(changedSection)
    ) {
      updatedOptionsToAvoid[selectedGradeToRollOver].push(changedSection);
    } else if (!updatedOptionsToAvoid.hasOwnProperty(selectedGradeToRollOver)) {
      updatedOptionsToAvoid[selectedGradeToRollOver] = [changedSection];
    }
    setOptionsToAvoidToRollOver(updatedOptionsToAvoid);
    setSelectedSectionToRollOver(changedSection);
    setValue(`mappings.${index}.sectionTo`, changedSection);
    onMappingChange(index, {
      gradeFrom: selectedGradeFromRollOver,
      sectionFrom: selectedSectionFromRollOver,
      gradeTo: selectedGradeToRollOver,
      sectionTo: changedSection,
    });
    trigger();
  };

  return (
    <Grid container spacing={3} style={{ paddingTop: 10 }}>
      <Grid item md={2}>
        <Controller
          control={control}
          name={`mappings.${index}.gradeFrom`}
          render={({
            field: { value, onChange, ...field },
            fieldState: { error },
          }) => (
            <>
              <FormLabel>Grade</FormLabel>
              <Select
                {...field}
                ref={field.ref}
                SelectDisplayProps={{
                  style: { paddingTop: 10, paddingBottom: 10 },
                }}
                value={selectedGradeFromRollOver}
                onChange={handleGradeFromChange}
                style={{ width: "100%" }}
              >
                {updatedGradesData &&
                  selectedGradeFromRollOver &&
                  !Object.keys(
                    updatedGradesData["gradesFromRollOver"]
                  ).includes(selectedGradeFromRollOver) && (
                    <MenuItem
                      key={selectedGradeFromRollOver}
                      value={selectedGradeFromRollOver}
                    >
                      {selectedGradeFromRollOver}
                    </MenuItem>
                  )}
                {updatedGradesData &&
                  Object.keys(updatedGradesData["gradesFromRollOver"]).map(
                    (grade) => {
                      return (
                        <MenuItem key={grade} value={grade}>
                          {grade}
                        </MenuItem>
                      );
                    }
                  )}
              </Select>
              {error && (
                <span
                  style={{
                    marginTop: -15,
                    marginBottom: 10,
                    color: "red",
                  }}
                >
                  {error?.message}
                </span>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item md={3}>
        <Controller
          control={control}
          name={`mappings.${index}.sectionFrom`}
          render={({ field, fieldState: { error } }) => (
            <>
              <FormLabel>Section</FormLabel>
              <Select
                {...field}
                ref={field.ref}
                name={`mappings.${index}.sectionFrom`}
                SelectDisplayProps={{
                  style: { paddingTop: 10, paddingBottom: 10 },
                }}
                value={selectedSectionFromRollOver}
                onChange={handleSectionFromChange}
                style={{ width: "100%" }}
              >
                {selectedSectionFromRollOver && (
                  <MenuItem
                    key={selectedSectionFromRollOver}
                    value={selectedSectionFromRollOver}
                  >
                    {selectedSectionFromRollOver}
                  </MenuItem>
                )}
                {selectedGradeFromRollOver &&
                  updatedGradesData["gradesFromRollOver"][
                    selectedGradeFromRollOver
                  ]?.map((section) => {
                    return (
                      <MenuItem key={section} value={section}>
                        {section}
                      </MenuItem>
                    );
                  })}
              </Select>
              {error && (
                <span
                  style={{
                    marginTop: -15,
                    marginBottom: 10,
                    color: "red",
                  }}
                >
                  {error?.message}
                </span>
              )}
            </>
          )}
        />
      </Grid>
      <Grid
        item
        md={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grid item md={1} style={{ flex: 0.5 }}></Grid>
        <DoubleArrowIcon />
        {errors?.mappings?.[index] && (
          <Grid item md={1} style={{ flex: 0.5 }}></Grid>
        )}
      </Grid>
      <Grid item md={2}>
        <Controller
          control={control}
          name={`mappings.${index}.gradeTo`}
          render={({ field, fieldState: { error } }) => (
            <>
              <FormLabel>Section</FormLabel>
              <Select
                SelectDisplayProps={{
                  style: { paddingTop: 10, paddingBottom: 10 },
                }}
                value={selectedGradeToRollOver}
                disabled={selectedGradeFromRollOver}
                onChange={handleGradeToChange}
                style={{ width: "100%" }}
              >
                {updatedGradesData &&
                  selectedGradeToRollOver &&
                  !Object.keys(updatedGradesData["gradesToRollOver"]).includes(
                    selectedGradeToRollOver
                  ) && (
                    <MenuItem
                      key={selectedGradeToRollOver}
                      value={selectedGradeToRollOver}
                    >
                      {selectedGradeToRollOver}
                    </MenuItem>
                  )}
                {updatedGradesData &&
                  Object.keys(updatedGradesData["gradesToRollOver"]).map(
                    (grade) => {
                      return (
                        <MenuItem key={grade} value={grade}>
                          {grade}
                        </MenuItem>
                      );
                    }
                  )}
              </Select>
              {error && (
                <span
                  style={{
                    marginTop: -15,
                    marginBottom: 10,
                    color: "red",
                  }}
                >
                  {error?.message}
                </span>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item md={3}>
        <Controller
          control={control}
          name={`mappings.${index}.sectionTo`}
          render={({ field, fieldState: { error } }) => (
            <>
              <FormLabel>Section</FormLabel>
              <Select
                {...field}
                ref={field.ref}
                SelectDisplayProps={{
                  style: { paddingTop: 10, paddingBottom: 10 },
                }}
                value={selectedSectionToRollOver}
                onChange={handleSectionToChange}
                style={{ width: "100%" }}
              >
                {selectedSectionToRollOver && (
                  <MenuItem
                    key={selectedSectionToRollOver}
                    value={selectedSectionToRollOver}
                  >
                    {selectedSectionToRollOver}
                  </MenuItem>
                )}
                {selectedGradeToRollOver &&
                  updatedGradesData["gradesToRollOver"][
                    selectedGradeToRollOver
                  ]?.map((section) => {
                    return (
                      <MenuItem key={section} value={section}>
                        {section}
                      </MenuItem>
                    );
                  })}
              </Select>
              {error && (
                <span
                  style={{
                    marginTop: -15,
                    marginBottom: 10,
                    color: "red",
                  }}
                >
                  {error?.message}
                </span>
              )}
            </>
          )}
        />
      </Grid>
      <Grid
        item
        md={1}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grid item md={1} style={{ flex: 0.5 }}></Grid>
        <RemoveCircleIcon
          style={{ cursor: "pointer" }}
          type="button"
          onClick={() => {
            updateGradeOptionsToAvoidFromRollOver();
            setMappingData((mappingsData) => {
              const newData = [...mappingsData];
              newData.splice(index, 1);
              return newData;
            });
            remove(index);
          }}
        />
        {errors?.mappings?.[index] && (
          <Grid item md={1} style={{ flex: 0.5 }}></Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default StudentRollOverClassesMap;
