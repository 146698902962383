import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import { green } from '@mui/material/colors';
import React, { useRef, useState } from 'react';

const accepted_extension = ['xlsx'];

export default function UserImportDialog(props) {
  const {
    selectedFile, openDialog, handleUnselectFile, handleRefreshUserList, type
  } = props;
  const [open, setOpen] = useState(openDialog);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [importError, setImportError] = useState(false);
  const timer = useRef();
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  React.useEffect(() => () => {
    clearTimeout(timer.current);
  }, []);

  const handleUploadFile = () => {
    const file = selectedFile;

    const formData = new FormData();

    formData.append('file', file);
    formData.append('schoolcode', 'ss');
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/import/${type}`, requestOptions)
      .then((res) => {
        if (res.status == '202') {
          res.json().then((data) => {
            setSuccess(true);
            setLoading(false);
            handleRefreshUserList();
          });
        } else {
          res.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `test_${type}_creation_info.txt`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setImportError(true);
          });
        }
      });
  };

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
  };

  const handleImportUser = () => {
    setImportLoading(true);
    setLoading(true);
    handleUploadFile();
  };
  const handleClose = () => {
    handleUnselectFile();
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{type} Import</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {
                    accepted_extension.includes(selectedFile.name.split('.')[1])
                      ? `Importing ${type}s from file: ${selectedFile.name}`
                      : `.${selectedFile.name.split('.')[1]} File format not supported. Please use .xlsx file`
                }
        </DialogContentText>
        {importError
            && (
            <p style={{ color: 'red' }}>
              There is error in  uploaded excel file.
              <br />
              Please check downloaded error.txt file for resolving issue in uploaded excel file
            </p>
            )}
        {!importError
          ? importLoading
            && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ m: 1, position: 'relative' }}>
                <Fab
                  aria-label="save"
                  color="primary"
                  sx={buttonSx}
                >
                  {success ? <CheckIcon /> : <SaveIcon />}
                </Fab>
                {loading && (
                <CircularProgress
                  size={68}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: -6,
                    left: -6,
                    zIndex: 1,
                  }}
                />
                )}
              </Box>
              <Box sx={{ m: 1, position: 'relative' }}>
                <Button
                  variant="contained"
                  sx={buttonSx}
                  disabled={loading}
                >
                  {success ? 'Imported' : 'Importing'}
                </Button>
                {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
                )}
              </Box>
            </Box>
            )
          : ''}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{success || importError ? 'Close' : 'Cancel'}</Button>
        {importError || success || !(accepted_extension.includes(selectedFile.name.split('.')[1])) ? '' : <Button disabled={importLoading} onClick={handleImportUser}>Import</Button>}
      </DialogActions>
    </Dialog>
  );
}
