import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const colourMappings = {
  completed: '#25A596',
  incomplete: '#FE7D00',
  ongoing: '#AECB2A',
  locked: '#8B8B8B',
};
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily:"rajdhani-medium",
  },
}));
export default function StoryButton(props) {
  const svgIconElem = (
    <g transform="translate(-35 -129)">
            <ellipse style={{ fill: `${colourMappings[props.status ? props.status : 'incomplete']}` }} id="story-icon-coloring" cx="60.5" cy="57.5" rx="60.5" ry="57.5" transform="translate(35 129)" fill="#25a596" />
            <g transform="translate(57.795 152.155)">
              <g transform="translate(0)">
                <path d="M76.047,31.794H69.4V27.682A1.586,1.586,0,0,0,67.823,26.1a73.135,73.135,0,0,0-27.837,5.536A71.38,71.38,0,0,0,12.148,26.1a1.586,1.586,0,0,0-1.582,1.582v4.112H4.082A1.586,1.586,0,0,0,2.5,33.376V74.894a1.586,1.586,0,0,0,1.582,1.582H76.127a1.586,1.586,0,0,0,1.582-1.582V33.376A1.712,1.712,0,0,0,76.047,31.794ZM5.584,73.313V34.878h5.14V69.121A1.586,1.586,0,0,0,12.306,70.7a69.259,69.259,0,0,1,18.505,2.61Zm32.9-.712A71.556,71.556,0,0,0,13.73,67.619V29.263A67.916,67.916,0,0,1,38.483,34.4Zm3.163-38.2A70.038,70.038,0,0,1,66.4,29.184V67.619A73.043,73.043,0,0,0,41.646,72.6Zm32.9,38.909H49.317A69.26,69.26,0,0,1,67.823,70.7,1.586,1.586,0,0,0,69.4,69.121V34.878h5.14Z" transform="translate(-2.5 -13.605)" stroke="#000" strokeWidth="1" />
                <path d="M23.743,41.7a66.513,66.513,0,0,1,12.416,2.531,1,1,0,0,0,.474.079,1.7,1.7,0,0,0,1.5-1.107,1.549,1.549,0,0,0-1.028-1.9,73.912,73.912,0,0,0-12.97-2.61,1.645,1.645,0,0,0-1.74,1.344A1.565,1.565,0,0,0,23.743,41.7Z" transform="translate(-6.661 -16.239)" stroke="#000" strokeWidth="1" />
                <path d="M23.743,51.2a66.512,66.512,0,0,1,12.416,2.531,1,1,0,0,0,.474.079,1.587,1.587,0,0,0,1.5-1.107,1.549,1.549,0,0,0-1.028-1.9,73.911,73.911,0,0,0-12.97-2.61,1.645,1.645,0,0,0-1.74,1.344A1.565,1.565,0,0,0,23.743,51.2Z" transform="translate(-6.661 -18.226)" stroke="#000" strokeWidth="1" />
                <path d="M23.743,60.6a66.515,66.515,0,0,1,12.416,2.531,1,1,0,0,0,.474.079,1.587,1.587,0,0,0,1.5-1.107,1.549,1.549,0,0,0-1.028-1.9,73.914,73.914,0,0,0-12.97-2.61,1.645,1.645,0,0,0-1.74,1.344A1.516,1.516,0,0,0,23.743,60.6Z" transform="translate(-6.661 -20.192)" stroke="#000" strokeWidth="1" />
                <path d="M23.743,70.1a66.514,66.514,0,0,1,12.416,2.531,1,1,0,0,0,.474.079,1.587,1.587,0,0,0,1.5-1.107,1.549,1.549,0,0,0-1.028-1.9,73.91,73.91,0,0,0-12.97-2.61,1.645,1.645,0,0,0-1.74,1.344A1.565,1.565,0,0,0,23.743,70.1Z" transform="translate(-6.661 -22.179)" stroke="#000" strokeWidth="1" />
                <path d="M71.682,38.6a72.491,72.491,0,0,0-12.97,2.61,1.549,1.549,0,0,0-1.028,1.9,1.617,1.617,0,0,0,1.5,1.107,1,1,0,0,0,.474-.079A76.866,76.866,0,0,1,72.077,41.6a1.486,1.486,0,0,0,1.344-1.74A1.6,1.6,0,0,0,71.682,38.6Z" transform="translate(-14.03 -16.218)" stroke="#000" strokeWidth="1" />
                <path d="M71.682,48.094a72.49,72.49,0,0,0-12.97,2.61,1.549,1.549,0,0,0-1.028,1.9,1.617,1.617,0,0,0,1.5,1.107,1,1,0,0,0,.474-.079A73.554,73.554,0,0,1,72.077,51.1a1.516,1.516,0,1,0-.4-3.005Z" transform="translate(-14.03 -18.2)" stroke="#000" strokeWidth="1" />
                <path d="M71.682,57.594a72.494,72.494,0,0,0-12.97,2.61,1.549,1.549,0,0,0-1.028,1.9,1.617,1.617,0,0,0,1.5,1.107,1,1,0,0,0,.474-.079A73.554,73.554,0,0,1,72.077,60.6a1.516,1.516,0,1,0-.4-3.005Z" transform="translate(-14.03 -20.187)" stroke="#000" strokeWidth="1" />
                <path d="M71.682,67a72.49,72.49,0,0,0-12.97,2.61,1.549,1.549,0,0,0-1.028,1.9,1.617,1.617,0,0,0,1.5,1.107,1,1,0,0,0,.474-.079A73.554,73.554,0,0,1,72.077,70a1.486,1.486,0,0,0,1.344-1.74A1.6,1.6,0,0,0,71.682,67Z" transform="translate(-14.03 -22.158)" stroke="#000" strokeWidth="1" />
                <path d="M49.582,18.92a1.586,1.586,0,0,0,1.582-1.582V11.882a1.582,1.582,0,1,0-3.163,0v5.457A1.586,1.586,0,0,0,49.582,18.92Z" transform="translate(-12.017 -10.3)" stroke="#000" strokeWidth="1" />
                <path d="M37.7,20.921a1.631,1.631,0,0,0,1.344.791,1.526,1.526,0,0,0,.791-.237,1.488,1.488,0,0,0,.554-2.135l-2.768-4.745a1.551,1.551,0,0,0-2.135-.554,1.488,1.488,0,0,0-.554,2.135Z" transform="translate(-9.234 -11.035)" stroke="#000" strokeWidth="1" />
                <path d="M58.589,21.474a1.5,1.5,0,0,0,2.135-.554l2.768-4.745a1.625,1.625,0,0,0-.554-2.135,1.533,1.533,0,0,0-2.135.554l-2.768,4.745A1.473,1.473,0,0,0,58.589,21.474Z" transform="translate(-14.065 -11.035)" stroke="#000" strokeWidth="1" />
              </g>
            </g>
          </g>
  )

  if (props.icon) {
    return (
      <svg
        id='story-icon'
        className='nav-button'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 121 119'
      >
        {svgIconElem}
      </svg>
    );
  }

  return (
    <BootstrapTooltip placement="left" title={props.status}>
      <Link to={props.link ? props.link : ''} state={props?.linkState ? props?.linkState : null}>
        <svg onClick={props.onButtonClicked !== null ? () => props.onButtonClicked('story', true) : () => {}} id="story-icon" className={props.active ? 'nav-button active' : 'nav-button'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121 115">
          {svgIconElem}
        </svg>
      </Link>
    </BootstrapTooltip>
  );
}
