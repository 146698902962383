import React from "react";
import ClearConsoleIcon from "../../Assets/Images/ClearConsoleIcon.svg";
import EnterIcon from "../../Assets/Images/EnterIcon.png";
export default function PythonConsole(props) {
  return (
    <>
      <div id="console-banner">
        <p>Console</p>
        <img src={ClearConsoleIcon} alt="" id="clear-console-btn" onClick={props.onClearConsole} />
      </div>
      <div className="console-box">
        <div id={props.consoleId} className="console"></div>
        <div className="input-container">
          <input type="text" placeholder="Enter your commands..." id="console-input-box" ref={props.inputBoxRef} onKeyDown={props.inputBoxOnKeyDown} onChange={props.inputBoxOnChange} />
          <button type="button" id="send-input-button" ref={props.sendButtonRef} onClick={props.onSendButtonClick}>
            <img src={EnterIcon} alt="" className="send-input-btn-icon"/>
          </button>
        </div>
      </div>
    </>
  );
}
