import React from "react";
import { Typography } from "@mui/material";

function SkillProfile(props) {
  const { skills_acuired } = props;
  return (
    <div>
      <Typography variant='h6' component='h4' className='progress-header'>
        Skills Profile{" "}
      </Typography>
      <div className='skill-profile-container'>
        {skills_acuired &&
          skills_acuired.map((skill) => (
            <div key={skill[0]} className='skill-profile-row'>
              <div className='skill-profile-label'>{skill[0]}</div>
              <div
                style={{
                  backgroundColor: `${
                    skill[1] === "Developing"
                      ? "#FF755C"
                      : skill[1] === "Beginner"
                      ? "#FF9A9B"
                      : skill[1] === "Proficient"
                      ? "#E0325C"
                      : "#BABABA"
                  }`,
                }}
                className='skill-profile-status'
              >
                {skill[1]}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default SkillProfile;
