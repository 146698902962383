import React from "react";

import BigArrowLeft from "../../Assets/Images/BigArrowLeft.svg";
import BigArrowRight from "../../Assets/Images/BigArrowRight.svg";

export default function PythonStoryView(props) {
  const {
    storyTitleRef,
    story,
    storyTitle,
    currentPage,
    handleBigArrowLeftClick,
    maxPages,
    handleBigArrowRightClick,
    showFinishButton,
    setShowFinishButton,
    urlList,
    teacher,
    teacherView,
    setFeedbackActive,
  } = props;
  return (
    <>
      <div className='python-story-area'>
        <div className='d-flex justify-content-between'>
          <div className='story-title-container d-flex align-items-end'>
            <strong>
              <p className='story-title'>{storyTitle}</p>
            </strong>
          </div>
          <div className='d-flex justify-content-end'>
            <div className='page-number mr-2 d-flex align-items-end '>
              <p
                className='story-page-number'
                style={{ fontFamily: "rajdhani-medium", fontSize: "1.6rem" }}
              >
                Page {currentPage}/{maxPages}
              </p>
            </div>
            <div className='python-story-arrows'>
              <img
                className='story-nav-arrow-left'
                style={currentPage === 1 ? { opacity: ".5" } : {}}
                src={BigArrowLeft}
                alt=''
                onClick={currentPage !== 1 ? handleBigArrowLeftClick : null}
              />
              <img
                className='story-nav-arrow-right'
                style={currentPage === maxPages ? { opacity: ".5" } : {}}
                src={BigArrowRight}
                alt=''
                onClick={
                  currentPage !== maxPages ? handleBigArrowRightClick : null
                }
              />
              {currentPage === maxPages && showFinishButton && (
                <button
                  className='story-completed-button ml-3'
                  onClick={() => {
                    if (!teacher || (teacher && teacherView))
                      setFeedbackActive(true);
                    setShowFinishButton(false);
                  }}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
        <div className='python-story-content'>
          <div className='python-story-text-container' ref={storyTitleRef}>
            <pre className='story-text'>
              {story.Frames[currentPage - 1].text}
            </pre>
          </div>
          <div style={{ width: "49%", border: "1px solid grey" }}>
            <img
              className='story-slide'
              src={urlList[currentPage - 1]}
              alt=''
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
