import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";

import { Controller, useFormContext } from "react-hook-form";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  field: {
    marginTop: "5px !important",
    marginBottom: "20px !important",
    display: "block !important",
  },
});
export default function SchoolSubscription({
  classError,
  sectionError,
  updateClassSectionErrors,
  classSection,
  schoolRollOver,
  academicYearOptionsForRollover,
}) {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext();
  const [curriculumList, setCurriculumList] = useState([]);
  const [render, setRender] = useState();
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const classes = useStyles();

  function compareName(a, b) {
    // converting to uppercase to have case-insensitive comparison
    const name1 = a.label.toUpperCase();
    const name2 = b.label.toUpperCase();

    let comparison = 0;

    if (name1 > name2) {
      comparison = 1;
    } else if (name1 < name2) {
      comparison = -1;
    }
    return comparison;
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/cms/curriculumn/all`)
      .then((res) => res.json())
      .then((data) => {
        const newCurrList = [];
        data.map((curr) => {
          newCurrList.push({
            label: curr["Curriculum Name"],
            id: curr.curriculumn_id,
          });
        });
        const newlist = newCurrList.sort(compareName);
        setCurriculumList(newlist);
      });
    setAcademicYearsOptions();
  }, []);

  const setAcademicYearsOptions = () => {
    if (schoolRollOver) {
      setAcademicYearOptions(academicYearOptionsForRollover);
    } else {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/current-academic-year`)
        .then((res) => res.json())
        .then((data) => {
          setAcademicYearOptions(data["academicYearsList"]);
        });
    }
  };

  const handleCloseSnackBar = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateClassSectionErrors();
  };

  const addClassSection = () => {
    const grade = getValues("classes");
    const sections = getValues("sections");
    if (grade in classSection) {
      const section = sections.split(",");
      for (const sec of section) {
        if (!classSection[grade].includes(sec.trim())) {
          classSection[grade].push(sec.trim());
        }
      }
    } else {
      const section = sections.split(",").map((sec) => sec.trim());
      classSection[grade] = section;
      setRender(grade);
    }
    setValue("classes", "");
    setValue("sections", "");
    setValue("classesSections", classSection);
    setRender(`${grade}${sections}`);
  };

  const removeClass = (classes) => {
    delete classSection[classes];
    setValue("classesSections", classSection);
    setRender(classes);
  };
  const removeSection = (classes, section, e) => {
    const pos = classSection[classes].indexOf(section);
    if (pos > -1) {
      classSection[classes].splice(pos, 1);
    }
    setValue("classesSections", classSection);
    setRender(pos);
  };

  return (
    <div>
      <Typography variant="h6" style={{ color: "#00868D", marginBottom: 10 }}>
        School Subscription
      </Typography>
      {classError && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={classError}
          autoHideDuration={2000}
          onClose={handleCloseSnackBar}
          sx={{ top: "10vh !important" }}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity="error"
            sx={{ width: "100%" }}
          >
            At least one class and a section required
          </Alert>
        </Snackbar>
      )}
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Controller
            control={control}
            name="mode"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>
                  Mode
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <RadioGroup
                  {...field}
                  row
                  onChange={(event, value) => field.onChange(value)}
                  value={field.value}
                >
                  {[
                    { id: "trial", label: "Trial", value: "Trial" },
                    { id: "licensed", label: "Licensed", value: "Licensed" },
                  ].map((option) => (
                    <FormControlLabel
                      key={option.id}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            control={control}
            name="status"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>
                  Status
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <RadioGroup
                  {...field}
                  row
                  onChange={(event, value) => field.onChange(value)}
                  value={field.value}
                >
                  {[
                    { id: "active", label: "Active", value: "Active" },
                    { id: "inactive", label: "In-active", value: "Inactive" },
                  ].map((option) => (
                    <FormControlLabel
                      key={option.id}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            name="expiryDate"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>
                  Expiry date
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    openTo="day"
                    views={["year", "month", "day"]}
                    inputFormat="dd/MM/yyyy"
                    {...field}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Controller
            control={control}
            name="paymentSchedule"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>
                  Payment Schedule
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <RadioGroup
                  {...field}
                  row
                  onChange={(event, value) => field.onChange(value)}
                  value={field.value}
                >
                  {[
                    { id: "monthly", label: "Monthly", value: "Monthly" },
                    { id: "quaterly", label: "Quaterly", value: "Quaterly" },
                    {
                      id: "half-yearly",
                      label: "Half-Yearly",
                      value: "Half-Yearly",
                    },
                    { id: "yearly", label: "Yearly", value: "Yearly" },
                  ].map((option) => (
                    <FormControlLabel
                      key={option.id}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Controller
            name="classes"
            rules={{ required: "this field is required." }}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>
                  Classes
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  labelId="classes-label"
                  {...field}
                  margin="normal"
                  variant="outlined"
                  SelectDisplayProps={{
                    style: { paddingTop: 10, paddingBottom: 10 },
                  }}
                  error={Boolean(errors?.classes)}
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="6">6</MenuItem>
                  <MenuItem value="7">7</MenuItem>
                  <MenuItem value="8">8</MenuItem>
                  <MenuItem value="9">9</MenuItem>
                  <MenuItem value="10">10</MenuItem>
                </Select>
                <FormHelperText error>{errors.classes?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            control={control}
            name="sections"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>
                  Section
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  style={{ marginTop: "0px" }}
                  id="sections"
                  variant="outlined"
                  placeholder="Enter Sections"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.sections)}
                  helperText={errors.sections?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={2}>
          <FormControl>
            <FormLabel />
            <Button
              variant="contained"
              style={{ backgroundColor: "#00868D", marginTop: 25 }}
              onClick={addClassSection}
              disabled={!watch("classes") || !watch("sections")}
            >
              Add
            </Button>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          {classSection &&
            Object.entries(classSection).map(([classes, sections]) => (
              <>
                <hr />
                <Stack direction="row" mb={1} key={sections}>
                  <Box
                    sx={{
                      width: "auto",
                      height: "auto",
                      backgroundColor: "#2F4F4F",
                      color: "white",
                      alignItems: "center",
                      borderRadius: 25,
                      fontSize: "1.1vw",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 7,
                      }}
                      className="legend-label"
                    >
                      Class {classes}
                      <CloseIcon
                        fontSize="string"
                        sx={{ marginLeft: 1, cursor: "pointer" }}
                        onClick={() => removeClass(classes)}
                      />
                    </span>
                  </Box>
                  <hr
                    style={{
                      marginLeft: 5,
                      marginRight: 5,
                      marginTop: "1.5vw",
                      height: 0,
                      width: "5%",
                    }}
                  />
                  {sections.sort().map((section, index) => (
                    <Box
                      key={section}
                      sx={{
                        width: "auto",
                        height: "auto",
                        backgroundColor: "#2F4F4F",
                        color: "white",
                        alignItems: "center",
                        borderRadius: 25,
                        marginRight: 1,
                        fontSize: "1.1vw",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 6,
                        }}
                        className="legend-label"
                      >
                        Section {section}
                        <CloseIcon
                          fontSize="string"
                          sx={{ marginLeft: 1, cursor: "pointer" }}
                          onClick={() => {
                            removeSection(classes, section);
                          }}
                        />
                      </span>
                    </Box>
                  ))}
                </Stack>
              </>
            ))}
          <hr />
        </Grid>
      </Grid>
      <h6 style={{ display: "none" }}>{render}</h6>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Controller
            control={control}
            name="assignCurricullum"
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>
                  Assign Curriculum
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Autocomplete
                  disableClearable
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(_, data) => {
                    field.onChange(data.id);
                    setValue("curriculum_name", data.label);
                    setValue("assignCurricullum", data.id);
                  }}
                  disablePortal
                  id="combo-box-demo"
                  defaultValue={{
                    label: getValues("curriculum_name"),
                    id: parseInt(getValues("assignCurricullum")),
                  }}
                  options={curriculumList}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors?.assignCurricullum)}
                      helperText={errors.assignCurricullum?.message}
                    />
                  )}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            control={control}
            name="academicYear"
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>
                  Academic Year
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Autocomplete
                  disableClearable
                  onChange={(event, value) => {
                    setValue("academicYear", value);
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={academicYearOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors?.academicYear)}
                      helperText={errors.academicYear?.message}
                    />
                  )}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
