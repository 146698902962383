import React from "react";
import { Typography } from "@mui/material";

function Feedback(props) {
  const { feedbackStars, isFeedbackSubmitted } = props;
  return (
    <>
      <Typography variant='h6' component='h4' className='progress-header'>
        Teacher Feedback
      </Typography>
      <div className='feedback-box'>
        {feedbackStars.length &&
          feedbackStars.map((item, index) => (
            <svg
              key={index}
              xmlns='http://www.w3.org/2000/svg'
              width='91.02'
              height='86.776'
              viewBox='0 0 91.02 86.776'
            >
              <g transform='translate(-4.491 -6.621)'>
                <path
                  fill={item ? "#ffeb00" : "#ffffff"}
                  d='M6.1,42.294,26.36,62.043l-4.785,27.88c-.444,2.575,1.076,3.678,3.386,2.461L50,79.218,75.04,92.384c2.314,1.217,3.832.114,3.387-2.461l-4.781-27.88L93.9,42.294c1.87-1.823,1.29-3.6-1.294-3.979L64.617,34.247,52.094,8.878c-1.157-2.343-3.029-2.343-4.188,0L35.388,34.246l-28,4.068C4.8,38.688,4.229,40.471,6.1,42.294Z'
                  stroke='#ffcca0'
                  strokeWidth='1'
                  opacity={isFeedbackSubmitted ? 1 : 0.5}
                />
              </g>
            </svg>
          ))}
      </div>
    </>
  );
}

export default Feedback;
