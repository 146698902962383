import { useEffect, useState, useContext, useRef } from "react";
import clickSound from "../../Assets/sounds/click_sound.mp3";
import "../../Styles/Module.css";
import "../../Styles/Notebook.css";
import { NotebookContext } from "../../Components/Contexts/NotebookContext";
import InformatonBar from "../../Components/NavBars/InformatonBar";
import { authUserContext } from "../../Components/Contexts/AuthUser";
import { PrimaryNavBarContext } from "../../Components/Layout/Layout";
import NotebookLevelButtons from "../../Components/Notebook/NotebookLevelButtons";
import NotebookSideBar from "../../Components/NavBars/NotebookSideBar";
import NotebookView from "../../Components/Notebook/NotebookView";
import PlayVideo from "../../Components/ModuleComponents/PlayVideo";

function Notebook() {
  const {
    notebookId,
    notebookStrapiData,
    notebookData,
    isLoading,
    uploadNotebookData,
    currentSectionActivated,
    setCurrentSectionActivated,
    isTeachModule,
    grade,
    className,
    moduleNumber,
    isTeacherSeeingStudentWork,
    studentUserId,
    state,
    dataLoadingCompleted,
    setCurrentlyRunningLevel,
  } = useContext(NotebookContext);
  const { user } = useContext(authUserContext);
  const {
    setIsModulePage,
    setIsTeacherDashboard,
    setNavbarModuleData,
    setTeacherGrade,
    setTeacherSection,
  } = useContext(PrimaryNavBarContext);
  const [stateChanges, setStateChanged] = useState(true);
  const [accordionNotOpen, setAccordionNotOpen] = useState({
    exercise: {},
    assessment: {},
  });
  const exerciseCsvDataFiles = useRef([]);
  const assessmentCsvDataFiles = useRef([]);
  document.title = `CSSoch | ${currentSectionActivated} Notebook`;
  const [backLinkState, setBackLinkState] = useState({
    module: notebookId,
    moduleNo: moduleNumber,
    type: "notebook",
    user: isTeachModule ? null : studentUserId,
  });
  const [selctedLevel, setSelectedLevel] = useState(-1);
  const [helpVideoPopup, setHelpVideoPopup] = useState(false);
  const [helpVideoUrl, setHelpVideoUrl] = useState(null);
  const [helpVideoLabel, setHelpVideoLabel] = useState(null);

  useEffect(() => {
    setIsModulePage(true);
    setIsTeacherDashboard(false);
    setTeacherGrade(grade);
    setTeacherSection(className);

    function fetchHelpVideoUrl() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/cms/help-video/notebook`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          setHelpVideoUrl(data?.details?.videoUrl);
          setHelpVideoLabel(data?.details?.label);
        });
    }
    fetchHelpVideoUrl();
  }, []);

  useEffect(() => {
    if (!currentSectionActivated || !dataLoadingCompleted) return;
    let updataStatusData = {};
    if (currentSectionActivated === "Exercise") {
      if (notebookData?.exercise_status !== "completed") {
        updataStatusData["exercise_status"] = "ongoing";
      }
      if (notebookData?.assessment_status === "ongoing") {
        updataStatusData["assessment_status"] = "incomplete";
      }
    } else {
      if (notebookData?.assessment_status !== "completed") {
        updataStatusData["assessment_status"] = "ongoing";
      }
      if (notebookData?.exercise_status === "ongoing") {
        updataStatusData["exercise_status"] = "incomplete";
      }
    }
    if (Object.keys(updataStatusData).length)
      uploadNotebookData(updataStatusData);
    setCurrentlyRunningLevel(-1);
  }, [currentSectionActivated]);

  useEffect(() => {
    if (currentSectionActivated && notebookStrapiData) {
      setNavbarModuleData({ moduleName: notebookStrapiData?.notebookName });
    }
  }, [isTeachModule, currentSectionActivated, notebookStrapiData]);

  function handleCurrentSectionChange(newSection, immediate = false) {
    if (newSection !== currentSectionActivated) {
      setStateChanged((value) => !value);
      if (immediate) {
        const audio = new Audio(clickSound);
        audio.volume = 0.3;
        audio.play();
      }
      setCurrentSectionActivated(
        newSection === "story" ? "Exercise" : "Assessment"
      );
    }
  }

  if (isLoading) {
    return <p>Page is Loading...</p>;
  }

  return (
    <>
      <NotebookSideBar
        notebook={true}
        handleCurrentSectionChange={handleCurrentSectionChange}
        currentSection={currentSectionActivated}
        backLink={
          isTeachModule
            ? `/dashboard/${grade}/${className}`
            : isTeacherSeeingStudentWork
            ? `/dashboard/${grade}/${className}`
            : `/home?module=${notebookId}&type=notebook`
        }
        backLinkState={backLinkState}
        state={state}
        teacher={user?.role.includes("teacher")}
      />
      <div className="notebook-container">
        {isTeacherSeeingStudentWork && (
          <div style={{ fontFamily: "rajdhani-medium" }}>
            <InformatonBar textToDisplay={`${notebookData?.studentName}`} />
          </div>
        )}
        <div className="notebook-title-header">
          <NotebookLevelButtons
            data={
              currentSectionActivated === "Exercise"
                ? notebookStrapiData.exerciseCells
                : notebookStrapiData.assessmentCells
            }
            notebookData={notebookData}
            notebookType={currentSectionActivated}
            isTeachModule={isTeachModule}
            selctedLevel={selctedLevel}
            setSelectedLevel={setSelectedLevel}
            setAccordionNotOpen={setAccordionNotOpen}
            accordionNotOpen={accordionNotOpen}
            helpVideo={helpVideoUrl}
            helpVideoLabel={helpVideoLabel}
            onVideoPlayBtnClick={() => setHelpVideoPopup(true)}
          />
        </div>
        {currentSectionActivated === "Exercise" && (
          <NotebookView
            notebookStrapiData={notebookStrapiData}
            stateChanges={stateChanges}
            setSelectedLevel={setSelectedLevel}
            accordionNotOpen={accordionNotOpen}
            setAccordionNotOpen={setAccordionNotOpen}
            csvDataFiles={exerciseCsvDataFiles}
            notebookType={"exercise"}
          />
        )}
        {currentSectionActivated === "Assessment" && (
          <NotebookView
            notebookStrapiData={notebookStrapiData}
            stateChanges={stateChanges}
            setSelectedLevel={setSelectedLevel}
            accordionNotOpen={accordionNotOpen}
            setAccordionNotOpen={setAccordionNotOpen}
            csvDataFiles={assessmentCsvDataFiles}
            notebookType={"assessment"}
          />
        )}
        <PlayVideo
          setHelpVideoPopup={setHelpVideoPopup}
          videoUrl={helpVideoUrl}
          helpVideoPopup={helpVideoPopup}
        />
      </div>
    </>
  );
}

export default Notebook;
