import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HorizontalBar from "../../../Assets/Images/HorizontalBar.svg";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily: "rajdhani-medium",
  },
}));

function LevelButtons(props) {
  const {
    type,
    gameInfo,
    gameInfoObj,
    currentLevel,
    setCurrentLevel,
    codeCarryForwardLevels = null,
    statusStorage,
  } = props;
  let gameLevelButtonLabel = 1;
  const [gameLevels, setGameLevels] = useState([]);

  useEffect(() => {
    if (statusStorage && statusStorage?.game_levels) {
      setGameLevels(statusStorage.game_levels);
    }
  }, [statusStorage]);

  if (type === "playgroundEnvironment") {
    return (
      <div className="game-button-holder">
        {gameInfo.levels.map((lvl, index) => {
          if (index === 0) {
            return (
              <>
                {gameInfoObj.Levels[index].DemoLevel ? (
                  <>
                    <BootstrapTooltip placement="bottom" title="Demo Level">
                      <button
                        className={`demo-level-btn ${currentLevel === index + 1 ? "active" : ""}`}
                        key={`btn-${index}`}
                        onClick={() => {
                          setCurrentLevel(index + 1);
                        }}
                      />
                    </BootstrapTooltip>
                    <img key={`img-${index}`} src={HorizontalBar} />
                  </>
                ) : (
                  <>
                    <button
                      key={`btn-${index}`}
                      className={`${currentLevel === index + 1 ? "active" : ""} ${gameLevels && (gameLevels[index]?.status === "completed" ? "completed" : gameLevels[index]?.status === "ongoing" ? "ongoing" : "incomplete")}`}
                      onClick={() => {
                        setCurrentLevel(index + 1);
                      }}
                    >
                      {gameLevelButtonLabel++}
                    </button>
                    <img key={`img-${index}`} src={HorizontalBar} />
                  </>
                )}
              </>
            );
          }
          if (index === gameInfo.levels.length - 1) {
            return (
              <>
                {gameInfoObj.Levels[index].DemoLevel ? (
                  <>
                    <img key={`img-${index}`} src={HorizontalBar} />
                    <BootstrapTooltip placement="bottom" title="Demo Level">
                      <button
                        className={`demo-level-btn ${currentLevel === index + 1 ? "active" : ""}`}
                        key={`btn-${index}`}
                        onClick={() => {
                          setCurrentLevel(index + 1);
                        }}
                      />
                    </BootstrapTooltip>
                  </>
                ) : (
                  <>
                    <img key={`img-${index}`} src={HorizontalBar} />
                    <button
                      key={`btn-${index}`}
                      className={`${currentLevel === index + 1 ? "active" : ""} ${gameLevels && (gameLevels[index]?.status === "completed" ? "completed" : gameLevels[index]?.status === "ongoing" ? "ongoing" : "incomplete")}`}
                      onClick={() => {
                        setCurrentLevel(index + 1);
                      }}
                    >
                      {gameLevelButtonLabel++}
                    </button>
                  </>
                )}
              </>
            );
          }
          return (
            <>
              {gameInfoObj.Levels[index].DemoLevel ? (
                <>
                  <img key={`img-${index}`} src={HorizontalBar} />
                  <BootstrapTooltip placement="bottom" title="Demo Level">
                    <button
                      className={`demo-level-btn ${currentLevel === index + 1 ? "active" : ""}`}
                      key={`btn-${index}`}
                      onClick={() => {
                        setCurrentLevel(index + 1);
                      }}
                    />
                  </BootstrapTooltip>
                  <img key={`img-${index}`} src={HorizontalBar} />
                </>
              ) : (
                <>
                  <img key={`img-${index}`} src={HorizontalBar} />
                  <button
                    key={`btn-${index}`}
                    className={`${currentLevel === index + 1 ? "active" : ""} ${gameLevels && (gameLevels[index]?.status === "completed" ? "completed" : gameLevels[index]?.status === "ongoing" ? "ongoing" : "incomplete")}`}
                    onClick={() => {
                      setCurrentLevel(index + 1);
                    }}
                  >
                    {gameLevelButtonLabel++}
                  </button>
                  <img key={`img-${index}`} src={HorizontalBar} />
                </>
              )}
            </>
          );
        })}
      </div>
    );
  }

  return (
    <div className="game-button-holder">
      {gameInfo.levels.map((lvl, index) => {
        if (codeCarryForwardLevels.includes(index + 1)) return null;
        const isFirstLevel = index === 0;
        const isLastLevel = index === gameInfo.levels.length - 1;
        const isDemoLevel = gameInfoObj.Levels[index].DemoLevel;
        let connectedLevelCount = 0;
        let isLevelConnectedToLastLevel = false;
        for (let i = index + 1; i <= gameInfo.levels.length; i++) {
          if (codeCarryForwardLevels.includes(i + 1)) {
            connectedLevelCount++;
            if (i + 1 === gameInfo.levels.length)
              isLevelConnectedToLastLevel = true;
          } else break;
        }
        return (
          <>
            {isDemoLevel ? (
              isFirstLevel ? (
                <>
                  <BootstrapTooltip placement="bottom" title="Demo Level">
                    <button
                      className={`demo-level-btn ${currentLevel === index + 1 ? "active" : ""}`}
                      key={`btn-${index}`}
                      onClick={() => {
                        setCurrentLevel(index + 1);
                      }}
                    />
                  </BootstrapTooltip>
                  <img key={`img-${index}`} src={HorizontalBar} />
                </>
              ) : isLastLevel ? (
                <>
                  <img key={`img-${index}`} src={HorizontalBar} />
                  <BootstrapTooltip placement="bottom" title="Demo Level">
                    <button
                      className={`demo-level-btn ${currentLevel === index + 1 ? "active" : ""}`}
                      key={`btn-${index}`}
                      onClick={() => {
                        setCurrentLevel(index + 1);
                      }}
                    />
                  </BootstrapTooltip>
                </>
              ) : (
                <>
                  <img key={`img-${index}`} src={HorizontalBar} />
                  <BootstrapTooltip placement="bottom" title="Demo Level">
                    <button
                      className={`demo-level-btn ${currentLevel === index + 1 ? "active" : ""}`}
                      key={`btn-${index}`}
                      onClick={() => {
                        setCurrentLevel(index + 1);
                      }}
                    />
                  </BootstrapTooltip>
                  <img key={`img-${index}`} src={HorizontalBar} />
                </>
              )
            ) : isFirstLevel ? (
              codeCarryForwardLevels.includes(index + 2) ? (
                <div
                  style={
                    isLevelConnectedToLastLevel
                      ? {
                          paddingLeft: "0.5vw",
                          paddingRight: "0.5vw",
                        }
                      : { paddingLeft: "0.5vw" }
                  }
                >
                  <button
                    key={`btn-${index}`}
                    className={`${currentLevel === index + 1 ? "active" : ""} ${gameLevels && (gameLevels[index]?.status === "completed" ? "completed" : gameLevels[index]?.status === "ongoing" ? "ongoing" : "incomplete")}`}
                    onClick={() => {
                      setCurrentLevel(index + 1);
                    }}
                  >
                    {gameLevelButtonLabel++}
                  </button>
                  <img key={`img-${index}`} src={HorizontalBar} />
                  {Array.from({ length: connectedLevelCount }).map((_, i) => {
                    if (index + i === gameInfo.levels.length - 1) {
                      return (
                        <>
                          <img key={`img-${index}`} src={HorizontalBar} />
                          <button
                            key={`btn-${index}`}
                            className={`${currentLevel === index + 1 ? "active" : ""} ${gameLevels && (gameLevels[index]?.status === "completed" ? "completed" : gameLevels[index]?.status === "ongoing" ? "ongoing" : "incomplete")}`}
                            onClick={() => {
                              setCurrentLevel(index + i + 2);
                            }}
                          >
                            {gameLevelButtonLabel++}
                          </button>
                        </>
                      );
                    }
                    return (
                      <>
                        <img key={`img-${index}`} src={HorizontalBar} />
                        <button
                          key={`btn-${index}`}
                          className={`${currentLevel === index + 1 ? "active" : ""} ${gameLevels && (gameLevels[index]?.status === "completed" ? "completed" : gameLevels[index]?.status === "ongoing" ? "ongoing" : "incomplete")}`}
                          onClick={() => {
                            setCurrentLevel(index + i + 2);
                          }}
                        >
                          {gameLevelButtonLabel++}
                        </button>
                        <img key={`img-${index}`} src={HorizontalBar} />
                      </>
                    );
                  })}
                </div>
              ) : (
                <>
                  <button
                    key={`btn-${index}`}
                    className={`${currentLevel === index + 1 ? "active" : ""} ${gameLevels && (gameLevels[index]?.status === "completed" ? "completed" : gameLevels[index]?.status === "ongoing" ? "ongoing" : "incomplete")}`}
                    onClick={() => {
                      setCurrentLevel(index + 1);
                    }}
                  >
                    {gameLevelButtonLabel++}
                  </button>
                  <img key={`img-${index}`} src={HorizontalBar} />
                </>
              )
            ) : isLastLevel ? (
              <>
                <img key={`img-${index}`} src={HorizontalBar} />
                <button
                  key={`btn-${index}`}
                  className={`${currentLevel === index + 1 ? "active" : ""} ${gameLevels && (gameLevels[index]?.status === "completed" ? "completed" : gameLevels[index]?.status === "ongoing" ? "ongoing" : "incomplete")}`}
                  onClick={() => {
                    setCurrentLevel(index + 1);
                  }}
                >
                  {gameLevelButtonLabel++}
                </button>
              </>
            ) : codeCarryForwardLevels.includes(index + 2) ? (
              <div
                style={
                  isLevelConnectedToLastLevel ? { paddingRight: "0.5vw" } : {}
                }
              >
                {Array.from({ length: connectedLevelCount + 1 }).map((_, i) => {
                  if (index + i === gameInfo.levels.length - 1) {
                    return (
                      <>
                        <img key={`img-${index}`} src={HorizontalBar} />
                        <button
                          key={`btn-${index}`}
                          className={`${currentLevel === index + 1 ? "active" : ""} ${gameLevels && (gameLevels[index]?.status === "completed" ? "completed" : gameLevels[index]?.status === "ongoing" ? "ongoing" : "incomplete")}`}
                          onClick={() => {
                            setCurrentLevel(index + i + 1);
                          }}
                        >
                          {gameLevelButtonLabel++}
                        </button>
                      </>
                    );
                  }
                  return (
                    <>
                      <img key={`img-${index}`} src={HorizontalBar} />
                      <button
                        key={`btn-${index}`}
                        className={`${currentLevel === index + 1 ? "active" : ""} ${gameLevels && (gameLevels[index]?.status === "completed" ? "completed" : gameLevels[index]?.status === "ongoing" ? "ongoing" : "incomplete")}`}
                        onClick={() => {
                          setCurrentLevel(index + i + 1);
                        }}
                      >
                        {gameLevelButtonLabel++}
                      </button>
                      <img key={`img-${index}`} src={HorizontalBar} />
                    </>
                  );
                })}
              </div>
            ) : (
              <>
                <img key={`img-${index}`} src={HorizontalBar} />
                <button
                  key={`btn-${index}`}
                  className={`${currentLevel === index + 1 ? "active" : ""} ${gameLevels && (gameLevels[index]?.status === "completed" ? "completed" : gameLevels[index]?.status === "ongoing" ? "ongoing" : "incomplete")}`}
                  onClick={() => {
                    setCurrentLevel(index + 1);
                  }}
                >
                  {gameLevelButtonLabel++}
                </button>
                <img key={`img-${index}`} src={HorizontalBar} />
              </>
            )}
          </>
        );
      })}
    </div>
  );
}

export default LevelButtons;
