import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { React, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ClassSectionDetails from "../../Components/School/ClassSectionDetails";

const useStyles = makeStyles({
  label: {
    fontWeight: 401,
  },
});

export default function TeacherDetails() {
  document.title = "CSSoch | Teacher Details";
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const {state} = location;
  const [isLoading, setisLoading] = useState(false);
  const [person, setPerson] = useState();
  const [teacherDetails, setTeacherDetails] = useState(state.teacherDetails ? state.teacherDetails : null);
  useEffect(() => {
    if(!state?.teacherDetails){
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/teacher/details/${location.state.id}`
      )
        .then((res) => res.json())
        .then((data) => {
          setTeacherDetails(data.teacherDetails);
          console.log("Teacher Details: ", data.teacherDetails);
          setisLoading(true);
        });
    }
    if(state?.teacherDetails){
      setisLoading(true);
    }
  }, []);
  return (
    <div style={{ backgroundColor: "#DDE9F5" }}>
      {isLoading ? (
        <Container>
          <Typography variant="h6" component="h2" style={{ padding: 10 }}>
            Teacher Details
          </Typography>
          <div
            style={{
              backgroundColor: "white",
              marginTop: 5,
              padding: 20,
              borderRadius: 6,
            }}
          >
            <Grid container spacing={2} mb={1}>
              <Grid item md={8} xs={12}>
                <Grid container spacing={2}>
                  <Grid item md={3} className={classes.label}>
                    Name:
                  </Grid>
                  <Grid item md={7}>
                    {teacherDetails.fullname}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <Grid container spacing={2}>
                  <Grid item md={4} className={classes.label}>
                    Status:
                  </Grid>
                  <Grid item md={3}>
                    {teacherDetails.status}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={1}>
              <Grid item md={8}>
                <Grid container spacing={2}>
                  <Grid item md={3} className={classes.label}>
                    Username:
                  </Grid>
                  <Grid item md={7}>
                    {teacherDetails.username}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <Grid container spacing={2}>
                  <Grid item md={3} className={classes.label}>
                    Gender:
                  </Grid>
                  <Grid item md={7}>
                    {teacherDetails.gender}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Typography
              style={{ color: "#00868D" }}
              mt={2}
              mb={2}
              variant="h6"
              component="h2"
            >
              Teacher Assigned to
            </Typography>
            {teacherDetails && (
              <ClassSectionDetails gradeDetails={teacherDetails.grades} type="teacher"/>
            )}
            <Typography
              style={{ color: "#00868D" }}
              mt={5}
              mb={2}
              variant="h6"
              component="h2"
            >
              Teacher contact
            </Typography>
            <Grid container spacing={2} mb={1}>
              <Grid item md={8}>
                <Grid container spacing={2}>
                  <Grid item md={3} className={classes.label}>
                    Contact number:
                  </Grid>
                  <Grid item md={7}>
                    {teacherDetails.phone}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={1}>
              <Grid item md={8}>
                <Grid container spacing={2}>
                  <Grid item md={3} className={classes.label}>
                    Official Email:
                  </Grid>
                  <Grid item md={7}>
                    {teacherDetails.email}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={1}>
              <Grid item md={8}>
                <Grid container spacing={2}>
                  <Grid item md={3} className={classes.label}>
                    Personal Email:
                  </Grid>
                  <Grid item md={7}>
                    {teacherDetails.secondary_email}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Stack
              spacing={2}
              direction="row"
              style={{ justifyContent: "center" }}
            >
              <Button
                onClick={() =>
                  navigate("/teacher/edit", {
                    state: {
                      mode: "edit",
                      id: teacherDetails.id,
                      user: location.state?.user,
                      teacherDetails: teacherDetails,
                    },
                  })
                }
                variant="contained"
                size="medium"
                style={{ backgroundColor: "#00868D" }}
              >
                Edit
              </Button>
              <Button
                onClick={() =>
                  navigate("/teacher/list", {
                    state: { user: location.state?.user },
                  })
                }
                variant="text"
                style={{ color: "black" }}
              >
                Cancel
              </Button>
            </Stack>
          </div>
        </Container>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
}
