import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tab from "@mui/material/Tab";
import { React, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authUserContext } from "../../Components/Contexts/AuthUser";
import { downloadReportContext } from "../../Components/Contexts/downloadReport";
import { schoolNameContext } from "../../Components/Contexts/schoolName";
import useFileDownloader from "../../hooks/useFileDownloader";
import ModuleAnalytics from "./ModuleAnalytics";
import ModuleAssignment from "./ModuleAssignment";
import ModuleProgress from "./ModuleProgress";
import SkillAnalytics from "./SkillAnalytics";
import "../../Styles/AdminDashboard.css";
import { PrimaryNavBarContext } from "../../Components/Layout/Layout";

export default function Dashboard(params) {
  const [isLoading, setisLoading] = useState(false);
  const [grade, setGrade] = useState();
  const [allGrades, setAllGrades] = useState();
  const [gradeFetched, setGradeFetched] = useState(false);
  const [gradeData, setGradeData] = useState();
  const [section, setSection] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [changedSections, setChangedSection] = useState([]);
  const [tabValue, setTabValue] = useState("moduleProgress");
  const [gradeChanged, setGradeChanged] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(authUserContext);
const {setIsAdminDashboardOrTeacherClassess} = useContext(PrimaryNavBarContext);
  const { search } = useLocation();
  const schoolname = useContext(schoolNameContext);
  const openFileDownloader = useContext(downloadReportContext);
  const isAllSelected =
    section.length > 0 && changedSections.length === section.length;
  const style = {
    tabPanel: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
    },
    vLine: {
      borderLeftColor: "#C9C6C6",
      borderLeftWidth: 1,
      borderLeftStyle: "solid",
      marginLeft: 10,
      marginRight: 10,
      marginTop: 7,
      marginBottom: 2,
    },
  };
  useEffect(() => {
    const schoolcode = location.state
      ? location.state.schoolcode
      : user.schoolcode;
    if (!gradeFetched) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/all-grades/${schoolcode}`)
        .then((res) => res.json())
        .then((data) => {
          setAllGrades(data.grade);
          if (user.role.includes("teacher")) {
            const currentGrade = new URLSearchParams(search).get("grade");
            setGrade(currentGrade);
            setGradeChanged(currentGrade);
            setGradeData(data.grade);
            setSection(data.grade[currentGrade]);
            selectedSections.map((key) => {
              if (!section.includes(key)) {
                selectedSections.splice(selectedSections.indexOf(key), 1);
              }
            });
            if (selectedSections.length === 0)
              setSelectedSections([section[0]]);
            setSelectedSections([data.grade[currentGrade][0]]);
            if (selectedSections.length === 0) setChangedSection([section[0]]);
            setChangedSection([data.grade[currentGrade][0]]);
            setGradeFetched(true);
          } else {
            for (const prop in data.grade) {
              setGrade(prop);
              setGradeChanged(prop);
              setSection(data.grade[prop]);
              selectedSections.map((key) => {
                if (!section.includes(key)) {
                  selectedSections.splice(selectedSections.indexOf(key), 1);
                }
              });
              if (selectedSections.length === 0)
                setSelectedSections([section[0]]);
              setSelectedSections([data.grade[prop][0]]);
              if (selectedSections.length === 0)
                setChangedSection([section[0]]);
              setChangedSection([data.grade[prop][0]]);
              break;
            }
            setGradeData(data.grade);
            setGradeFetched(true);
          }
          setGradeData(data.grade);
          setGradeFetched(true);
        });
    }
    if (gradeFetched) {
      setisLoading(true);
    }
  }, [grade, gradeFetched]);

  useEffect(() => {
    if (gradeFetched) {
      setSection(gradeData[gradeChanged]);
      setChangedSection([gradeData[gradeChanged][0]]);
    }
  }, [gradeChanged]);

  useEffect(() => {
    if (user && user?.role?.includes("teacher")) {
      setIsAdminDashboardOrTeacherClassess(true);
    }
  }, [user]);
  function handleGradeChange(e) {
    setGradeChanged(e.target.value);
  }

  function handleSectionChange(event) {
    const { value } = event.target;
    if (value[value.length - 1] === "all") {
      setChangedSection(
        changedSections.length === section.length ? [section[0]] : section
      );
      return;
    }
    setChangedSection(value);
    if (value.length === 0) setChangedSection([section[0]]);
  }

  const updateSelectedSection = () => {
    setGrade(gradeChanged);
    setSelectedSections(changedSections);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDownloadReport = () => {
    const body = {};
    const passedSection =
      selectedSections.length === section.length ? "All" : selectedSections;
    if (passedSection !== "All") {
      body.grades = {};
      body.grades[grade.toString()] = selectedSections;
    }
    const schoolcode = location.state
      ? location.state.schoolcode
      : user.schoolcode;
    const reportName = schoolname.school;
    const url = {
      name: reportName.split().join(""),
      thumb: "report.thumb",
      file: `${process.env.REACT_APP_API_BASE_URL}/school/report/${schoolcode}/${grade}`,
      body,
      passedSection,
      filename: `CSSoch_Report_${grade}_${selectedSections.join(
        "_"
      )}_${schoolcode}`,
    };
    openFileDownloader.updateDownloadReport(true, url);
  };

  return (
    <div
      style={{
        backgroundColor: "#DDE9F5",
        minHeight: "90vh",
        paddingBottom: 10,
      }}
    >
      {isLoading ? (
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              style={{
                padding: 10,
                fontFamily: "rajdhani-semibold",
                fontSize: 27,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              {user.role.includes("teacher") && (
                <Button
                  variant="text"
                  style={{
                    backgroundColor: "transparent",
                    fontWeight: "normal",
                    color: "black",
                    fontSize: 20,
                  }}
                  onClick={() => {
                    navigate("/classes");
                  }}
                  startIcon={<ArrowBackIosIcon />}
                  sx={{
                    ".MuiButton-startIcon": {
                      marginLeft: 0,
                      marginRight: 0,
                    },
                  }}
                >
                  Back
                </Button>
              )}
              Progress Report
            </Typography>
            <div
              style={{
                padding: "10px 20px 10px 10px",
                color: "#484C51",
                display: "flex",
              }}
            >
              <FormControl style={{ display: "inline" }}>
                <FormLabel>Select Grade</FormLabel>
                <br />
                <Select
                  onChange={handleGradeChange}
                  value={gradeChanged}
                  style={{ height: 30, width: 100 }}
                >
                  {Object.keys(allGrades).map((key) => (
                    <MenuItem value={key} key={key}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ display: "inline", marginLeft: 10 }}>
                <FormLabel id="section-multiple-checkbox-label">
                  Select Section{" "}
                </FormLabel>{" "}
                <br />
                <Select
                  labelId="mutiple-select-label"
                  multiple
                  value={changedSections}
                  onChange={handleSectionChange}
                  renderValue={
                    changedSections.length === section.length &&
                    section.length !== 1
                      ? () => "All"
                      : (selected) => selected.join(", ")
                  }
                  style={{ height: 30, width: 170 }}
                >
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox checked={isAllSelected} />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {section.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox
                          checked={changedSections.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#00868D",
                  marginLeft: 10,
                  marginTop: 24,
                  height: 29,
                }}
                onClick={updateSelectedSection}
              >
                GO
              </Button>
            </div>
          </div>
          <div
            className="progress-report-container"
            style={{ backgroundColor: "white", borderRadius: 6, padding: 20 }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "end",
                paddingBottom: "15px",
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                className="admin-progress-header"
              >
                Grade: {grade} -{" "}
                {selectedSections.map((section, index) =>
                  selectedSections.length === index + 1
                    ? ` ${section}`
                    : ` ${section},`
                )}
              </Typography>
              <Tooltip title="Download Progress Report" arrow>
                <Button
                  variant="contained"
                  endIcon={<FileDownloadIcon />}
                  style={{
                    backgroundColor: "#00868D",
                    marginLeft: "auto",
                    height: 29,
                  }}
                  onClick={handleDownloadReport}
                >
                  Report
                </Button>
              </Tooltip>
            </div>
            <TabContext value={tabValue}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
                TabIndicatorProps={{ style: { display: "none" } }}
                sx={{
                  "& button": {
                    padding: 1,
                    fontSize: 16,
                    paddingBottom: 0.5,
                    textTransform: "none",
                    justifyContent: "flex-end",
                    fontFamily: "rajdhani-medium",
                    fontSize: 20,
                    color: "black",
                  },
                  "& button:active": {
                    backgroundColor: "none",
                  },
                  "& button.Mui-selected": {
                    color: "#00868D",
                    borderBottom: 1,
                    fontFamily: "rajdhani-semibold",
                  },
                  "& button.MuiButtonBase-root": {
                    minHeight: 26,
                  },
                }}
              >
                <Tab
                  disableTouchRipple
                  label="Module Progress"
                  value="moduleProgress"
                />
                <Box style={style.vLine} />
                <Tab
                  disableTouchRipple
                  label="Module Assignment"
                  value="moduleAssignment"
                />
                <Box style={style.vLine} />
                <Tab
                  disableTouchRipple
                  label="Module Analytics"
                  value="moduleAnalytics"
                />
                <Box style={style.vLine} />
                <Tab
                  disableTouchRipple
                  label="Skills Analytics"
                  value="skillsAnalytics"
                />
              </TabList>
              <TabPanel value="moduleProgress" style={style.tabPanel}>
                <ModuleProgress
                  grade={grade}
                  selectedSections={selectedSections}
                  gradeData={gradeData}
                />
              </TabPanel>
              <TabPanel value="moduleAssignment" style={style.tabPanel}>
                <ModuleAssignment grade={grade} gradeData={gradeData} />
              </TabPanel>
              <TabPanel value="moduleAnalytics" style={style.tabPanel}>
                <ModuleAnalytics
                  grade={grade}
                  selectedSections={selectedSections}
                  gradeData={gradeData}
                />
              </TabPanel>
              <TabPanel value="skillsAnalytics" style={style.tabPanel}>
                <SkillAnalytics
                  grade={grade}
                  selectedSections={selectedSections}
                  gradeData={gradeData}
                />
              </TabPanel>
            </TabContext>
          </div>
        </Container>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
}
