export function carryForwardPreviousLevelCode(previousLevelCode, currentLevelCode) {
  const parser = new DOMParser();
  const previousXml = parser.parseFromString(previousLevelCode, 'text/xml');
  const currentXml = parser.parseFromString(currentLevelCode, 'text/xml');
  const allBlockElements = previousXml.getElementsByTagName('block');
  const parentBlockElement = [];
  for (let i = 0; i < allBlockElements.length; i++) {
    const block = allBlockElements[i];
    if (block.parentElement.tagName.toLowerCase() === 'xml') {
      parentBlockElement.push(block);
    }
  }
  for (let i = 0; i < parentBlockElement.length; i++) {
    const perviousCodeBlock = parentBlockElement[i];
    const previousCodeBlockType = perviousCodeBlock.getAttribute('type');
    const currentCodeBlock = currentXml.querySelector(`block[type="${previousCodeBlockType}"]`);

    if (currentCodeBlock) {
      currentCodeBlock.innerHTML = perviousCodeBlock.innerHTML;
    }
  }
  const serializer = new XMLSerializer();
  const finalCode = serializer.serializeToString(currentXml);
  return finalCode;
}
