import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../Styles/NotFound.css";
import { useDispatch } from "react-redux";
import { setIsErrorPage } from "../../features/PrimaryNavbarData/NavbarActions";

function NotFound() {
  document.title = "CSSoch | Error";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsErrorPage(true));
  }, []);
  return (
    <div id='not-found-container'>
      <a
        onClick={() => {
          dispatch(setIsErrorPage(false));
          navigate("/");
        }}
      >
        <img className='company' src='/static/svg/CompanyLogin.svg' alt='' />
      </a>
      <div className='not-found-content'>
        <div className='row'>
          <div className='col-1'>
            <img
              src='/static/svg/snapshots/Cheeti.png'
              width='200px'
              height='300px'
              style={{ marginTop: "20px", marginLeft: "-50px" }}
              alt='Friendly ant'
            />
          </div>
          <div
            className='col-10 text-center'
            style={{ marginTop: "60px", marginLeft: "60px" }}
          >
            <h2>Oops</h2>
            <p className='error-code'>Error Code: 404</p>
            <p>We can't seem to find the page you're looking for</p>
            <a
              onClick={() => {
                dispatch(setIsErrorPage(false));
                navigate("/");
              }}
            >
              Home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
