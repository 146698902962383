import React, { useEffect, useState,useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import SecondaryNav from "../../Components/NavBars/SecondaryNav";
import LetsWork from "../../Components/StudentHomePage/LetsWork";
import Parents from "../../Components/StudentHomePage/Parents";
import Progress from "../../Components/StudentHomePage/Progress";
import Community from "../../Components/StudentHomePage/Community";
import "../../Styles/Global.css";
import "../../Styles/StudentHome.css";
import "../../Styles/Playground.css";
import ModuleStatusBar from "../../Components/StudentHomePage/ModuleStatusBar";
import SideNav from "../../Components/StudentHomePage/SideNav";
import clickSound from "../../Assets/sounds/click_sound.mp3";
import { PrimaryNavBarContext } from "../../Components/Layout/Layout";

export default function StudentHome(props) {
  document.title = "CSSoch | Home";
  const [grade, setGrade] = useState();
  const { search, state } = useLocation();
  const [section, setSection] = useState(state?.section);
  const [currentModuleData, setCurrentModuleData] = useState();
  const [currentTab, setCurrentTab] = useState(state?.tab ? state?.tab :'work');
  const [moduleID, setModuleID] = useState(new URLSearchParams(search).get('module') ? parseInt(new URLSearchParams(search).get('module')) : null);
  const moduleType = new URLSearchParams(search).get('type');
  const [currentModuleType, setCurrentModuleType] = useState(moduleType && moduleType);
  const [currentModuleID, setCurrentModuleID] = useState();
  const [chageModuleStatusSignal, setChangeModuleStatusSignal] = useState(true);
  const [teacherName, setTeacherName] = useState();
  const [activateButtons, setActivateButtons] = useState(true);
  const [previousModuleInfo, setPreviousModuleInfo] = useState({});
  const [moduleStatusData, setModuleStatusData]= useState()
  const [currentModuleAssignment, setCurrentModuleAssignment] = useState([]);
  const [parentNotes, setParentNotes] = useState([]);
  const [parentLetter, setParentLetter] = useState("");
  const  currentModuleNum = useRef(1);
  const {setIsModulePage, setNavbarModuleData} = useContext(PrimaryNavBarContext)
    
  useEffect(() => {
    setIsModulePage(false)
    setNavbarModuleData({})
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/student/get-module-status`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setGrade(data.grade);
        setSection(data.section);
        setTeacherName(data.teacher);
        setModuleStatusData(data)
    });
  }, []);
  
  useEffect(() => {
    if (moduleStatusData && currentModuleID) {
      const currentModuleIndex = moduleStatusData.module_ids.indexOf(currentModuleID)
      const type = moduleStatusData.module_types[currentModuleIndex]
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          module_id: currentModuleID,
          type: type,
          reset: previousModuleInfo
        }),
      };
      fetch(`${process.env.REACT_APP_API_BASE_URL}/home`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          data["status"] = moduleStatusData.statuses[currentModuleIndex]
          setChangeModuleStatusSignal((prev) => !prev);
          setCurrentModuleData(data);
          setCurrentModuleAssignment(moduleStatusData.dates[currentModuleIndex])
          setCurrentModuleType(type)
        });
    }
  }, [currentModuleID,moduleStatusData]);
  const callClickSound = () => {
    const audio = new Audio(clickSound);
    audio.volume = 0.3;
    audio.play();
  }

  function changeTab(tab) {
    if(currentTab === tab) return
    callClickSound();
    if (tab !== "work"){ 
      setActivateButtons(false)
      setModuleID(null);
    }
    else setActivateButtons(true);
    setCurrentTab(tab);
  }
  function updateCurrentModule(previousModule, newModule) {
    setPreviousModuleInfo(previousModule);
    setCurrentModuleType(newModule.type);
    setCurrentModuleID(newModule.id);
  }
  return (
    <div style={{ margin: 0, padding: 0 }}>
      <SecondaryNav
        grade={grade}
        section={section}
        teacherName={teacherName}
      />
      <div>
        <SideNav 
          currentTab={currentTab} 
          changeTab={changeTab} 
        />
       <div
          className={`content ${
            currentTab === "progress" ? "progress-container" : ""
          }`}
        >
          {currentTab === "work" && (
            <ModuleStatusBar
              currentID={moduleID}
              activateButtons={activateButtons}
              updateCurrentModule={updateCurrentModule}
              moduleStatusData = {moduleStatusData}
              setCurrentModuleID = {setCurrentModuleID}
              setTeacherName={setTeacherName}
              currentModuleNum={currentModuleNum}
            />
          )}
          <div className='content-content'>
            {currentTab === "work" && (
              <LetsWork 
                moduleData={currentModuleData} 
                currentModuleAssignment={currentModuleAssignment} 
                currentModuleNum={currentModuleNum.current} 
                currentModuleType={currentModuleType} 
                callClickSound={callClickSound}
              />
            )}
            {currentTab === "progress" && (
              <Progress 
                moduleStatusData={moduleStatusData} 
                section={section} 
              />
            )}
            {currentTab === "community" && (
              <Community 
                grade={grade} 
                section={section} 
              />
            )}
            {currentTab === "parents" && 
              <Parents 
                moduleStatusData={moduleStatusData} 
                parentNotes={parentNotes} 
                setParentNotes={setParentNotes} 
                section={section} 
                parentLetter={parentLetter} 
                setParentLetter={setParentLetter} 
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
