import React, { useEffect, useState } from "react";
import HorizontalBar from "../../../Assets/Images/HorizontalBar.svg";

function LevelButtons(props) {
  const { puzzle, currentLevel, setCurrentLevel, loadLevel, statusStorage } =
    props;
  const [puzzleLevels, setPuzzleLevels] = useState([]);
  useEffect(() => {
    if (statusStorage && statusStorage?.puzzle_levels) {
      setPuzzleLevels(statusStorage.puzzle_levels);
    }
  }, [statusStorage]);
  return (
    <div className='puzzle-button-holder'>
      {props.puzzle.map((lvl, index) => {
        if (index === puzzle.length - 1) {
          return (
            <button
              key={`btn-${index}`}
              className={`${currentLevel === index + 1 ? "active" : ""} ${
                puzzleLevels &&
                (puzzleLevels[index]?.status === "completed"
                  ? "completed"
                  : puzzleLevels[index]?.status === "ongoing"
                  ? "ongoing"
                  : "incomplete")
              }`}
              onClick={() => {
                setCurrentLevel(index + 1);
                loadLevel(index + 1);
                console.log(index + 1);
              }}
            >
              {index + 1}
            </button>
          );
        }
        return (
          <>
            <button
              key={`btn-${index}`}
              className={`${currentLevel === index + 1 ? "active" : ""} ${
                puzzleLevels &&
                (puzzleLevels[index]?.status === "completed"
                  ? "completed"
                  : puzzleLevels[index]?.status === "ongoing"
                  ? "ongoing"
                  : "incomplete")
              }`}
              onClick={() => {
                setCurrentLevel(index + 1);
                loadLevel(index + 1);
                console.log(index + 1);
              }}
            >
              {index + 1}
            </button>
            <img
              key={`img-${index}`}
              src={HorizontalBar}
              alt='horizontal bar'
            />
          </>
        );
      })}
    </div>
  );
}

export default LevelButtons;
