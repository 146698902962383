import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import Bar from '../../Assets/Images/Bar.svg';

const colourMappings = {
  completed: '#25A596',
  incomplete: '#FE7D00',
  ongoing: '#AECB2A',
  locked: '#8B8B8B',
};
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily:"rajdhani-medium",
  },
}));
export default function ProjectButton(props) {
  return (
    <>
      <img src={Bar} className="bar" />
      <BootstrapTooltip placement="left" title={props.status}>
        <Link to={props.link ? props.link : ''}>
          <svg onClick={props.onButtonClicked !== null ? () => props.onButtonClicked('project', true) : () => {}} id="project-icon" className={props.active ? 'nav-button active' : 'nav-button'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121 121">
            <g id="Group_1610" data-name="Group 1610" transform="translate(-35 -594)">
              <circle style={{ fill: `${colourMappings[props.status ? props.status : 'incomplete']}` }} id="project-icon-coloring" data-name="Ellipse 211" cx="60.5" cy="60.5" r="60.5" transform="translate(35 594)" fill="#25a596" />
              <g id="noun_computer_code_445095" data-name="noun_computer code_445095" transform="translate(53.027 611)"><g id="Group_1607" data-name="Group 1607" transform="translate(6 12)"><path id="Path_3860" data-name="Path 3860" d="M10.974,12A5,5,0,0,0,6,16.974V58.421a5,5,0,0,0,4.974,4.974H35.013v8.289h-3.29a1.658,1.658,0,1,0,0,3.316h21.5a1.658,1.658,0,1,0,0-3.316h-3.29V63.395H73.973a5,5,0,0,0,4.974-4.974V16.974A5,5,0,0,0,73.973,12Zm0,3.316h63a1.619,1.619,0,0,1,1.658,1.658V58.421a1.619,1.619,0,0,1-1.658,1.658h-63a1.619,1.619,0,0,1-1.658-1.658V16.974A1.619,1.619,0,0,1,10.974,15.316ZM47.24,23.579a1.693,1.693,0,0,0-1.321,1.062L35.972,49.51a1.658,1.658,0,1,0,3.057,1.218l9.947-24.868A1.747,1.747,0,0,0,47.24,23.579ZM30.817,27.724a1.666,1.666,0,0,0-.751.233L15.144,36.247a1.748,1.748,0,0,0,0,2.9l14.921,8.289a1.658,1.658,0,1,0,1.606-2.9L19.367,37.7l12.3-6.839a1.658,1.658,0,0,0-.855-3.134Zm23.107,0a1.658,1.658,0,0,0-.648,3.134L65.58,37.7l-12.3,6.839a1.658,1.658,0,1,0,1.606,2.9L69.8,39.148a1.748,1.748,0,0,0,0-2.9L54.882,27.957A1.67,1.67,0,0,0,53.923,27.724ZM38.329,71.684V63.395h8.289v8.289" transform="translate(-6 -12)" /></g></g>
            </g>
          </svg>
        </Link>
      </BootstrapTooltip>
    </>
  );
}
