import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";

const exerciseEnum = {
  game: { type: "Game", name: "Games", label: "G" },
  puzzle: { type: "Puzzle", name: "Puzzles", label: "P" },
  python: { type: "Python", name: "Python", label: "Py" },
};
const exercise = ["game", "python", "puzzle"];

function ExerciseProgress(props) {
  const { exerciseData, noOfExerciseComponentsInModule } = props;
  const [lastGameCompleted, setLastGameCompleted] = useState(0);
  const [lastPuzzleCompleted, setLastPuzzleCompleted] = useState(0);
  const [lastPythonCompleted, setLastPythonCompleted] = useState(0);

  useEffect(() => {
    if (exerciseData) {
      setLastGameCompleted(0);
      setLastPuzzleCompleted(0);
      setLastPythonCompleted(0);
      if (exerciseData?.game?.length || exerciseData?.puzzle?.length) {
        setGameProgressData(exerciseData?.game ?? []);
        setPuzzleProgressData(exerciseData?.puzzle ?? []);
      }
      if (exerciseData?.python) {
        if (exerciseData?.python?.length)
          setPythonProgressData(exerciseData?.python ?? []);
      }
    }
  }, [exerciseData]);

  const setGameProgressData = (data=[]) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].status === "completed") {
        setLastGameCompleted(i + 1);
        continue;
      }
    }
  };

  const setPuzzleProgressData = (data=[]) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].status === "completed") {
        setLastPuzzleCompleted(i + 1);
        continue;
      }
    }
  };
  const setPythonProgressData = (data=[]) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].status === "completed") {
        setLastPythonCompleted(i + 1);
        continue;
      }
    }
  };

  return (
    <>
      {exercise.map((data, index) => {
        if (exerciseEnum[data].type === "Python" && !Object.keys(exerciseData).includes("python")) return <></>;
        if(exerciseEnum[data].type === "Puzzle" && !Object.keys(exerciseData).includes("puzzle")) return <></>;
        if(exerciseEnum[data].type === "Game" && !Object.keys(exerciseData).includes("game")) return <></>;
        return (
          <Grid item xs={noOfExerciseComponentsInModule > 2 ? 4 : 5.5}>
            <Typography variant='h6' component='h4' className='progress-header'>
              {exerciseEnum[data].name}
            </Typography>
            {(
              exerciseEnum[data].type === "Game"
                ? lastGameCompleted
                : exerciseEnum[data].type === "Puzzle"
                ? lastPuzzleCompleted
                : lastPythonCompleted
            ) ? (
              <div className='progress-exercise-type-content'>
                <div className='row exercise-header-row'>
                  <div className='exercise-row-label exercise-header-row-label'></div>
                  <div className='exercise-row-result'>
                    {exerciseEnum[data].type === "Puzzle"
                      ? "Result"
                      : "Attempts"}
                  </div>
                </div>
                {exerciseData[data]?.map((item, levelIndex) => {
                  if (
                    levelIndex <
                    (exerciseEnum[data].type === "Game"
                      ? lastGameCompleted
                      : exerciseEnum[data].type === "Puzzle"
                      ? lastPuzzleCompleted
                      : lastPythonCompleted)
                  ) {
                    return (
                      <>
                        {levelIndex !== 0 && (
                          <div className='bar-container'>
                            <div className='vertical-bar'></div>
                          </div>
                        )}
                        <div
                          className='row exercise-row'
                          style={{
                            backgroundColor:
                              item.status !== "completed"
                                ? "transparent"
                                : !item.result &&
                                  exerciseEnum[data].type === "Puzzle"
                                ? "rgba(255, 140, 119, .7)"
                                : undefined,
                          }}
                        >
                          <div className='exercise-row-label'>
                            {exerciseEnum[data].label}
                            {levelIndex + 1}
                          </div>
                          {item.status === "completed" && (
                            <div className='exercise-row-result'>
                              {exerciseEnum[data].type === "Puzzle"
                                ? item.result
                                  ? "Correct"
                                  : "Incorrect"
                                : item.attempts}
                            </div>
                          )}
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            ) : (
              <></>
            )}
          </Grid>
        );
      })}
    </>
  );
}

export default ExerciseProgress;
