import React from "react";
import BigArrowLeft from "../../Assets/Images/BigArrowLeft.svg";
import BigArrowRight from "../../Assets/Images/BigArrowRight.svg";

export default function StoryView(props) {
  const {
    story,
    currentPage,
    handleBigArrowLeftClick,
    storyTitle,
    maxPages,
    handleBigArrowRightClick,
    showFinishButton,
    setShowFinishButton,
    urlList,
    setFeedbackActive,
    teacher,
    teacherView,
  } = props;
  return (
    <>
      <div
        className='story-area d-flex justify-content-center align-items-center'
        style={{ padding: "0px 10px" }}
      >
        <img
          className='story-nav-arrow-left'
          style={currentPage === 1 ? { visibility: "hidden" } : {}}
          src={BigArrowLeft}
          alt=''
          onClick={handleBigArrowLeftClick}
        />
        <div className='main-story-view'>
          <div className='row'>
            <div className='col' style={{ flex: 1 }} />
            <div >
              <strong>
                <p className='story-title'>{storyTitle}</p>
              </strong>
            </div>
            <div className='d-flex col justify-content-end' style={{ flex: 1 }}>
              <p className='story-page-number mr-2'>
                {currentPage}/{maxPages}
              </p>
              {currentPage === maxPages && showFinishButton && (
                <button
                  className='story-completed-button'
                  onClick={() => {
                    if (!teacher || (teacher && teacherView))
                      setFeedbackActive(true);
                    setShowFinishButton(false);
                  }}
                >
                  Next
                </button>
              )}
            </div>
          </div>
          <img
            className='story-slide'
            src={urlList[currentPage - 1]}
            alt=''
            style={{ width: "100%", height: "32vw" }}
          />
          <pre className='story-text'>{story.Frames[currentPage - 1].text}</pre>
        </div>
        <img
          className='story-nav-arrow-right'
          style={currentPage === maxPages ? { visibility: "hidden" } : {}}
          src={BigArrowRight}
          alt=''
          onClick={handleBigArrowRightClick}
        />
      </div>
    </>
  );
}
