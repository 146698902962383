import { Backdrop, CircularProgress } from "@mui/material";
import React, { createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogout } from "../../app/store";
const authUserContext = createContext();

async function getUserData() {
  try {
    const user_response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/get-user-info`
    );
    if (!user_response.ok) {
      localStorage.clear();
      sessionStorage.clear();
      return { user: { is_authenticated: false } };
    }
    const response = await user_response.json();
    return response;
  } catch (error) {
    localStorage.clear();
    sessionStorage.clear();
    return { user: { is_authenticated: false } };
  }
}

function SetAuthUser(props) {
  const { children, user, setUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    getUserData().then((data) => {
      if (!data?.user?.is_authenticated) {
        dispatch({ type: userLogout });
        navigate("/");
      }
      setUser(data.user);
    });
  }, []);
  const handleUserLogout = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
    };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/logout`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.user) {
          setUser(data.user);
        }
        const notesHandleWindowName = localStorage.getItem("popupWindowHandleName");
        if (notesHandleWindowName) {
          const notesWindow = window.open("", notesHandleWindowName);
          if (notesWindow && !notesWindow?.closed) {
            notesWindow.close();
          }
        }
        localStorage.clear();
        sessionStorage.clear();
        dispatch({ type: userLogout });
        navigate("/");
      });
  };
  return user ? (
    <authUserContext.Provider
      value={
        user && user.is_authenticated
          ? { user, handleUserLogout }
          : { user, setUser }
      }
    >
      {children}
    </authUserContext.Provider>
  ) : (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
export { SetAuthUser, authUserContext };
