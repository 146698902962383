import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Bar from "../../Assets/Images/Bar.svg";
const colourMappings = {
  completed: "#25A596",
  incomplete: "#FE7D00",
  ongoing: "#AECB2A",
  locked: "#8B8B8B",
  notebookIconColor : "#00868D",
  transparent: "#FFFFFF00",
};
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily:"rajdhani-medium",
  },
}));
export default function PythonButton(props) {
  const svgIconElem = (<g
    id="Group_1720"
    data-name="Group 1720"
    transform="translate(-6427 -5131)"
  >
    <ellipse
      style={{ fill: `${colourMappings[props.status ? props.status : 'incomplete']}` }}
      id="python-icon-coloring"
      data-name="Ellipse 272"
      cx="60.5"
      cy="59.5"
      rx="60.5"
      ry="59.5"
      transform="translate(6427 5131)"
      fill="#fe7d00"
    />
    <path
      id="icons8-python-100"
      d="M39.153,2C32.6,2,28.167,3.56,25.341,5.83a9.943,9.943,0,0,0-3.956,7.638v7.916H37.538V23H13.468a10.2,10.2,0,0,0-8.074,4.01C3.269,29.719,2,33.732,2,39.153S3.269,48.587,5.395,51.3a10.2,10.2,0,0,0,8.074,4.01h7.916v9.531a9.943,9.943,0,0,0,3.956,7.638c2.826,2.27,7.257,3.83,13.813,3.83s10.987-1.56,13.813-3.83a9.943,9.943,0,0,0,3.956-7.638V56.922H40.769V55.307h24.07a10.2,10.2,0,0,0,8.074-4.01c2.126-2.709,3.395-6.722,3.395-12.144s-1.269-9.434-3.395-12.144A10.2,10.2,0,0,0,64.838,23H56.922V13.468a9.936,9.936,0,0,0-3.963-7.7C50.128,3.512,45.7,2,39.153,2Zm0,3.231c6.051,0,9.7,1.395,11.8,3.06a6.6,6.6,0,0,1,2.742,5.177V32.692a4.839,4.839,0,0,1-4.846,4.846H29.461a8.11,8.11,0,0,0-8.077,8.077v6.461H13.468A6.772,6.772,0,0,1,7.935,49.3c-1.549-1.975-2.7-5.233-2.7-10.15S6.385,30.979,7.935,29a6.772,6.772,0,0,1,5.534-2.773h27.3V18.154H24.615V13.468a6.667,6.667,0,0,1,2.748-5.121C29.464,6.66,33.109,5.231,39.153,5.231Zm-8.077,4.846a3.231,3.231,0,1,0,3.231,3.231A3.24,3.24,0,0,0,31.077,10.077ZM56.922,26.23h7.916A6.772,6.772,0,0,1,70.372,29c1.549,1.975,2.7,5.233,2.7,10.15s-1.154,8.175-2.7,10.15a6.772,6.772,0,0,1-5.534,2.773h-27.3v8.077H53.692v4.685a6.667,6.667,0,0,1-2.748,5.121c-2.1,1.688-5.746,3.117-11.79,3.117s-9.689-1.429-11.79-3.117a6.667,6.667,0,0,1-2.748-5.121V45.615a4.839,4.839,0,0,1,4.846-4.846H48.845a8.017,8.017,0,0,0,.82-.041,8.118,8.118,0,0,0,7.257-8.036ZM47.23,61.768A3.231,3.231,0,1,0,50.461,65,3.24,3.24,0,0,0,47.23,61.768Z"
      transform="translate(6448 5151)"
      fill={props.notebookView ? "white" : "#000"}
    />
  </g>)
  
  if (props.icon) {
    return (
      <svg
        id='python-icon'
        class='nav-button'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 121 119'
      >
        {svgIconElem}
      </svg>
    );
  }

  return (
    <>
      {props.isGamePresent || props.isStoryPresent ? <img src={Bar} className="bar" /> : <></>}
      <BootstrapTooltip placement="left" title={props.status}>
        <Link to={props.link ? props.link : ""} state={props?.linkState ? props?.linkState : null}>
          <svg
            onClick={
              props.onButtonClicked !== null
                ? () => props.onButtonClicked("python", true)
                : () => {}
            }
            className={props.active ? "nav-button active" : "nav-button"}
            id="python-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 121 119"
          >
            {svgIconElem}
          </svg>
        </Link>
      </BootstrapTooltip>
    </>
  );
}
