import {
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import { Controller, useFormContext } from "react-hook-form";

const useStyles = makeStyles({
  field: {
    marginTop: "5px !important",
    marginBottom: "20px !important",
    display: "block !important",
  },
});
export default function SchoolDetails(props) {
  const { schoolRollOver } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h6" style={{ color: "#00868D", marginBottom: 10}}>
        School Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <Controller
            control={control}
            name="schoolName"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>
                  School Name
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  className={classes.field}
                  id="school-name"
                  variant="outlined"
                  placeholder="Enter Your School Name"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.schoolName)}
                  helperText={errors.schoolName?.message}
                  disabled={schoolRollOver}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            control={control}
            name="schoolCode"
            rules={{ validate: (value, formValues) => value === "test" }}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>
                  School Code
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  className={classes.field}
                  id="school-code"
                  variant="outlined"
                  placeholder="Enter Your School Code"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.schoolCode)}
                  helperText={
                    errors.schoolCode?.message ||
                    "School Code will be saved as lowercase"
                  }
                  disabled={schoolRollOver}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <Controller
            control={control}
            name="address"
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>Address</FormLabel>
                <TextField
                  className={classes.field}
                  id="address"
                  variant="outlined"
                  placeholder="Enter Your School Address"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.address)}
                  helperText={errors.address?.message}
                  disabled={schoolRollOver}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Controller
            control={control}
            name="city"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>
                  City
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  className={classes.field}
                  id="city"
                  variant="outlined"
                  placeholder="Enter Your School City"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.city)}
                  helperText={errors.city?.message}
                  disabled={schoolRollOver}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            control={control}
            name="state"
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>State</FormLabel>
                <TextField
                  className={classes.field}
                  id="state"
                  variant="outlined"
                  placeholder="Enter Your School State"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.state)}
                  helperText={errors.state?.message}
                  disabled={schoolRollOver}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            control={control}
            name="pincode"
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>Pin Code</FormLabel>
                <TextField
                  className={classes.field}
                  id="pincode"
                  variant="outlined"
                  placeholder="Enter Your School Pin Code"
                  fullWidth
                  margin="normal"
                  {...field}
                  inputProps={{ style: { height: 10 } }}
                  error={Boolean(errors?.pincode)}
                  helperText={errors.pincode?.message}
                  disabled={schoolRollOver}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
