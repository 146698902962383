import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { React, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import SkillPerformance from "../../Components/Charts/SkillPerformance";
import { authUserContext } from "../../Components/Contexts/AuthUser";

const skillsLegend = {
  Beginner: {
    color: "#FF9A9B",
    description:
      "Has completed less than 50% of exercise and assessment game levels",
  },
  Developing: {
    color: "#FF755C",
    description:
      "Has completed  50% or more but less than all game levels or has completed all levels but needed help on any level in assessment",
  },
  Proficient: {
    color: "#E0325C",
    description:
      "Has completed all game levels (Exercise as well as assessment)",
  },
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function SkillAnalytics(props) {
  document.title = "CSSoch | Skills Analytics";
  const [isLoading, setisLoading] = useState(false);
  const [skillData, setSkillData] = useState();
  const [openCollapse, setOpenCollapse] = useState();
  const [completeData, setCompleteData] = useState();
  const [reloadData, setReloadData] = useState();
  let fulldata = {};
  const location = useLocation();
  const { user } = useContext(authUserContext);
  const schoolcode = location.state
    ? location.state.schoolcode
    : user.schoolcode;
  const [gradeWiseData, setGradeWiseData] = useState(
    JSON.parse(sessionStorage.getItem("skills_analytics_data"))?.hasOwnProperty(
      schoolcode
    )
      ? JSON.parse(sessionStorage.getItem("skills_analytics_data"))[schoolcode]
      : {}
  );
  const [gradeChanged, setGradeChanged] = useState(true);
  const dataFetchedRef = useRef(true);
  const grade = useRef();
  const selectedSections = useRef();
  useEffect(() => {
    grade.current = props.grade;
    selectedSections.current = props.selectedSections;
    setisLoading(false);
    setCompleteData();
    setSkillData();
    setGradeChanged((prev) => !prev);
  }, [props.grade]);

  useEffect(() => {
    if (dataFetchedRef.current) {
      dataFetchedRef.current = false;
      return;
    }
    let sections = [];
    props.gradeData[props.grade].forEach((section) => {
      if (props.selectedSections.includes(section)) {
        sections.push(section);
      }
    });
    if (!sections.length) {
      sections = props.gradeData[props.grade];
    }
    if (gradeWiseData && gradeWiseData[props.grade]) {
      const data = gradeWiseData[props.grade];
      setCompleteData(data.skills);
      createdData(data.skills, sections);
    } else {
      const skillsDataCollector = [];
      const promises = [];

      const body = {
        sections: props.selectedSections,
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };
      const selectedSectionRequestPromise = fetch(
        `${process.env.REACT_APP_API_BASE_URL}/skill-analytics/${schoolcode}/${props.grade}`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          skillsDataCollector.push(data);
          setCompleteData(data.skills);
          createdData(data.skills, sections);
        });
      promises.push(selectedSectionRequestPromise);

      props.gradeData[props.grade].forEach((section) => {
        if (props.selectedSections.includes(section)) return;
        const body = {
          sections: [section],
        };
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        };
        const requestPromise = fetch(
          `${process.env.REACT_APP_API_BASE_URL}/skill-analytics/${schoolcode}/${props.grade}`,
          requestOptions
        )
          .then((res) => res.json())
          .then((data) => {
            skillsDataCollector.push(data);
          });
        promises.push(requestPromise);
      });

      Promise.all(promises)
        .then(() => {
          const data = combineSkillsData(skillsDataCollector);
          setGradeWiseData((currentValue) => {
            if (currentValue) {
              currentValue[props.grade] = data;
              return currentValue;
            }
            const newValue = {};
            newValue[props.grade] = data;
            return newValue;
          });
          const skills_analytics_data = JSON.parse(
            sessionStorage.getItem("skills_analytics_data")
          );
          if (!skills_analytics_data.hasOwnProperty(schoolcode)) {
            skills_analytics_data[schoolcode] = {};
          }
          skills_analytics_data[schoolcode][props.grade] = data;
          sessionStorage.setItem(
            "skills_analytics_data",
            JSON.stringify(skills_analytics_data)
          );
          let runCreateData = true;
          sections.map((section) => {
            if (!props.gradeData[grade.current].includes(section)) {
              runCreateData = false;
            }
          });
          if (runCreateData) {
            setCompleteData(data.skills);
            if (sections.length === selectedSections.current.length) {
              if (
                !sections.every(
                  (item) => selectedSections.current.indexOf(item) > -1
                )
              ) {
                createdData(data.skills, selectedSections.current);
              }
            } else {
              createdData(data.skills, selectedSections.current);
            }
          }
        })
        .catch((error) => {
          console.error(
            "Error occurred while fetching skill analytics data:",
            error
          );
        });
    }
  }, [gradeChanged]);

  const [sectionsChanged, setSectionChanged] = useState(true);
  useEffect(() => {
    selectedSections.current = props.selectedSections;
    setSkillData();
    setisLoading(false);
    setSectionChanged((prev) => !prev);
  }, [props.selectedSections]);

  useEffect(() => {
    const sections = [];
    props.gradeData[props.grade].map((section) => {
      if (props.selectedSections.includes(section)) {
        sections.push(section);
      }
    });
    completeData && createdData(completeData, sections);
  }, [sectionsChanged]);

  function createdData(data, sections) {
    fulldata = {};
    Object.keys(data).map((skill) => {
      fulldata[skill] = { ...data[skill] };
      const brack = { Beginner: [], Developing: [], Proficient: [] };
      Object.keys(data[skill].bins).map((bin) => {
        let concat_student = [];
        sections.map((section) => {
          concat_student = concat_student.concat(
            data[skill].bins[bin][section]
          );
        });
        brack[bin] = concat_student;
      });
      fulldata[skill].bins = brack;
    });
    let exitFunction = false;
    sections.map((section) => {
      if (!props.gradeData[grade.current].includes(section)) {
        exitFunction = true;
      }
    });
    if (exitFunction) return;
    if (sections.length === selectedSections.current.length) {
      if (
        !sections.every((item) => selectedSections.current.indexOf(item) > -1)
      )
        return;
    } else {
      return;
    }
    setSkillData(fulldata);
    setReloadData(Math.random());
    setisLoading(true);
  }

  function combineSkillsData(dataArray) {
    if (dataArray.length === 0) return {};
    if (dataArray.length === 1) return dataArray[0];
    const combinedData = dataArray[0];
    for (let i = 1; i < dataArray.length; i++) {
      const data = dataArray[i]["skills"];
      for (let skill in data) {
        const bins = data[skill]["bins"];
        for (let category in bins) {
          const categoryData = bins[category];
          for (let section in categoryData) {
            combinedData["skills"][skill]["bins"][category][section] =
              categoryData[section];
          }
        }
      }
    }
    return combinedData;
  }

  function handleOpen(clickedIndex) {
    if (openCollapse === clickedIndex) {
      setOpenCollapse(null);
    } else {
      setOpenCollapse(clickedIndex);
    }
  }

  return isLoading && skillData ? (
    <>
      <div
        style={{
          width: "100%",
          marginBottom: "30px",
          marginTop: "-15px",
          display: "flex",
          justifyContent: "end",
        }}
      >
        {Object.keys(skillsLegend).map((key) => (
          <div
            key={key}
            style={{ display: "flex", alignItems: "center", paddingLeft: 15,paddingBottom:10 }}
          >
            <LightTooltip title={skillsLegend[key].description} arrow style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <span
                style={{
                  height: 15,
                  width: 15,
                  backgroundColor: skillsLegend[key].color,
                  borderRadius: "50%",
                }}
              />
              <Typography
                variant="subtitle2"
                style={{ paddingLeft: 5, color: "#484C51",fontFamily: "rajdhani-medium",fontSize: 15}}
              >
                {key}
              </Typography>
            </LightTooltip>
          </div>
        ))}
      </div>
      {skillData &&
        Object.keys(skillData).map((skill, index) => {
          const datacount = [];
          for (const bin in skillData[skill].bins) {
            if (skillData[skill].bins.hasOwnProperty(bin)) {
              datacount.push({
                name: bin,
                data: [skillData[skill].bins[bin].length],
              });
            }
          }
          return (
            <div>
              <Grid
                container
                spacing={2}
                style={{ background: "#F4F4F4", maxHeight: "110px" }}
              >
                <Grid item xs={3} style={{ paddingTop: "10px" }}>
                  <h6
                    style={{
                      fontFamily: "rajdhani-semibold",
                      fontSize: "20px",
                      margin: "0px",
                    }}
                  >
                    {skill}
                  </h6>
                  <Typography
                    key={index}
                    noWrap
                    variant="subtitle2"
                    style={{
                      paddingLeft: 2,
                      color: "#00868D",
                      fontFamily: "rajdhani-medium",
                      fontSize: 14,
                    }}
                  >
                    {skillData[skill].module[0]["module FullName"]}
                    <br />
                    {skillData[skill].module.length > 1 &&
                      skillData[skill].module[1]["module FullName"]}
                  </Typography>
                </Grid>
                <Grid item xs={8.8} style={{ marginTop: "-27px" }}>
                  <SkillPerformance key={reloadData} seriesData={datacount} />
                </Grid>
              </Grid>
              <span style={{ paddingBottom: 16 }} />
              <Collapse
                in={openCollapse === index}
                timeout="auto"
                unmountOnExit
              >
                <Grid
                  container
                  spacing={2}
                  style={{
                    background: "#F4F4F4",
                    marginTop: "0",
                    maxHeight: "390px",
                  }}
                >
                  <Grid item xs={3} style={{ marginTop: "-24px" }}>
                    {skillData[skill].module.map(
                      (item, index) =>
                        index > 1 && (
                          <Typography
                            noWrap
                            variant="subtitle2"
                            style={{
                              paddingTop:4,
                              paddingLeft: 2,
                              color: "#00868D",
                              fontFamily: "rajdhani-medium",
                              fontSize: 14,
                            }}
                          >
                            {item["module FullName"]}
                          </Typography>
                        )
                    )}
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontWeight: "300",
                        paddingLeft: 2,
                        color: "#606060",
                        paddingTop: "10px",
                        fontFamily: "rajdhani-light",
                        fontSize: 15,
                        lineHeight: 1.5,
                      }}
                    >
                      {skillData[skill].description}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={8.7}
                    style={{ marginTop: "-16px", paddingLeft: "42px" }}
                  >
                    <Grid container spacing={1}>
                      {Object.keys(skillData[skill].bins).map((bin, index) => (
                        <Grid item xs={3.8}>
                          <Typography
                            variant="subtitle1"
                            style={{
                              textAlign: "center",
                              fontFamily: "rajdhani-medium",
                              fontSize: 20,
                            }}
                          >
                            {bin.toUpperCase()}
                          </Typography>
                          {Object.keys(skillsLegend).map(
                            (key, legendindex) =>
                              legendindex === index && (
                                <Divider
                                  sx={{
                                    borderBottomWidth: 3,
                                    bgcolor: skillsLegend[key].color,
                                  }}
                                />
                              )
                          )}
                        </Grid>
                      ))}
                    </Grid>
                    <Grid
                      container
                      spacing={1}
                      style={{
                        paddingLeft: "30px",
                        overflow: "auto",
                        height: "200px",
                        marginTop: "5px",
                      }}
                    >
                      {Object.keys(skillData[skill].bins).map((bin, index) => (
                        <Grid item xs={3.8}>
                          {skillData[skill].bins[bin].map((fullname) => (
                            <Typography
                              variant="subtitle2"
                              style={{
                                fontFamily: "rajdhani-medium",
                                fontSize: 15,
                              }}
                            >
                              {fullname}
                            </Typography>
                          ))}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Collapse>
              <Box
                style={{ marginTop: "-20px" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton
                  style={{ color: "#00868D" }}
                  aria-label="collapse-button"
                  onClick={() => {
                    handleOpen(index);
                  }}
                >
                  {openCollapse === index ? (
                    <RemoveCircleOutlineIcon />
                  ) : (
                    <AddCircleOutlineSharpIcon />
                  )}
                </IconButton>
              </Box>
              <br />
            </div>
          );
        })}
    </>
  ) : (
    <Box sx={{ display: "flex", p: 5 }} justifyContent="center">
      <CircularProgress />
    </Box>
  );
}
