import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";

function TeacherFeedback(props) {
  const { feedback } = props;
  const [feedbackStars, setFeedbackStars] = useState([]);

  useEffect(() => {
    if (feedback) {
      let stars = [];
      for (let i = 1; i <= 5; i++) {
        if (i <= feedback) {
          stars.push(1);
        } else {
          stars.push(0);
        }
      }
      setFeedbackStars((pre) => stars);
    }
  }, [feedback]);
  return (
    <>
      <Typography variant='h6' component='h4' className='feedback-header'>
        Teacher Feedback
      </Typography>
      <div className='feedback-box'>
        {feedbackStars.length &&
          feedbackStars.map((item, index) =>
            item ? (
              <svg
                key={index}
                width='82'
                height='78'
                viewBox='0 0 82 78'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M2.15834 35.1964L18.1438 46.7352C19.9703 48.053 20.7356 50.3891 20.0393 52.5223L13.9298 71.1975C12.3711 75.968 17.866 79.9358 21.953 76.9881L37.9384 65.4474C39.7649 64.1279 42.236 64.1279 44.0625 65.4474L60.048 76.9881C64.1331 79.9358 69.6298 75.968 68.0711 71.1975L61.9643 52.5223C61.2644 50.3891 62.0297 48.053 63.858 46.7352L79.8417 35.1964C83.925 32.2469 81.8288 25.8276 76.7796 25.8276H57.0204C54.7636 25.8276 52.7583 24.3827 52.0629 22.2495L45.958 3.58059C44.3974 -1.19353 37.6053 -1.19353 36.0429 3.58059L29.9344 22.2504C29.239 24.3836 27.2409 25.8285 24.9805 25.8285H5.22035C0.17116 25.8285 -1.92495 32.2478 2.15834 35.1964Z'
                  fill='#FFB017'
                />
              </svg>
            ) : (
              <svg
                key={index}
                width='84'
                height='80'
                viewBox='0 0 84 80'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M2.86563 36.6017L2.8657 36.6018L18.8512 48.1406L18.8513 48.1407C20.5023 49.3319 21.1924 51.4418 20.564 53.3671L14.4546 72.042L14.4545 72.0422C12.7418 77.2842 18.7759 81.6173 23.2454 78.3937L23.2456 78.3935L39.2311 66.8528L39.2312 66.8527C40.883 65.6594 43.118 65.6594 44.7697 66.8527L44.7698 66.8528L60.7553 78.3935L60.7554 78.3936C65.2232 81.6174 71.2591 77.2841 69.5464 72.0422L69.5463 72.0421L63.4396 53.3668L63.4394 53.3664C62.8078 51.4416 63.4976 49.3321 65.1504 48.1408L65.1507 48.1406L81.1343 36.6017L81.1344 36.6017C85.6162 33.3644 83.3074 26.3276 77.7796 26.3276H58.0204C55.9777 26.3276 54.1659 25.0197 53.5383 23.0945L53.5382 23.0941L47.4332 4.42524C47.4332 4.42522 47.4332 4.4252 47.4332 4.42518C45.7224 -0.80868 38.2803 -0.808072 36.5677 4.42508L37.0429 4.58059L36.5677 4.4251L30.4591 23.0949L30.459 23.0954C29.8312 25.0211 28.0262 26.3285 25.9805 26.3285H6.22035C0.692765 26.3285 -1.61639 33.3653 2.86563 36.6017Z'
                  stroke='#FF9A9B'
                />
              </svg>
            )
          )}
      </div>
    </>
  );
}

export default TeacherFeedback;
