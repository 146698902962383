import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import {
  CardActionArea,
  Container,
  Typography,
  CardMedia,
  CardContent,
  Card,
  Button,
} from "@mui/material";
import ArrowLeft from "../../Assets/Images/ArrowLeft.svg";
import GameSnapshotImg from "../../Assets/snapshots/GameSnapshot.jpg";
import PuzzleSnapshotImg from "../../Assets/snapshots/PuzzleSnapshot.jpg";
import FoxSnapshotImg from "../../Assets/snapshots/MotherFoxDownSnapshot.svg"
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import PythonPlaygroundSnapshot from "../../Assets/snapshots/PythonPlaygroundSnapshot.svg";
import PythonGraphicsPlaygroundSnapshot from "../../Assets/snapshots/PythonGraphicsPlaygroundSnapshot.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import "../../Styles/Playground.css";
import { PrimaryNavBarContext } from "../../Components/Layout/Layout";
import PythonButton from "../../Components/ModuleComponentsButtons/PythonButton";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily: "rajdhani-medium",
  },
}));

export default function TeacherViewCommunity() {
  document.title = "CSSoch | Community";
  const { grade } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [communityProjectsData, setCommunityProjectsData] = useState([]);
  const [userId, setUserId] = useState();
  const { setIsAdminDashboardOrTeacherClassess } =
    useContext(PrimaryNavBarContext);
  const [playgroundProjectIdList, setPlaygroundProjectIdList] = useState([]);
  const [helpInstructionIds, setHelpInstructionIds] = useState([]);

  useEffect(() => {
    setIsAdminDashboardOrTeacherClassess(false);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ grade: grade }),
    };
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/get-playground-set`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          setHelpInstructionIds(data["helpInstructionIds"]);
        })
        .catch((err) => console.log(err));
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/get-community-projects`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setCommunityProjectsData(data?.projects);
        setUserId(data?.user_id);
        setIsLoading(false);
        setPlaygroundProjectIdList(data?.playground_game_set_ids?.sort() ?? []);
      });
  }, []);

  const gotoPlayground = (project, type) => {
    const state = {
      projectDetails: project,
      type: type,
      grade: grade,
      helpInstructionIds: helpInstructionIds
    };
    if (
      project?.projectType === "python" ||
      project?.projectType === "pythonGraphics"
    ) {
      navigate("/playground/python/view", {
        state: state,
      });
    } else {
      navigate("/playground/view", {
        state: state,
      });
    }
  };

  const assignImageToProject = (project) => {
    if (project?.projectType === "python") {
      return PythonPlaygroundSnapshot;
    } else if (project?.projectType === "pythonGraphics") {
      return PythonGraphicsPlaygroundSnapshot;
    } else if (
      playgroundProjectIdList.indexOf(project?.strapi_game_set_id) % 3 === 2
    ) {
      return FoxSnapshotImg;
    } else if (playgroundProjectIdList.indexOf(project?.strapi_game_set_id) % 3 === 1) {
      return GameSnapshotImg;
    } else {
      return PuzzleSnapshotImg;
    }
  };

  const assignIconToProject = (project) => {
    if (
      project?.projectType === "python" ||
      project?.projectType == "pythonGraphics"
    ) {
      return <PythonButton key={project} status={"transparent"} icon={true} />;
    } else {
      return <SportsEsportsOutlinedIcon />;
    }
  };

  return (
    <div>
      {!isLoading ? (
        <div className="teacher-community-project-container">
          <div className="actions-wrapper">
            <Button
              className="return-community-button"
              onClick={() => navigate("/classes")}
            >
              <span className="playground-return-home d-flex align-items-center">
                <img src={ArrowLeft} alt="" />
                <span className="back-text">Back</span>
              </span>
            </Button>{" "}
            <Typography
              variant="h4"
              gutterBottom
              component="h1"
              className="playground-headings"
              sx={{ fontSize: "2rem!important" }}
            >
              Community Projects - Grade{grade}
            </Typography>
            <div></div>
          </div>

          <Container className="playground-projects-container" maxWidth={false}>
            {communityProjectsData.length !== 0 ? (
              <>
                {communityProjectsData?.map((project) => {
                  if (project.student_id !== userId)
                    return (
                      <BootstrapTooltip
                        key={project.project_id}
                        title={project?.student_name}
                        placement="top"
                      >
                        <Card
                          key={project.project_id}
                          className="project-card user-project-card"
                          onClick={() => gotoPlayground(project, "view")}
                          sx={{
                            backgroundColor: "#25A596",
                          }}
                        >
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              image={assignImageToProject(project)}
                              alt="project Img"
                              className="project-card-content-container"
                            />
                            <CardContent className="project-card-content-content">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  minWidth: "80%",
                                }}
                              >
                                {assignIconToProject(project)}
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="span"
                                  className="project-title"
                                >
                                  {project.title}
                                </Typography>
                              </div>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </BootstrapTooltip>
                    );
                  return <></>;
                })}
              </>
            ) : (
              <>
                <div className="no-community-projects">
                  <p>No Projects Shared Yet</p>
                </div>
              </>
            )}
          </Container>
        </div>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
}
