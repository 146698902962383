import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../Styles/Global.css";
import "../../Styles/Classes.css";
import AvatarGrade2 from "../../Assets/Images/AvatarBlackGrade2.svg";
import AvatarGrade3 from "../../Assets/Images/AvatarBlackGrade3.svg";
import AvatarGrade4 from "../../Assets/Images/AvatarBlackGrade4.svg";
import AvatarGrade5 from "../../Assets/Images/AvatarBlackGrade5.svg";
import AvatarGrade6 from "../../Assets/Images/AvatarBlackGrade6.svg";
import AvatarGrade7 from "../../Assets/Images/AvatarBlackGrade7.svg";
import AvatarGrade8 from "../../Assets/Images/AvatarBlackGrade8.svg";
import { setClassesData } from "../../features/TeacherUserData/ClassesData";
import Loader from "../../Components/Loader/Loader";
import { PrimaryNavBarContext } from "../../Components/Layout/Layout";

const avatarGradeMapper = {
  1: AvatarGrade2,
  2: AvatarGrade2,
  3: AvatarGrade3,
  4: AvatarGrade4,
  5: AvatarGrade5,
  6: AvatarGrade6,
  7: AvatarGrade7,
  8: AvatarGrade8,
};
function TeacherClasses() {
  document.title = "CSSoch | Classes";
  const [classes, setClasses] = useState(
    useSelector((state) => state.classesData.classesData)
  );
  const {
    setIsAdminDashboardOrTeacherClassess,
    setIsTeacherDashboard,
    isTeacherClassesPage,
    setIsTeacherClassesPage,
  } = useContext(PrimaryNavBarContext);

  const dispatch = useDispatch();
  const [grades, setGrades] = useState({});
  const [classSectionAssignment, setClassSectionAssignment] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function fetchClasesDetails() {
      setIsAdminDashboardOrTeacherClassess(true);
      setIsTeacherDashboard(false);
      setIsTeacherClassesPage(true);
      if (classes && Object.keys(classes).length) {
        updateClassesData(classes);
        return;
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      };
      fetch(`${process.env.REACT_APP_API_BASE_URL}/classes`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          dispatch(setClassesData(data));
          updateClassesData(data);
        });
    }
    fetchClasesDetails();

    return () => {
      setIsTeacherClassesPage(false);
    };
  }, [isTeacherClassesPage]);

  const updateClassesData = (data) => {
    setClasses(data);
    setClassSectionAssignment(data.classes);
    setGrades(data.grades);
    setIsLoading(false);
  };

  return (
    <>
      <div style={{ margin: 0, padding: 0 }}>
        {!isLoading ? (
          <div className="classes-container">
            <h1>Assigned Classes</h1>
            <hr />
            <div className="classes-selection">
              {grades &&
                Object.keys(grades)
                  .sort()
                  .map((grade, gradeIndex) => (
                    <>
                      <h2
                        key={grade}
                        className="grade-name"
                        style={{ marginTop: gradeIndex ? "2vw" : "" }}
                      >
                        Grade {grade}
                        <span className="d-flex">
                          <button
                            className="view-community"
                            id="view-community2"
                            onClick={() => {
                              setIsAdminDashboardOrTeacherClassess(false);
                              navigate(`/community/${grade}`);
                            }}
                          >
                            <span className="community-text"> Community </span>
                          </button>
                          <button
                            className="view-analytics"
                            id="view-analytics2"
                            onClick={() =>
                              navigate(`/admin/dashboard?grade=${grade}`)
                            }
                          >
                            <img
                              className="analytics-icon"
                              src="../static/svg/TeacherAnalytics.svg"
                              alt=""
                            />
                            <span className="analytics-text">Analytics</span>
                          </button>
                        </span>
                      </h2>
                      {[...grades[grade]].sort().map((section) => (
                        <div
                          key={section}
                          className="class-box"
                          onClick={() => {
                            setIsAdminDashboardOrTeacherClassess(false);
                            navigate(`/dashboard/${grade}/${section}`);
                          }}
                        >
                          <p className="d-flex">
                            <img
                              src={avatarGradeMapper[grade]}
                              alt="avatar"
                              style={{
                                width:
                                  grade === "5" ||
                                  grade === "3" ||
                                  grade === "6"
                                    ? "2.3vw"
                                    : "1.5vw",
                              }}
                            />{" "}
                            Class {section}
                          </p>
                          <div className="assignment-box">
                            {classSectionAssignment[grade][section].length &&
                              classSectionAssignment[grade][section].map(
                                (assignment, index) => (
                                  <>
                                    {index !== 0 ? (
                                      <img
                                        className="bar"
                                        src="../static/svg/HorizontalBarTeacher.svg"
                                        alt=""
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {assignment === "assigned" ? (
                                      <img
                                        className="icon"
                                        src="../static/svg/AssignedClassesIcon.svg"
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        className="icon"
                                        src="../static/svg/NotassignedClassesIcon.svg"
                                        alt=""
                                      />
                                    )}
                                  </>
                                )
                              )}
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
              <h2 style={{ display: "none" }}> No Classes is Assigned Yet</h2>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}

export default TeacherClasses;
