/* eslint-disable */
import {
  eventTypes,
  postMessageCommands,
} from "../../Utils/Constants/PythonConstants";
const pyodideWorker = new Worker(new URL("./webworker.js", import.meta.url));
const callbacks = {};
let pyodideLoaded = false;
let consoleOutputBox = "console";
pyodideWorker.onmessage = (event) => {
  if (event.data.type === eventTypes.loadedPyodide) {
    pyodideLoaded = true;
  } else if (event.data.type === eventTypes.outputBeforeInput) {
    let consoleText = document.createElement("p");
    consoleText.classList.add("console-text");
    consoleText.textContent = event.data.results;
    let outputBox = document.getElementById(consoleOutputBox);
    outputBox.appendChild(consoleText);
  } else {
    const { id, ...data } = event.data;
    const onSuccess = callbacks[id];
    delete callbacks[id];
    onSuccess(data);
  }
};
let InputBuffer;
let interruptBuffer;
if (window.crossOriginIsolated) {
  InputBuffer = new window.SharedArrayBuffer(1024); //byte length
  interruptBuffer = new Int32Array(new SharedArrayBuffer(4));
} else {
  InputBuffer = new ArrayBuffer(1024); //byte length
  interruptBuffer = new Int32Array(new ArrayBuffer(4));
}
let id = 0;

pyodideWorker.postMessage({
  cmd: postMessageCommands.interruptExecution,
  interruptBuffer,
});


const setPyodideLoaded = async () => {
  if(!pyodideLoaded) {
    await loadPyodide();
  }
  return pyodideLoaded;
};

if (!pyodideLoaded) {
  const interval = setInterval(setPyodideLoaded, 5000);
  clearInterval(interval);
}

const setInput = (input) => {
  let typedArr = new Int32Array(InputBuffer);
  for (let i = 0; i < input.length; i++) {
    typedArr[i] = input.charCodeAt(i);
  }
  Atomics.notify(typedArr, 0);
};

const interruptCodeExecution = () => {
  let typedArr = new Int32Array(InputBuffer);
  Atomics.notify(typedArr, 0);
  interruptBuffer[0] = 2;
};

const interruptCodeExecutionAfterTimeout = () => {
  interruptBuffer[0] = 3;
};

function asyncRun(script, consoleId) {
  if (consoleId) {
    consoleOutputBox = consoleId;
  }
  let typedArr = new Int32Array(InputBuffer);
  typedArr.fill(-1);
  return new Promise((onSuccess) => {
    callbacks[id] = onSuccess;
    pyodideWorker.postMessage({
      python: script,
      id: id++,
      InputBuffer,
      cmd: postMessageCommands.runPython,
    });
  });
}

function asyncRunTest(
  codeToCheck,
  consoleOutput,
  testCaseJsonFile,
  testingScript
) {
  return new Promise((onSuccess) => {
    callbacks[id] = onSuccess;
    pyodideWorker.postMessage({
      code: codeToCheck,
      output: consoleOutput,
      testCases: testCaseJsonFile,
      script: testingScript,
      id: id++,
      cmd: postMessageCommands.runTestCases,
    });
  });
}

function asyncConsoleRun(script) {
  return new Promise((onSuccess) => {
    interruptBuffer[0] = 0;
    callbacks[id] = onSuccess;
    pyodideWorker.postMessage({
      python: script,
      id: id++,
      cmd: postMessageCommands.runConsoleCode,
    });
  });
}

function loadPyodide() {
  return new Promise((onSuccess) => {
    callbacks[id] = onSuccess;
    pyodideWorker.postMessage({
      id: id++,
      cmd: postMessageCommands.loadPyodide,
    });
  });
}

function clearNamespaces() {
  return new Promise((onSuccess) => {
    callbacks[id] = onSuccess;
    pyodideWorker.postMessage({
      id: id++,
      cmd: postMessageCommands.clearNamespaces,
    });
  });
}

export {
  asyncRun,
  asyncConsoleRun,
  setInput,
  interruptCodeExecution,
  interruptCodeExecutionAfterTimeout,
  setPyodideLoaded,
  asyncRunTest,
  loadPyodide,
  clearNamespaces,
};
