import React from "react";
import { Divider, Typography } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
export default function PythonTestCases(props) {
  const { showTestCase } = props;
  return (
    <>
      {showTestCase.length > 0 && (
        <>
          <Divider sx={{ backgroundColor: '#00868d', opacity: "0.5"}}/>
          <div style={{ textAlign: "center", fontSize: "15px" }}>
            <h2>Test Cases</h2>
          </div>
          <div>
            {showTestCase.map((test) => {
              return (
                <>
                  <div
                    className="test-case-message"
                    style={
                      test.result === "success"
                        ? { backgroundColor: "#aecb2a" }
                        : { backgroundColor: "#ffb017" }
                    }
                  >
                    <div style={{ marginLeft: "10px", textWrap: "wrap" }}>
                      {test.name}
                    </div>
                    {test.result === "success" ? (
                      <CheckRoundedIcon />
                    ) : (
                      <HighlightOffRoundedIcon />
                    )}
                  </div>
                  {test.result !== "success" && (
                    <div className="test-case-failed-content">
                      {test.feedback}
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}
