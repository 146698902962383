import { React, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

function AssesmentPuzzlePerformance(props) {
  const [isSet, setisSet] = useState(false);
  const [state, setState] = useState();
  const [reRender, setRerender] = useState(true);
  const labels = ['A', 'B', 'C', 'D', 'Needed Help'];
  const colorMapping = {
    A: ['#D1D526', '#FD9E63', '#F7B09C', '#FF8C77', '#B30A74'],
    B: ['#FF8C77', '#D1D526', '#FD9E63', '#F7B09C', '#B30A74'],
    C: ['#F7B09C', '#FF8C77', '#D1D526', '#FD9E63', '#B30A74'],
    D: ['#FD9E63', '#F7B09C', '#FF8C77', '#D1D526', '#B30A74'],
  };

  useEffect(() => {
    if (props.grade === props.reRenderData.grade) {
      setisSet(false);
      setState();
      setRerender((prev) => !prev);
    }
  }, [props.reRenderData.reRender]);

  useEffect(() => {
    if (!isSet) {
      let isData = false;
      props.data.forEach((val) => {
        if (val > 0) isData = true;
      });
      setState({
        series: isData ? props.data : [],
        options: {
          colors: colorMapping[props.correctOpt],
          chart: {
            type: 'donut',
          },
          stroke: {
            show: false,
          },
          noData: {
            text: `Puzzle ${props.index + 1}: No Data Available`,
            align: 'center',
            verticalAlign: 'middle',
            style: {
              fontFamily: 'rajdhani-medium',
              fontSize: 20,
            },
          },
          states: {
            hover: {
              filter: 'none'
            }
          },
          labels,
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: true,
            style: {
              fontFamily: 'rajdhani-medium',
              fontSize: '15px',
              fontWeight: 'normal',
              colors: ['black'],
            },
            formatter(value, { seriesIndex, dataPointIndex, w }) {
              return labels[seriesIndex] === 'Needed Help' ? '' : labels[seriesIndex];
            },
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.45,
            },
          },
          grid: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
          },
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                size: '60%',
                labels: {
                  show: true,
                  name: {
                    show: false,
                    offsetY: -10,
                    formatter: () => '',
                  },
                  value: {
                    show: true,
                    fontSize: '15px',
                    fontFamily: 'rajdhani-medium',
                    color: undefined,
                    formatter: () => `Puzzle ${props.index + 1}`,
                  },
                  total: {
                    show: true,
                    label: '',
                    formatter: () => `Puzzle ${props.index + 1}`,
                  },
                },
              },
            },
          },
          tooltip: {
            style: {
              fontSize: '12px',
              fontFamily: 'rajdhani-medium',
            },
            y: {
              formatter: (val) => `${val}`,
            },
            theme: 'dark', // Adjust theme if needed
            onDatasetHover: {
              highlightDataSeries: true,
            },
            marker: {
              show: false,
            },
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
              const color = w.globals.colors[seriesIndex];
              return `<div class="tooltip-container" style="border: 2px solid white; background-color: ${color}; padding: 5px 15px;">
                        <span>${w.globals.labels[seriesIndex]}: ${series[seriesIndex]}</span>
                      </div>`;
            },
          },
        },
      });
      
      setisSet(true);
    }
  }, [reRender]);

  return (
    <>
      {isSet && <Chart options={state.options} series={state.series} type="donut" />}
    </>
  );
}

export default AssesmentPuzzlePerformance;
