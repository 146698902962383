import {
  React, useEffect, useRef, useState,
} from 'react';
import Chart from 'react-apexcharts';

function AssesmentProgress(props) {
  const [isSet, setisSet] = useState(false);
  const [state, setState] = useState();
  const [reRender, setRerender] = useState(true);
  const dataRefreshRef = useRef(true);
  let Completed;
  let Incomplete;
  let Gaveup;
  let categories;
  let numberofStudents;

  useEffect(() => {
    if (props.grade === props.data.grade) {
      setisSet(false);
      setState();
      setRerender((prev) => !prev);
    }
  }, [props.data.reRender]);

  useEffect(() => {
    if (dataRefreshRef.current) {
      dataRefreshRef.current = false;
      return;
    }
    if (!isSet) {
      let isData = false;
      props.data.Completed.map((key) => {
        if (key > 0) isData = true;
      });
      props.data.Incomplete.map((key) => {
        if (key > 0) isData = true;
      });
      props.data.Gaveup.map((key) => {
        if (key > 0) isData = true;
      });
      if (isData) {
        Completed = props.data.Completed;
        Incomplete = props.data.Incomplete;
        Gaveup = props.data.Gaveup;
      } else {
        Completed = [];
        Incomplete = [];
        Gaveup = [];
      }
      categories = props.data.categories;
      numberofStudents = props.data.numberofStudents;
      setisSet(true);
      setState({
        series: [{
          name: 'Completed',
          data: Completed,
        }, {
          name: 'Incomplete',
          data: Incomplete,
        }, {
          name: 'Needed Help',
          data: Gaveup,
        },
        ],
        options: {
          colors: ['#25a596', '#fe7d00', '#B30A74'],
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          stroke: {
            colors: ['transparent'],
            width: 5,
          },
          noData: {
            text: 'No Data Available',
            align: 'center',
            verticalAlign: 'middle',
            style: {
              fontFamily: 'rajdhani-medium',
              fontSize: 20,
            },
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          }],
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          xaxis: {
            type: 'text',
            categories,
            labels: {
              style: {
                fontFamily: 'rajdhani-medium',
                fontSize: '15px',
              },
            },
            title: {
              text: 'Assessment Levels',
              offsetY: 95,
              style: {
                fontFamily: 'rajdhani-medium',
                fontSize: '15px',
              },
            },
            axisBorder: {
              show: true,
              color: '#78909C',
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: 'No. of Students',
              style: {
                fontFamily: 'rajdhani-medium',
                fontSize: '15px',
                cssClass: 'apexcharts-yaxis-title',
              },
            },
            labels: {
              formatter(val) {
                return val.toFixed(0);
              },
              style: {
                fontFamily: 'rajdhani-medium',
                fontSize: '15px',
              },
            },
            axisBorder: {
              show: true,
              color: '#78909C',
              offsetX: 0,
              offsetY: 0,
            },
            max: numberofStudents,
          },
          title: {
            text: 'Assessment Progress',
            align: 'center',
            style: {
              fontFamily: 'rajdhani-semibold',
              fontSize: '22px',
              color: '#263238',
            },
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'right',
            fontFamily: 'rajdhani-medium',
            fontSize: '15px',
            onItemClick: {
              toggleDataSeries: false,
            },
            onItemHover: {
              highlightDataSeries: false,
            },
            markers: {
              width: 15,
              height: 15,
              strokeWidth: 0,
              strokeColor: '#fff',
              fillColors: undefined,
              radius: 15,
              customHTML: undefined,
              onClick: undefined,
              offsetX: 0,
              offsetY: 2,
            },
            formatter: function(seriesName, opts) {
              return '<span style="margin-left: 3px;margin-right: 5px">' + seriesName + '</span>';
            }
          },
          fill: {
            opacity: 1,
          },
        },
      });
    }
  }, [reRender]);

  return (
    <>
      {isSet
      && <Chart options={state.options} series={state.series} height="500" type="bar" />}
    </>
  );
}

export default AssesmentProgress;
