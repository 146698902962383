import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAdminDashboardPage: false,
  isErrorPage: false,
  moduleName: "",
};

export const NavbarActionsSlice = createSlice({
  name: "navbarActions",
  initialState,
  reducers: {
    setIsAdminDashboardPage: (state, action) => {
      state.isAdminDashboardPage = action.payload;
    },
    setIsErrorPage: (state, action) => {
      state.isErrorPage = action.payload;
    },
    setNavbarModuleName: (state, action) => {
      state.moduleName = action.payload;
    },
  },
});

export const { setIsAdminDashboardPage, setIsErrorPage, setNavbarModuleName } =
NavbarActionsSlice.actions;
export default NavbarActionsSlice.reducer;
