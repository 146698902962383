import {
  Button,
  Container,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormLabel,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { schoolNameContext } from "../../Components/Contexts/schoolName";
import TableDataGrid from "../../Components/TableDataGrid/TableDataGrid";

export default function SchoolList() {
  document.title = "CSSoch | School List";
  const navigate = useNavigate();
  const location = useLocation();
  const schoolcontext = useContext(schoolNameContext);
  schoolcontext.updateSchool("");
  const [schoolCodeList, setSchoolCodeList] = useState();
  const [academicYears, setAcademicYears] = useState();
  const [selectedAcademicYear, setSelectedAcademicYear] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [studentRollOverStatus, setStudentRollOverStatus] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/academic-years-list`)
      .then((res) => res.json())
      .then((data) => {
        if (data["academicYearsList"]?.length > 1) {
          setStudentRollOverStatus(true);
        }
        setAcademicYears(data["academicYearsList"]);
        setSelectedAcademicYear(
          data["academicYearsList"][data["academicYearsList"].length - 1]
        );
        setIsLoading(false);
      });
    fetch(`${process.env.REACT_APP_API_BASE_URL}/schoolcode/list`)
      .then((res) => res.json())
      .then((data) => {
        setSchoolCodeList(data);
      });
  }, []);

  const handleAcademicYearChange = (event) => {
    if (
      academicYears.length > 1 &&
      academicYears.indexOf(event.target.value) == 1
    ) {
      setStudentRollOverStatus(true);
    } else {
      setStudentRollOverStatus(false);
    }
    setSelectedAcademicYear(event.target.value);
  };

  const columnsOfSchoolList = [
    {
      field: "school_name",
      flex: 0.7,
      headerName: "Name",
    },
    {
      field: "school_code",
      flex: 0.25,
      headerName: "Code",
    },
    {
      field: "curriculum_name",
      flex: 0.5,
      headerName: "Curriculum Name",
      renderCell: (params) => (
        <Tooltip title={params.row.curriculum_name}>
          <span style={{ whiteSpace: "pre-wrap" }}>
            {params.row.curriculum_name}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "total_student",
      flex: 0.2,
      headerName: "Students",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "total_teacher",
      flex: 0.2,
      headerName: "Teachers",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      flex: 0.2,
      headerName: "Status",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "mode",
      flex: 0.3,
      headerName: "Mode",
      align: "center",
      headerAlign: "center",
    },
  ];

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          {" "}
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div style={{ backgroundColor: "#DDE9F5", height: "100%" }}>
          <Container maxWidth="auto">
            <Typography
              variant="h6"
              component="h2"
              style={{ padding: "10px 10px 0" }}
            >
              Schools
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginBottom: 15,
                height: 36.5,
              }}
            >
              <FormControl
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 10,
                  height: "100%",
                }}
              >
                <FormLabel style={{ marginRight: 10 }}>Academic Year</FormLabel>
                <Select
                  onChange={handleAcademicYearChange}
                  value={selectedAcademicYear}
                  style={{ height: "100%" }}
                >
                  {academicYears.map((year) => (
                    <MenuItem value={year} key={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                style={{ backgroundColor: "#00868D", height: "100%" }}
                variant="contained"
                onClick={() =>
                  navigate("/school/add", {
                    state: {
                      user: location.state?.user,
                      schoolcode: schoolCodeList,
                    },
                  })
                }
              >
                Add a School
              </Button>
            </Box>
            <TableDataGrid
              type="school"
              dataReload={selectedAcademicYear}
              academicYearForSchoolList={selectedAcademicYear}
              columns={
                studentRollOverStatus
                  ? [
                      ...columnsOfSchoolList,
                      {
                        field: "student_roll_over_status",
                        flex: 0.3,
                        headerName: "Student Roll Over Status",
                        align: "center",
                        headerAlign: "center",
                      },
                    ]
                  : columnsOfSchoolList
              }
            />
          </Container>
        </div>
      )}
    </>
  );
}
