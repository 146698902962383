import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Container,
  Backdrop, Typography,
  MenuItem,
  CircularProgress,
  FormControl, Select
} from "@mui/material";
import MapDataGrid from "../../Components/TableDataGrid/MapDataGrid";

const grades = ["1", "2", "3", "4", "5", "6", "7", "8"];
const puzzle_columns = [
  { field: "puzzle_name", headerName: "Puzzle Name", flex: 1, hideable: false },
  { field: "1", headerName: "Grade 1", flex: 1, renderCell: (params) => (
    renderCellParams(params)
  )},
  { field: "2", headerName: "Grade 2", flex: 1, renderCell: (params) => (
    renderCellParams(params)
  )},
  { field: "3", headerName: "Grade 3", flex: 1, renderCell: (params) => (
    renderCellParams(params)
  )},
  { field: "4", headerName: "Grade 4", flex: 1, renderCell: (params) => (
    renderCellParams(params)
  )},
  { field: "5", headerName: "Grade 5", flex: 1, renderCell: (params) => (
    renderCellParams(params)
  )},
  { field: "6", headerName: "Grade 6", flex: 1, renderCell: (params) => (
    renderCellParams(params)
  )},
  { field: "7", headerName: "Grade 7", flex: 1, renderCell: (params) => (
    renderCellParams(params)
  )},
  { field: "8", headerName: "Grade 8", flex: 1, renderCell: (params) => (
    renderCellParams(params)
  )},
];

const renderCellParams = (params) => {
  return (
    <div style={{ whiteSpace: 'pre-line' }}>
      {params.value.split(', ').map((part, index) => (
            <React.Fragment key={index}>
              {part}
              {index !== params.value.split(', ').length - 1 && <hr />}
            </React.Fragment>
          ))}
    </div>
  );

}

const columnVisibility = {};
export default function PuzzleMap() {
  document.title = "CSSoch | Puzzle Map";
  const [moduleCurriculumnMap, setModuleCurriculumnMap] = useState(sessionStorage.getItem('puzzle_map') ? JSON.parse(sessionStorage.getItem('puzzle_map')) : undefined);
  const [curriculum, setCurriculum] = useState(sessionStorage.getItem('puzzle_map') ? JSON.parse(sessionStorage.getItem('puzzle_map'))?.curriculumn[0]: undefined);
  const [rows, setRows] = useState(sessionStorage.getItem('puzzle_map_rows') ? JSON.parse(sessionStorage.getItem('puzzle_map_rows')) : undefined);
  useEffect(() => {
    if (moduleCurriculumnMap === undefined) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/cms/curriculumn/puzzle/map`)
        .then((response) => response.json())
        .then((data) => {
          sessionStorage.setItem('puzzle_map', JSON.stringify(data));
          setModuleCurriculumnMap(data);
          setCurriculum(data.curriculumn[0]);
          setData(data, data.curriculumn[0]);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setData(moduleCurriculumnMap, moduleCurriculumnMap.curriculumn[0]);
    }
  }, []);

  const setData = (data, curr) => {
    const puzzleMapForCurr = data.rows.filter(
      (c) => c.curriculum_name === curr
    );
    const matrix = [];
    for (const puzzle of data.allPuzzleList) {
      const row = { puzzle_name: puzzle };
      for (const grade of grades) {
        row[grade] = "-";
      }
      matrix.push(row);
    }

    // Step 3: Populating the Matrix
    for (const puzzle of puzzleMapForCurr) {
      const rowsToUpdate = matrix.filter(
        (row) => row.puzzle_name === puzzle.puzzle_name
      );
      for (const row of rowsToUpdate) {
        row[puzzle.grade.toLowerCase()] = puzzle.combined_module;
      }
    }
    sessionStorage.setItem('puzzle_map_rows', JSON.stringify(matrix));
    setRows(matrix);
  };

  return (
    <div style={{ backgroundColor: "#DDE9F5", height: "100%" }}>
      <Container>
        <div
          style={{ display: "flex", justifyContent: "space-between", padding: "5px" }}
        >
          <Typography variant="h6" component="h2" style={{ padding: 10 }}>
            Puzzle Map
          </Typography>
          { curriculum && <FormControl>
            <Select
              id="curriculum"
              value={curriculum}
              onChange={(e) => {
                setCurriculum(e.target.value)
                setData(moduleCurriculumnMap, e.target.value)
              }}
            >
              {moduleCurriculumnMap &&
                moduleCurriculumnMap.curriculumn.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>}
        </div>
        <Box sx={{ height: "79vh", width: "100%" }}>
          {rows ? (
            <MapDataGrid rows={rows} columnVisibility={columnVisibility} columns={puzzle_columns} />
          ):(
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Box>
      </Container>
    </div>
  );
}
