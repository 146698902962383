import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modulesData: {},
};

export const modulesDataSlice = createSlice({
  name: "modulesData",
  initialState,
  reducers: {
    setModulesData: (state, action) => {
      state.modulesData = action.payload;
    },
    updateMouleStatus: (state, action) => {
      state.modulesData[action.payload.grade][action.payload.section][
        action.payload.moduleNum
      ].status = action.payload.status;
      state.modulesData[action.payload.grade][action.payload.section][
        action.payload.moduleNum
      ].deadline = action.payload.endDate;
      state.modulesData[action.payload.grade][action.payload.section][
        action.payload.moduleNum
      ].assignment_date = action.payload.startDate;
    },
  },
});

export const { setModulesData, updateMouleStatus } = modulesDataSlice.actions;
export default modulesDataSlice.reducer;
