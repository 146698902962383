import {
  Button,
  Container,
  Typography,
  Input,
  Stack,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { filterContext } from "../../Components/Contexts/filterContext";
import TableDataGrid from "../../Components/TableDataGrid/TableDataGrid";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UserImportDialog from "../Students/StudentImportDialog";

export default function TeacherList() {
  document.title = "CSSoch | Teacher List";
  const filterData = useContext(filterContext);
  const [reloadData, setReloadData] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [disableAddImportTeacher, setDisableAddImportTeacher] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const handleOnFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setShowDialog(true);
  };

  const handleRefreshTeacherList = () => {
    setTimeout(() => {
      setReloadData(Math.random());
    }, 1000);
  };

  const handleDownloadTeacherTemplate = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/template/teacher`).then(
      (res) => {
        res.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.setAttribute("download", "teacher_import_template.xlsx");
          document.body.appendChild(a);
          a.click();
          a.parentNode.removeChild(a);
        });
      }
    );
  };

  const handleUnselectFile = () => {
    setSelectedFile(undefined);
    setShowDialog(false);
  };

  return (
    <div style={{ backgroundColor: "#DDE9F5", height: 600 }}>
      <Container>
        <Typography variant="h6" component="h2" style={{ padding: 10 }}>
          Manage Teachers
        </Typography>
        <Box textAlign="right" marginTop="-10px">
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <label htmlFor="contained-button-file">
              <Input
                onChange={handleOnFileChange}
                onClick={(event) => (event.target.value = null)}
                id="contained-button-file"
                multiple
                type="file"
                inputProps={{
                  style: { display: "none" },
                  accept:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }}
                disabled={disableAddImportTeacher}
              />
              <Tooltip title="Import Teacher Excel" arrow>
                <Button
                  style={{ backgroundColor: "#00868D", marginBottom: 15 }}
                  variant="contained"
                  component="span"
                  endIcon={<FileUploadIcon />}
                  disabled={disableAddImportTeacher}
                >
                  Import
                </Button>
              </Tooltip>
            </label>
            <Tooltip title="Download Teacher Template" arrow>
              <Button
                variant="contained"
                endIcon={<FileDownloadIcon />}
                style={{ backgroundColor: "#00868D", marginBottom: 15 }}
                onClick={handleDownloadTeacherTemplate}
              >
                Template
              </Button>
            </Tooltip>
            <Button
              style={{ backgroundColor: "#00868D", marginBottom: 15 }}
              variant="contained"
              disabled={disableAddImportTeacher}
              onClick={() =>
                navigate("/teacher/add", {
                  state: { user: location.state?.user },
                })
              }
            >
              Add teacher
            </Button>
          </Stack>
        </Box>
        <TableDataGrid
          type="teacher"
          filter={filterData.teacherFilter}
          dataReload={reloadData}
          setDisableAddImportTeacher={setDisableAddImportTeacher}
          columns={[
            {
              field: "fullname",
              flex: 0.8,
              headerName: "Name",
            },
            {
              field: "grade",
              flex: 0.6,
              headerName: "Class",
            },
            {
              field: "section",
              flex: 0.6,
              headerName: "Section",
            },
            { field: "username", flex: 0.8, headerName: "User Id" },
            { field: "type", flex: 0.4, headerName: "Type" },
          ]}
        />
      </Container>
      {showDialog && (
        <UserImportDialog
          selectedFile={selectedFile}
          openDialog
          handleUnselectFile={handleUnselectFile}
          handleRefreshUserList={handleRefreshTeacherList}
          type="teacher"
        />
      )}
    </div>
  );
}
