import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotebookContext } from "../Contexts/NotebookContext";
import ArrowLeft from "../../Assets/Images/ArrowLeft.svg";
import StoryButton from "../ModuleComponentsButtons/StoryButton";
import AssesmentButton from "../ModuleComponentsButtons/AssesmentButton";
import { authUserContext } from "../Contexts/AuthUser";
import { openPopupWindow } from "../../Utils/helper";

function NotebookSideBar(props) {
  const {
    backLink,
    handleCurrentSectionChange,
    currentSection,
    teacher = false,
    backLinkState=null
  } = props;
  const {user} = useContext(authUserContext);
  const { grade, className, moduleNumber, notebookId} = useContext(NotebookContext);
  const { notebookStrapiData, notebookData, isTeachModule } = useContext(NotebookContext);
  const navigate = useNavigate();
  const [popUpWindowHandle, setPopUpWindowHandle] = useState();
  const [notesUrl, setNotesUrl] = useState(`${process.env.REACT_APP_API_BASE_URL}/notes/${grade}/${className}/notebook/${moduleNumber}/${notebookId}`);

  return (
    <nav className='notebook-side-nav'>
      <div className="d-flex flex-column justify-content-start align-items-center">
        <span
          className='module-return-dashboard d-flex align-items-center'
          onClick={() => {
            if(user?.role?.includes("teacher")) {
              navigate(backLink ,{state:backLinkState})
            }
            else {
              navigate(backLink, {state:backLinkState})
            }
          }}
          style={{paddingBottom:'2vh'}}
        >
          <img src={ArrowLeft} alt='' />
          Back
        </span>
        {notebookStrapiData?.exerciseCells?.length > 0 && (
          <StoryButton
            status={notebookData?.exercise_status ? notebookData?.exercise_status : "incomplete"}
            onButtonClicked={handleCurrentSectionChange}
            active={currentSection === "Exercise"}
            linkState={props.state}
          />
        )}
        {notebookStrapiData?.assessmentCells?.length > 0 && (
          <AssesmentButton
            isExercisePresent={notebookStrapiData?.exerciseCells?.length > 0 ? true : false}
            assesmentDetails={{
              status: notebookData?.assessment_status ? notebookData?.assessment_status : "incomplete",
              isgamelevelgiveup: notebookData?.is_game_level_giveup ? true : false,
              ispuzzlelevelgiveup: notebookData?.is_puzzle_level_giveup ? true : false,
            }}
            exerciseStatus={isTeachModule ? false : notebookData?.exercise_status}
            isNotebookExerciseNotPresent={notebookStrapiData?.exerciseCells?.length > 0 ? false : true}
            onButtonClicked={handleCurrentSectionChange}
            active={currentSection === "Assessment"}
            teacher={teacher}
            linkState={props.state}
          />
        )}
      </div>
      <div>
        {isTeachModule && (
          <button id='notes-id' onClick={() => openPopupWindow(popUpWindowHandle, setPopUpWindowHandle, notesUrl)}>
            Notes
          </button>
        )}
      </div>
    </nav>
  );
}

export default NotebookSideBar;
