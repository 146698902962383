import React, { useEffect, useState } from 'react';
import ModuleProgressBar from './Progress/ModuleProgressBar';
import MarkdownView from 'react-showdown';

export default function Parents(props) {
  document.title = "CSSoch | Parents";
  const { 
    moduleStatusData, 
    section, 
    parentNotes, 
    setParentNotes, 
    parentLetter, 
    setParentLetter } = props;
  const [currentModuleNum, setCurrentModuleNum] = useState(-1);
  const [moduleData, setModuleData] = useState();

  useEffect(() => {
    if (moduleStatusData) {
      const moduleStusDataCopy = JSON.parse(JSON.stringify(moduleStatusData));
      setModuleData(moduleStusDataCopy);
    }
  }, [moduleStatusData]);

  useEffect(() => {
    if (moduleStatusData && section && parentNotes.length === 0) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/parent-notes`)
        .then((res) => res.json())
        .then((data) => {
          setParentNotes(data.parentNotes);
          setParentLetter(data.parentLetter);
        });
    }
  }, [moduleStatusData, section]);

  return (
    <div className="parents-content">
      <ModuleProgressBar
        currentModuleIndex={currentModuleNum}
        setCurrentModuleIndex={setCurrentModuleNum}
        moduleStatusData={moduleData}
      />
      {currentModuleNum === -1 ?
        <MarkdownView className='parents-markdown' markdown={parentLetter} />
        :
        <>
          {moduleData && moduleData?.statuses[currentModuleNum] !== "locked" ?
            <>
              {parentNotes[currentModuleNum] ?
                <MarkdownView className='parents-markdown' markdown={parentNotes[currentModuleNum]} />
                :
                <div className='progress-locked-box'>
                  <p>Module notes coming soon……</p>
                </div>
              }
            </>
            :
            <div className='progress-locked-box'>
              <p>Module is not assigned yet</p>
            </div>
          }
        </>
      }
    </div>
  );
}
