import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily:"rajdhani-medium",
  },
}));
function ModuleStatusBar(props) {
  const { moduleStatusData } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [moduleBarWidth, setModuleBarWidth] = useState();
  const [getModuleStatusData, setGetModuleStatusesData] = useState();
  const [moduleStatuses, setModuleStatuses] = useState();
  const [currentID, setCurrentID] = useState(
    props.currentID ? props.currentID : null
  );
  const activateButtons = useRef(props.activateButtons);
  const [isRendered, setIsRendered] = useState(false);
  const [numOfModules, setNumOfModules] = useState();
  const [previouslySelectedModule, setPreviouslySelectedModule] = useState(1);
  useEffect(() => {
    if(moduleStatusData){
      setModuleStatuses(moduleStatusData.statuses);
      setGetModuleStatusesData(moduleStatusData);
      setNumOfModules(moduleStatusData.num_modules);
      setModuleBarWidth(88 / moduleStatusData.num_modules);
      if (currentID) {
        setPreviouslySelectedModule(moduleStatusData.module_ids.indexOf(currentID) + 1);
      }
      statusIndication(moduleStatusData);
      setIsLoading(false);
    }
  }, [moduleStatusData]);

  function statusIndication(data) {
    const modules = data.num_modules;
    let ongoingFound = false;
    let ongoingModuleIndex = -1;
    let currentModuleIdFound = false;
    for (let i = 1; i < modules + 1; i++) {
      if (data.statuses[i - 1] === "ongoing") {
        ongoingFound = true;
        setCurrentID(data.module_ids[i - 1]);
        props.currentModuleNum.current = i;
        ongoingModuleIndex = i;
      }
    }
    if (ongoingFound) {
      const newModuleInfo = {
        id: data.module_ids[ongoingModuleIndex - 1],
        type: data.module_types[ongoingModuleIndex - 1],
      };
      props.updateCurrentModule({}, newModuleInfo);
      props.setCurrentModuleID(data.module_ids[ongoingModuleIndex - 1]);
      currentModuleIdFound = true;
    } else if (currentID) {
      props.currentModuleNum.current = data.module_ids.indexOf(currentID) + 1;
      props.setCurrentModuleID(currentID);
      currentModuleIdFound = true;
    } else {
      for (let i = 1; i < modules + 1; i++) {
        if (data.statuses[i - 1] === "incomplete") {
          setModuleStatuses((prev) => {
            prev[i - 1] = "ongoing";
            return prev;
          });
          props.currentModuleNum.current = i;
          setPreviouslySelectedModule(i);
          setCurrentID(data.module_ids[i - 1]);
          props.setCurrentModuleID(data.module_ids[i - 1]);
          currentModuleIdFound = true;
          break;
        }
      }
    }

    if (!currentModuleIdFound) {
      props.setCurrentModuleID(data.module_ids[0]);
      props.currentModuleNum.current = 1;
      setPreviouslySelectedModule(1);
    }
    window.history.replaceState({}, "", "/home");
    setIsRendered(true);
  }

  const handleModuleChange = (moduleIndex) => {
    if (props.currentModuleNum.current === moduleIndex + 1) return;
    if (moduleStatuses[props.currentModuleNum.current - 1] === "ongoing") {
      moduleStatuses[props.currentModuleNum.current - 1] = "incomplete";
    }

    if (
      moduleStatuses[moduleIndex] !== "completed" &&
      moduleStatuses[moduleIndex] !== "locked"
    ) {
      moduleStatuses[moduleIndex] = "ongoing";
    }

    if (props.currentModuleNum.current !== moduleIndex + 1 && activateButtons.current) {
      const previousModuleInfo = {
        id: getModuleStatusData.module_ids[props.currentModuleNum.current - 1],
        type: getModuleStatusData.module_types[props.currentModuleNum.current - 1],
      };
      const newModuleInfo = {
        id: getModuleStatusData.module_ids[moduleIndex],
        type: getModuleStatusData.module_types[moduleIndex],
      };
      setCurrentID(getModuleStatusData.module_ids[moduleIndex]);
      props.currentModuleNum.current = moduleIndex + 1;
      props.updateCurrentModule(previousModuleInfo, newModuleInfo);
    }
  };

  return !isLoading ? (
    <Box
      sx={{typography: "body1" }}
      className='module-status-bar'
    >
      <div className='container'>
        <div className='module-bar' style={{ display: "flex" }}>
          <div className='step' style={{ width: "10%" }}>
            {" "}
            <span>Modules</span>{" "}
          </div>
          {moduleStatuses &&
            moduleStatuses.map((status, index) => (
              <BootstrapTooltip key={index + 1} title={status}>
                <div
                  key={index + 1}
                  id={index}
                  className={`step ${
                    status === "completed"
                      ? "completed"
                      : status === "ongoing" ||
                        (props.currentModuleNum.current === index + 1 && status !== "locked")
                      ? "ongoing"
                      : status === "incomplete"
                      ? "incomplete"
                      : "locked"
                  } ${status === "assigned" ? "assigned" : ""} ${props.currentModuleNum.current === index + 1 ? "active" : ""}`}
                  onClick={() => handleModuleChange(index)}
                  style={{
                    cursor: "Pointer",
                    width: `${moduleBarWidth}%`,
                  }}
                >
                  {" "}
                  <span>{index + 1}</span>{" "}
                </div>
              </BootstrapTooltip>
            ))}
        </div>
      </div>
    </Box>
  ) : (
    <></>
  );
}

export default ModuleStatusBar;
