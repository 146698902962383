import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box, IconButton, LinearProgress, Snackbar, Typography,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Axios, { CancelToken } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import './index.css';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(
            Number(props.value),
          )}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function Downloader({ files = [], remove }) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="downloader">
      <div className="card">
        <div className="card-header">
          <Typography variant="h6">
            Report Downloading In Progress ...
          </Typography>
        </div>
        <ul className="list-group list-group-flush">
          {files.map((file, idx) => (
            <DownloadItem
              key={idx}
              removeFile={() => remove(file.downloadId)}
              {...file}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

function DownloadItem({
  name, file, filename, body, passedSection, removeFile,
}) {
  const [open, setOpen] = React.useState(false);
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });
  const cancelFileUpload = useRef(null);
  useEffect(() => {
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
      cancelToken: new CancelToken((cancel) => cancelFileUpload.current = cancel),
    };

    passedSection === 'All'
      ? Axios.get(file, {
        responseType: 'blob',
        ...options,
      }).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          }),
        );

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        setDownloadInfo((info) => ({
          ...info,
          completed: true,
        }));

        setTimeout(() => {
          removeFile();
        }, 4000);
      })
      : Axios.post(
        file,
        body,
        {
          responseType: 'blob',
          ...options,
        },
      ).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          }),
        );

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        setDownloadInfo((info) => ({
          ...info,
          completed: true,
        }));

        setTimeout(() => {
          removeFile();
        }, 4000);
      });
  }, []);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  const cancelDownload = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current('Request canceled');
      handleClick();
    }
    setTimeout(() => {
      removeFile();
    }, 4000);
  };
  return (
    <div>
      <li className="list-group-item">
        <div className="row" style={{ display: 'block' }}>
          <div className="col-12">
            <div className="d-inline font-weight-bold text-truncate">
              <div>
                <IconButton
                  disabled={downloadInfo.completed}
                  style={{ float: 'right' }}
                  color="primary"
                  aria-label="Cancel Download"
                  onClick={() => cancelDownload()}
                >
                  <CancelIcon />
                </IconButton>
                <Typography variant="h6">
                  {name}
                </Typography>
              </div>
            </div>
            <div className="d-inline ml-2">
              <small>
                {downloadInfo.loaded > 0 && (
                <>
                  <span className="text-success">
                    {formatBytes(downloadInfo.loaded)}
                  </span>
                  /
                  {' '}
                  {formatBytes(downloadInfo.total)}
                </>
                )}

                {downloadInfo.loaded === 0 && <>Initializing...</>}
              </small>
            </div>
            <div className="d-inline ml-2 ml-auto">
              {downloadInfo.completed && (
              <span className="text-success">
                Completed
              </span>
              )}
            </div>
          </div>
          <div className="col-12 mt-2">
            {
            downloadInfo.loaded > 0
              ? <LinearProgressWithLabel value={downloadInfo.progress} />
              : <LinearProgress sx={{ width: '92%' }} />
          }
          </div>
        </div>
      </li>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Report Downloading Cancelled
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Downloader;
