import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import Bar from '../../Assets/Images/Bar.svg';

const colourMappings = {
  completed: '#25A596',
  incomplete: '#FE7D00',
  ongoing: '#AECB2A',
  locked: '#8B8B8B',
  notebookIconColor : "#00868D"
};
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily:"rajdhani-medium",
  },
}));
export default function GameButton(props) {
  const svgIconElem = (
    <g transform="translate(-38 -895)">
              <circle style={{ fill: `${colourMappings[props.status ? props.status : 'incomplete']}` }} id="game-icon-coloring" cx="60.5" cy="60.5" r="60.5" transform="translate(38 895)" fill="#25a596" />
              <g fill={props.notebookView ? "white" : "#000"} transform="translate(51 902.7)">
                <g transform="translate(2.498 12.3)">
                  <path d="M89.849,50.462c-2.261-11.119-7.067-26.007-21.672-26.384a46.492,46.492,0,0,0-10.365,1.037,48.579,48.579,0,0,1-8.292.942v-11.5a2.261,2.261,0,0,0-4.523,0v11.5a60.348,60.348,0,0,1-8.292-.942,52.151,52.151,0,0,0-10.365-1.037C11.734,24.361,6.929,39.249,4.667,50.462c-2.544,12.344-3.392,21.767.471,27.514a12.368,12.368,0,0,0,8.386,5.088c9.611,1.7,18.751-5.842,25.159-20.542H55.833C61.58,75.9,69.59,83.253,78.259,83.253a24.83,24.83,0,0,0,2.638-.188,11.961,11.961,0,0,0,8.386-5.088C93.241,72.229,92.393,62.806,89.849,50.462Zm-4.24,25.064a7.849,7.849,0,0,1-5.465,3.2c-10.553,1.79-17.62-11.873-20.165-17.809a4.509,4.509,0,0,0-4.146-2.733H38.683a4.509,4.509,0,0,0-4.146,2.733c-2.544,5.936-9.611,19.694-20.165,17.809a7.848,7.848,0,0,1-5.465-3.2C5.7,70.815,7.117,61.11,9.1,51.4,12.3,35.857,17.67,28.884,26.434,28.7h.66a41.93,41.93,0,0,1,8.669.942,58.048,58.048,0,0,0,22.8,0,49.615,49.615,0,0,1,9.329-.942c8.857.188,14.228,7.256,17.338,22.709C87.4,61.11,88.812,70.815,85.608,75.527Z" transform="translate(-2.498 -12.3)" />
                  <path d="M36.33,45.354H31.807V40.831A1.146,1.146,0,0,0,30.677,39.7H27.284a1.146,1.146,0,0,0-1.131,1.131v4.523H21.631A1.146,1.146,0,0,0,20.5,46.484v3.392a1.146,1.146,0,0,0,1.131,1.131h4.523V55.53a1.146,1.146,0,0,0,1.131,1.131h3.392a1.146,1.146,0,0,0,1.131-1.131V51.007H36.33a1.146,1.146,0,0,0,1.131-1.131V46.484A1.146,1.146,0,0,0,36.33,45.354Z" transform="translate(-3.537 -13.882)" />
                  <path d="M69.815,39a3.015,3.015,0,1,0,3.015,3.015A3.036,3.036,0,0,0,69.815,39Z" transform="translate(-6.21 -13.841)" />
                  <path d="M76.215,45.5a3.015,3.015,0,1,0,3.015,3.015A2.977,2.977,0,0,0,76.215,45.5Z" transform="translate(-6.579 -14.217)" />
                  <path d="M63.315,45.5a3.015,3.015,0,1,0,3.015,3.015A3.036,3.036,0,0,0,63.315,45.5Z" transform="translate(-5.835 -14.217)" />
                  <path d="M69.715,52a3.015,3.015,0,1,0,3.015,3.015A2.977,2.977,0,0,0,69.715,52Z" transform="translate(-6.204 -14.592)" />
                </g>
              </g>
            </g>
  )

  if (props.icon) {
    return (
      <svg
        id='game-icon'
        className='nav-button'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 121 121'
      >
        {svgIconElem}
      </svg>
    );
  }

  return (
    <>
      {props.isStoryPresent ? <img src={Bar} className="bar" /> : <></>}
      <BootstrapTooltip placement="left" title={props.status}>
        <Link to={props.link ? props.link : ''} state={props.linkState? props.linkState : null}>
          <svg onClick={props.onButtonClicked !== null ? () => props.onButtonClicked('game', true) : () => {}} id="game-icon" className={props.active ? 'nav-button active' : 'nav-button'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121 121">
           {svgIconElem} 
          </svg>
        </Link>
      </BootstrapTooltip>
    </>
  );
}
