import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import Chart from "react-apexcharts";

function HorizontalBarChart(props) {
  const { completed } = props.data[props.title];
  const { incomplete } = props.data[props.title];
  const { notAssigned } = props.data[props.title];
  const { categories } = props.data[props.title];
  const maxAxisTicks = props.data.studentsCount;
  let axisTicks = [0, 1, 2, 3, 4];
  if (maxAxisTicks > 4) {
    const mid = Math.round(maxAxisTicks / 2);
    axisTicks[1] = Math.floor(mid / 2);
    axisTicks[2] = mid;
    axisTicks[3] = Math.floor((mid + maxAxisTicks) / 2);
    axisTicks[4] = maxAxisTicks;
  } else if (maxAxisTicks === 1) axisTicks = [0, 1];
  else if (maxAxisTicks === 2) axisTicks = [0, 1, 2];
  else if (maxAxisTicks === 3) axisTicks = [0, 1, 2, 3];
  axisTicks = axisTicks.sort((a, b) => a - b);

  categories.map((key, index) => {
    categories[index] = key.charAt(0).toUpperCase() + key.slice(1);
  });

  const state = {
    series: [
      {
        name: "Completed",
        data: completed,
      },
      {
        name: "Incomplete",
        data: incomplete,
      },
      {
        name: "Not Assigned",
        data: notAssigned,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 150,
        stacked: true,
        parentHeightOffset: 0,
        toolbar: {
          show: false,
        },
      },
      colors: ["#25a596", "#fe7d00", "#DBDBDB"],
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          top: -25,
          bottom: -10,
          right: 0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "55%",
        },
      },
      stroke: {
        show: false,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        categories,
        labels: {
          show: false,
          formatter(val) {
            return val.toFixed(0);
          },
          style: {
            fontSize: "8px",
          },
          offsetY: -6,
        },
        axisTicks: {
          show: false,
          height: 4,
        },
        max: maxAxisTicks,
        tickAmount: 4,
      },
      yaxis: {
        labels: {
          offsetX: 10,
          offsetY: 2,
          minWidth: 60,
          style: {
            fontFamily: "rajdhani-medium",
            fontSize: "12px",
          },
        },
      },
      tooltip: {
        show: false,
        y: {
          formatter(val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  };
  return (
    <>
      {props.topAxis && (
        <Grid container spacing={0} style={{ padding: 0 }}>
          <Grid
            container="true"
            item
            sx={12}
            style={{ borderRadius: 5, alignItems: "center" }}
          >
            <Grid
              item="true"
              xs={3}
              style={{ display: "flex", alignItems: "center", paddingLeft: 15 }}
            />
            <Grid item="true" xs={9} style={{ paddingLeft: 147, paddingRight: 15 }}>
              <div
                style={{
                  fontFamily: "rajdhani-medium",
                  fontSize: 12,
                  marginBottom: "-8px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {axisTicks.map((value, index) => (
                  <span>{value}</span>
                ))}
              </div>
              <hr />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        spacing={0}
        style={{
          height: categories?.length > 4 ? 130 : 110,
          marginTop: props.topAxis ? 0 : 12,
          padding: 0,
        }}
      >
        <Grid
          container="true"
          item
          sx={12}
          style={{
            backgroundColor: "#F4F4F4",
            borderRadius: 5,
            alignItems: "center",
          }}
        >
          <Grid
            item="true"
            xs={3}
            style={{ display: "flex", alignItems: "center", paddingLeft: 15 }}
          >
            <Typography style={{ fontFamily: "rajdhani-medium" }}>
              <span style={{ fontSize: 15 }}>
                MODULE {props.index + 1}:
              </span>
              <br />
              <span style={{ fontSize: 20 }}>{props.title}</span>
            </Typography>
          </Grid>
          <Grid item="true" xs={9} style={{ paddingLeft: 60, paddingRight:15 }}>
            <Chart
              options={state.options}
              series={state.series}
              type="bar"
              height={categories?.length > 4 ? 100 : 80}
              width="100%"
            />
          </Grid>
        </Grid>
      </Grid>
      {props.data["lastAssignedModule"] === props.title && (
        <Grid container spacing={0} style={{ padding: 0 }}>
          <Grid
            container="true"
            item
            sx={12}
            style={{ borderRadius: 5, alignItems: "center" }}
          >
            <Grid
              item="true"
              xs={3}
              style={{ display: "flex", alignItems: "center", paddingLeft: 15 }} 
            />
            <Grid item="true" xs={9} style={{ paddingLeft: 147 ,paddingRight: 15}}>
              <hr />
              <div
                style={{
                  fontFamily: "rajdhani-medium",
                  fontSize: 12,
                  marginTop: "-8px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {axisTicks.map((value, index) => (
                  <span>{value}</span>
                ))}
              </div>
            </Grid>
          </Grid>
          <Grid container="true" item sx={12} style={{ alignItems: "center" }}>
            <Grid
              item="true"
              xs={3}
              style={{ display: "flex", alignItems: "center", paddingLeft: 15 }}
            />
            <Grid item="true" xs={9} style={{ paddingLeft: 147, paddingRight: 15 }}>
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "rajdhani-medium",
                  fontSize: 15,
                }}
              >
                Number of Students
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

HorizontalBarChart.defaultProps = {
  title: "title",
};
export default HorizontalBarChart;
