import {
  Backdrop, Button, CircularProgress, Container, Grid, Stack, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { React, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  label: {
    fontWeight: 401,
  },
});

export default function StudentDetails() {
  document.title = "CSSoch | Student Details";
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [studentDetails, setStudentDetails] = useState([{}]);
  const location = useLocation();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/student/details/${location.state?.id}`)
      .then((res) => res.json())
      .then((data) => {
        setStudentDetails(data.studentDetails);
        setisLoading(true);
      });
  }, []);
  return (
    <div style={{ backgroundColor: '#DDE9F5', height: 620 }}>
      { isLoading
        ? (
          <Container>
            <Typography
              variant="h6"
              component="h2"
              style={{ padding: 10 }}
            >
              Student Details
            </Typography>
            <div style={{
              backgroundColor: 'white', marginTop: 5, padding: 20, borderRadius: 6,
            }}
            >
              <Grid container spacing={2} mb={1}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item md={3} className={classes.label}>Name:</Grid>
                    <Grid item md={7}>{studentDetails.fullname}</Grid>
                  </Grid>
                </Grid>
                <Grid item md={4}>
                  <Grid container spacing={2}>
                    <Grid item md={4} className={classes.label}>Class:</Grid>
                    <Grid item md={3}>{studentDetails.grade}</Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={1}>
                <Grid item md={8}>
                  <Grid container spacing={2}>
                    <Grid item md={3} className={classes.label}>Username:</Grid>
                    <Grid item md={7}>{studentDetails.username}</Grid>
                  </Grid>
                </Grid>
                <Grid item md={4}>
                  <Grid container spacing={2}>
                    <Grid item md={4} className={classes.label}>Section:</Grid>
                    <Grid item md={3}>{studentDetails.section}</Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <Grid container spacing={2}>
                    <Grid item md={3} className={classes.label}>Gender:</Grid>
                    <Grid item md={7}>
                        {studentDetails.gender.charAt(0).toUpperCase() + studentDetails.gender.slice(1)}
                      </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4}>
                  <Grid container spacing={2}>
                    <Grid item md={4} className={classes.label}>Status:</Grid>
                    <Grid item md={3}>{studentDetails.status.charAt(0).toUpperCase() + studentDetails.status.slice(1)}</Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Typography style={{ color: '#00868D' }} mt={5} mb={2} variant="h6" component="h2">Contact Details</Typography>
              <Grid container spacing={2} mb={1}>
                <Grid item md={8}>
                  <Grid container spacing={2}>
                    <Grid item md={3} className={classes.label}>Parent's number 1:</Grid>
                    <Grid item md={7}>{studentDetails.phone}</Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={1}>
                <Grid item md={8}>
                  <Grid container spacing={2}>
                    <Grid item md={3} className={classes.label}>Parent's number 2:</Grid>
                    <Grid item md={7}>{studentDetails.secondary_phone}</Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={1}>
                <Grid item md={8}>
                  <Grid container spacing={2}>
                    <Grid item md={3} className={classes.label}>Email:</Grid>
                    <Grid item md={7}>{studentDetails.email}</Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Stack spacing={2} direction="row" style={{ justifyContent: 'center' }}>
                <Button
                  onClick={() => navigate('/student/edit', { state: { mode: 'edit', id: studentDetails.id, user: location.state?.user } })}
                  variant="contained"
                  size="medium"
                  style={{ backgroundColor: '#00868D' }}
                >
                  Edit
                </Button>
                <Button
                  onClick={() => navigate('/student/list', { state: { user: location.state?.user } })}
                  variant="text"
                  style={{ color: 'black' }}
                >
                  Cancel
                </Button>
              </Stack>
            </div>
          </Container>
        )
        : (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
    </div>
  );
}
