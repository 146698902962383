import {
  Backdrop,
  Paper,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import { format } from "date-fns";
import { React, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ClassSectionDetails from "../../Components/School/ClassSectionDetails";

const useStyles = makeStyles({
  label: {
    fontWeight: 401,
  },
});
export default function SchoolDetails() {
  document.title = "CSSoch | School Details";
  const rows = [];
  function createData(personType, name, email, phone) {
    rows.push({
      personType,
      name,
      email,
      phone,
    });
  }
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSuperAdmin, setIsSuperadmin] = useState(location.state?.user?.role.includes('superadmin'));
  const [isLoading, setisLoading] = useState(false);
  const [person, setPerson] = useState();
  const [schoolDetails, setSchoolDetails] = useState([{}]);
  const [isLicensed, setIsLicensed] = useState(false);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/school/details/${isSuperAdmin ? location.state.id : location.state.schoolcode}`)
      .then((res) => res.json())
      .then((data) => {
        setSchoolDetails(data);
        if (data.mode === "Licensed") {
          setIsLicensed(true);
        }
        setisLoading(true);
        createData(
          "Contact Person",
          data.schoolDetails?.contact_person_name,
          data.schoolDetails?.contact_person_email,
          data.schoolDetails?.contact_person_phone
        );
        createData(
          "Principal",
          data.schoolDetails?.principal_name,
          data.schoolDetails?.principal_email,
          data.schoolDetails?.principal_phone
        );
        createData(
          "Admin",
          data.schoolDetails?.admin_person_name,
          data.schoolDetails?.admin_person_email,
          data.schoolDetails?.admin_person_phone
        );
        createData(
          "CSSoch IT POC",
          data.schoolDetails?.cssoch_IT_person_name,
          data.schoolDetails?.cssoch_IT_person_email,
          data.schoolDetails?.cssoch_IT_person_phone
        );
        setPerson(rows);
      });
  }, []);

  return (
    <div style={{ backgroundColor: "#DDE9F5" }}>
      {isLoading ? (
        <Container>
          <Typography variant="h6" component="h2" style={{ padding: 10 }}>
            School Details
          </Typography>
          <div
            style={{
              backgroundColor: "white",
              marginTop: 5,
              padding: 20,
              borderRadius: 6,
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={8} xs={12}>
                <Grid container spacing={2}>
                  <Grid item md={3} className={classes.label}>
                    School Name:
                  </Grid>
                  <Grid item md={7}>
                    {schoolDetails.schoolDetails?.school_name}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={3} className={classes.label}>
                    School Code:
                  </Grid>
                  <Grid item md={7}>
                    {schoolDetails.schoolDetails?.school_code}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={3} className={classes.label}>
                    Status:
                  </Grid>
                  <Grid item md={7}>
                    {schoolDetails.schoolDetails?.status}
                  </Grid>
                </Grid>
                {schoolDetails.schoolDetails?.status==='Inactive' &&  
                  <Grid container spacing={2}>
                    <Grid item md={3} className={classes.label}>
                      Inactivate Date:
                    </Grid>
                    <Grid item md={7}>
                      {format(
                        new Date(schoolDetails.schoolDetails?.inactivate_date),
                        "do MMM Y"
                      )}
                    </Grid>
                  </Grid>
                }
                <Grid container spacing={2}>
                  <Grid item md={3} className={classes.label}>
                    Address:
                  </Grid>
                  <Grid item md={7}>
                    {schoolDetails.schoolDetails?.address.length
                      ? `${schoolDetails.schoolDetails?.address}, `
                      : ""}
                    {schoolDetails.schoolDetails?.city.length
                      ? `${schoolDetails.schoolDetails?.city}`
                      : ""}
                    {schoolDetails.schoolDetails?.state.length
                      ? `, ${schoolDetails.schoolDetails?.state}`
                      : ""}
                    {schoolDetails.schoolDetails?.pincode.length
                      ? `, ${schoolDetails.schoolDetails?.pincode}`
                      : ""}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <Grid container spacing={2}>
                  <Grid item md={6} className={classes.label}>
                    Mode:
                  </Grid>
                  <Grid item md={5}>
                    {schoolDetails.schoolDetails?.mode}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} className={classes.label}>
                    {schoolDetails.schoolDetails?.mode === "Trial"
                      ? "Started on: "
                      : "Created on: "}
                  </Grid>
                  {schoolDetails.schoolDetails?.created_at &&
                    <Grid item md={5}>
                      {format(
                        new Date(schoolDetails.schoolDetails?.created_at),
                        "do MMM Y"
                      )}
                    </Grid>
                  }
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} className={classes.label}>
                    Expires on:
                  </Grid>
                  <Grid item md={5}>
                    {format(
                      new Date(schoolDetails.schoolDetails?.trial_expiry_date),
                      "do MMM Y"
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} className={classes.label}>
                    Payment Schedule:
                  </Grid>
                  <Grid item md={5}>
                    {schoolDetails.schoolDetails?.payment_schedule}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Typography
              style={{ color: "#00868D" }}
              mt={2}
              mb={2}
              variant="h6"
              component="h2"
            >
              Class and Sections :
            </Typography>
            {schoolDetails && (
              <ClassSectionDetails gradeDetails={schoolDetails?.schoolDetails?.grades_data} />
            )}
            <Typography
              style={{ color: "#00868D" }}
              mt={5}
              variant="h6"
              component="h2"
            >
              Contact Details:
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Box sx={{ marginLeft: 0, marginRight: 2 }}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell align="left">Name</TableCell>
                          <TableCell align="left">Email ID</TableCell>
                          <TableCell align="left">Contact Number</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {person &&
                          person.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                variant="head"
                                component="th"
                                scope="row"
                              >
                                {row.personType}
                              </TableCell>
                              <TableCell align="left">{row.name}</TableCell>
                              <TableCell align="left">{row.email}</TableCell>
                              <TableCell align="left">{row.phone}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() =>
                  navigate("/edit/school/details", {
                    state: { ...location.state },
                  })
                }
                variant="contained"
                size="medium"
                style={{ marginTop: 17, backgroundColor: "#00868D" }}
              >
                Edit
              </Button>
            </div>
          </div>
        </Container>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
}
