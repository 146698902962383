import React, { createContext, useState } from 'react';

const downloadReportContext = createContext();

function DownloadReport({ children }) {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState();

  function updateDownloadReport(value, file) {
    setOpen(value);
    setUrl(file);
  }
  return (
    <downloadReportContext.Provider
      value={{ open, url, updateDownloadReport }}
    >
      {children}
    </downloadReportContext.Provider>
  );
}

export { DownloadReport, downloadReportContext };
