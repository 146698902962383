import React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import GameButton from "../ModuleComponentsButtons/GameButton";
import PuzzleButton from "../ModuleComponentsButtons/PuzzleButton";
import PythonButton from "../ModuleComponentsButtons/PythonButton";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRight: "none",
  borderLeft: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightIcon sx={arrowStyle} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const arrowStyle = { color: "white", fontSize: "3vw" };

function NotebookAccordion({
  children,
  accordionNotOpen,
  setAccordionNotOpen,
  index,
  setSelectedLevel,
  cellType,
  section,
}) {
  const accordionButtonMapper = (type) => {
    if (type === "Game") {
      return (
        <GameButton
          key={index}
          icon={true}
          notebookView={true}
          status={"notebookIconColor"}
        />
      );
    }
    if (type === "Puzzle") {
      return (
        <PuzzleButton
          key={index}
          icon={true}
          notebookView={true}
          status={"notebookIconColor"}
        />
      );
    }
    if (type === "Python") {
      return (
        <PythonButton
          key={index}
          icon={true}
          notebookView={true}
          status={"notebookIconColor"}
        />
      );
    }
    return <></>;
  };
  return (
    <Accordion
      expanded={
        accordionNotOpen[section] &&
        Object.keys(accordionNotOpen[section]).includes(index.toString())
          ? !accordionNotOpen[section][index]
          : true
      }
      onChange={() => {
        setAccordionNotOpen((pre) => {
          if (Object.keys(pre[section]).includes(index.toString())) {
            pre[section][index] = !pre[section][index];
          } else {
            pre[section] = {
              ...pre[section],
              [index]: true,
            };
          }
          return { ...pre };
        });
      }}
      onClick={() => setSelectedLevel((pre) => index)}
    >
      <AccordionSummary
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          ".MuiAccordionSummary-content": {
            margin: "0px",
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        {accordionButtonMapper(cellType)}
        {cellType}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

export default NotebookAccordion;
