import React from "react";
import CloseCross from "../../../Assets/Images/CloseCross.svg";
import LevelFailSadEmoji from "../../../Assets/Images/LevelFailSadEmoji.svg";

export default function ErrorBox(props) {
  const showError = props.showError;
  const errorMsg = props.errorMsg;
  return (
    <div
      className={`feedback-main-box bad-job-feedback d-flex flex-column align-items-center ${
        showError ? "active" : ""
      }`}
      style={
        props.currentItem === "assessment"
          ? { height: "fit-content", padding: "1vw 0px" }
          : {}
      }
    >
      <img
        className="close-cross"
        src={CloseCross}
        alt=""
        onClick={props.closeErrorBoxHandler}
      />
      <div
        className="heading d-flex justify-content-center align-items-center"
        style={{ height: "2vw", marginBottom: "0.2vw" }}
      >
        <h5>Oh No</h5>
        <img src={LevelFailSadEmoji} alt="" />
      </div>
      <hr />
      <p>{errorMsg}</p>
      <button
        className="feedback-okay-button"
        onClick={props.closeErrorBoxHandler}
      >
        OK
      </button>
    </div>
  );
}