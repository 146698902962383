import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily:"rajdhani-medium",
  },
}));
function ModuleProgressBar(props) {
  const { currentModuleIndex, setCurrentModuleIndex, moduleStatusData } = props;
  const [moduleBarWidth, setModuleBarWidth] = useState();
  const [moduleStatuses, setModuleStatuses] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (moduleStatusData) {
      setModuleStatuses(moduleStatusData.statuses);
      setModuleBarWidth(88 / moduleStatusData.num_modules);
      setIsLoading(false);
    }
  }, [moduleStatusData]);

  useEffect(() => {
    if (moduleStatusData && moduleStatuses && currentModuleIndex >= 0) {
      handleModuleChange(currentModuleIndex);
    }
  }, [moduleStatusData, moduleStatuses, currentModuleIndex]);

  const handleModuleChange = (moduleIndex) => {
    if (currentModuleIndex === moduleIndex) return;
    setCurrentModuleIndex(moduleIndex);
  };

  return (
    <>
      {!isLoading ? (
        <Box
          sx={{ typography: "body1" }}
          className='module-progress-bar-container'
        >
          <div className='container'>
            <div className='module-bar d-flex'>
              <div className={`step ${currentModuleIndex === -1 ? "active" : ""}`}
                      onClick={() => setCurrentModuleIndex(-1)}
              >
                {" "}
                <span>Modules</span>{" "}
              </div>
              {moduleStatuses &&
                moduleStatuses.map((status, index) => (
                  <BootstrapTooltip key={index + 1} title={status}>
                    <div
                      key={index + 1}
                      id={index}
                      className={`step ${
                        status === "completed"
                          ? "completed"
                          : status === "ongoing"
                          ? "ongoing"
                          : status === "incomplete"
                          ? "incomplete"
                          : "locked"
                      } ${status === "assigned" ? "assigned" : ""} ${currentModuleIndex === index ? "active" : ""}`}
                      onClick={() => handleModuleChange(index)}
                      style={{
                        cursor: "Pointer",
                        width: `${moduleBarWidth}%`,
                      }}
                    >
                      {" "}
                      <span>{index + 1}</span>{" "}
                    </div>
                  </BootstrapTooltip>
                ))}
            </div>
          </div>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

export default ModuleProgressBar;
