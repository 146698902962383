import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Button,
  Container,
  Input,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { filterContext } from "../../Components/Contexts/filterContext";
import TableDataGrid from "../../Components/TableDataGrid/TableDataGrid";
import StudentImportDialog from "./StudentImportDialog";

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-row:focus":
      {
        outline: "none",
      },
  },
});

export default function StudentGrid() {
  document.title = "CSSoch | Student List";
  const filterData = useContext(filterContext);
  const [open, setOpen] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [reloadData, setReloadData] = useState();
  const [isDeleted, setIsDeleted] = useState();
  const [showStudentRollOverBtn, setShowStudentRollOverBtn] = useState(false);
  const [disableAddImportStudent, setDisableAddImportStudent] = useState(true);
  const [rollOverClassesData, setRollOverClassesData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleCloseSnackBar = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleOnFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setShowDialog(true);
  };

  const handleUnselectFile = () => {
    setSelectedFile(undefined);
    setShowDialog(false);
  };

  const handleRefreshStudentList = () => {
    setTimeout(() => {
      setReloadData(Math.random());
    }, 1000);
  };

  const handleDownloadStudentTemplate = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/template/student`).then(
      (res) => {
        res.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.setAttribute("download", "student_import_template.xlsx");
          document.body.appendChild(a);
          a.click();
          a.parentNode.removeChild(a);
        });
      }
    );
  };

  return (
    <div style={{ backgroundColor: "#DDE9F5", height: 600 }}>
      <Container>
        <Typography variant="h6" component="h2" style={{ padding: 10 }}>
          Manage Students
        </Typography>
        <Box textAlign="right" marginTop="-10px">
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <label htmlFor="contained-button-file">
              <Input
                onChange={handleOnFileChange}
                onClick={(event) => (event.target.value = null)}
                id="contained-button-file"
                multiple
                type="file"
                inputProps={{
                  style: { display: "none" },
                  accept:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }}
                disabled={disableAddImportStudent}
              />
              <Tooltip title="Import Student Excel" arrow>
                <Button
                  style={{ backgroundColor: "#00868D", marginBottom: 15 }}
                  variant="contained"
                  component="span"
                  endIcon={<FileUploadIcon />}
                  disabled={disableAddImportStudent}
                >
                  Import
                </Button>
              </Tooltip>
            </label>
            <Tooltip title="Download Student Template" arrow>
              <Button
                variant="contained"
                endIcon={<FileDownloadIcon />}
                style={{ backgroundColor: "#00868D", marginBottom: 15 }}
                onClick={handleDownloadStudentTemplate}
              >
                Template
              </Button>
            </Tooltip>
            <Button
              style={{ backgroundColor: "#00868D", marginBottom: 15 }}
              variant="contained"
              onClick={() =>
                navigate("/student/add")
              }
              disabled={disableAddImportStudent}
            >
              Add Student
            </Button>
            {showStudentRollOverBtn && (
              <Button
                style={{ backgroundColor: "#00868D", marginBottom: 15 }}
                variant="contained"
                onClick={() =>
                  navigate("/student/rollover", {
                    state: { rollOverClassesData },
                  })
                }
              >
                Roll Over
              </Button>
            )}
          </Stack>
        </Box>
        {isDeleted && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={2000}
            onClose={handleCloseSnackBar}
            sx={{ top: "10vh !important" }}
          >
            <Alert
              onClose={handleCloseSnackBar}
              severity="success"
              sx={{ width: "100%" }}
            >
              Student deleted successfully
            </Alert>
          </Snackbar>
        )}
        <h6 style={{ display: "none" }}>{reloadData}</h6>
        <TableDataGrid
          type="student"
          filter={filterData.studentFilter}
          reloadData={Math.random()}
          columns={[
            {
              field: "fullname",
              flex: 0.8,
              headerName: "Name",
            },
            {
              field: "grade",
              flex: 0.6,
              headerName: "Class",
              valueGetter: (params) => `${params.row.grade}`,
            },
            {
              field: "section",
              flex: 0.6,
              headerName: "Section",
              valueGetter: (params) => `${params.row.section}`,
            },
            { field: "username", flex: 0.8, headerName: "User Id" },
          ]}
          setShowStudentRollOverBtn={setShowStudentRollOverBtn}
          setDisableAddImportStudent={setDisableAddImportStudent}
          setRollOverClassesData={setRollOverClassesData}
        />
      </Container>
      {showDialog && (
        <StudentImportDialog
          selectedFile={selectedFile}
          openDialog
          handleUnselectFile={handleUnselectFile}
          handleRefreshUserList={handleRefreshStudentList}
          type="student"
        />
      )}
    </div>
  );
}
