import React, { createContext, useState } from 'react';

const schoolNameContext = createContext();

function SetSchoolName({ children }) {
  const [school, setSchool] = useState('');

  function updateSchool(value) {
    setSchool(value);
  }
  return (
    <schoolNameContext.Provider
      value={{ school, updateSchool }}
    >
      {children}
    </schoolNameContext.Provider>
  );
}

export { SetSchoolName, schoolNameContext };
