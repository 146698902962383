import React from "react";
import { Typography } from "@mui/material";

function RecentRewards() {
  return (
    <>
      <Typography variant='h6' component='h4' className='progress-header'>
        Recent Rewards
      </Typography>
      <div className='rewards-coming-soon'>
        <p>Coming Soon...</p>
      </div>
    </>
  );
}

export default RecentRewards;
