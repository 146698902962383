import React, { createContext, useState, useContext, useEffect } from "react";
import { authUserContext } from "../Contexts/AuthUser";
import clickSound from "../../Assets/sounds/click_sound.mp3";

export const ModuleAPIManagerContext = createContext();

function ModuleAPIManager({ children }) {
  const { user } = useContext(authUserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [statusStorage, setStatusStorage] = useState({});
  const [statusesSet, setStatusesSet] = useState(false);
  const [structure, setStructure] = useState([]);
  const [moduleName, setModuleName] = useState();
  const [initialItem, setInitial] = useState("ongoing");
  const [currentSection, setCurrentSection] = useState(null);
  const [strapiData, setStrapiData] = useState();
  const [studentName, setStudentName] = useState();
  const [userId, setUserId] = useState(null);
  const [grade, setGrade] = useState(null);
  const [className, setClassName] = useState(null);
  const [moduleNo, setModuleNo] = useState(null);
  const [teacherView, setTeacherView] = useState(null);
  const [failedToUpdateStatus, setFailedToUpdateStatus] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    updateItems();
  }, [statusStorage]);

  const updateStatusStorage = async (updateStatuses, item = null) => {
    let statusStorageCopy = { ...statusStorage };
    if (
      !user.role.includes("teacher") ||
      (user.role.includes("teacher") && teacherView)
    ) {
      const statuses = resetOngoingComponent(statusStorageCopy, updateStatuses, item);
      statusStorageCopy = { ...statusStorageCopy, ...updateStatuses, ...statuses };
    }
    const statusUpadted = await updateToCloud(statusStorageCopy);
    if (
      statusUpadted ||
      teacherView ||
      (user.role.includes("teacher") && item)
    ) {
      setStatusStorage(statusStorageCopy);
      return true;
    }
  };

  const resetOngoingComponent = (previousStatuses, newStatuses, item) => {
    let statuses = {}
    if(structure?.length === 0 || previousStatuses[`${item}_status`] === "ongoing") return statuses;
    for (let i = 0; i < structure.length; i++) {
      if (structure[i] === item && previousStatuses[`${item}_status`] === "incomplete" && newStatuses[`${item}_status`] !== "completed") {
        statuses[`${item}_status`] = "ongoing";
        continue;
      }
      if (previousStatuses[`${structure[i]}_status`] === "ongoing") {
        statuses[`${structure[i]}_status`] = "incomplete";
      }
    }
    return statuses;
  };

  async function updateToCloud(statuses = statusStorage) {
    if (user.role.includes("teacher")) return false;
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/status-upload`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(statuses),
      }
    );
    if (response.status !== 200) {
      setFailedToUpdateStatus(true);
      if (response.redirected === true) {
        document.location = "/landing";
      }
      return false;
    }
    setFailedToUpdateStatus(false);
    return true;
  }

  function updateItems() {
    if (statusesSet) {
      if (user.role.includes("teacher") && !document.URL.includes("view"))
        return;
      const items = [
        "story",
        "game",
        "python",
        "puzzle",
        "assessment",
        "project",
      ];
      let allComplete = true;
      let statusStorageCopy = JSON.parse(JSON.stringify(statusStorage));
      for (let i = 0; i < items.length; i++) {
        if (!statusStorageCopy.hasOwnProperty(`${items[i] + "_status"}`))
          continue;
        if (statusStorageCopy[`${items[i] + "_status"}`] !== "completed") {
          allComplete = false;
          break;
        }
      }
      if (allComplete && statusStorage.status !== "completed") {
        statusStorageCopy.status = "completed";
        updateStatusStorage(statusStorageCopy);
      }
    }
  }

  async function handleCurrentSectionChange(newSection, immediate = false) {
    if (newSection !== currentSection) {
      const status = { ...statusStorage };
      if (immediate) {
        const audio = new Audio(clickSound);
        audio.volume = 0.3;
        audio.play();
      }
      if (
        !user.role.includes("teacher") ||
        (user.role.includes("teacher") && teacherView)
      ) {
        if (
          statusStorage[`${currentSection + "_status"}`] === "ongoing" &&
          statusStorage[`${newSection + "_status"}`] === "incomplete"
        ) {
          status[`${newSection + "_status"}`] = "ongoing";
          status[`${currentSection + "_status"}`] = "incomplete";
        } else if (
          statusStorage[`${currentSection + "_status"}`] === "ongoing"
        ) {
          status[`${currentSection + "_status"}`] = "incomplete";
        } else if (
          statusStorage[`${newSection + "_status"}`] === "incomplete"
        ) {
          status[`${newSection + "_status"}`] = "ongoing";
        }
        await updateStatusStorage(status, newSection);
      }
      setCurrentSection(newSection);
    }
  }

  const valueToPass = {
    isLoading,
    setIsLoading,
    statusStorage,
    setStatusStorage,
    statusesSet,
    setStatusesSet,
    structure,
    setStructure,
    moduleName,
    setModuleName,
    initialItem,
    setInitial,
    currentSection,
    setCurrentSection,
    strapiData,
    setStrapiData,
    studentName,
    setStudentName,
    userId,
    setUserId,
    grade,
    setGrade,
    className,
    setClassName,
    moduleNo,
    setModuleNo,
    teacherView,
    setTeacherView,
    failedToUpdateStatus,
    setFailedToUpdateStatus,
    retryCount,
    setRetryCount,
    updateStatusStorage,
    updateToCloud,
    updateItems,
    handleCurrentSectionChange,
    resetOngoingComponent,
  };

  return (
    <ModuleAPIManagerContext.Provider value={valueToPass}>
      {children}
    </ModuleAPIManagerContext.Provider>
  );
}

export default ModuleAPIManager;
