import React from "react";
import MarkdownView from "react-showdown";

function TextCell(props) {
  const { cellcontent } = props;

  return (
    <div className="text-cell">
      <MarkdownView
        markdown={cellcontent.text}
        className="text-cell-markdown"
      />
    </div>
  );
}

export default TextCell;
