import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../../Styles/Reset.css";
import zxcvbn from "zxcvbn";
import { authUserContext } from "../../Components/Contexts/AuthUser";

const COLOR_MAPPER = ["#FF0000", "#FE7D00", "#FFB017", "#AECB2A", "#25A596"];
const STRENGTHS = ["VERY WEAK", "WEAK", "GOOD", "STRONG", "VERY STRONG"];

function ResetPassword() {
  document.title = "CSSoch | Reset Password";
  const { token } = useParams();
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [strength, setStrength] = useState(0);
  const {handleUserLogout}  = useContext(authUserContext);

  const navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/reset/${token}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setIsTokenExpired(data?.token_expired);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (confirmPasswordError) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password }),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/reset/${token}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          if(handleUserLogout)
            handleUserLogout();
          else {
            navigate("/");
          }
        }
      });
  };

  return (
    <div className='reset-password-container'>
      <a onClick={() => navigate("/")}>
        <img className='company' src='/static/svg/CompanyLogin.svg' alt='' />
      </a>
      <img id='surrounding' src='/static/svg/ResetSurrounding.svg' alt='' />
      <div className='reset-password-content'>
        <h2>Reset password</h2>
        {isTokenExpired ? (
          <p>
            Oh no, it looks like your token has expired. Please request to reset
            your password again and use the link in the email within 30 minutes.
          </p>
        ) : (
          <form className='reset-password-form' onSubmit={handleSubmit}>
            <input
              className='initial_password'
              type='password'
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setStrength(zxcvbn(e.target.value).score);
              }}
              placeholder='New Password'
              name='password'
              required
            />
            <input
              className='confirm_password'
              type='password'
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPasswordError(e.target.value !== password);
                setConfirmPassword(e.target.value);
              }}
              placeholder='Confirm Password'
              name='confirm_password'
              required
            />
            {confirmPasswordError && confirmPassword ? (
              <p className='error'>Passwords don't match</p>
            ) : (
              <></>
            )}
            {password ? (
              <div className='password-checker'>
                <div className='strength-box'>
                  <div
                    className='strength-indicator'
                    style={{
                      width: `${(strength + 1) * 20}%`,
                      backgroundColor: `${COLOR_MAPPER[strength]}`,
                    }}
                  ></div>
                </div>
                <p>Password Strength: {STRENGTHS[strength]}</p>
              </div>
            ) : (
              <></>
            )}
            <button
              className='submit-button-reset'
              type='submit'
              disabled={!(confirmPassword && password && !confirmPasswordError)}
            >
              Reset Password
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;
