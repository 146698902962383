import React, { useEffect, useState } from "react";

function ResetPasswordModal(props) {
  const { emailSent, setEmailSent } = props;
  const [isEmailSent, setIsEmailSent] = useState(emailSent);
  const [username, setUsername] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);

  const sendEmail = () => {
    if (sendingEmail) {
      return;
    }
    setSendingEmail(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username }),
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/reset-password`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setEmailSent(true);
          setIsEmailSent(true);
        }
        setSendingEmail(false);
      });
  };
  return (
    <div className='reset-password-modal'>
      <div className='row'>
        <div className='col image-container'>
          <img
            src='/static/svg/PasswordResetCheeti.png'
            alt='A happy caterpillar telling you to keep your password secure'
          />
        </div>
        <div className='col form-container'>
          <h2>Reset Password</h2>
          {!isEmailSent ? (
            <div className='request-reset-form'>
              <input
                id='reset-password-username'
                type='text'
                placeholder='Username'
                name='username'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autocomplete='off'
                spellcheck='false'
                required
              />
              <p className='error username-error' style={{ display: "none" }}>
                Please enter your username
              </p>
              <button className='submit-button' onClick={sendEmail}>
                Send Email
              </button>
            </div>
          ) : (
            <p className='reset-instructions-message'>
              Check the email you are registered with for further instructions.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordModal;
