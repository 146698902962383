import { Box, CircularProgress, Typography } from '@mui/material';
import {
  React, useContext, useEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { authUserContext } from '../../Components/Contexts/AuthUser';
import '../../index.css';

export default function ModuleAssignment(props) {
  document.title = "CSSoch | Module Assignment";
  const [data, setData] = useState();
  const [moduleBarWidth, setModuleBarWidth] = useState();
  const location = useLocation();
  const {user} = useContext(authUserContext);
  const assignmentLegend = {
    Assigned: '#25a596',
    'Not Assigned': '#DBDBDB',
  };
  const schoolcode = location.state ? location.state.schoolcode : user.schoolcode;
  const [gradeWiseData, setGradeWiseData] = useState(JSON.parse(sessionStorage.getItem('module_assignment_data'))?.hasOwnProperty(schoolcode) ? JSON.parse(sessionStorage.getItem('module_assignment_data'))[schoolcode] : {});

  useEffect(() => {
    const schoolcode = location.state ? location.state.schoolcode : user.schoolcode;
    setData();
    if (gradeWiseData && gradeWiseData[props.grade]) {
      const data = gradeWiseData[props.grade];
      let numberofmodules;
      Object.keys(data).map((key) => {
        numberofmodules = data[key].length;
      });
      const module_bar_width = 98 / (numberofmodules);
      setModuleBarWidth(module_bar_width);
      setData(data);
    } else {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/module-assignment/${schoolcode}/${props.grade}`).then((res) => res.json()).then((data) => {
        let numberofmodules;
        Object.keys(data).map((key) => {
          numberofmodules = data[key].length;
        });
        const module_bar_width = 98 / (numberofmodules);
        setModuleBarWidth(module_bar_width);
        if (user.role.includes('teacher')) {
          const teacher_data = {};
          Object.keys(data).map((section) => {
            if (props.gradeData[props.grade].includes(section)) {
              teacher_data[section] = data[section];
            }
          });
          var module_assignment_data = JSON.parse(sessionStorage.getItem('module_assignment_data'));
          if (!module_assignment_data.hasOwnProperty(schoolcode)) {
            module_assignment_data[schoolcode] = {};
          }
          module_assignment_data[schoolcode][props.grade] = teacher_data;
          sessionStorage.setItem('module_assignment_data', JSON.stringify(module_assignment_data));
          setGradeWiseData((currentValue) => {
            if (currentValue) {
              currentValue[props.grade] = teacher_data;
              return currentValue;
            }
            const newValue = {};
            newValue[props.grade] = teacher_data;
            return newValue;
          });
          setData(teacher_data);
        } else {
          var module_assignment_data = JSON.parse(sessionStorage.getItem('module_assignment_data'));
          if (!module_assignment_data.hasOwnProperty(schoolcode)) {
            module_assignment_data[schoolcode] = {};
          }
          module_assignment_data[schoolcode][props.grade] = data;
          sessionStorage.setItem('module_assignment_data', JSON.stringify(module_assignment_data));
          setGradeWiseData((currentValue) => {
            if (currentValue) {
              currentValue[props.grade] = data;
              return currentValue;
            }
            const newValue = {};
            newValue[props.grade] = data;
            return newValue;
          });
          setData(data);
        }
      });
    }
  }, [props.grade]);

  return (
    data
      ? (
        <div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            {
                Object.keys(assignmentLegend).map((key) => (
                  <div key={key} style={{ display: 'flex', alignItems: 'center', paddingLeft: 20,paddingBottom: 10 }}>
                    <span style={{
                      height: 15, width: 15, backgroundColor: assignmentLegend[key], borderRadius: '50%',
                    }}
                    />
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: 5, color: '#484C51', fontFamily: 'rajdhani-medium', fontSize: 15,
                      }}
                    >
                      {key}
                    </Typography>
                  </div>
                ))
            }
          </div>
          {
            Object.keys(data).map((section) => (
              <div
                className="container"
                style={{
                  backgroundColor: '#F4F4F4', display: 'flex', alignItems: 'center', height: 110, marginTop: 6, marginBottom: 6, paddingLeft: 10,paddingRight: 10,
                }}
              >
                <span style={{
                  width: '20%', fontFamily: 'rajdhani-medium', fontSize: 20,
                }}
                >
                  Section {section}
                </span>
                <div className="module-arrow-steps">
                  {data[section].map((status, index) => (
                    <div className={status === 'assigned' ? 'step current' : 'step'} style={{ minWidth: `${moduleBarWidth}%` }}>
                      {' '}
                      <span>{index + 1}</span>
                      {' '}
                    </div>
                  ))}
                  <div className="step" style={{ width: '2%' }}>
                    {' '}
                    <span />
                    {' '}
                  </div>
                </div>
              </div>
            ))
        }
        </div>
      )
      : (
        <Box sx={{ display: 'flex', p: 5 }} justifyContent="center">
          <CircularProgress />
        </Box>
      )
  );
}
