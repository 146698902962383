import Prism from 'prismjs';
import 'prismjs/components/prism-python';
function Console(outputElementID) {
  let enabled = false;

  this.enable = function() {
    enabled = true;
  }
  this.disable = function() {
    enabled = false;
  }

  this.addMessage = function(msg, error=false) {
    let outputBox = document.getElementById(outputElementID);
    if (!enabled) return;
    if (error) {
      let errorDiv = document.createElement('div');
      errorDiv.classList.add('error-message');
    
      let errorHeader = document.createElement('p');
      errorHeader.classList.add('error-header');
      errorHeader.textContent = "Traceback";
      errorDiv.appendChild(errorHeader);
    
      let errorMessage = document.createElement('p');
      errorMessage.textContent = msg;
      errorDiv.appendChild(errorMessage);
    
      outputBox.appendChild(errorDiv);
    } else {
      let consoleText = document.createElement('p');
      consoleText.classList.add('console-text');
      consoleText.textContent = msg;
      outputBox.appendChild(consoleText);
    }
    updated();
  }

  this.addCommand = function(cmd) {
    let outputBox = document.getElementById(outputElementID);
    const codeSnippet = document.createElement('code');
    codeSnippet.classList.add('language-python');
    const textNode = document.createTextNode(">>> " + cmd);
    codeSnippet.appendChild(textNode);
    outputBox.appendChild(codeSnippet);
    Prism.highlightElement(codeSnippet);
    updated();
  }

  function updated() {
    let outputBox = document.getElementById(outputElementID);
    outputBox.scrollTop = outputBox.scrollHeight;
  }

  this.clear = function() {
    let outputBox = document.getElementById(outputElementID);
    while (outputBox?.firstChild) {
      outputBox.removeChild(outputBox.firstChild);
    }
  }
}

export default Console;