import React, { useState, useEffect, useRef } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import GridIcon from "../../../Assets/Images/Grid.svg";
import HelpIcon from "../../../Assets/Images/Help.svg";
import { styled } from "@mui/material/";
import { openPopupWindow } from "../../../Utils/helper";

function PythonGraphicsBox(props) {
  const {
    sketchHolderId,
    preLoadedImage,
    programCurrentlyRunning,
    helpInstructionIds,
    moduleName,
    grade,
    className,
  } = props;
  const [sliderVisible, setSliderVisible] = useState(false);
  const [gridOpacity, setGridOpacity] = useState(1);
  const [gridHeight, setGridHeight] = useState(400);
  const [gridWidth, setGridWidth] = useState(400);
  const [popUpWindowHandle, setPopUpWindowHandle] = useState();
  const sketchHolder = useRef(null);
  const gridOverlay = useRef(null);
  const xAxis = useRef(null);
  const yAxis = useRef(null);
  const [helpGraphicsUrl, setHelpGraphicsUrl] = useState(
    `${process.env.REACT_APP_API_BASE_URL}/help-instructions?${grade ? `grade=${grade}&` : ""}${className ? `section=${className}&` : ""}${moduleName ? `module_name=${moduleName}&` : ""}&ids=${helpInstructionIds}`
  );

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontFamily: "rajdhani-medium",
    },
  }));
  useEffect(() => {
    if (sketchHolder.current) {
      const canvas = sketchHolder.current.getElementsByTagName("canvas")[0];
      gridOverlay.current.style.height = programCurrentlyRunning
        ? `${canvas?.height}px`
        : "400px";
      gridOverlay.current.style.width = programCurrentlyRunning
        ? `${canvas?.width}px`
        : "400px";
      setGridHeight(programCurrentlyRunning ? canvas?.height : 400);
      setGridWidth(programCurrentlyRunning ? canvas?.width : 400);
    }
  }, [programCurrentlyRunning]);

  useEffect(() => {
    const xSpans = xAxis.current.getElementsByTagName("span");
    for (let i = 0; i < xSpans.length; i++) {
      const value = parseInt(xSpans[i].innerText);
      const width = xSpans[i].getBoundingClientRect().width;
      if (i === xSpans.length - 1) {
        xSpans[i].style.left = `${value - width - 1}px`;
      } else if (i === 0) {
        xSpans[i].style.left = "0px";
      } else {
        xSpans[i].style.left = `${value - width / 2}px`;
      }
    }
    const ySpans = yAxis.current.getElementsByTagName("span");
    for (let i = 0; i < ySpans.length; i++) {
      const value = parseInt(ySpans[i].innerText);
      const height = ySpans[i].getBoundingClientRect().height;
      if (i === ySpans.length - 1) {
        ySpans[i].style.top = `${value - height - 1}px`;
      } else if (i === 0) {
        ySpans[i].style.top = "0px";
      } else {
        ySpans[i].style.top = `${value - height / 2 + 1}px`;
      }
    }
  }, [gridWidth, gridHeight, programCurrentlyRunning]);

  useEffect(() => {
    const clickOutsideSlider = (e) => {
      if (
        e.target.closest(".grid-slider") ||
        e.target.closest(".grid-toggle-btn")
      )
        return;
      setSliderVisible(false);
    };

    window.addEventListener("click", clickOutsideSlider);
    return () => window.removeEventListener("click", clickOutsideSlider);
  }, []);

  return (
    <>
      <div
        className='d-flex justify-content-end'
        style={{ margin: "5px 10px" }}
      >
        <BootstrapTooltip placement='top' title='Toggle Grid'>
          <div
            className='grid-toggle-btn'
            onClick={() => setGridOpacity(!gridOpacity)}
          >
            <img src={GridIcon} alt='' />
          </div>
        </BootstrapTooltip>
        {helpInstructionIds?.length ? (
          <BootstrapTooltip placement='top' title='Help'>
            <div
              id='help-icon'
              onClick={() =>
                openPopupWindow(
                  popUpWindowHandle,
                  setPopUpWindowHandle,
                  helpGraphicsUrl
                )
              }
            >
              <img src={HelpIcon} alt='' />
            </div>
          </BootstrapTooltip>
        ) : (
          <></>
        )}
      </div>
      <div id={sketchHolderId} className='sketch-holder' ref={sketchHolder}>
        {preLoadedImage != null && (
          <img
            src={preLoadedImage}
            style={{
              height: "100%",
              width: "100%",
              display: programCurrentlyRunning ? "none" : "block",
            }}
          />
        )}
        <div
          className='python-graphics-grid-overlay'
          style={{ opacity: gridOpacity ? 1 : 0 }}
          ref={gridOverlay}
        >
          <div className='python-graphics-grid-x-axis' ref={xAxis}>
            {[...Array(Math.floor(gridWidth / 50) + 1)].map((_, i) => (
              <span key={i}>{i * 50}</span>
            ))}
            {(gridWidth / 50) % 2 !== 0 && <span>{gridWidth}</span>}
          </div>
          <div className='python-graphics-grid-y-axis' ref={yAxis}>
            {[...Array(Math.floor(gridHeight / 50) + 1)].map((_, i) => (
              <span key={i}>{i * 50}</span>
            ))}
            {(gridHeight / 50) % 2 !== 0 && <span>{gridHeight}</span>}
          </div>
        </div>
      </div>
    </>
  );
}

export default PythonGraphicsBox;
