import { Box, Modal, Typography } from "@mui/material";
import CloseCross from "../../Assets/Images/CloseCross.svg";
const CheetiImg = require("../../Assets/Images/Cheeti.png");

const internetModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  boxShadow: 24,
  padding: "1vw",
  fontFamily: "Rajdhani",
  border: "1px solid #00868d",
  backgroundColor: "rgba(255, 255, 255, 0.93)",
  borderRadius: "2vw",
  transition: "0.3s",
  fontSize: "2vw",
};

function CheckInternet({ open, onClose }) {
  return (
    <>
      <img src={CheetiImg} style={{ display: "none" }} />
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop
        style={{ backgroundColor: "rgba(0,0,0, 0.4)" }}
      >
        <Box sx={internetModalStyle}>
          <img
            className="close-cross"
            src={CloseCross}
            alt=""
            onClick={onClose}
          />
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
              fontFamily: "Rajdhani",
            }}
          >
            <img
              src={CheetiImg}
              alt="Cheeti"
              height={100}
              style={{ marginRight: "10px" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "20px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h5
                style={{
                  fontSize: "2vw",
                  margin: 0,
                  fontWeight: 500,
                }}
              >
                Oops!
              </h5>
              <hr
                style={{
                  display: "block",
                  width: "90%",
                  backgroundColor: "#707070",
                  opacity: 0.2,
                  margin: 0,
                  height: "1px",
                  marginBottom: "0.5vw",
                  marginTop: 0,
                }}
              />
              <span
                style={{
                  textAlign: "center",
                  fontSize: "1.7vw",
                  color: "#6a6a6a",
                  lineHeight: "1.8vw",
                  marginBottom: "0.6vw",
                  marginTop: 0,
                }}
              >
                Something went wrong <br />
                Check your network connection
              </span>
              <button
                className="feedback-okay-button"
                onClick={onClose}
                style={{ width: "50%" }}
              >
                Ok
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default CheckInternet;
