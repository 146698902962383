import React, { useEffect, useState } from "react";
import HorizontalBar from "../../../Assets/Images/HorizontalBar.svg";
import AssesmentStatusSuccess from "../../../Assets/Images/AssesmentStatusSuccess.svg";
import AssesmentStatusFail from "../../../Assets/Images/AssesmentStatusFail.svg";

function LevelButtons(props) {
  const {
    viewOnly,
    currentLevel,
    setPrevClicked,
    setCurrentLevel,
    data,
    codeCarryForwardLevels,
    teacher,
    teacherView,
    setPageIsRendered,
    setShowFinishScreen,
    setShowTestScreen,
    statusStorage,
    onwards,
    nextButtonText,
  } = props;
  const [assessmentLevels, setAssessmentLevels] = useState([])

  useEffect(() => {
    if(statusStorage && statusStorage.assessment_levels){
      setAssessmentLevels(statusStorage.assessment_levels)
    }
  }, [statusStorage])

  return (
    <div
      className="row"
      style={{
        width: "77vw",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <div
        className="col d-flex justify-content-end align-items-center"
        style={{ flex: "1" }}
      >
        {viewOnly && currentLevel !== 1 && (
          <p
            className="assesment-viewonly-prev m-0 mr-3"
            onClick={() => {
              setPrevClicked(true);
              setCurrentLevel(currentLevel - 1);
            }}
          >
            &larr; Previous
          </p>
        )}
      </div>
      <div
        className={`col assesment-button-holder ${!viewOnly ? "locked" : ""}`}
        style={{ flex: "1" }}
      >
        {data.levels.map((lvl, index) => {
          if (codeCarryForwardLevels.includes(index + 1)) return null;
          const isFirstLevel = index === 0;
          const isLastLevel = index === data.levels.length - 1;
          let connectedLevelCount = 0;
          let isLevelConnectedToLastLevel = false;
          for (let i = index + 1; i <= data.levels.length; i++) {
            if (codeCarryForwardLevels.includes(i + 1)) {
              connectedLevelCount++;
              if (i + 1 === data.levels.length)
                isLevelConnectedToLastLevel = true;
            } else break;
          }
          return (
            <>
              {isFirstLevel ? (
                codeCarryForwardLevels.includes(index + 2) ? (
                  <div
                    style={
                      isLevelConnectedToLastLevel
                        ? {
                            paddingLeft: "0.5vw",
                            paddingRight: "0.5vw",
                          }
                        : { paddingLeft: "0.5vw" }
                    }
                  >
                    <button
                      key={`btn-${index}`}
                      className={`${currentLevel === index + 1 ? "active" : ""} ${assessmentLevels && ((statusStorage?.assessment_status === "completed" || assessmentLevels[index]?.status === "completed") ? "completed" : (assessmentLevels[index]?.status === "ongoing" && currentLevel === index + 1) ? "ongoing" : "incomplete")}`}
                      onClick={() => {
                        if (viewOnly) {
                          setPrevClicked(true);
                          setCurrentLevel(index + 1);
                        }
                      }}
                    >
                      {index + 1}
                    </button>
                    <img key={`img-${index}`} src={HorizontalBar} alt="horizontal-bar"/>
                    {Array.from({
                      length: connectedLevelCount,
                    }).map((_, i) => {
                      if (index + i === data.levels.length - 1) {
                        return (
                          <>
                            <img key={`img-${index}`} src={HorizontalBar} alt="horizontal-bar"/>
                            <button
                              key={`btn-${index}`}
                              className={`${currentLevel === index + 1 ? "active" : ""} ${assessmentLevels && ((statusStorage?.assessment_status === "completed" || assessmentLevels[index]?.status === "completed") ? "completed" : (assessmentLevels[index]?.status === "ongoing" && currentLevel === index + 1) ? "ongoing" : "incomplete")}`}
                              onClick={() => {
                                if (viewOnly) {
                                  setPrevClicked(true);
                                  setCurrentLevel(index + i + 2);
                                }
                              }}
                            >
                              {index + i + 2}
                            </button>
                          </>
                        );
                      }
                      return (
                        <>
                          <img key={`img-${index}`} src={HorizontalBar} alt="horizontal-bar"/>
                          <button
                            key={`btn-${index}`}
                            className={`${currentLevel === index + 1 ? "active" : ""} ${assessmentLevels && ((statusStorage?.assessment_status === "completed" || assessmentLevels[index]?.status === "completed") ? "completed" : (assessmentLevels[index]?.status === "ongoing" && currentLevel === index + 1) ? "ongoing" : "incomplete")}`}
                            onClick={() => {
                              if (viewOnly) {
                                setPrevClicked(true);
                                setCurrentLevel(index + i + 2);
                              }
                            }}
                          >
                            {index + i + 2}
                          </button>
                          <img key={`img-${index}`} src={HorizontalBar} alt="horizontal-bar"/>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    <button
                      key={`btn-${index}`}
                      className={`${currentLevel === index + 1 ? "active" : ""} ${assessmentLevels && ((statusStorage?.assessment_status === "completed" || assessmentLevels[index]?.status === "completed") ? "completed" : (assessmentLevels[index]?.status === "ongoing" && currentLevel === index + 1) ? "ongoing" : "incomplete")}`}
                      onClick={() => {
                        if (viewOnly) {
                          setPrevClicked(true);
                          setCurrentLevel(index + 1);
                        }
                      }}
                    >
                      {index + 1}
                    </button>
                    <img key={`img-${index}`} src={HorizontalBar} alt="horizontal-bar"/>
                  </>
                )
              ) : isLastLevel ? (
                <>
                  <img key={`img-${index}`} src={HorizontalBar} alt="horizontal-bar"/>
                  <button
                    key={`btn-${index}`}
                    className={`${currentLevel === index + 1 ? "active" : ""} ${assessmentLevels && ((statusStorage?.assessment_status === "completed" || assessmentLevels[index]?.status === "completed") ? "completed" : (assessmentLevels[index]?.status === "ongoing" && currentLevel === index + 1) ? "ongoing" : "incomplete")}`}
                    onClick={() => {
                      if (viewOnly) {
                        setPrevClicked(true);
                        setCurrentLevel(index + 1);
                      }
                    }}
                  >
                    {index + 1}
                  </button>
                </>
              ) : codeCarryForwardLevels.includes(index + 2) ? (
                <div
                  style={
                    isLevelConnectedToLastLevel ? { paddingRight: "0.5vw" } : {}
                  }
                >
                  {Array.from({
                    length: connectedLevelCount + 1,
                  }).map((_, i) => {
                    if (index + i === data.levels.length - 1) {
                      return (
                        <>
                          <img key={`img-${index}`} src={HorizontalBar} alt="horizontal-bar"/>
                          <button
                            key={`btn-${index}`}
                            className={`${currentLevel === index + 1 ? "active" : ""} ${assessmentLevels && ((statusStorage?.assessment_status === "completed" || assessmentLevels[index]?.status === "completed") ? "completed" : (assessmentLevels[index]?.status === "ongoing" && currentLevel === index + 1) ? "ongoing" : "incomplete")}`}
                            onClick={() => {
                              if (viewOnly) {
                                setPrevClicked(true);
                                setCurrentLevel(index + i + 1);
                              }
                            }}
                          >
                            {index + i + 1}
                          </button>
                        </>
                      );
                    }
                    return (
                      <>
                        <img key={`img-${index}`} src={HorizontalBar} alt="horizontal-bar"/>
                        <button
                          key={`btn-${index}`}
                          className={`${currentLevel === index + 1 ? "active" : ""} ${assessmentLevels && ((statusStorage?.assessment_status === "completed" || assessmentLevels[index]?.status === "completed") ? "completed" : (assessmentLevels[index]?.status === "ongoing" && currentLevel === index + 1) ? "ongoing" : "incomplete")}`}
                          onClick={() => {
                            if (viewOnly) {
                              setPrevClicked(true);
                              setCurrentLevel(index + i + 1);
                            }
                          }}
                        >
                          {index + i + 1}
                        </button>
                        <img key={`img-${index}`} src={HorizontalBar} alt="horizontal-bar"/>
                      </>
                    );
                  })}
                </div>
              ) : (
                <>
                  <img key={`img-${index}`} src={HorizontalBar} alt="horizontal-bar"/>
                  <button
                    key={`btn-${index}`}
                    className={`${currentLevel === index + 1 ? "active" : ""} ${assessmentLevels && ((statusStorage?.assessment_status === "completed" || assessmentLevels[index]?.status === "completed") ? "completed" : (assessmentLevels[index]?.status === "ongoing" && currentLevel === index + 1) ? "ongoing" : "incomplete")}`}
                    onClick={() => {
                      if (viewOnly) {
                        setPrevClicked(true);
                        setCurrentLevel(index + 1);
                      }
                    }}
                  >
                    {index + 1}
                  </button>
                  <img key={`img-${index}`} src={HorizontalBar} alt="horizontal-bar"/>
                </>
              )}
            </>
          );
        })}
      </div>
      <div
        className="col d-flex align-items-center"
        style={{ flex: "1", alignItems: "center" }}
      >
        {viewOnly &&
          (!teacher ||
            (teacher && (!teacherView || currentLevel !== data.numLevels))) && (
            <p
              className="assesment-viewonly-next m-0 ml-3"
              onClick={() => {
                if (currentLevel === data.numLevels) {
                  setPageIsRendered(true);
                  setShowFinishScreen(true);
                  setShowTestScreen(false);
                } else setCurrentLevel(currentLevel + 1);
              }}
            >
              Next &rarr;
            </p>
          )}
        {!viewOnly &&
          currentLevel !== data.numLevels &&
          props.statusStorage.assessment_levels[currentLevel - 1]?.status ===
            "completed" && (
            <button
              className="ml-auto"
              id="assesment-next-level"
              style={{ margin: 0 }}
              onClick={onwards}
            >
              {nextButtonText}
            </button>
          )}
        {!props.teacherView && viewOnly && (
          <div className="ml-auto assesment-status-indicator-holder">
            {(statusStorage?.assessment_levels[currentLevel - 1]?.type ===
              "game" &&
              statusStorage?.assessment_levels[currentLevel - 1].giveup !==
                1) || 
              (statusStorage?.assessment_levels[currentLevel - 1]?.type ===
                "python" &&
                statusStorage?.assessment_levels[currentLevel - 1].giveup !==
              1) ||
            (!statusStorage?.assessment_levels[
              currentLevel - 1
            ].hasOwnProperty("attempts") &&
              statusStorage?.assessment_levels[currentLevel - 1].result ===
                1) ? (
              <img
                className="assesment-status-indicator"
                src={AssesmentStatusSuccess}
                alt="success-img"
              />
            ) : (
              <img
                className="assesment-status-indicator"
                src={AssesmentStatusFail}
                alt="fail-img"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default LevelButtons;
