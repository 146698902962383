import React from "react";

import LetsWorkIcon from "../../Assets/Images/LetsWork.svg";
import ParentsIcon from "../../Assets/Images/Parents.svg";
import ProgressIcon from "../../Assets/Images/Progress.svg";
import PlaygroundIcon from "../../Assets/Images/Playground.svg";
import WorkInactiveBlobIcon from "../../Assets/Images/WorkInactiveBlobIcon.svg";
import ProgressActiveBlobIcon from "../../Assets/Images/ProgressActiveBlobIcon.svg";
import ParentsInactiveBlobIcon from "../../Assets/Images/ParentsInactiveBlobIcon.svg";
import PlaygroundInactiveBlobIcon from "../../Assets/Images/PlaygroundInactiveBlobIcon.svg";
import WorkActiveBlobIcon from "../../Assets/Images/WorkActiveBlobIcon.svg";
import ProgressInactiveBlobIcon from "../../Assets/Images/ProgressInactiveBlobIcon.svg";
import ParentsActiveBlobIcon from "../../Assets/Images/ParentsActiveBlobIcon.svg";
import PlaygroundActiveBlobIcon from "../../Assets/Images/PlaygroundActiveBlobIcon.svg";

const tabs = ["work", "progress", "community", "parents"];
const tabNames = ["Let's Work", "Progress", "Playground", "Parents"];
const tabIcons = [LetsWorkIcon, ProgressIcon, PlaygroundIcon, ParentsIcon];
const tabActiveBlobIcons = [
  WorkActiveBlobIcon,
  ProgressActiveBlobIcon,
  PlaygroundActiveBlobIcon,
  ParentsActiveBlobIcon,
];
const tabInactiveBlobIcons = [
  WorkInactiveBlobIcon,
  ProgressInactiveBlobIcon,
  PlaygroundInactiveBlobIcon,
  ParentsInactiveBlobIcon,
];

function SideNav(props) {
  const { changeTab, currentTab } = props;
  return (
    <nav className='side-nav'>
      {tabs.map((tab, index) => (
        <button
          onClick={() => changeTab(tab)}
          className={`${tab} ${currentTab === tab ? "selected" : ""}`}
        >
          <div className='d-flex align-items-center'>
            <div>
              <img
                src={tabIcons[index]}
                alt=''
                style={{
                  backgroundImage: `url('${
                    currentTab === tab
                      ? tabActiveBlobIcons[index]
                      : tabInactiveBlobIcons[index]
                  }')`,
                }}
              />
            </div>
            <p
              className={`${currentTab === tab ? "side-nav-tab-selected" : ""}`}
            >
              {tabNames[index]}
            </p>
          </div>
        </button>
      ))}
    </nav>
  );
}

export default SideNav;
