import React, { useEffect, useRef } from "react";
import { Typography } from "@mui/material";
import Bar from "../../../Assets/Images/Bar.svg";

function AssesmentProgress(props) {
  const {
    assesmentGameReportData,
    isAssesmentCompleted,
    assesmentPuzzleReportData,
    assesmentPythonReportData,
    assesmentScore,
  } = props;
  const renderedAssessmentFirstLevel = useRef(false);
  useEffect(() => {
    renderedAssessmentFirstLevel.current = false;
  },[props])

  return (
    <>
      <Typography variant='h6' component='h4' className='progress-header'>
        Assesment
      </Typography>
      <div id='assesment-tab'>
        {isAssesmentCompleted ? (
          <div>
            <div className='row assesment-header-row'>
              <div className='assesment-row-label assesment-header-row-label'></div>
              <div className='assesment-row-content assesment-empty'></div>
              <div className='assesment-row-content assesment-result'>
                Result/Attempts
              </div>
              <div className='assesment-row-content assesment-score'>Score</div>
            </div>
            {assesmentGameReportData.map((level, index) => {
              const firstElement = renderedAssessmentFirstLevel.current;
              renderedAssessmentFirstLevel.current = true;
              return (<>
                {firstElement && (
                  <div className='bar-container assesment-bar-container'>
                    <div className='vertical-bar'></div>
                  </div>
                )}
                <div
                  className={`row assesment-row ${
                    level.result === "Gave Up" ? "gave-up" : ""
                  }`}
                >
                  <div className='assesment-row-label'>Game {level.num}</div>
                  <div className='assesement-row-content assesment-empty'></div>
                  <div className='assesement-row-content assesment-result'>
                    {level.result === "Correct"
                      ? level.attempts
                      : "Needed help: " + level.attempts}
                  </div>
                  <div className='assesement-row-content assesment-score'>
                    {level.score + "/5"}
                  </div>
                </div>
              </>)
            })}
            {assesmentPythonReportData.map((level, index) => {
              const firstElement = renderedAssessmentFirstLevel.current;
              renderedAssessmentFirstLevel.current = true;
              return (<>
                {firstElement && (
                  <div className='bar-container assesment-bar-container'>
                    <div className='vertical-bar'></div>
                  </div>
                )}
                <div
                  className={`row assesment-row ${
                    level.result === "Gave Up" ? "gave-up" : ""
                  }`}
                >
                  <div className='assesment-row-label'>Python {level.num}</div>
                  <div className='assesement-row-content assesment-empty'></div>
                  <div className='assesement-row-content assesment-result'>
                    {level.result === "Correct"
                      ? level.attempts
                      : "Needed help: " + level.attempts}
                  </div>
                  <div className='assesement-row-content assesment-score'>
                    {level.score + "/5"}
                  </div>
                </div>
              </>)
            })}
            {assesmentPuzzleReportData.map((level, index) => {
              const firstElement = renderedAssessmentFirstLevel.current;
              renderedAssessmentFirstLevel.current = true;
              return (<>
                {firstElement && (
                  <div className='bar-container assesment-bar-container'>
                    <div className='vertical-bar'></div>
                  </div>
                )}
                <div
                  className={`row assesment-row ${
                    level.result === "Gave Up" ? "gave-up" : ""
                  }`}
                  style={{
                    backgroundColor:
                      level.result === "Incorrect"
                        ? "rgba(255, 140, 119, .7)"
                        : undefined,
                  }}
                >
                  <div className='assesment-row-label'>Puzzle {level.num}</div>
                  <div className='assesement-row-content assesment-empty'></div>
                  <div className='assesement-row-content assesment-result'>
                    {level.result === "Gave Up" ? "Needed help" : level.result}
                  </div>
                  <div className='assesement-row-content assesment-score'>
                    {level.score + "/1"}
                  </div>
                </div>
              </>)
            })}
            <div className='bar-container assesment-bar-container'>
              <div className='vertical-bar'></div>
            </div>
            <div className='row assesment-row assesment-total-row'>
              <div className='assesment-row-label assesment-total-row-label'>
                Total
              </div>
              <div className='assesement-row-content assesment-empty'></div>
              <div className='assesement-row-content assesment-result'></div>
              <div className='assesement-row-content assesment-score assesment-total-score'>
                {assesmentScore[0] + "/" + assesmentScore[1]}
              </div>
            </div>
          </div>
        ) : (
          <div id='assesment-not-done-tab'>
            <div className='assesment-notdone-view'>
              <p>Assesment Not Yet Completed</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AssesmentProgress;
