import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import BigArrowLeft from "../../../Assets/Images/BigArrowLeft.svg";
import BigArrowRight from "../../../Assets/Images/BigArrowRight.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

dayjs.extend(isBetweenPlugin);

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily:"rajdhani-medium",
  },
}));

const HoverWrapper = styled('div')({
  pointerEvents: 'auto', // Re-enable pointer events for this wrapper
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
  width: "14%",
  height: "78%",
});

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(
  ({
    theme,
    dayIsBetween,
    isFirstDay,
    isLastDay,
    today,
    isTodayInBetween,
    isTodayStartDay,
    isTodayEndDay,
  }) => ({
    pointerEvents: 'none',
    borderRadius: 0,
    position: "relative",
    ...(dayIsBetween && {
      backgroundColor: "rgba(255, 140, 119, 0.42) !important",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.dark,
      },
    }),
    ...(isFirstDay && {
      backgroundColor: "transparent !important",
      zIndex: 1,
      "&::before": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "75%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        height: "100%",
        backgroundColor: "rgba(255, 140, 119, 0.42) !important",
        zIndex: -1,
      },
      "&::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        height: "100%",
        zIndex: -1,
        borderRadius: "50%",
        backgroundColor: "#FF8C77 !important",
      },
    }),
    ...(isLastDay && {
      borderRadius: "0 50% 50% 0",
      backgroundColor: "transparent !important",
      zIndex: 1,
      "&::before": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "75%",
        transform: "translate(-150%, -50%)",
        width: "50%",
        height: "100%",
        backgroundColor: "rgba(255, 140, 119, 0.42) !important",
        zIndex: -1,
      },
      "&::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        height: "100%",
        zIndex: -1,
        borderRadius: "50%",
        backgroundColor: "#B30A74 !important",
      },
    }),
    ...(today && {
      color: "inherit !important",
      fontWeight: "400 !important",
      borderRadius: "0 50% 50% 0",
      backgroundColor: "transparent !important",
      border: "0 !important",
      zIndex: 1,
      ...(isTodayInBetween && {
        "&::before": {
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "101%",
          height: "100%",
          backgroundColor: "rgba(255, 140, 119, 0.42) !important",
          zIndex: -1,
          ...(isTodayStartDay
            ? {
                left: "75%",
                transform: "translate(-40%, -50%)",
                width: "50%",
              }
            : isTodayEndDay && {
                left: "75%",
                transform: "translate(-150%, -50%)",
                width: "50%",
              }),
        },
        "&::after": {
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          height: "100%",
          zIndex: -1,
          borderRadius: "50%",
          ...(isTodayStartDay
            ? {
                backgroundColor: "#FF8C77 !important",
              }
            : isTodayEndDay
            ? {
                backgroundColor: "#B30A74 !important",
              }
            : { backgroundColor: "white !important" }),
        },
      }),
    }),
  })
);

export default function ModuleCalender(props) {
  const { assignment } = props;
  const [value, setValue] = useState(dayjs(new Date()));
  const [assignedDate, setAssignedDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
  if(assignment){  
    setValue(dayjs(assignment[0], "DD/MM/YYYY"));
    let startDate = dayjs(assignment[0], "DD/MM/YYYY")
    let completeDate = dayjs(assignment[1], "DD/MM/YYYY")
    setAssignedDate(startDate);
    setEndDate(completeDate);}
  }, [assignment]);

  useEffect(() => {
    if (assignedDate && endDate) {
      handleDateChange(assignedDate, endDate);
      handleArrowChange();
    }
  }, [assignedDate, endDate]);

  const handleDateChange = (assignedDate, endDate) => {
    assignedDate = assignedDate.format("MMMM D, YYYY");
    endDate = endDate.format("MMMM D, YYYY");
    const newDiv = document.createElement("div");
    newDiv.className = "module-session-details-container";
    const calenderHeader = `
    <div class="module-session-details" >
      <span>Assigned: ${assignedDate}</span>
      <span>Complete by: ${endDate}</span>
    </div>`;
    newDiv.innerHTML = calenderHeader;
    const header = document.getElementsByClassName(
      "MuiPickersCalendarHeader-labelContainer"
    )[0];
    const moduleSessionDetails = header.querySelector(
      ".module-session-details-container"
    );
    if (moduleSessionDetails) {
      moduleSessionDetails.remove();
    }
    if (header) header.appendChild(newDiv);
  };

  const handleArrowChange = () => {
    const BigArrowLeftEle = document.createElement("div");
    BigArrowLeftEle.className = "big-arrow-left-ele";
    const BigArrowRightEle = document.createElement("div");
    BigArrowRightEle.className = "big-arrow-right-ele";
    BigArrowLeftEle.innerHTML = `<img
    class='story-nav-arrow-left'
    src=${BigArrowLeft}
  />`;
    BigArrowRightEle.innerHTML = `<img
      className='story-nav-arrow-right'
      src=${BigArrowRight}
      alt=''
    />`;
    const switchButtons = document.getElementsByClassName(
      "MuiPickersArrowSwitcher-button"
    );
    const isBigArrowLeftEle = switchButtons[0].querySelector(
      ".big-arrow-left-ele"
    );
    const isBigArrowRightEle = switchButtons[1].querySelector(
      ".big-arrow-right-ele"
    );
    if (isBigArrowLeftEle) isBigArrowLeftEle.remove();
    if (isBigArrowRightEle) isBigArrowRightEle.remove();
    switchButtons[0].appendChild(BigArrowLeftEle);
    switchButtons[1].appendChild(BigArrowRightEle);
  };

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }
    const start = assignedDate
    const end = endDate
    let today = dayjs();

    const dayIsBetween = date.isBetween(start, end, null, "[]");
    const isFirstDay = date.isSame(start, "day");
    const isLastDay = date.isSame(end, "day");
    const isToday = date.isSame(today, "day");
    const isTodayStartDay = today.isSame(start, "day");
    const isTodayEndDay = today.isSame(end, "day");
    let isTodayInBetween = false;
    if (start <= today && today <= end) {
      isTodayInBetween = true;
    }

    const tooltipTitle = isFirstDay ? "Assigned on" : isLastDay ? "Complete by" : "";
  
    return (
      <BootstrapTooltip title={tooltipTitle} disableHoverListener={!tooltipTitle}>
        <HoverWrapper>
          <CustomPickersDay
            {...pickersDayProps}
            disableMargin
            dayIsBetween={dayIsBetween}
            isFirstDay={isFirstDay}
            isLastDay={isLastDay}
            today={isToday}
            isTodayInBetween={isTodayInBetween}
            isTodayStartDay={isTodayStartDay}
            isTodayEndDay={isTodayEndDay}
          />
        </HoverWrapper>
      </BootstrapTooltip>
    );
  };

  return (
    assignedDate &&
    endDate && (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          displayStaticWrapperAs='desktop'
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderDay={renderWeekPickerDay}
          renderInput={(params) => <TextField {...params} />}
          inputFormat="'Week of' MMM d"
          minDate={assignedDate}
          maxDate={endDate}
          view="day"
        />
      </LocalizationProvider>
    )
  );
}
