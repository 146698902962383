import React, {
  useContext
} from 'react';
import AvatarGrade2 from '../../Assets/Images/AvatarGrade2.svg';
import AvatarGrade3 from '../../Assets/Images/AvatarGrade3.svg';
import AvatarGrade4 from '../../Assets/Images/AvatarGrade4.svg';
import AvatarGrade5 from '../../Assets/Images/AvatarGrade5.svg';
import AvatarGrade6 from '../../Assets/Images/AvatarGrade6.svg';
import AvatarGrade7 from '../../Assets/Images/AvatarGrade7.svg';
import AvatarGrade8 from '../../Assets/Images/AvatarGrade8.svg';
import CheetiLeft from '../../Assets/Images/CheetiLeft.svg';
import { authUserContext } from '../Contexts/AuthUser';
import '../../Styles/Global.css';
import './SecondaryNav.css';

const avatarGradeMapper = {
  1: AvatarGrade2,
  2: AvatarGrade2,
  3: AvatarGrade3,
  4: AvatarGrade4,
  5: AvatarGrade5,
  6: AvatarGrade6,
  7: AvatarGrade7,
  8: AvatarGrade8,
};
export default function SecondaryNav(props) {
  const {user} = useContext(authUserContext);
  return <div className="secondary-nav">
  <div className="row personal-info">
    <div className="col-9 d-flex align-items-center">
      <div>
        <div className="outer-cover">
          <div className="avatar-circle-main" style={[1, 2, 3].includes(props.grade) ? { backgroundColor: 'rgba(156, 215, 15, 0.4)' } : { backgroundColor: 'rgba(218, 215, 15, 0.4)' }}>
            <img src={avatarGradeMapper[props.grade]} alt="" />
          </div>
        </div>
      </div>
      <div style={{paddingLeft:'1vw'}}>
        <p id="student-fullname">{user.fullname}</p>
        <p className="student-grade">Grade {props?.grade + " " +props?.section } | {props?.teacherName}</p>
      </div>
    </div>
  </div>
</div>;
}
