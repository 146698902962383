import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog, DialogTitle, IconButton, styled,
} from '@mui/material/';
import PropTypes from 'prop-types';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    fontFamily: 'rajdhani-medium',
    border: '3px solid #25A596',
    borderRadius: '4vw',
    maxWidth: '60vw',
    width: '60vw',
    height: '70vh',
    position: 'absolute',
    top: 0,
    backgroundColor: 'rgba(255,255,255,0.85)',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  '& 	.MuiIconButton-root': {
    padding: 0,
  },
  '& 	.MuiSvgIcon-root': {
    height: '1.5vw',
    width: '1.5vw',
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          size="small"
          aria-label="close"
          onClick={onClose}
          sx={{
            height: '0.5vw',
            poadding: 0,
            position: 'absolute',
            right: '1vw',
            top: '1.5vw',
            color: '#343a40',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function PlayVideo(props) {
  const { videoUrl, setHelpVideoPopup, helpVideoPopup } = props;

  function handleHelpPopupClose() {
    setHelpVideoPopup(false);
  }
  const handleVideoLoaded = () => {
    const video = document.getElementById('game-help-video');
    video.play();
  };
  
  return <BootstrapDialog
    onClose={handleHelpPopupClose}
    aria-labelledby="customized-dialog-title"
    open={helpVideoPopup}
  >
    <BootstrapDialogTitle
      id="customized-dialog-title"
      onClose={handleHelpPopupClose}
    />
    <div className="play-video-help">
      <video
        id="game-help-video"
        width="100%"
        controls
        onLoadedMetadata={handleVideoLoaded}
        style={{ borderRadius: '1vw' }}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  </BootstrapDialog>;
}

export default PlayVideo;
